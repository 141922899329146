import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { SimpleModalComponent, SimpleModalService } from '@looorent/ngx-simple-modal';
import { AbstractLogger } from '@mobilejazz/harmony-core';

import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { WaitModalService } from '@app/shared/services/wait-modal.service';
import { CallFeedbackData, PutCallFeedbackInteractor } from '@together/common';

export interface CallFeedbackModal {
    callId: string;
}

@Component({
    selector: 'app-call-feedback-modal',
    templateUrl: './call-feedback-modal.component.html',
    styleUrls: ['./call-feedback-modal.component.scss'],
})
export class CallFeedbackModalComponent extends SimpleModalComponent<CallFeedbackModal, void> {
    callId: string;
    isLoading = false;

    callFeedbackForm = this.fb.group({
        activitiesRating: [0, []],
        feedback: ['', []],
        videoQualityRating: [0, []],
    });

    constructor(
        protected fb: UntypedFormBuilder,
        protected logger: AbstractLogger,
        protected putCallFeedback: PutCallFeedbackInteractor,
        protected modalService: SimpleModalService,
        protected waitService: WaitModalService,
    ) {
        super();
    }

    async onSubmit() {
        const data: CallFeedbackData = {
            ...this.callFeedbackForm.value,
            callId: this.callId,
        };

        this.logger.info('CallFeedbackModal', 'Submit');
        this.isLoading = true;
        this.waitService.show();

        try {
            await this.putCallFeedback.execute(data);

            this.close();
            this.modalService.addModal(AlertModalComponent, {
                title: 'Thanks!',
                message: [
                    `We appreciate your feedback.`,
                    ` If you have other comments or suggestions contact us at support@togethervideoapp.com`,
                ].join(' '),
                type: 'success',
                icon: 'checked-round',
            });
        } catch (err) {
            this.logger.error('CallFeedbackModal', err.toString());
            this.modalService.addModal(AlertModalComponent, {
                title: 'Oops!',
                message: err.message ? err.message : 'There was an error while sending the feedback, please try again.',
                type: 'error',
                icon: 'error',
            });
        } finally {
            this.isLoading = false;
            this.waitService.hide();
        }
    }

    setActivitiesRating(rating: number): void {
        this.callFeedbackForm.controls.activitiesRating.setValue(rating);
    }

    setVideoQualityRating(rating: number): void {
        this.callFeedbackForm.controls.videoQualityRating.setValue(rating);
    }
}
