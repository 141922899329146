import { Subject } from 'rxjs';
import { interpret } from 'xstate';
import { Base64 } from 'js-base64';

import {
    IframeActivityInterpreter,
    IframeActivityMachine,
    IframeActivityState,
} from '../machines/iframe-activity.machine';
import {
    IActivity,
    ActivityOutgoingEvent,
    CallMetadata,
    ActivityEvent,
    ActivityModel,
    ActivityEventDeliveryType,
    APP_VERSION_COMPATIBILITY,
} from '@together/common';
import { Device, DeviceInfo } from '@capacitor/device';

export const MIN_ANDROID_VERSION_FOR_FULL_URL = 11.0;

export class IframeActivity implements IActivity {
    id: string;
    event$: Subject<ActivityOutgoingEvent> = new Subject();

    protected activity: ActivityModel;
    protected machine: IframeActivityInterpreter;
    protected deviceInfo: DeviceInfo;

    constructor(protected iframe: HTMLIFrameElement, protected metadata: CallMetadata) {
        window.addEventListener('message', message => {
            const { type, payload } = message.data;
            this.event$.next({ type, payload });
        });
    }

    close() {
        this.iframe.src = 'about:blank';
    }

    getActivity(): ActivityModel {
        return this.activity;
    }

    getState(): IframeActivityState | null {
        if (this.machine) {
            return this.machine.state;
        }

        return null;
    }

    isFavorite(): boolean {
        return this.metadata.localParticipant.isFavoriteActivity(this.id);
    }

    getLocalParticipant() {
        return this.metadata.localParticipant;
    }

    handleEvent(event: ActivityEvent) {
        switch (event.name) {
            case 'activityEvent':
                const payload = JSON.parse(Base64.decode(event.content));
                this.iframe.contentWindow.postMessage(payload, '*');
                break;

            default:
                throw new Error(`Unknown event type: ${event.name}`);
        }
    }

    async init(activity: ActivityModel) {
        this.deviceInfo = await Device.getInfo();
        const params = {
            callType: this.determineEventDeliveryType(activity),
            callId: this.metadata.callId,
            userId: this.metadata.localParticipant.id,
            mode: this.metadata.activityMode,
            localUserId: this.metadata.localParticipant.id,
            localDisplayName: this.metadata.localParticipant.displayName,
            localAvatar: this.metadata.localParticipant.photoURL,
            remoteUserId: this.metadata.remoteParticipant.id,
            remoteDisplayName: this.metadata.remoteParticipant.displayName,
            remoteAvatar: this.metadata.remoteParticipant.photoURL,
        };

        const getParams = Object.entries(params)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        let activityURL = `${activity.activityURL}?${getParams}`;

        if (this.shouldUseActivityLightUrl(activity)) {
            activityURL = `${activity.activityLightURL}?${getParams}`;
        }

        this.activity = activity;
        this.machine = interpret(IframeActivityMachine).start();
        this.id = activity.id;
        this.iframe.src = activityURL;
    }

    localReady() {
        this.machine.send('LOCAL_READY');
    }

    remoteReady() {
        this.machine.send('REMOTE_READY');
    }

    private shouldUseActivityLightUrl(activity: ActivityModel) {
        const currentOsVersion = parseInt(this.deviceInfo.osVersion);
        if (
            this.deviceInfo.platform === 'android' &&
            activity.activityLightURL &&
            currentOsVersion < MIN_ANDROID_VERSION_FOR_FULL_URL
        ) {
            return true;
        }
        return false;
    }

    private determineEventDeliveryType(activity: ActivityModel): ActivityEventDeliveryType {
        if (!activity) {
            return ActivityEventDeliveryType.IFrame;
        }

        const eventDeliveryType = activity.eventDeliveryType;
        if (!eventDeliveryType) {
            return ActivityEventDeliveryType.IFrame;
        }

        if (eventDeliveryType === ActivityEventDeliveryType.WebSocket) {
            const remoteBuildNumber = this.metadata.remoteParticipant?.currentDevice?.appBuildNumber;
            if (remoteBuildNumber && remoteBuildNumber >= APP_VERSION_COMPATIBILITY.SOCKET_ENABLEMENT) {
                // TODO: verify the build number before it goes to production.
                // Starting v2.X (Build number 8000+) we are enabling the option to use Socket event delivery
                return ActivityEventDeliveryType.WebSocket;
            }
        }

        return ActivityEventDeliveryType.IFrame;
    }
}
