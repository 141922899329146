<div class="modal-clean modal-clean--wide modal-activity --action-sheet">
    <div class="modal-clean__content">
        <div class="modal-clean__header">
            <div class="modal__close" (click)="onActionClose()"></div>
            <div class="modal__title">{{ fieldConfig.title }}</div>
            <div class="modal__clear-action" (click)="clear()">
                <span class="modal__clear-action--label">Clear</span>
            </div>
        </div>
        <div class="modal-dropdown">
            <form [formGroup]="form" (ngSubmit)="apply()">
                <ng-container *ngIf="fieldConfig?.multiple">
                    <section
                        class="checkbox-item"
                        *ngFor="let option of fieldConfig.options; let i = index"
                        [formArrayName]="fieldConfig.id">
                        <mat-checkbox
                            [value]="option.value"
                            [formControlName]="i"
                            [disabled]="fieldConfig.multiple && isOptionDisabled(fieldConfig, option.value)">
                            {{ option.label }}
                        </mat-checkbox>
                    </section>
                </ng-container>
                <ng-container *ngIf="!fieldConfig?.multiple">
                    <mat-radio-group name="options" [formControlName]="fieldConfig.id">
                        <mat-radio-button
                            *ngFor="let option of fieldConfig.options; let i = index"
                            name="option"
                            [value]="option.value"
                            >{{ option.label }}</mat-radio-button
                        >
                    </mat-radio-group>
                </ng-container>
                <div class="action-container">
                    <button class="button-v2 --small --purple --rounded">Apply</button>
                </div>
            </form>
        </div>
    </div>
</div>
