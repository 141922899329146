import { Component, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss'],
})
export class RatingComponent {
    public rating = -1;

    @Output() readonly rated: EventEmitter<number> = new EventEmitter<number>();

    isRated(value: number): boolean {
        return this.rating >= value;
    }

    setRating(value: number): void {
        this.rating = value;
        this.rated.emit(value);
    }
}
