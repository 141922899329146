import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@env/environment';

import 'hammerjs';

// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/remote-config';
import 'firebase/storage';
import mixpanel from 'mixpanel-browser';

if (environment.production) {
    enableProdMode();
}

// Init Firebase
firebase.initializeApp(environment.firebaseConfig);

if (environment.firebaseConfig.measurementId && environment.firebaseConfig.measurementId !== '') {
    firebase.analytics();
}

// Trying to debug Firestore permission issues
// See: https://github.com/firebase/firebase-js-sdk/issues/2135#issuecomment-527509148
firebase.firestore.setLogLevel('error');
firebase.firestore().enablePersistence({
    synchronizeTabs: true,
});

//Init Mixpanel
mixpanel.init(environment.mixpanelConfig.token, { debug: !environment.production });

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.log(err));
