import { ActionType } from '@app/web/modals/alert-modal/alert-modal.component';
import { BookPurchaseTransactionType } from '@app/shared/services/store/base-store.service';
import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SimpleModalComponent, SimpleModalService } from '@looorent/ngx-simple-modal';
import { DateTime, Interval } from 'luxon';
import { BookProduct, IStoreService } from '@app/shared/services/store/store-service.interface';
import { WaitModalService } from '@app/shared/services/wait-modal.service';
import { AlertModalComponent } from '@app/web/modals/alert-modal/alert-modal.component';
import {
    BookModel,
    DeleteUserBookInteractor,
    UserModel,
    UserService,
    PurchaseModel,
    GetBookCategoriesInteractor,
    BookCategoryModel,
    GetMyBooksInteractor,
} from '@together/common';
import { WebviewModalComponent } from '../webview-modal/webview-modal.component';
import { environment } from '@env/environment';

export interface BookInfoModal {
    book: BookModel;
    purchase: PurchaseModel;
    isLoggedIn: boolean;
    allowPurchase: boolean;
}

@Component({
    selector: 'app-book-info-modal',
    templateUrl: './book-info-modal.component.html',
    styleUrls: ['./book-info-modal.component.scss'],
})
export class BookInfoModalComponent extends SimpleModalComponent<BookInfoModal, boolean> implements OnInit {
    // Modal Inputs
    public book: BookModel;
    public purchase: PurchaseModel;
    public isLoggedIn = false;
    public allowPurchase = false;

    public bookRentalProducts: BookProduct[];
    public bookSaleProducts: BookProduct[];
    public canDeleteBook = false;
    public isFav = false;

    private currentUser: UserModel;
    private categories: BookCategoryModel[];

    constructor(
        private readonly deleteBook: DeleteUserBookInteractor,
        private readonly modalService: SimpleModalService,
        private readonly sanitizer: DomSanitizer,
        private readonly userService: UserService,
        private readonly waitService: WaitModalService,
        private getCategories: GetBookCategoriesInteractor,
        private getMyBooks: GetMyBooksInteractor,
        @Inject('StoreService') private readonly storeService: IStoreService,
    ) {
        super();
    }

    public async ngOnInit(): Promise<void> {
        if (this.isLoggedIn) {
            [this.currentUser, this.bookRentalProducts, this.bookSaleProducts] = await Promise.all([
                this.userService.getUser(),
                this.storeService.getBookRentalProducts(),
                this.storeService.getBookSaleProducts(),
            ]);
        }
        this.categories = await this.getCategories.execute();

        this.canDeleteBook = this.isLoggedIn && this.currentUser.id === this.book.ownerId;
        this.isFav = this.isLoggedIn && this.currentUser.isFavoriteBook(this.book.id);
    }

    public getLink(): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(this.book.externalLink);
    }

    public async onDelete(): Promise<void> {
        const removeBook = await this.modalService
            .addModal(AlertModalComponent, {
                type: 'error',
                icon: 'question',
                title: `Delete Book`,
                message: `Are you sure you want to remove "${this.book.title}" from your book list?`,
                primaryActionLabel: 'Ok',
                secondaryActionLabel: 'Cancel',
            })
            .toPromise();

        if (removeBook === ActionType.Primary) {
            try {
                this.waitService.show();

                await this.deleteBook.execute(this.currentUser, this.book);
                await this.getMyBooks.execute(true);
                this.result = true;
                this.close();
            } catch (err) {
                this.modalService.addModal(AlertModalComponent, {
                    title: 'Oops!',
                    message: `We couldn't delete this book. Please try again.`,
                    type: 'error',
                    icon: 'error',
                });
            } finally {
                this.waitService.hide();
            }
        }
    }

    public getBookSaleProduct(): BookProduct {
        if (!this.bookSaleProducts) {
            return;
        }

        return this.bookSaleProducts.find(tier => tier.tierId === this.book.salePriceTier);
    }

    public getBookRentProduct(): BookProduct {
        if (!this.bookRentalProducts) {
            return;
        }

        return this.bookRentalProducts.find(tier => tier.tierId === this.book.rentalPriceTier);
    }

    public showMoreInfo(): void {
        this.modalService.addModal(WebviewModalComponent, {
            title: '',
            url: environment.purchaseFaqsURL,
        });
    }

    public async purchaseBook(): Promise<void> {
        try {
            this.waitService.show();
            const result = await this.storeService.startBookPurchase(this.book.id, BookPurchaseTransactionType.Sale);
            if (result && (await this.storeService.purchaseBook(this.getBookSaleProduct()))) {
                // this will be invoked only for native store.
                // for stripe redirect happens so this will not be executed. hence handled in result-handler by sending this.springboard.bookPurchased event
                this.close();
                this.storeService.showBuyBookSuccessModal(this.book);
            }
        } finally {
            this.waitService.hide();
        }
    }

    public async rentBook(): Promise<void> {
        try {
            this.waitService.show();
            const result = await this.storeService.startBookPurchase(this.book.id, BookPurchaseTransactionType.Rent);
            if (result && (await this.storeService.rentBook(this.getBookRentProduct(), this.book))) {
                // this will be invoked only for native store.
                // for stripe redirect happens so this will not be executed. hence handled in result-handler by sending this.springboard.bookPurchased event
                this.close();
                this.storeService.showRentBookSuccessModal(this.book);
            }
        } finally {
            this.waitService.hide();
        }
    }

    public getRentalDaysLeft(): string {
        if (this.book?.purchaseDetails?.rentalStartDate) {
            const rentalEndDate = DateTime.fromJSDate(this.book.purchaseDetails.rentalStartDate).plus({
                days: this.book.purchaseDetails.rentalDuration,
            });
            const i = Interval.fromDateTimes(DateTime.now(), rentalEndDate);
            return i.length('days').toFixed(0);
        }
        return null;
    }

    public getRentalExpiredDate(): Date {
        if (this.book?.purchaseDetails?.rentalStartDate) {
            const rentalEndDate = DateTime.fromJSDate(this.book.purchaseDetails.rentalStartDate).plus({
                days: this.book.purchaseDetails.rentalDuration,
            });
            return rentalEndDate.toJSDate();
        }
        return null;
    }

    public getCategoryLabel(id) {
        return this.categories?.find(c => c.id === id)?.labels?.en;
    }
}
