import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { SimpleModalComponent, SimpleModalService } from '@looorent/ngx-simple-modal';
import { AbstractLogger } from '@mobilejazz/harmony-core';

import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { WaitModalService } from '@app/shared/services/wait-modal.service';
import { PutFeedbackInteractor } from '@together/common';

@Component({
    selector: 'app-feedback-modal',
    templateUrl: './feedback-modal.component.html',
    styleUrls: ['./feedback-modal.component.scss'],
})
export class FeedbackModalComponent extends SimpleModalComponent<void, void> {
    isLoading = false;

    feedbackForm = this.fb.group({
        rating: [0, []],
        message: ['', []],
    });

    constructor(
        protected fb: UntypedFormBuilder,
        protected logger: AbstractLogger,
        protected putFeedback: PutFeedbackInteractor,
        protected modalService: SimpleModalService,
        protected waitService: WaitModalService,
    ) {
        super();
    }

    async onSubmit() {
        const data = this.feedbackForm.value;

        this.logger.info('FeedbackModal', 'Submit');
        this.isLoading = true;
        this.waitService.show();

        try {
            await this.putFeedback.execute(data.rating, data.message);

            this.close();
            this.modalService.addModal(AlertModalComponent, {
                title: 'Thanks!',
                message: [
                    `We really appreciate your feedback.`,
                    `If you have other comments or suggestions don't hesitate to contact us: support@togethervideoapp.com`,
                ].join(' '),
                type: 'success',
                icon: 'checked-round',
            });
        } catch (err) {
            this.logger.error('FeedbackModal', err.toString());
            this.modalService.addModal(AlertModalComponent, {
                title: 'Oops!',
                message: err.message ? err.message : 'There was an error while sending feedback, please try again.',
                type: 'error',
                icon: 'error',
            });
        } finally {
            this.isLoading = false;
            this.waitService.hide();
        }
    }

    setRating(rating): void {
        this.feedbackForm.controls.rating.setValue(rating);
    }
}
