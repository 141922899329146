<div class="modal-clean modal-clean--wide --centered modal-invite-accept">
    <div class="modal-clean__content">
        <div class="modal-clean__header">
            <div class="modal__title">Invite received</div>
            <div class="modal__close" (click)="close()"></div>
        </div>
        <div class="modal-invite__container">
            <p>
                <strong>{{ invite.creator.displayName }}</strong> would like to connect. <br />If you accept they will
                be able to call you and you will be able to call them.
            </p>
            <p>Invite from:</p>
            <div class="modal-invite-accept__user-details">
                <div class="modal-invite-accept__user-detail-row">{{ invite.creator.displayName }}</div>
                <div class="modal-invite-accept__user-detail-row">{{ invite.creator.email }}</div>
            </div>
            <div class="modal-invite-accept__action">
                <button class="button-v2 --purple --large --rounded --wide" (click)="accept()">Accept</button>
                <div class="modal-invite-accept__deny" (click)="deny()">Deny</div>
            </div>
        </div>
    </div>
</div>
