import { Machine, assign, State } from 'xstate';
import { Actor } from 'xstate/lib/Actor';

import { CallMetadata, DeviceModel } from '@together/common';
import { CallContext, CallEvent } from './call.machine';

export interface CallRouterStateSchema {
    states: {
        idle: {
            states: {
                issue: {};
                wait: {};
            };
        };
        permissions_prompt: {};
        permissions_error: {};
        feedback: {};
        busy: {
            states: {
                calling: {};
                ringing: {
                    states: {
                        dialog: {};
                        connecting: {};
                    };
                };
                waiting: {
                    states: {
                        connecting: {
                            states: {
                                stream: {};
                                call: {};
                            };
                        };
                        dialog: {};
                    };
                };
            };
        };
    };
}

export interface PointScoredEvent {
    type: 'CALL.POINT_SCORED';
    activityCapture: Blob;
    localCapture: Promise<Blob>;
    remoteCapture: Promise<Blob>;
}

export interface StartCallEvent {
    type: 'START_CALL';
    metadata: CallMetadata;
}

export interface CallAcceptedEvent {
    type: 'CALL_STATUS_ACCEPTED';
    remoteParticipantDevice: DeviceModel;
}

export type CallRouterEvent =
    | { type: 'ACCEPT' }
    | { type: 'CALL.CONNECTED' }
    | { type: 'CALL.DISCONNECTED' }
    | PointScoredEvent
    | { type: 'CALL.REMOTE_DROPPED' }
    | CallAcceptedEvent
    | { type: 'CALL_STATUS_CANCELLED' }
    | { type: 'CALL_STATUS_COMPLETED' }
    | { type: 'CALL_STATUS_DENIED' }
    | { type: 'CALL_STATUS_ERRORED' }
    | { type: 'CALL_STATUS_MISSED' }
    | { type: 'CALL_STATUS_USERBUSY' }
    | { type: 'CANCEL' }
    | { type: 'DENY' }
    | { type: 'DISMISS' }
    | { type: 'HUNG_UP' }
    | { type: 'HUNG_UP_FREE_CALL' }
    | { type: 'INCOMING_CALL' }
    | { type: 'ISSUE' }
    | { type: 'OUTGOING_CALL' }
    | { type: 'PERMISSIONS_DENIED' }
    | { type: 'START_CAMERA_FAILURE' }
    | StartCallEvent;

export enum CallRouterIssueCode {
    Cancelled = 'CALL_CANCELLED',
    ConnectVideoStream = 'CONNECT_VIDEO_STREAM',
    CreateCall = 'CREATE_CALL',
    Denied = 'CALL_DENIED',
    Error = 'CALL_ERROR',
    Dropped = 'CALL_DROPPED',
    Missed = 'CALL_MISSED',
    NoQuota = 'NO_QUOTA',
    UserUnavailable = 'USER_UNAVAILABLE',
    UserBusy = 'USER_BUSY',
}

export interface CallRouterIssue {
    code: CallRouterIssueCode;
    message: string;
    title?: string;
    type?: 'default' | 'success' | 'warning' | 'error';
    icon?: string;
}

export interface CallRouterContext {
    call: Actor<CallContext, CallEvent>;
    callEndTime: number;
    callStartTime: number;
    interactionCount: number;
    issue: CallRouterIssue;
    metadata: CallMetadata;
}

export type CallRouterState = State<CallRouterContext, CallRouterEvent>;

export const CallRouterMachine = Machine<CallRouterContext, CallRouterStateSchema, CallRouterEvent>(
    {
        id: 'router',
        initial: 'idle',
        context: {
            call: null,
            callEndTime: null,
            callStartTime: null,
            interactionCount: 0,
            issue: {
                code: null,
                message: null,
            },
            metadata: null,
        },
        states: {
            idle: {
                initial: 'wait',
                exit: assign({
                    interactionCount: () => 0,
                    issue: () => ({ code: null, message: null }),
                }),
                invoke: {
                    src: 'incomingCall$',
                },
                on: {
                    START_CALL: {
                        target: 'permissions_prompt',
                        actions: [
                            assign({
                                metadata: (_, event: StartCallEvent) => event.metadata,
                            }),
                        ],
                    },
                },
                states: {
                    wait: {},
                    issue: {
                        on: {
                            DISMISS: 'wait',
                        },
                    },
                },
            },
            permissions_prompt: {
                invoke: [{ src: 'showPermissionsPrompt' }],
                on: {
                    INCOMING_CALL: 'busy.ringing',
                    OUTGOING_CALL: 'busy.waiting',
                },
            },
            permissions_error: {
                entry: ['errorCall', 'showPermissionsError'],
                on: {
                    '': 'idle',
                },
            },
            feedback: {
                entry: 'showFeedback',
                on: {
                    '': 'idle',
                },
            },
            busy: {
                entry: ['clearCallHighlights', 'clearPhotoboothImages'],
                exit: 'hangUpCall',
                invoke: [{ src: 'rejectIncomingCalls' }],
                on: {
                    PERMISSIONS_DENIED: 'permissions_error',
                    START_CAMERA_FAILURE: {
                        target: 'idle.issue',
                        actions: assign({
                            issue: context =>
                                ({
                                    code: 'CALL_ERROR',
                                    title: 'Unable to start camera',
                                    icon: 'call-ended',
                                    type: 'error',
                                    message: `An error occurred while starting the camera. Please close any other apps using the camera and try again.`,
                                } as CallRouterIssue),
                        }),
                    },
                    ISSUE: {
                        target: 'idle.issue',
                        actions: assign({
                            issue: (_, event: any) => ({
                                code: 'UNKNOWN',
                                message: `An unknown error occurred. Sorry for the inconvenience.`,
                                ...event.data,
                            }),
                        }),
                    },
                    CALL_STATUS_USERBUSY: {
                        target: '#router.idle.issue',
                        actions: assign({
                            issue: context =>
                                ({
                                    code: 'USER_BUSY',
                                    title: 'Call Ended',
                                    icon: 'call-ended',
                                    type: 'warning',
                                    message: `${context.metadata.remoteParticipant.displayName} is busy at the moment. Try later.`,
                                } as CallRouterIssue),
                        }),
                    },
                },
                states: {
                    calling: {
                        entry: 'assignCallTimes',
                        exit: 'increaseUserCompletedCalls',
                        invoke: [
                            { src: 'callStatusChange$' },
                            { src: 'endCallTimeout' },
                            { src: 'showCallEndWarningTimeout' },
                            { src: 'updateCallDurationStat' },
                            { src: 'checkVonageCompatibility' },
                        ],
                        on: {
                            HUNG_UP: {
                                target: '#router.feedback',
                                actions: 'completeCall',
                            },
                            HUNG_UP_FREE_CALL: {
                                target: '#router.feedback',
                                actions: ['completeCall', 'showFreeCallEndedWarning'],
                            },
                            CALL_STATUS_COMPLETED: '#router.feedback',
                            CALL_STATUS_ERRORED: {
                                target: '#router.idle.issue',
                                actions: assign({
                                    issue: context =>
                                        ({
                                            code: 'CALL_ERROR',
                                            message: `There was an error and the call has ended.`,
                                        } as CallRouterIssue),
                                }),
                            },
                            // TODO: For now dropping a call equals to finish the call, iPad just
                            // quits the call without updating the Call status, in the future
                            // Call status should be the source of truth
                            'CALL.DISCONNECTED': '#router.feedback',
                            'CALL.REMOTE_DROPPED': {
                                target: '#router.idle.issue',
                                actions: assign({
                                    issue: context =>
                                        ({
                                            code: 'CALL_DROPPED',
                                            message: `There was a connection issue and the call has ended.`,
                                        } as CallRouterIssue),
                                }),
                            },
                            'CALL.POINT_SCORED': {
                                target: '.',
                                actions: ['increaseInteractionCount', 'storeHighlight'],
                            },
                        },
                    },
                    ringing: {
                        initial: 'dialog',
                        entry: 'ringCall',
                        invoke: {
                            src: 'callStatusChange$',
                        },
                        on: {
                            CALL_STATUS_CANCELLED: {
                                target: '#router.idle.issue',
                                actions: assign({
                                    issue: context =>
                                        ({
                                            code: 'CALL_CANCELLED',
                                            title: 'Call Ended',
                                            icon: 'call-ended',
                                            type: 'warning',
                                            message: `${context.metadata.remoteParticipant.displayName} is busy at the moment. Try later.`,
                                        } as CallRouterIssue),
                                }),
                            },
                            CALL_STATUS_MISSED: {
                                target: '#router.idle',
                            },
                        },
                        states: {
                            dialog: {
                                entry: 'ringingSoundPlay',
                                exit: 'ringingSoundStop',
                                on: {
                                    ACCEPT: { target: 'connecting', actions: 'acceptCall' },
                                    DENY: { target: '#router.idle', actions: 'denyCall' },
                                },
                            },
                            connecting: {
                                entry: 'spawnCall',
                                on: {
                                    'CALL.CONNECTED': '#router.busy.calling',
                                },
                            },
                        },
                    },
                    waiting: {
                        initial: 'connecting',
                        states: {
                            connecting: {
                                initial: 'stream',
                                on: {
                                    CANCEL: { target: '#router.idle' },
                                },
                                states: {
                                    stream: {
                                        entry: 'spawnCall',
                                        on: {
                                            'CALL.CONNECTED': 'call',
                                        },
                                    },
                                    call: {
                                        invoke: {
                                            src: 'createCall',
                                            onDone: '#router.busy.waiting.dialog',
                                            onError: {
                                                target: '#router.idle.issue',
                                                actions: assign({
                                                    issue: (_, event) => event.data,
                                                }),
                                            },
                                        },
                                    },
                                },
                            },
                            dialog: {
                                invoke: {
                                    src: 'callStatusChange$',
                                },
                                on: {
                                    CANCEL: { target: '#router.idle', actions: 'cancelCall' },
                                    CALL_STATUS_ACCEPTED: {
                                        target: '#router.busy.calling',
                                        actions: 'updateRemoteParticipant',
                                    },
                                    CALL_STATUS_DENIED: {
                                        target: '#router.idle.issue',
                                        actions: assign({
                                            issue: context =>
                                                ({
                                                    code: 'CALL_DENIED',
                                                    title: 'Call Ended',
                                                    icon: 'call-ended',
                                                    type: 'warning',
                                                    message: `${context.metadata.remoteParticipant.displayName} is busy at the moment. Try later.`,
                                                } as CallRouterIssue),
                                        }),
                                    },
                                },
                                after: {
                                    MISSED_DELAY: {
                                        target: '#router.idle.issue',
                                        actions: [
                                            assign({
                                                issue: context =>
                                                    ({
                                                        code: 'CALL_MISSED',
                                                        title: 'Call Missed',
                                                        icon: 'call-ended',
                                                        type: 'warning',
                                                        message: `${context.metadata.remoteParticipant.displayName} didn't answer your call.`,
                                                    } as CallRouterIssue),
                                            }),
                                            'missedCall',
                                        ],
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
    },
    {
        delays: {
            MISSED_DELAY: 120000,
        },
    },
);
