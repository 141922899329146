<div class="modal-clean modal-clean--wide modal-share-gift --centered">
    <div class="modal-clean__content">
        <div class="modal-clean__header">
            <div class="modal__title">Your Gift is Ready!</div>
            <div class="modal__close" (click)="presenter.onActionClose()"></div>
        </div>

        <div class="modal-share-gift__container">
            <img class="modal-share-gift__gift-box" src="./assets/present-box.svg" />
            <div class="modal-share-gift__description">Time to share your {{ promoCode.title }} gift subscription!</div>
            <form class="form">
                <label>Gift code</label>
                <input type="text" name="code" [value]="promoCode.promoCode" disabled />
            </form>
            <div class="btn-container">
                <button
                    class="button-v2 --purple --large --rounded --wide"
                    *ngIf="displayCopyButton"
                    (click)="presenter.onActionCopy(promoCode)">
                    Copy Gift Code
                </button>
                <button
                    class="button-v2 --purple --large --rounded --wide"
                    *ngIf="displayShareButton"
                    (click)="presenter.onActionShare(promoCode)">
                    Send Gift
                </button>
            </div>
            <div class="modal-share-gift__extra-actions">
                <p>Questions or feedback?</p>
                <a href="mailto:support@togethervideoapp.com">Contact Us</a>
            </div>
        </div>
    </div>
</div>
