<div class="filter-header" #searchContainer>
    <div class="filter-header--icon" (click)="hideFilters.emit()">
        <fa-icon [icon]="['tg', 'arrow-left']"></fa-icon>
    </div>
    <div class="filter-header__title" *ngIf="filters?.category?.length === 1; else genericTitle">
        {{ getSelectedLabelFromConfig('category', filters.category[0]) }}
    </div>
    <ng-template #genericTitle>
        <div class="filter-header__title">Filter {{ section }}</div>
    </ng-template>
    <div
        class="filter-header--action-link"
        *ngIf="canShowFilters() && hasFiltersSelected()"
        (click)="clearAllFilters()">
        Clear
    </div>
    <div class="filter-header--action-link" *ngIf="!callState && !canShowFilters()" (click)="showAddBookModal()">
        Add Book
    </div>
</div>

<ng-container *ngIf="!isLoading; else loading">
    <form [formGroup]="form" class="form search-filter" *ngIf="canShowFilters()">
        <div class="search-filter--field-container --drop-down" [ngClass]="{ '--two-items': config?.length == 2 }">
            <div class="form--field" *ngFor="let cfg of config">
                <mat-label>{{ cfg.title }}</mat-label>
                <mat-form-field *ngIf="cfg.type === 'select'">
                    <mat-select
                        [formControlName]="cfg.id"
                        [multiple]="cfg.multiple"
                        [hideSingleSelectionIndicator]="true">
                        <mat-select-trigger *ngIf="cfg.multiple">
                            <ng-container *ngFor="let selected of filters[cfg.id]; index as i">
                                <span *ngIf="i < 2" class="selected__label">
                                    {{ getSelectedLabel(cfg.options, selected) }}
                                    <fa-icon
                                        class="selected--clear-icon"
                                        [icon]="['tg', 'xmark']"
                                        (click)="removeSelected(cfg, selected); $event.stopPropagation()"></fa-icon>
                                </span>
                            </ng-container>
                            <span class="selected__count" *ngIf="getCountOfSelected(cfg) > 2">
                                +{{ getCountOfSelected(cfg) - 2 }}</span
                            >
                        </mat-select-trigger>
                        <mat-option
                            *ngFor="let option of cfg.options"
                            [value]="option.value"
                            [disabled]="cfg.multiple && isOptionDisabled(cfg, option.value)">
                            {{ option.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="search-filter--field-container --pop-over" [ngClass]="{ '--two-items': config?.length == 2 }">
            <div class="form--field" *ngFor="let cfg of config">
                <mat-form-field
                    *ngIf="cfg.type === 'select'"
                    [ngClass]="{
                        '--hide-input --selected':
                            (cfg.multiple && getCountOfSelected(cfg) > 0) ||
                            (!cfg.multiple && getSelectedLabel(cfg.options, filters[cfg.id]))
                    }"
                    (click)="openDropdownSelectModal(cfg)">
                    <input matInput readonly [formControlName]="cfg.id" [placeholder]="cfg.title" />
                    <span class="form--field__icon" matSuffix>
                        <fa-icon [icon]="['tg', 'vertical-ellipsis']"></fa-icon>
                    </span>
                    <span class="form--field__selected-label" *ngIf="cfg.multiple && getCountOfSelected(cfg) > 0">
                        <span class="form--field__selected-label__text">{{ cfg.title }}</span>
                        <span class="form--field__selected-label__count">{{ getCountOfSelected(cfg) }}</span>
                    </span>
                    <span
                        class="form--field__selected-label"
                        *ngIf="!cfg.multiple && getSelectedLabel(cfg.options, filters[cfg.id])">
                        <span class="form--field__selected-label__text"
                            >{{ getSelectedLabel(cfg.options, filters[cfg.id]) }}
                            <span *ngIf="cfg.id === 'age'"> y/o</span></span
                        >
                    </span>
                </mat-form-field>
            </div>
        </div>
        <div
            class="filter-info"
            [ngClass]="{ '--two-items': config?.length == 2 }"
            *ngIf="!isSearching && section === SpringboardSection.Books && !hasFiltersSelected()">
            <div class="filter-info__help-text alert --wide --purple">
                Filter books to search the library and find your new favorite story!
            </div>
        </div>
    </form>

    <ng-container *ngIf="!isSearching || isInfiniteScrolling; else searching">
        <ng-container *ngIf="!showError; else errorMessage">
            <ng-container *ngIf="items.length; else noResults">
                <div
                    class="section__result"
                    *ngIf="section === SpringboardSection.Books"
                    [infiniteScrollContainer]="'.infinite_scroll__container'"
                    infiniteScroll
                    [infiniteScrollDistance]="2"
                    [infiniteScrollThrottle]="50"
                    (scrolled)="onScroll()"
                    [fromRoot]="true">
                    <app-book-thumbnail
                        *ngFor="let item of items"
                        [book]="item"
                        [isFav]="users[0]?.isFavoriteBook(item.id)"
                        [isContentRestricted]="checkIfContentIsRestricted(item)"
                        [allowPurchase]="!callState"
                        (selected)="selected.emit($event)"
                        (toggleFavorite)="toggleFavorite.emit($event)">
                    </app-book-thumbnail>
                </div>
                <div class="section__result" *ngIf="section !== SpringboardSection.Books">
                    <app-activity-thumbnail
                        *ngFor="let item of items"
                        [activity]="item"
                        [isFav]="users[0]?.isFavoriteActivity(item.id)"
                        [isContentRestricted]="checkIfContentIsRestricted(item)"
                        (selected)="selected.emit($event)">
                    </app-activity-thumbnail>
                </div>
            </ng-container>
            <ng-template #noResults>
                <div class="section__no-result">
                    <img class="section__no-result__image" src="/assets/search-no-records.svg" />
                    <div class="section__no-result__text">
                        Sorry, we didn’t find any {{ section }}! Try changing your filters and searching again.
                    </div>
                </div>
            </ng-template>
        </ng-container>
        <ng-template #errorMessage>
            There was an error while searching.
            <button (click)="retrySearch()">Retry</button>
        </ng-template>
    </ng-container>
    <ng-template #searching>
        <div class="loading-animation">
            <ng-lottie [options]="animation"></ng-lottie>
        </div>
    </ng-template>
</ng-container>
<ng-template #loading>
    <div class="loading-animation">
        <ng-lottie [options]="animation"></ng-lottie>
    </div>
</ng-template>
