import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import {
    ActivityCategory,
    ActivityModel,
    GetActivitiesByCategoryInteractor,
} from '@together/common';

@Component({
    selector: 'app-activities-catalog',
    templateUrl: './activities-catalog.component.html',
    styleUrls: ['./activities-catalog.component.scss'],
})
export class ActivitiesCatalogComponent implements OnInit {
    @Input() addSafeMargin = false;
    @Input() category: ActivityCategory;

    @Output() readonly activityClick: EventEmitter<void> = new EventEmitter<void>();
    @Output() readonly closeClick: EventEmitter<void> = new EventEmitter<void>();

    public activities: ActivityModel[] | false = false;

    constructor(protected getActivitiesByCategory: GetActivitiesByCategoryInteractor) {}

    async ngOnInit() {
        this.activities = await this.getActivitiesByCategory.execute(this.category);
    }
}
