import { ManageSubscriptionModalComponent } from '@app/web/modals/manage-subscription-modal/manage-subscription-modal.component';
import { Component, ElementRef, Inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
    IStoreService,
    LimitedContentCohortConfig,
    SubscriptionTier,
} from '@app/shared/services/store/store-service.interface';
import { WaitModalService } from '@app/shared/services/wait-modal.service';
import {
    AnalyticsEvent,
    GetPlatformInteractor,
    LogAnalyticsEventInteractor,
    UserModel,
    UserService,
    PutCancellationFeedbackInteractor,
    CancellationFeedbackData,
    GetContentByTierInteractor,
    ContentByTierModel,
    ContentByTierFilter,
} from '@together/common';
import { SimpleModalComponent, SimpleModalService } from '@looorent/ngx-simple-modal';
import { Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { AnimationOptions } from 'ngx-lottie';

@Component({
    selector: 'app-cancel-subscription-modal',
    templateUrl: './cancel-subscription-modal.component.html',
    styleUrls: ['./cancel-subscription-modal.component.scss'],
})
export class CancelSubscriptionModalComponent extends SimpleModalComponent<void, void> implements OnInit, OnDestroy {
    public isSubscribed: boolean;
    public user: UserModel;
    public cancellationFlowSteps: Array<string> = ['benefits', 'cancel_survey', 'finish'];
    public activeSection: string = 'benefits';
    public isAndroid: boolean;
    protected subs = new Subscription();
    public cancelSurveyForm: UntypedFormGroup;
    @ViewChild('container') container: ElementRef<HTMLDivElement>;
    public subscribedTier: SubscriptionTier;
    public content: ContentByTierModel;
    public isLoadingContent = false;
    public animation: AnimationOptions = {
        path: './assets/animations/loading.json',
    };

    constructor(
        protected fb: UntypedFormBuilder,
        protected logAnalyticsEvent: LogAnalyticsEventInteractor,
        protected getPlatform: GetPlatformInteractor,
        protected modalService: SimpleModalService,
        protected router: Router,
        protected userService: UserService,
        protected waitService: WaitModalService,
        protected zone: NgZone,
        @Inject('StoreService') protected storeService: IStoreService,
        protected putCancellationFeedbackInteractor: PutCancellationFeedbackInteractor,
        private readonly getContent: GetContentByTierInteractor,
    ) {
        super();
    }

    public async ngOnInit(): Promise<void> {
        this.user = await this.userService.getUser();
        this.setView('benefits');
        this.initFormData();

        this.logAnalyticsEvent.execute(AnalyticsEvent.ManageSubscriptionCancel);

        this.isAndroid = this.getPlatform.isAndroid();

        // Get subscription state & watch for changes
        this.isSubscribed = this.user.isSubscribed();
        if (this.user.isLimitedContentCohort()) {
            this.initialiseLimitedContentCohortData();
        }

        this.subs.add(
            this.userService.getUser$().subscribe(user => {
                this.zone.run(() => {
                    this.user = user;
                    this.isSubscribed = this.user.isSubscribed();
                });
            }),
        );
    }

    public ngOnDestroy() {
        this.subs.unsubscribe();
    }

    public nextSection() {
        let currentIndex = this.cancellationFlowSteps.indexOf(this.activeSection);
        if (currentIndex !== this.cancellationFlowSteps.length - 1) {
            this.setView(this.cancellationFlowSteps[currentIndex + 1]);
        }
    }

    public previousSection() {
        let currentIndex = this.cancellationFlowSteps.indexOf(this.activeSection);
        if (currentIndex !== 0) {
            this.setView(this.cancellationFlowSteps[currentIndex - 1]);
        } else {
            this.close();
            this.modalService.addModal(ManageSubscriptionModalComponent, null, {
                closeOnEscape: false,
                closeOnClickOutside: false,
            });
        }
    }

    public setView(key) {
        this.activeSection = key;
        if (this.activeSection === 'benefits') {
            this.logAnalyticsEvent.execute(AnalyticsEvent.ManageSubscriptionBenefits);
        } else if (this.activeSection === 'cancel_survey') {
            this.logAnalyticsEvent.execute(AnalyticsEvent.ManageSubscriptionSurvey);
        }
        setTimeout(() => {
            this.container.nativeElement.scrollTo(0, 0);
        }, 10);
    }

    public async openManageSubscription(): Promise<void> {
        this.waitService.show();
        this.logAnalyticsEvent.execute(AnalyticsEvent.ManageSubscriptionCancelComplete);
        this.close();
        await this.storeService.openManageSubscription();
        this.waitService.hide();
    }

    public async submitSurvey() {
        const data: CancellationFeedbackData = {
            ...this.cancelSurveyForm.value,
            subscriptionProductId: this.user?.subscriptionProduct?.subscriptionProductId,
        };
        this.waitService.show();
        try {
            await this.putCancellationFeedbackInteractor.execute(data);
            this.nextSection();
        } catch (err) {
            this.modalService.addModal(AlertModalComponent, {
                title: 'Oops!',
                message: err.message ? err.message : 'There was an error while sending the feedback, please try again.',
                type: 'error',
                icon: 'error',
            });
        } finally {
            this.waitService.hide();
        }
    }

    private initFormData(): void {
        this.cancelSurveyForm = this.fb.group({
            reason: ['', [Validators.required]],
            feedback: [''],
        });
    }

    private async initialiseLimitedContentCohortData() {
        const limitedContentCohort: LimitedContentCohortConfig =
            await this.storeService.getLimitedContentCohortConfig();
        this.subscribedTier = limitedContentCohort.tiers.find(tier =>
            this.user.subscriptionProduct?.entitlements?.includes(tier.tierId),
        );
        if (this.user.isBasicSubscription()) {
            this.getContentForBasicTier();
        }
    }

    private async getContentForBasicTier() {
        this.isLoadingContent = true;
        const filter: ContentByTierFilter = {
            tier: this.subscribedTier.tierId,
            includeBooks: true,
            includeActivities: true,
            includePhotoboothFilters: true,
        };
        this.content = await this.getContent.execute(filter, false);
        this.isLoadingContent = false;
    }
}
