<div class="modal-clean modal-clean--ultra-wide-xl modal-tier-showcase --centered">
    <div class="modal-clean__content --no-outer-padding">
        <div class="modal-clean__header">
            <div class="modal__title">What is included</div>
            <div class="modal__close --back" (click)="close()"></div>
        </div>
        <div class="modal-tier-showcase__container" *ngIf="!isLoading; else loadingView">
            <div class="image-container">
                <ng-container *ngFor="let item of content?.books">
                    <div class="image" *ngIf="item.coverImageURL">
                        <img [src]="item.coverImageURL" />
                        <span class="label"> Book </span>
                    </div>
                </ng-container>
                <ng-container *ngFor="let item of content?.games">
                    <div class="image" *ngIf="item.coverImageURL">
                        <img [src]="item.coverImageURL" />
                        <span class="label"> Game </span>
                    </div>
                </ng-container>
                <ng-container *ngFor="let item of content?.activities">
                    <div class="image" *ngIf="item.coverImageURL">
                        <img [src]="item.coverImageURL" />
                        <span class="label"> Activity </span>
                    </div>
                </ng-container>
                <ng-container *ngFor="let item of content?.topics">
                    <div class="image" *ngIf="item.coverImageURL">
                        <img [src]="item.coverImageURL" />
                        <span class="label"> Topic </span>
                    </div>
                </ng-container>
                <ng-container *ngFor="let item of content?.photoboothFilters">
                    <div class="image" *ngIf="item.thumbnailURL">
                        <img [src]="item.thumbnailURL" />
                        <span class="label"> Filter </span>
                    </div>
                </ng-container>
            </div>
            <div class="showcase-footer">
                <div class="showcase-footer__action-container">
                    <button class="button-v2 --purple --rounded" (click)="triggerPurchase()">
                        Subscribe for {{ displayPrice }}
                    </button>
                </div>
                <div class="showcase-footer__terms">
                    <span class="--heading">Terms & conditions: </span> Your Together membership is a recurring
                    subscription, which means will auto-renew monthly (plus tax where applicable). You can cancel at
                    least 24 hours before your membership expires to avoid getting charged for the next membership
                    period, as there are no partial refunds.
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #loadingView>
    <div class="loading-animation">
        <ng-lottie [options]="animation"></ng-lottie>
    </div>
</ng-template>
