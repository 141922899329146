<div class="modal-clean modal-alert --centered {{ type }}">
    <div class="modal-clean__content">
        <div class="modal-clean__icon-box">
            <fa-icon [icon]="['tg', icon]"></fa-icon>
        </div>
        <div class="modal-clean__header">
            <div class="modal__title">{{ title }}</div>
        </div>
        <div class="modal-alert__content">
            <div class="modal-alert__message" [innerHTML]="message"></div>
            <div class="modal-alert__subText" *ngIf="subText" [innerHTML]="subText"></div>
            <button
                class="button-v2 --rounded --wide --large"
                [ngClass]="{
                    '--purple': type == 'default' || 'success',
                    '--orange': type == 'warning',
                    '--red': type == 'error'
                }"
                (click)="onPrimaryAction()">
                {{ primaryActionLabel }}
            </button>
            <p class="modal-alert__secondaryActionLabel" *ngIf="secondaryActionLabel" (click)="onSecondaryAction()">
                {{ secondaryActionLabel }}
            </p>
        </div>
    </div>
</div>
