<div class="modal-clean modal-clean--wide modal-book modal-highlights">
    <div class="modal-clean__content">
        <div class="modal-clean__header">
            <div class="modal__close" (click)="close()"></div>
            <div class="modal__title">🌟 Highlights 🌟</div>
            <div
                class="modal__secondary-action"
                (click)="saveAll()"
                *ngIf="!canShowPhotoBooth() && !isLoading && highlights.length > 0">
                <span class="modal__secondary-action--label">Save all</span>
            </div>
        </div>
        <div class="modal-highlights__menu" *ngIf="canShowPhotoBooth()">
            <div class="modal-highlights__menu__tab">
                <span [ngClass]="{ active: selectedTab === 'highlights' }" (click)="selectTab('highlights')">
                    Highlights
                </span>
                <span [ngClass]="{ active: selectedTab === 'photobooth' }" (click)="selectTab('photobooth')">
                    Photo Booth
                </span>
            </div>
            <div
                class="modal-highlights__menu__action"
                (click)="saveAll()"
                *ngIf="!isLoading && selectedTab === 'highlights' && highlights.length > 0">
                <span class="modal-highlights__menu__action__label">Save all</span>
            </div>
            <div
                class="modal-highlights__menu__action"
                (click)="saveAll()"
                *ngIf="!isLoading && selectedTab === 'photobooth' && photoboothImages.length > 0">
                <span class="modal-highlights__menu__action__label">Save all</span>
            </div>
        </div>
        <div class="modal-highlights__content modal-highlights__content--loading" *ngIf="isLoading">
            <p class="modal-highlights__notice">Loading...</p>
            <div class="progress">
                <div class="progress__bar" [style.width.%]="progress * 100"></div>
            </div>
        </div>

        <ng-container *ngIf="selectedTab === 'highlights'">
            <div class="modal-highlights__content" #container *ngIf="!isLoading && highlights.length === 0">
                <div class="modal-highlights__not-found">
                    <img
                        class="modal-highlights__not-found__image"
                        src="../../../../assets/images/highlights-empty-state.svg"
                        alt="" />
                    <div class="modal-highlights__not-found__text">
                        Start collecting highlights!<br /><br />
                        Try playing some games during your next video call. Your highlights are captured and collected
                        here.
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="selectedTab === 'photobooth'">
            <div class="modal-highlights__content" #container *ngIf="!isLoading && photoboothImages.length === 0">
                <div class="modal-highlights__not-found">
                    <img
                        class="modal-highlights__not-found__image"
                        src="../../../../assets/images/photobooth-empty-state.svg"
                        alt="" />
                    <div class="modal-highlights__not-found__text">
                        Start making memories!<br /><br />
                        Try the Photo Booth feature during your next video call. Play with the face filters and capture
                        your memories by tapping the camera icon.
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="selectedTab === 'highlights'">
            <div
                class="modal-highlights__content modal-highlights__content--highlights"
                #container
                (scroll)="loadHighlights()"
                *ngIf="!isLoading && highlights.length > 0">
                <p class="modal-highlights__notice">
                    All highlights are kept on your device only and are never stored or shared anywhere.
                </p>
                <div class="highlight" *ngFor="let highlight of highlights">
                    <div class="highlight__image-container">
                        <img class="highlight__image" [src]="highlight.pictureStr" />
                    </div>
                    <div class="highlight__actions">
                        <div *ngIf="showShare">
                            <div
                                class="modal-highlights__share-btn button-v2 --icon-only --purple --shadow"
                                (click)="shareHighlight(highlight)">
                                <fa-icon [icon]="['tg', 'share-link']"></fa-icon>
                            </div>
                            <div class="highlight__actions__label">Share</div>
                        </div>
                        <div>
                            <div
                                class="modal-highlights__save-btn button-v2 --icon-only --purple --shadow"
                                (click)="downloadHighlight(highlight)">
                                <fa-icon [icon]="['tg', 'save']"></fa-icon>
                            </div>
                            <div class="highlight__actions__label">Save</div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="selectedTab === 'photobooth'">
            <div
                class="modal-highlights__content modal-highlights__content--highlights"
                #container
                *ngIf="!isLoading && photoboothImages.length > 0">
                <p class="modal-highlights__notice">
                    All images are kept on your device only and are never stored or shared anywhere.
                </p>
                <div class="highlight" *ngFor="let image of photoboothImages">
                    <div class="highlight__image-container">
                        <img class="highlight__image" [src]="image.imageStr" />
                    </div>
                    <div class="highlight__actions">
                        <div *ngIf="showShare">
                            <div
                                class="modal-highlights__share-btn button-v2 --icon-only --purple --shadow"
                                (click)="sharePhotoboothImage(image)">
                                <fa-icon [icon]="['tg', 'share-link']"></fa-icon>
                            </div>
                            <div class="highlight__actions__label">Share</div>
                        </div>
                        <div>
                            <div
                                class="modal-highlights__save-btn button-v2 --icon-only --purple --shadow"
                                (click)="downloadPhotoboothImage(image)">
                                <fa-icon [icon]="['tg', 'save']"></fa-icon>
                            </div>
                            <div class="highlight__actions__label">Save</div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
