<div class="layout" [ngClass]="{ 'layout--filtered': state.isFiltered }">
    <div class="header" [ngClass]="{ ' --call-state': !showSpringboardActions }">
        <div
            class="header__profile-menu"
            *ngIf="users?.length && showSpringboardActions"
            (click)="showProfileModal()"
            [style.backgroundImage]="'url(' + users[0]?.getAvatarUrl() + ')'"></div>
        <div class="header__favorites-menu" *ngIf="!showSpringboardActions" (click)="showFavorites.emit()">
            <fa-icon class="v-top square" [icon]="['tg', 'heart-solid']"></fa-icon>
        </div>
        <div class="header__back-icon" *ngIf="!isLoggedIn && isWelcomePage()" (click)="back()">
            <fa-icon class="v-top square" [icon]="['tg', 'arrow-left']"></fa-icon>
            <span class="header__back-icon__text">Welcome</span>
        </div>
        <div class="header__sections">
            <div
                class="header__sections__tab"
                *ngFor="let section of sections"
                (click)="showSection(section.id)"
                [ngClass]="{ 'header__sections__tab--active': state.section === section.id }">
                <div class="header__sections__tab__icon-box">
                    <fa-icon [icon]="['tg', section.icon]"></fa-icon>
                </div>
                <div class="header__sections__tab__title">
                    {{ section.label }}
                </div>
            </div>
        </div>
        <div class="header__action-box">
            <ng-container>
                <img
                    *ngIf="!hasUnreadInboxMessage"
                    (click)="openInboxModal()"
                    src="/assets/notification.svg"
                    class="header__action-box__notification-icon" />
            </ng-container>
            <ng-container>
                <img
                    *ngIf="hasUnreadInboxMessage"
                    (click)="openInboxModal()"
                    src="/assets/notification-unread.svg"
                    class="header__action-box__notification-icon" />
            </ng-container>
            <ng-container>
                <img (click)="showHelpModal()" src="/assets/question-mark.svg" class="header__action-box__help-icon" />
            </ng-container>

            <!-- <ng-container *ngIf="isLoggedIn && showSpringboardActions">
                <button
                    class="button-v2 --small --purple --rounded"
                    *ngIf="!isSubscribed"
                    (click)="showUpgradeModal.emit()">
                    Upgrade
                </button>
            </ng-container> -->
        </div>
    </div>
    <div class="layout__upgrade-banner" *ngIf="isLoggedIn && showSpringboardActions && !isSubscribed">
        <div class="layout__upgrade-banner__content">{{ upgradeBannerText }}</div>
        <button class="button-v2 --upgrade-btn --small --purple --rounded" (click)="showUpgradeModal.emit()">
            Upgrade
        </button>
    </div>

    <div class="layout__title" data-section-title-portal></div>
</div>

<!-- Books -->
<ng-container *ngIf="state.section == SpringboardSection.Books">
    <app-section-search
        *ngIf="state.isFiltered; else bookSections"
        [section]="state.section"
        [users]="users"
        [callState]="callState"
        [filters]="state.filters"
        (filtersChanged)="filterBy($event)"
        (hideFilters)="hideFilters()"
        (selected)="emitSelect($event)"></app-section-search>
    <ng-template #bookSections>
        <app-section-featured
            [section]="state.section"
            [callState]="callState"
            [users]="users"
            [shouldShowAddBook]="shouldShowAddBook"
            (filterByCategory)="filterBy({ category: $event })"
            (selected)="emitSelect($event)">
        </app-section-featured>
    </ng-template>
</ng-container>

<!-- Games -->
<ng-container *ngIf="state.section == SpringboardSection.Games">
    <app-section-search
        *ngIf="state.isFiltered; else gameSections"
        [section]="state.section"
        [users]="users"
        [filters]="state.filters"
        (filtersChanged)="filterBy($event)"
        (hideFilters)="hideFilters()"
        (selected)="emitSelect($event)"></app-section-search>
    <ng-template #gameSections>
        <app-section-featured
            [section]="state.section"
            [callState]="callState"
            [users]="users"
            (filterByCategory)="filterBy({ category: $event })"
            (selected)="emitSelect($event)">
        </app-section-featured>
    </ng-template>
</ng-container>

<!-- Activities -->
<ng-container *ngIf="state.section == SpringboardSection.Activities">
    <app-section-search
        *ngIf="state.isFiltered; else activitySections"
        [section]="state.section"
        [users]="users"
        [filters]="state.filters"
        (filtersChanged)="filterBy($event)"
        (hideFilters)="hideFilters()"
        (selected)="emitSelect($event)"></app-section-search>
    <ng-template #activitySections>
        <app-section-featured
            [section]="state.section"
            [callState]="callState"
            [users]="users"
            (filterByCategory)="filterBy({ category: $event })"
            (selected)="emitSelect($event)">
        </app-section-featured>
    </ng-template>
</ng-container>

<!-- Topics -->
<ng-container *ngIf="state.section == SpringboardSection.Topics">
    <app-section-search
        *ngIf="state.isFiltered; else topicSections"
        [section]="state.section"
        [users]="users"
        [filters]="state.filters"
        (filtersChanged)="filterBy($event)"
        (hideFilters)="hideFilters()"
        (selected)="emitSelect($event)"></app-section-search>
    <ng-template #topicSections>
        <app-section-featured
            [section]="state.section"
            [callState]="callState"
            [users]="users"
            (filterByCategory)="filterBy({ category: $event })"
            (selected)="emitSelect($event)">
        </app-section-featured>
    </ng-template>
</ng-container>
