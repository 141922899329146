import { Component, Inject, OnInit } from '@angular/core';
import { SimpleModalComponent } from '@looorent/ngx-simple-modal';
import { AbstractLogger } from '@mobilejazz/harmony-core';

import { ShareGiftPresenter } from './share-gift-modal.presenter';
import { PromoCodeModel } from '@together/common';

export interface ShareGiftView {
    onDisplayCopyButton(displayCopyButton: boolean): void;
    onDisplayShareButton(displayShareButton: boolean): void;
    onNotifyClose(): void;
}

export interface ShareGiftData {
    promoCode: PromoCodeModel;
}

@Component({
    selector: 'app-share-gift-modal',
    templateUrl: './share-gift-modal.component.html',
    styleUrls: ['./share-gift-modal.component.scss'],
})
export class ShareGiftModalComponent
    extends SimpleModalComponent<ShareGiftData, void>
    implements ShareGiftView, OnInit
{
    // Modal input
    public promoCode: PromoCodeModel;

    public displayCopyButton: boolean;
    public displayShareButton: boolean;

    constructor(
        private readonly logger: AbstractLogger,
        @Inject('ShareGiftPresenter')
        public readonly presenter: ShareGiftPresenter,
    ) {
        super();
        this.presenter.attachView(this);
    }

    public ngOnInit(): void {
        this.presenter.onViewNgOnInit();
    }

    public onDisplayCopyButton(displayCopyButton: boolean): void {
        this.displayCopyButton = displayCopyButton;
    }

    public onDisplayShareButton(displayShareButton: boolean): void {
        this.displayShareButton = displayShareButton;
    }

    public onNotifyClose(): void {
        this.close();
    }
}
