<div class="modal-clean modal-clean--wide modal-manage-gifts --centered">
    <div class="modal-clean__content --no-outer-padding">
        <div class="modal-clean__header">
            <div class="modal__title">Manage Gifts</div>
            <div class="modal__close" (click)="presenter.onActionClose()"></div>
        </div>
        <div class="modal-manage-gifts__content">
            <div class="modal-manage-gifts__content__title">PURCHASE HISTORY</div>
            <div *ngIf="isLoading">Loading…</div>
            <div class="modal-manage-gifts__content__empty-state" *ngIf="promoCodes && promoCodes.length == 0">
                <p>You haven’t purchased any gifts yet. You can purchase a gift from the Family and Settings page.</p>
            </div>
            <div>
                <div
                    *ngFor="let promoCode of promoCodes"
                    class="promo-code"
                    [ngClass]="{ 'promo-code--redeemed': promoCode.hasBeenRedeemed }"
                    (click)="presenter.onActionViewPromoCode(promoCode)">
                    <div class="promo-code__title-wrapper">
                        <h3 class="promo-code__title">
                            {{ promoCode.title }} Subscription
                            <!--  <span class="promo-code__status" *ngIf="promoCode.hasBeenRedeemed">Redeemed</span> -->
                        </h3>
                        <div class="promo-code__purchase-time" *ngIf="promoCode.creationDate">
                            Purchased {{ promoCode.creationDate | date: 'mediumDate' }}
                        </div>
                    </div>
                    <div class="promo-code__code-wrapper">
                        <div class="promo-code__label" *ngIf="!promoCode.hasBeenRedeemed">Gift Code</div>
                        <div class="promo-code__label" *ngIf="promoCode.hasBeenRedeemed">REDEEMED</div>
                        <div class="promo-code__code">{{ promoCode.promoCode }}</div>
                    </div>
                </div>
            </div>
            <div class="modal-manage-gifts__footer">
                <div class="modal-manage-gifts__action">
                    <a href="mailto:support@togethervideoapp.com">Need Help?</a>
                </div>
                <!-- <div class="modal-manage-gifts__action" (click)="presenter.onActionShowPurchaseGift()">Purchase Gift
                </div>
                <div class="modal-manage-gifts__action" *ngIf="showRedeem" (click)="presenter.onActionShowRedeemGift()">
                    Redeem Gift
                </div> -->
            </div>
        </div>
    </div>
</div>
