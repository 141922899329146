import { Component } from '@angular/core';
import { SimpleModalComponent } from '@looorent/ngx-simple-modal';

export interface AlertModal {
    title: string;
    message: string;
    subText?: string;
    type?: AlertType;
    icon?: string;
    primaryActionLabel?: string;
    secondaryActionLabel?: string;
}

export enum ActionType {
    Primary = 'primary',
    Secondary = 'secondary',
}

export type AlertType = 'default' | 'success' | 'warning' | 'error';

@Component({
    selector: 'app-alert-modal',
    templateUrl: './alert-modal.component.html',
    styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent extends SimpleModalComponent<AlertModal, ActionType> {
    type: string = 'default';
    icon: string = 'checked-round';
    title: string;
    message: string;
    subText: string;
    primaryActionLabel: string = 'Ok';
    secondaryActionLabel: string;

    constructor() {
        super();
    }

    onPrimaryAction() {
        this.result = ActionType.Primary;
        this.close();
    }

    onSecondaryAction() {
        this.result = ActionType.Secondary;
        this.close();
    }
}
