import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
    selector: 'app-toast-snack-bar',
    templateUrl: './toast-snack-bar.component.html',
    styleUrls: ['./toast-snack-bar.component.scss'],
})
export class ToastSnackBarComponent implements OnInit {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}

    ngOnInit() {}
}
