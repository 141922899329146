import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { SimpleModalComponent } from '@looorent/ngx-simple-modal';
import { Stripe, StripeElements, StripeEmbeddedCheckout, StripePaymentElement } from '@stripe/stripe-js';

export interface StripePaymentModal {
    clientSecret: string;
    stripe: Stripe;
}

@Component({
    selector: 'app-stripe-payment-modal',
    templateUrl: './stripe-payment-modal.component.html',
    styleUrls: ['./stripe-payment-modal.component.scss'],
})
export class StripePaymentModalComponent
    extends SimpleModalComponent<StripePaymentModal, string>
    implements AfterViewInit
{
    // Modal Input
    public clientSecret: string;
    public stripe: Stripe;

    private checkout: StripeEmbeddedCheckout;
    @ViewChild('paymentElement') private paymentEl: ElementRef;

    constructor(private cdr: ChangeDetectorRef) {
        super();
    }

    public async ngAfterViewInit(): Promise<void> {
        this.checkout = await this.stripe.initEmbeddedCheckout({
            clientSecret: this.clientSecret,
            onComplete: () => {
                this.submit();
            },
        });

        this.checkout.mount(this.paymentEl.nativeElement);
    }

    public ngOnDestroy(): void {
        this.checkout.destroy();
    }

    public async submit(): Promise<void> {
        try {
            this.result = 'success';
            this.close();
        } catch (err) {
            this.result = null;
            alert(err);
            console.error(err);
            this.close();
        }
    }
}
