import { Platform } from '@ionic/angular';
import { SimpleModalService } from '@looorent/ngx-simple-modal';

import { ConfirmationModalComponent } from '@app/web/modals/confirmation-modal/confirmation-modal.component';

export class NativeStoreRedirectService {
    private excludeUrlsFromRedirect = ['promopayment', 'promopayment-success'];

    constructor(
        protected modalService: SimpleModalService,
        protected platform: Platform,
        protected storeAndroidURL: string,
        protected storeIOSURL: string,
    ) {}

    async check(): Promise<void> {
        const url = new URL(window.location.href);
        const shouldExclude = this.excludeUrlsFromRedirect.some(route => {
            return url.pathname.includes(route);
        });
        // Is not running in capacitor
        if (!this.platform.is('capacitor') && !shouldExclude) {
            if (this.platform.is('android')) {
                const openStore = await this.modalService
                    .addModal(ConfirmationModalComponent, {
                        title: 'Open Google Play?',
                        message: 'Together App is available for Android, do you want to open the Google Play store?',
                    })
                    .toPromise();

                if (openStore) {
                    window.location.replace(this.storeAndroidURL);
                }
            }

            if (this.platform.is('ios')) {
                window.location.replace(this.storeIOSURL);
            }
        }
    }
}
