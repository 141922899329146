import { Injectable } from '@angular/core';
import { ReplaySubject, Subscription, Observable } from 'rxjs';
import { AbstractLogger } from '@mobilejazz/harmony-core';

import { UserModel, GetAllContactsInteractor } from '@together/common';

@Injectable({
    providedIn: 'root', // Force it to be a singleton
})
export class ContactsService {
    protected contact$: ReplaySubject<UserModel[]>;
    protected contact$Sub: Subscription;

    constructor(
        protected getAllContacts: GetAllContactsInteractor,
        protected logger: AbstractLogger,
    ) {
        this.stop();
    }

    getContact$(): Observable<UserModel[]> {
        return this.contact$.asObservable();
    }

    public async start(user: UserModel) {
        this.logger.info('ContactsService', `Start`);
        this.contact$Sub = this.getAllContacts.execute(user).subscribe(this.contact$);
    }

    public stop() {
        this.logger.info('ContactsService', `Stop`);

        // Reset the replay subject
        this.contact$ = new ReplaySubject<UserModel[]>();

        // If there is a previous Firestore observer cancel it
        if (this.contact$Sub) {
            this.contact$Sub.unsubscribe();
        }
    }
}
