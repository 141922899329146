import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SimpleModalComponent } from '@looorent/ngx-simple-modal';

export interface WebviewModal {
    title: string;
    url: string;
}

@Component({
    selector: 'app-webview-modal',
    templateUrl: './webview-modal.component.html',
    styleUrls: ['./webview-modal.component.scss'],
})
export class WebviewModalComponent extends SimpleModalComponent<WebviewModal, void> implements OnInit {
    public title: string;
    public url: string;
    public safeURL: SafeResourceUrl;

    constructor(protected sanitizer: DomSanitizer) {
        super();
    }

    ngOnInit() {
        this.safeURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    }
}
