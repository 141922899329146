<div class="doc-thumb doc-thumb--book" (click)="selected.emit(book)">
    <div class="doc-thumb__image-wrapper">
        <div class="doc-thumb__image" [style.backgroundImage]="'url(' + book.coverImageURL + ')'"></div>
        <button class="doc-thumb__user-fav button-v2 --icon-only --red" *ngIf="isFav">
            <fa-icon [icon]="['tg', 'heart-solid']"></fa-icon>
        </button>

        <div
            class="doc-thumb__purchase-state"
            *ngIf="!onlyImage && (book.isForSale || book.isPurchased || book.isRented)">
            <span class="doc-thumb__purchase-state__rental-expiry" *ngIf="book.isRented && book.isRentalActive">
                {{ getRentalDaysLeft() }} d left
            </span>
            <span class="doc-thumb__purchase-state__rental-expiry" *ngIf="book.isRented && !book.isRentalActive">
                Rental Expired
            </span>
            <div class="doc-thumb__purchase-state__btn-container" [ngClass]="{ '--merge-corner': book.isRented }">
                <button
                    class="button-v2 --icon-only --bg-btn"
                    [ngClass]="{
                        '--buy': book.isForSale,
                        '--purchased': book.isPurchased,
                        '--rented': book.isRented && book.isRentalActive,
                        '--expired': book.isRented && !book.isRentalActive
                    }"
                    (click)="purchaseAction(); $event.stopPropagation()"></button>
            </div>
        </div>
        <button
            *ngIf="ownerAvatar"
            class="doc-thumb__owner button-v2 --icon-only --bg-btn"
            [style.backgroundImage]="'url(' + ownerAvatar + ')'"></button>
    </div>
    <div class="doc-thumb__title" *ngIf="!onlyImage">
        <fa-icon
            class="content-lock"
            [icon]="['tg', 'locked']"
            *ngIf="isContentRestricted"
            [ngClass]="{ '--green': book.isPremiumContent }"></fa-icon>
        {{ book.title }}
    </div>
    <!--     <div class="doc-thumb__subtitle" *ngIf="!onlyImage">{{ book.authors }}</div>-->
</div>
