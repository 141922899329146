<app-springboard
    [users]="users"
    [showSpringboardActions]="true"
    [state]="springboardState"
    (selected)="springboardSelected($event)"
    (toggleFavorite)="toggleFavorite($event)"
    (stateChanged)="stateChanged($event)"
    (showUpgradeModal)="showUpgradeModal()">
</app-springboard>
<!-- <div class="section">
    <div class="section__title-wrapper">
        <div class="section__title">Using Together:</div>
    </div>
    <div class="section__content">
        <div class="horizontal-scroll horizontal-scroll--tutorials" *ngIf="tutorials === false">
            <div class="tutorial-button tutorial-button--placeholder"></div>
            <div class="tutorial-button tutorial-button--placeholder"></div>
            <div class="tutorial-button tutorial-button--placeholder"></div>
            <div class="tutorial-button tutorial-button--placeholder"></div>
            <div class="tutorial-button tutorial-button--placeholder"></div>
            <div class="tutorial-button tutorial-button--placeholder"></div>
            <div class="tutorial-button tutorial-button--placeholder"></div>
            <div class="tutorial-button tutorial-button--placeholder"></div>
            <div class="tutorial-button tutorial-button--placeholder"></div>
        </div>
        <div class="horizontal-scroll horizontal-scroll--tutorials" *ngIf="tutorials">
            <app-tutorial-button [tutorial]="feedbackModel" (click)="showFeedbackModal()"> </app-tutorial-button>
            <app-tutorial-button
                *ngFor="let tutorial of tutorials"
                [tutorial]="tutorial"
                (click)="showTutorialModal(tutorial)">
            </app-tutorial-button>
        </div>
    </div>
</div> -->
