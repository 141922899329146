import { AbstractLogger } from '@mobilejazz/harmony-core';
import { Component } from '@angular/core';
import { SimpleModalComponent, SimpleModalService } from '@looorent/ngx-simple-modal';

import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { WaitModalService } from '@app/shared/services/wait-modal.service';
import { InviteModel, AcceptInviteInteractor, DenyInviteInteractor, UserService } from '@together/common';

export interface AcceptInviteModal {
    invite: InviteModel;
}

@Component({
    selector: 'app-accept-invite-modal',
    templateUrl: './accept-invite-modal.component.html',
    styleUrls: ['./accept-invite-modal.component.scss'],
})
export class AcceptInviteModalComponent extends SimpleModalComponent<AcceptInviteModal, void> {
    invite: InviteModel;

    constructor(
        protected acceptInvite: AcceptInviteInteractor,
        protected denyInvite: DenyInviteInteractor,
        protected logger: AbstractLogger,
        protected modalService: SimpleModalService,
        protected userService: UserService,
        protected waitService: WaitModalService,
    ) {
        super();
    }

    async accept() {
        this.waitService.show();

        try {
            await this.acceptInvite.execute(this.invite);
            this.close();
            this.modalService.addModal(AlertModalComponent, {
                title: 'Success!',
                message: `Great! Now you are connected with ${this.invite.creator.displayName}.`,
                type: 'success',
                icon: 'checked-round',
            });
        } catch (err) {
            this.logger.error('AcceptInviteModal', err);
            const message = err ? err.message : `There was an error!`;
            this.modalService.addModal(AlertModalComponent, {
                title: 'Oops!',
                message: message,
                type: 'error',
                icon: 'error',
            });
        } finally {
            this.waitService.hide();
        }
    }

    async deny() {
        this.waitService.show();

        const user = await this.userService.getUser();

        try {
            await this.denyInvite.execute(this.invite, user);
        } catch (err) {
            this.logger.error('AcceptInviteModal', err);
            this.modalService.addModal(AlertModalComponent, {
                title: 'Oops!',
                message: `There was an error!`,
                type: 'error',
                icon: 'error',
            });
        } finally {
            this.waitService.hide();
            this.close();
        }
    }
}
