import { SpringboardService } from '@app/shared/services/springboard.service';
import { ModalsService } from '@app/shared/services/modals.service';
import { Component } from '@angular/core';
import { SimpleModalComponent } from '@looorent/ngx-simple-modal';

import { ActivityModel, UserService, UserModel, ToggleFavoriteActivityInteractor } from '@together/common';
import { ToastSnackBarComponent } from '@app/shared/components/toast-snack-bar/toast-snack-bar.component';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface ActivityInfoModal {
    activity: ActivityModel;
}

@Component({
    selector: 'app-activity-info-modal',
    templateUrl: './activity-info-modal.component.html',
    styleUrls: ['./activity-info-modal.component.scss'],
})
export class ActivityInfoModalComponent extends SimpleModalComponent<ActivityInfoModal, void> {
    public activity: ActivityModel;
    public isLoggedIn = false;
    public isFav = false;

    private currentUser: UserModel;

    constructor(
        protected modalService: ModalsService,
        private readonly userService: UserService,
        private readonly springboard: SpringboardService,
        private toggleFavoriteActivity: ToggleFavoriteActivityInteractor,
        private snackBar: MatSnackBar,
    ) {
        super();
    }

    public async ngOnInit() {
        this.isLoggedIn = await this.userService.isLoggedIn();
        if (this.isLoggedIn) {
            this.currentUser = await this.userService.getUser();
        }
        this.isFav = this.isLoggedIn && this.currentUser?.isFavoriteActivity(this.activity.id);
    }

    public showInstructions() {
        this.modalService.showWebview('Instructions', this.activity.instructionsURL);
    }

    public playDemo() {
        this.close();
        this.modalService.playDemo(this.activity);
    }

    public async toggleActivityAsFavorite(): Promise<void> {
        this.isFav = !this.isFav;
        if (this.currentUser) {
            await this.toggleFavoriteActivity
                .execute(this.currentUser, this.activity.id)
                .then(favoriteActivities => {
                    this.currentUser.favoriteActivities = favoriteActivities;
                    this.springboard.refreshUser(this.currentUser);
                    if (this.isFav) {
                        this.snackBar.openFromComponent(ToastSnackBarComponent, {
                            duration: 3000,
                            panelClass: ['toast-snackbar'],
                            data: { text: 'Added to favorites!', icon: 'checked-round' },
                        });
                    }
                })
                .catch(() => {
                    this.isFav = !this.isFav;
                });
        }
    }
}
