import { AfterViewInit, ApplicationRef, Component, ComponentFactoryResolver, Injector, ViewChild } from '@angular/core';
import { CdkPortal, DomPortalOutlet } from '@angular/cdk/portal';

@Component({
    selector: 'app-section-title-portal',
    templateUrl: './section-title-portal.component.html',
})
export class SectionTitlePortalComponent implements AfterViewInit {
    @ViewChild(CdkPortal)
    private portal: CdkPortal;
    private host: DomPortalOutlet;

    constructor(private cfr: ComponentFactoryResolver, private appRef: ApplicationRef, private injector: Injector) {}

    public ngAfterViewInit(): void {
        this.host = new DomPortalOutlet(
            document.querySelector('[data-section-title-portal]'),
            this.cfr,
            this.appRef,
            this.injector,
        );

        this.host.attach(this.portal);
    }

    public ngOnDestroy(): void {
        this.host.detach();
    }
}
