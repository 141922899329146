import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, AbstractControl, UntypedFormGroup } from '@angular/forms';
import { SimpleModalComponent, SimpleModalService } from '@looorent/ngx-simple-modal';
import { AbstractLogger } from '@mobilejazz/harmony-core';

import { AlertModalComponent, AlertType } from '../alert-modal/alert-modal.component';
import { RedeemModalPresenter } from './redeem-modal.presenter';
import { WaitModalService } from '@app/shared/services/wait-modal.service';

export interface RedeemModalView {
    onDisplayLoading(isLoading: boolean): void;
    onDisplayMessage(title: string, message: string, type: AlertType, icon: string, subText?: string): void;
    onNotifyClose(): void;
}

export interface RedeemModalData {
    code?: string;
    isFromLink?: boolean;
}

@Component({
    selector: 'app-redeem-modal',
    templateUrl: './redeem-modal.component.html',
    styleUrls: ['./redeem-modal.component.scss'],
})
export class RedeemModalComponent
    extends SimpleModalComponent<RedeemModalData, void>
    implements RedeemModalView, OnInit
{
    // Modal input
    public code?: string;
    public isFromLink = false;

    public isLoading = false;
    public redeemForm: UntypedFormGroup;

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly logger: AbstractLogger,
        private readonly modalService: SimpleModalService,
        @Inject('RedeemModalPresenter')
        public readonly presenter: RedeemModalPresenter,
        private readonly waitModal: WaitModalService,
    ) {
        super();
        this.presenter.attachView(this);
    }

    public ngOnInit(): void {
        // Before `ngOnInit` modal input data is not initialized yet
        this.redeemForm = this.fb.group({
            code: [this.code ?? '', [Validators.required]],
        });

        this.presenter.onViewNgOnInit(this.isFromLink);
    }

    public isControlInvalid(form: AbstractControl, field: string): boolean {
        return form.get(field).invalid && form.get(field).touched;
    }

    public onDisplayLoading(isLoading: boolean): void {
        this.isLoading = isLoading;
        this.waitModal.setVisible(isLoading);
    }

    public onDisplayMessage(title: string, message: string, type: AlertType, icon: string, subText?: string): void {
        this.modalService.addModal(AlertModalComponent, { title, message, type, icon, subText });
    }

    public onNotifyClose(): void {
        this.close();
    }

    async onRedeemSubmit() {
        this.redeemForm.markAllAsTouched();
        this.logger.info('RedeemModal', 'Submit');

        if (this.redeemForm.invalid) {
            this.logger.info('RedeemModal', `Form is invalid`);
            return;
        }

        const data = this.redeemForm.value;
        this.presenter.onActionRedeem(data.code);
    }
}
