import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'relativeTime',
})
export class RelativeTimePipe implements PipeTransform {
    transform(date: Date): string {
        if (!date) {
            return '';
        }
        const now = new Date();
        const diff = now.getTime() - date.getTime();

        if (diff < 60000) {
            // Less than a minute
            const seconds = Math.floor(diff / 1000);
            return seconds + 's ago';
        } else if (diff < 3600000) {
            // Less than an hour
            const minutes = Math.floor(diff / 60000);
            return minutes + 'm ago';
        } else if (diff < 86400000) {
            // Less than a day
            const hours = Math.floor(diff / 3600000);
            return hours + 'h ago';
        } else if (diff < 604800000) {
            // Less than a week
            const days = Math.floor(diff / 86400000);
            return days + 'd ago';
        } else if (diff < 2628000000) {
            // Less than a month (assuming an average of 30.44 days per month)
            const weeks = Math.floor(diff / 604800000);
            return weeks + 'w ago';
        } else if (diff < 31536000000) {
            // Less than a year
            const months = Math.floor(diff / 2628000000);
            return months + 'mo ago';
        } else {
            // More than a year
            const years = Math.floor(diff / 31536000000);
            return years + 'y ago';
        }
    }
}
