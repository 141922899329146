import { Observable } from 'rxjs';
import { ActivityEvent, FaceFilterModel } from '@together/common';

export enum StreamEventType {
    RemoteDisconnected,
    RemoteDropped,
    RemoteJoined,
    RemoteReconnecting,
    PhotoboothReady,
    RemoteVideoUnpublished,
    RemoteVideoPublished,
    LocalVideoUnpublished,
    LocalVideoPublished,
    LocalVideoToggled,
}

export interface StreamEvent {
    type: StreamEventType;
    value?: any;
}

export interface IVideoStream {
    activityEvent$: Observable<ActivityEvent>;
    event$: Observable<StreamEvent>;

    connect(): Promise<void>;
    disconnect(): void;
    getLocalCapture(): Promise<Blob>;
    getRemoteCapture(): Promise<Blob>;
    getLocalVideoEl(): HTMLVideoElement;
    getRemoteVideoEl(): HTMLVideoElement | HTMLCanvasElement;
    send(event: ActivityEvent);
    setMicrophoneState(state: boolean): IVideoStream;
    setVideoState(state: boolean): IVideoStream;
    switchCamera(): Promise<void>;
    applyFaceFilter(filter: FaceFilterModel): Promise<void>;
    clearFaceFilter(): void;
    startPhotobooth(): Promise<void>;
    shutDownDeepAR(): void;
}
