import { ModalsService } from '@app/shared/services/modals.service';
import { PurchaseGiftModalView } from './purchase-gift-modal.component';
import { WaitModalService } from '@app/shared/services/wait-modal.service';
import { GiftProduct, IStoreService } from '@app/shared/services/store/store-service.interface';
import { AnalyticsEvent, LogAnalyticsEventInteractor, UserService, SingularEvent } from '@together/common';

export interface PurchaseGiftModalPresenter {
    attachView(view: PurchaseGiftModalView): void;
    onActionClose(): void;
    onActionPurchase(product: GiftProduct): Promise<void>;
    onActionShowManageGifts(): void;
    onActionShowLearnMoreGifts(): void;
    onViewNgOnInit(): void;
}

export class DefaultPurchaseGiftModalPresenter implements PurchaseGiftModalPresenter {
    private view: PurchaseGiftModalView;

    constructor(
        private logAnalyticsEvent: LogAnalyticsEventInteractor,
        private modals: ModalsService,
        private wait: WaitModalService,
        private store: IStoreService,
        private userService: UserService,
    ) {}

    public attachView(view: PurchaseGiftModalView): void {
        this.view = view;
        this.logAnalyticsEvent.execute(AnalyticsEvent.GiftScreenDisplayed);
    }

    public async onViewNgOnInit(): Promise<void> {
        this.view.onDisplayLoading(true);
        this.view.onDisplayProducts(await this.store.getGiftProducts());
        this.view.onDisplayLoading(false);
    }

    public onActionClose(): void {
        this.view.onNotifyClose();
    }

    public async onActionPurchase(product: GiftProduct): Promise<void> {
        this.wait.show();

        try {
            const promoCode = await this.store.purchaseGift(product);
            this.onActionClose();
            this.wait.hide();
            this.logAnalyticsEvent.execute(AnalyticsEvent.GiftPurchased); // This is only for the Android Flow
            this.logAnalyticsEvent.execute(SingularEvent.SngPurchaseConsumables, {
                purchase_type: 'subscription gift',
            });
            await this.modals.alert(
                'Done!',
                `You're all set. Your purchase was successful.`,
                'success',
                'checked-round',
            );
            this.userService.triggerSuccessConfetti();
            this.modals.showShareGift(promoCode);
        } catch (e) {
            this.wait.hide();
            this.modals.alert('Oops!', 'There was an issue while purchasing the gift.', 'error', 'error', '(gift_005)');
        }
    }

    public onActionShowManageGifts(): void {
        this.onActionClose();
        this.modals.showManageGifts();
    }

    public onActionShowLearnMoreGifts(): void {
        this.modals.showLearnMoreGifts();
    }
}
