<div class="modal-clean modal-clean--wide modal-pending-invites --centered">
    <div class="modal-clean__content --no-outer-padding">
        <div class="modal-clean__header">
            <div class="modal__title">Pending Invitation</div>
            <div class="modal__close" (click)="closeModal()"></div>
        </div>

        <div class="modal-pending-invites__container" *ngIf="invites?.length > 0">
            <div class="modal-pending-invites__container__desc">
                You have {{ invites.length }} pending {{ invites.length > 1 ? 'invitations' : 'invitation' }}.
            </div>
            <div class="invites-grid">
                <div class="invites-row" *ngFor="let invite of invites">
                    <div class="invite">
                        <div
                            class="invite__avatar avatar"
                            [style.backgroundImage]="'url(' + invite?.creator?.getAvatarUrl() + ')'"></div>
                        <div class="invite__info-box">
                            <div class="invite__info-box__name">{{ invite?.creator?.displayName }}</div>
                            <div class="invite__info-box__action-buttons">
                                <button
                                    class="button-v2 --border-grey --rounded --wide --decline-btn"
                                    (click)="deny(invite)">
                                    Decline
                                </button>
                                <button class="button-v2 --purple --rounded --wide" (click)="accept(invite)">
                                    Accept
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-pending-invites__container__more-links">
                <a class="" (click)="acceptAll()"> Accept all invitations</a>
            </div>
        </div>
        <div class="modal-pending-invites__empty-state-container" *ngIf="invites?.length === 0">
            <img src="/assets/add-friends.svg" />
            <div class="title">You don’t have any pending requests.</div>
            <div class="content">You’re all caught up. Add more contacts to read books and play games together!</div>
        </div>
    </div>
</div>
