import { Inject, Injectable } from '@angular/core';
import { IStoreService } from '@app/shared/services/store/store-service.interface';
import { fromEventPattern, Subscription, Subject, ReplaySubject } from 'rxjs';
import { interpret, Interpreter, StateNode } from 'xstate';
import { SimpleModalService } from '@looorent/ngx-simple-modal';
import { AbstractLogger } from '@mobilejazz/harmony-core';

import { ActionType, AlertModal, AlertModalComponent } from '@app/web/modals/alert-modal/alert-modal.component';
import { CallRouterMachineFactory } from '../together/machines/call-router.machine.factory';
import { getStateDebugStr, getCallRouterContextStr } from '../utilities';
import {
    CanMakeCallsInteractor,
    CreateCallMetadataInteractor,
    UserService,
    UserModel,
    CanMakeCallsResult,
    APP_VERSION_COMPATIBILITY,
} from '@together/common';

import {
    CallRouterContext,
    CallRouterEvent,
    CallRouterStateSchema,
    CallRouterState,
} from '../together/machines/call-router.machine';
import { PurchaseTimeModalComponent } from '@app/web/modals/purchase-time-modal/purchase-time-modal.component';
import firebase from 'firebase/app';

export type CallRouterState$ = [CallRouterState, CallRouterEvent];
export type CallRouterInterpreter = Interpreter<CallRouterContext, CallRouterStateSchema, CallRouterEvent>;

@Injectable({
    providedIn: 'root', // Force it to be a singleton
})
export class CallRouterService {
    state$: Subject<CallRouterState$>;

    protected machine: CallRouterInterpreter;
    protected machineState$Sub: Subscription;

    constructor(
        protected canMakeCalls: CanMakeCallsInteractor,
        protected createCallMetadata: CreateCallMetadataInteractor,
        protected logger: AbstractLogger,
        protected modalService: SimpleModalService,
        protected userService: UserService,
        @Inject('StoreService') protected storeService: IStoreService,
        @Inject('FirebaseRemoteConfig') private readonly remoteConfig: firebase.remoteConfig.RemoteConfig,
    ) {
        this.state$ = new ReplaySubject<CallRouterState$>(1);
        this.state$.subscribe(([state, event]) => {
            this.logger.info('CallRouterService', getStateDebugStr(state));
            this.logger.info('CallRouterService', `context=${getCallRouterContextStr(state.context)}`);
            this.logger.info('CallRouterService', `event=${event.type}`);
        });
    }

    start(machine: StateNode<CallRouterContext, CallRouterStateSchema, CallRouterEvent>) {
        this.logger.info('CallRouterService', 'Start');

        // Create the call service based on the statechart machine
        this.machine = interpret(machine);

        // Create machineState$ Observable
        const addHandler = handler => {
            this.machine.onTransition(handler).start();
            return this.machine;
        };

        const removeHandler = (_, svc) => svc.stop();
        const machineState$ = fromEventPattern<CallRouterState$>(addHandler, removeHandler);

        // Pipe to state$
        this.machineState$Sub = machineState$.subscribe(this.state$);
    }

    stop() {
        this.logger.info('CallRouterService', 'Stop');

        if (this.machine) {
            this.machineState$Sub.unsubscribe();
            this.machineState$Sub = null;

            this.machine.stop();
            this.machine = null;
        }
    }

    getMachine(): CallRouterInterpreter {
        return this.machine;
    }

    acceptCall() {
        this.logger.info('CallRouterService', 'Accept call');
        this.getMachine().send('ACCEPT');
    }

    public async call(remoteUser: UserModel): Promise<void> {
        const localUser = await this.userService.getUser();
        const provider = this.identifyProvider(localUser, remoteUser);
        const result = this.canMakeCalls.execute(localUser, remoteUser, provider);

        this.logger.info('CallRouterService', `Start call to ${remoteUser.id}`);

        switch (result) {
            case CanMakeCallsResult.CanMakeCall:
                const metadata = this.createCallMetadata.execute(localUser, remoteUser);
                this.getMachine().send('START_CALL', { metadata });
                break;

            case CanMakeCallsResult.FreeLimitReached:
                const alertConfig: AlertModal = {
                    title: "You're Out of Call Time",
                    message: "You've used up your free calls. Upgrade to start a new call.",
                    subText: 'Contact us for more options at support@togethervideoapp.com',
                    type: 'success',
                    icon: 'phone-o',
                    primaryActionLabel: 'Upgrade',
                    secondaryActionLabel: 'Cancel',
                };

                this.modalService.addModal(AlertModalComponent, alertConfig).subscribe(actionType => {
                    // Open Upgrade modal after the upgrade button is clicked
                    if (actionType === ActionType.Primary) {
                        this.storeService.showUpgradeModal();
                    }
                });

                break;

            case CanMakeCallsResult.FreeDaysLimitReached:
                const trialDays = localUser.businessModelCohort?.numberOfFreeDays || 14;
                const alertConfigFreeDaysLimitReached: AlertModal = {
                    title: 'Upgrade to continue',
                    message: `Your ${trialDays}-day trial is over. Upgrade to access unlimited call time and continue this call.`,
                    type: 'success',
                    icon: 'phone-o',
                    primaryActionLabel: 'Upgrade',
                    secondaryActionLabel: 'Cancel',
                };

                this.modalService
                    .addModal(AlertModalComponent, alertConfigFreeDaysLimitReached)
                    .subscribe(actionType => {
                        // Open Upgrade modal after the upgrade button is clicked
                        if (actionType === ActionType.Primary) {
                            this.storeService.showUpgradeModal();
                        }
                    });

                break;

            case CanMakeCallsResult.LocalSubscriptionLimitReached:
                const alertConfigLocalSubscriptionLimitReached: AlertModal = {
                    title: "You're Out of Call Time",
                    message: "You've used up your included call time for the month. Upgrade to start a new call.",
                    subText: 'Contact us for more options at support@togethervideoapp.com',
                    type: 'warning',
                    icon: 'phone-o',
                    primaryActionLabel: 'Upgrade',
                    secondaryActionLabel: 'Cancel',
                };
                this.modalService
                    .addModal(AlertModalComponent, alertConfigLocalSubscriptionLimitReached)
                    .subscribe(actionType => {
                        // Open Upgrade modal after the upgrade button is clicked
                        if (actionType === ActionType.Primary) {
                            this.modalService.addModal(PurchaseTimeModalComponent);
                        }
                    });

                break;

            case CanMakeCallsResult.RemoteSubscriptionLimitReached:
                this.modalService.addModal(AlertModalComponent, {
                    title: 'Oops!',
                    message: `Your contact has used all their call time for the month.`,
                    subText: 'Contact us for more options at support@togethervideoapp.com',
                    type: 'warning',
                    icon: 'user',
                    primaryActionLabel: 'Ok',
                });

                break;
        }
    }

    cancelCall() {
        this.logger.info('CallRouterService', 'Cancel call');
        this.getMachine().send('CANCEL');
    }

    async denyCall(remoteUser: UserModel) {
        this.logger.info('CallRouterService', 'Deny call');
        this.getMachine().send('DENY');
        await this.checkForVonageCompatibility(remoteUser);
    }

    dismissIssue() {
        this.logger.info('CallRouterService', 'Dismiss issue');
        this.getMachine().send('DISMISS');
    }

    hungUpCall() {
        this.logger.info('CallRouterService', 'Hung up call');
        this.getMachine().send('HUNG_UP');
    }

    checkForVonageCompatibility(remoteUser: UserModel): Promise<boolean> {
        this.logger.info('CallRouterService OpenObserveLoggerTag', 'Check for Vonage compatibility of remote user');
        const vonageMigrationState = this.remoteConfig.getString('vonageMigrationState');
        if (remoteUser?.currentDevice?.appBuildNumber >= APP_VERSION_COMPATIBILITY.PHOTO_BOOTH_ENABLED) {
            this.logger.info('CallRouterService OpenObserveLoggerTag', 'Remote user has the latest version of the app');
            return new Promise((resolve, reject) => {
                resolve(true);
            });
        } else {
            this.logger.info('CallRouterService OpenObserveLoggerTag', 'Remote user has an older version of the app');
            if (vonageMigrationState === 'VONAGE_RECOMMENDED') {
                this.logger.info(
                    'CallRouterService OpenObserveLoggerTag',
                    'Vonage migration is recommended, show a warning',
                );
                return new Promise((resolve, reject) => {
                    this.modalService
                        .addModal(AlertModalComponent, {
                            title: 'Update required soon',
                            message: `Ask your contact to install the latest version of Together.`,
                            type: 'warning',
                            icon: 'user',
                            primaryActionLabel: 'Ok',
                        })
                        .subscribe(actionType => {
                            resolve(actionType === ActionType.Primary ? true : false);
                        });
                });
            } else if (vonageMigrationState === 'VONAGE_REQUIRED') {
                this.logger.info(
                    'CallRouterService OpenObserveLoggerTag',
                    'Vonage migration is required, show an error',
                );
                return new Promise((resolve, reject) => {
                    this.modalService
                        .addModal(AlertModalComponent, {
                            title: 'Update required',
                            message: `Ask your contact to install the latest version of Together and then try your call again.`,
                            type: 'error',
                            icon: 'user',
                            primaryActionLabel: 'Ok',
                        })
                        .subscribe(actionType => {
                            resolve(false);
                        });
                });
            } else {
                return new Promise((resolve, reject) => {
                    resolve(true);
                });
            }
        }
    }

    public identifyProvider(localUser, remoteUser) {
        const vonageMigrationState = this.remoteConfig.getString('vonageMigrationState');
        const canUseVonageAndPhotoBooth =
            (vonageMigrationState === 'VONAGE_SUPPORTED' ||
                vonageMigrationState === 'VONAGE_RECOMMENDED' ||
                vonageMigrationState === 'VONAGE_REQUIRED') &&
            localUser?.currentDevice?.appBuildNumber >= APP_VERSION_COMPATIBILITY.PHOTO_BOOTH_ENABLED &&
            remoteUser?.currentDevice?.appBuildNumber >= APP_VERSION_COMPATIBILITY.PHOTO_BOOTH_ENABLED;
        return canUseVonageAndPhotoBooth ? 'vonage' : 'twilio';
    }
}
