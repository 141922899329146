import { Component } from '@angular/core';
import { SimpleModalComponent } from '@looorent/ngx-simple-modal';
import {
    ToggleFavoriteActivityInteractor,
    ToggleFavoriteBookInteractor,
    UserService,
    UserModel,
} from '@together/common';

import { TogglefavoriteItem } from '@app/shared/components/springboard/springboard.component';
import { SpringboardService } from '@app/shared/services/springboard.service';
import { WaitModalService } from '@app/shared/services/wait-modal.service';

export interface UnfavoriteModal {
    item: TogglefavoriteItem;
}

@Component({
    selector: 'app-unfavorite-modal',
    templateUrl: './unfavorite-modal.component.html',
    styleUrls: ['./unfavorite-modal.component.scss'],
})
export class UnfavoriteModalComponent
    extends SimpleModalComponent<UnfavoriteModal, boolean>
    implements UnfavoriteModal
{
    public item: TogglefavoriteItem;

    constructor(
        private readonly waitModal: WaitModalService,
        private readonly springboard: SpringboardService,
        private readonly toggleFavoriteActivity: ToggleFavoriteActivityInteractor,
        private readonly toggleFavoriteBook: ToggleFavoriteBookInteractor,
        private readonly userService: UserService,
    ) {
        super();
    }

    public async confirm(): Promise<void> {
        this.waitModal.show();

        try {
            const user = await this.userService.getUser();

            switch (this.item.type) {
                case 'learn':
                case 'game':
                case 'topic':
                    await this.toggleFavoriteActivity.execute(user, this.item.value.id);
                    break;

                case 'book':
                    await this.toggleFavoriteBook.execute(user, this.item.value.id);
                    break;
            }

            this.springboard.refreshUser(user);
            this.result = true;
        } catch (err) {
            this.result = false;
        } finally {
            this.waitModal.hide();
            this.close();
        }
    }
}
