import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SelectFieldConfig, SelectedFilterType } from '@together/common';
import { SimpleModalComponent, SimpleModalService } from '@looorent/ngx-simple-modal';

export interface DropdownSelectModal {
    fieldConfig: SelectFieldConfig;
    selectedFilters: SelectedFilterType;
}

@Component({
    selector: 'app-dropdown-select-modal',
    templateUrl: './dropdown-select-modal.component.html',
    styleUrls: ['./dropdown-select-modal.component.scss'],
})
export class DropdownSelectModalComponent
    extends SimpleModalComponent<DropdownSelectModal, Record<string, SelectedFilterType>>
    implements OnInit
{
    public fieldConfig: SelectFieldConfig;
    public selectedFilters: SelectedFilterType;
    public form: UntypedFormGroup;

    constructor(protected modalService: SimpleModalService, protected fb: UntypedFormBuilder) {
        super();
    }

    public ngOnInit() {
        this.result = {};
        const formControl = {};
        if (this.fieldConfig?.multiple) {
            formControl[this.fieldConfig.id] = this.fb.array(
                this.fieldConfig.options.map(x =>
                    this.selectedFilters ? (this.selectedFilters as []).some(s => s == x.value) : false,
                ),
            );
        } else {
            formControl[this.fieldConfig.id] = this.selectedFilters ? [this.selectedFilters] : [];
        }
        this.form = this.fb.group(formControl);
    }

    public apply() {
        const key = this.fieldConfig.id;
        if (this.fieldConfig?.multiple) {
            this.result[key] = this.convertToValue(key);
        } else {
            this.result[key] = this.form.value[key];
        }
        this.close();
    }

    public onActionClose() {
        this.result = null;
        this.close();
    }

    public clear() {
        this.form.controls[this.fieldConfig.id].reset();
    }

    public isOptionDisabled(fieldConfig, option): boolean {
        const selectedValues = this.convertToValue(fieldConfig.id) as [];
        return selectedValues?.length >= 10 && !selectedValues.find(el => el == option);
    }

    private convertToValue(key: string) {
        return this.form.value[key].map((x, i) => x && this.fieldConfig.options[i].value).filter(x => !!x);
    }
}
