import { Subject, ReplaySubject } from 'rxjs';
import { AbstractLogger } from '@mobilejazz/harmony-core';
import * as deepar from 'deepar';
import * as OT from '@opentok/client';
import { environment } from '@env/environment';
import {
    ActivityEvent,
    AnalyticsEvent,
    CallMetadata,
    CallMode,
    FaceFilterModel,
    LogAnalyticsEventInteractor,
    GenerateVonageTokenInteractor,
    SingularEvent,
    GetPlatformInteractor,
} from '@together/common';
import { IVideoStream, StreamEvent, StreamEventType } from '../video-stream.interface';
import { App } from '@capacitor/app';
import { OpenObserveLogger } from '../logger/open-observe.logger';

// export const OT = (window as any).OT;

export class VonageVideoStream implements IVideoStream {
    activityEvent$: Subject<ActivityEvent>;
    event$: ReplaySubject<StreamEvent>;

    protected accessToken: string;
    protected sessionId: string;
    protected vonageSession: OT.Session;
    protected videoElLocal: HTMLVideoElement;
    protected videoElRemote: HTMLVideoElement;
    protected cameraFacingMode = 'user';
    protected deepAR: deepar.DeepAR;
    protected publisher: OT.Publisher;
    protected subscriber: OT.Subscriber;
    protected isAndroid: boolean;
    protected videoAutoToggledDueToBackground: boolean = false;
    constructor(
        protected logAnalyticsEvent: LogAnalyticsEventInteractor,
        protected logger: AbstractLogger,
        protected metadata: CallMetadata,
        protected iframeEl: Element,
        protected localEl: HTMLElement,
        protected remoteEl: HTMLElement,
        protected deeparCanvasEl: HTMLCanvasElement,
        protected generateVonageTokenInteractor: GenerateVonageTokenInteractor,
        protected getPlaform: GetPlatformInteractor,
    ) {
        this.activityEvent$ = new Subject();
        this.event$ = new ReplaySubject();
        this.isAndroid = this.getPlaform.isAndroid();
    }

    protected async initLocalTracks() {
        this.logger.info('VonageVideoStream OpenObserveLoggerTag', `Init local tracks`);

        return new Promise<void>((resolve, reject) => {
            this.vonageSession.publish(this.publisher, error => {
                if (error) {
                    this.handleError(error);
                    reject(error);
                } else {
                    this.logger.info('VonageVideoStream OpenObserveLoggerTag', `Local tracks published`);
                    resolve();
                }
            });
        });
    }

    protected attachTrack(event): void {
        const subscriberOptions: OT.SubscriberProperties = {
            width: '100%',
            height: '100%',
            insertDefaultUI: false,
            showControls: false,
        };

        this.subscriber = this.vonageSession.subscribe(event.stream, null, subscriberOptions, error => {
            if (error) {
                this.handleError(error);
            } else {
                this.logger.info('VonageVideoStream OpenObserveLoggerTag', `Subscriber initialised`);
            }
        });
        this.event$.next({ type: StreamEventType.RemoteVideoPublished });
        // Log that remote participant has joined, this is only logged for the Admin
        if (this.metadata.callMode === CallMode.Create) {
            this.logAnalyticsEvent.execute(AnalyticsEvent.CallParticipantJoined);
            this.logAnalyticsEvent.execute(SingularEvent.SngCallCompleted);
        }
        this.subscriber.on('videoElementCreated', event => {
            this.logger.info('VonageVideoStream OpenObserveLoggerTag', `Remote video element created`);
            this.event$.next({ type: StreamEventType.RemoteJoined });
            const videoElementRemote = this.remoteEl.querySelector('video');
            if (videoElementRemote) {
                this.remoteEl.removeChild(videoElementRemote);
            }
            this.videoElRemote = event.element as HTMLVideoElement;
            this.remoteEl.appendChild(this.videoElRemote);
        });
        this.subscriber.on('disconnected', event => {
            this.logger.info('VonageVideoStream OpenObserveLoggerTag', `Subscriber temporarily disconnected`);
            this.event$.next({ type: StreamEventType.RemoteReconnecting });
        });
        this.subscriber.on('connected', event => {
            this.logger.info('VonageVideoStream OpenObserveLoggerTag', `Subscriber reconnected`);
            this.event$.next({ type: StreamEventType.RemoteJoined });
        });
        this.subscriber.on('videoDisabled', event => {
            this.logger.info('VonageVideoStream OpenObserveLoggerTag', `Subscriber videoDisabled`);
            if (this.videoElRemote) {
                this.videoElRemote.style.display = 'none';
            }
        });
        this.subscriber.on('videoEnabled', event => {
            this.logger.info('VonageVideoStream OpenObserveLoggerTag', `Subscriber videoEnabled`);
            if (this.videoElRemote) {
                this.videoElRemote.style.display = 'block';
            }
        });
    }

    protected cleanup() {
        // const mediaTracks = [this.videoTrack, this.audioTrack];
        // this.logger.info('VonageVideoStream', `Clean-up`);
        // mediaTracks.forEach(track => track.stop());
        // this.vonageSession.localParticipant.unpublishTracks(this.tracks);
        // mediaTracks.forEach(track => track.detach());
    }

    async connect(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            this.logger.info('VonageVideoStream OpenObserveLoggerTag', `Connect to call ${this.metadata.callId}`);
            (this.logger as OpenObserveLogger).restart(this.metadata.callId, this.metadata.localParticipant.id);
            const tokenRes = await this.generateVonageTokenInteractor.execute(
                this.metadata.localParticipant.id,
                this.metadata.callId,
            );
            if (tokenRes?.data?.sessionId && tokenRes?.data?.token) {
                try {
                    this.accessToken = tokenRes.data.token;
                    this.sessionId = tokenRes.data.sessionId;
                    this.logger.info(
                        'VonageVideoStream OpenObserveLoggerTag',
                        `Connecting to session ${this.sessionId}`,
                    );
                    //Start the camera with deepar and pass that as a video source to vonage
                    const videoSource = await this.initialiseDeepAR();

                    this.vonageSession = OT.initSession(environment.vonageConfig.applicationId, this.sessionId);
                    if (this.vonageSession) {
                        this.addEventListeners();
                        const publisherOptions: OT.PublisherProperties = {
                            width: '100%',
                            height: '100%',
                            publishAudio: true,
                            publishVideo: true,
                            insertDefaultUI: false,
                            showControls: false,
                            videoSource: videoSource,
                            mirror: false,
                        };
                        this.publisher = OT.initPublisher(null, publisherOptions, error => {
                            if (error) {
                                this.handleError(error);
                                reject(error);
                            } else {
                                this.logger.info('VonageVideoStream OpenObserveLoggerTag', `Publisher initialised`);
                            }
                        });
                        this.publisher.on('videoElementCreated', event => {
                            this.logger.info('VonageVideoStream OpenObserveLoggerTag', `Local video element created`);
                            const videoElementRemote = this.localEl.querySelector('video');
                            if (videoElementRemote) {
                                this.localEl.removeChild(event.element);
                            }
                            this.videoElLocal = event.element as HTMLVideoElement;
                            this.localEl.appendChild(this.videoElLocal);
                            this.flipLocalViewX(true);
                        });
                        this.vonageSession.connect(this.accessToken, async error => {
                            if (error) {
                                this.handleError(error);
                                reject(error);
                            } else {
                                try {
                                    this.logger.info(
                                        'VonageVideoStream OpenObserveLoggerTag',
                                        `Session connected successfully`,
                                    );
                                    // Init local tracks
                                    await this.initLocalTracks();
                                    if (this.isAndroid) {
                                        this.trackAppStateChange();
                                    }
                                    resolve();
                                } catch (err) {
                                    this.handleError(err);
                                    reject(err);
                                }
                            }
                        });
                    } else {
                        this.logger.error(
                            'VonageVideoStream OpenObserveLoggerTag',
                            `Error initialising vonage session`,
                        );
                        reject(new Error('Error initialising vonage session'));
                    }
                } catch (err) {
                    this.handleError(err);
                    reject(err);
                }
            } else {
                this.logger.error(
                    'VonageVideoStream OpenObserveLoggerTag',
                    `Error getting token for call ${this.metadata.callId}`,
                );
                reject(new Error(`Error getting token for call ${this.metadata.callId}`));
            }
        });
    }

    private addEventListeners() {
        //Event when a client (including your own) connects to a Session
        this.vonageSession.on('connectionCreated', event => {
            this.logger.info('VonageVideoStream OpenObserveLoggerTag', `Client connection created`);
            //Nothing to do. streamCreated handles the participant joining
        });

        // Event when another client disconnects from the Session
        this.vonageSession.on('connectionDestroyed', event => {
            this.logger.info('VonageVideoStream OpenObserveLoggerTag', `Participant disconnected: ${event}`);
            this.disconnect();
            this.event$.next({ type: StreamEventType.RemoteDisconnected });
        });

        //Event dispatched by the Session object when another client starts publishing a stream to a Session
        this.vonageSession.on('streamCreated', event => {
            this.logger.info('VonageVideoStream OpenObserveLoggerTag', `Participant stream created`);
            this.attachTrack(event);
        });

        //Event dispatched by the Session object when another client stops publishing a stream to a Session
        this.vonageSession.on('streamDestroyed', event => {
            this.logger.info('VonageVideoStream OpenObserveLoggerTag', `Participant stream destroyed: ${event}`);
            this.disconnect();
            this.event$.next({ type: StreamEventType.RemoteDisconnected });
        });

        //Event when the local client has reconnected to the session after its connection was lost temporarily.
        this.vonageSession.on('sessionReconnected', event => {
            this.logger.info('VonageVideoStream OpenObserveLoggerTag', `Local Participant reconnected`);
            //Nothing to do here
        });

        //Event when the local client has lost its connection to an OpenTok session and is trying to reconnect.
        this.vonageSession.on('sessionReconnecting', event => {
            this.logger.info('VonageVideoStream OpenObserveLoggerTag', `Local Participant reconnecting`);
            //Nothing to do here
        });

        this.vonageSession.on('streamPropertyChanged', event => {
            this.logger.info('VonageVideoStream OpenObserveLoggerTag', `Stream Property Changed`);
            //Nothing to do here
        });

        this.vonageSession.on('signal', event => {
            this.logger.info(
                'VonageDataStream OpenObserveLoggerTag',
                `Signal sent from connection: ${event.from.connectionId} with payload: ${event}`,
            );
            if (
                event.type.includes('signal:data-track') &&
                event.from.connectionId !== this.publisher?.stream?.connection.connectionId
            ) {
                let data;
                try {
                    data = JSON.parse(event.data);
                } catch (e) {
                    data = String.fromCharCode.apply(null, new Uint8Array(data));
                    data = JSON.parse(event.data);
                }

                this.activityEvent$.next(data);
            }
        });
    }
    disconnect() {
        this.logger.info('VonageVideoStream OpenObserveLoggerTag', `Disconnect`);
        this.shutDownDeepAR();
        if (this.vonageSession) {
            //this.cleanup();
            if (this.subscriber) {
                this.vonageSession.unsubscribe(this.subscriber);
            }
            if (this.publisher) {
                this.vonageSession.unpublish(this.publisher);
                this.publisher.destroy();
            }
            this.vonageSession.disconnect();
            (this.logger as OpenObserveLogger).restart(null, this.metadata.localParticipant.id);
        }
    }

    protected getFrame(videoEl: HTMLVideoElement): Promise<Blob> {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.height = videoEl.videoHeight;
        canvas.width = videoEl.videoWidth;
        ctx.drawImage(videoEl, 0, 0, canvas.width, canvas.height);

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (blob) {
                    resolve(blob);
                } else {
                    reject();
                }
            });
        });
    }

    async getLocalCapture(): Promise<Blob> {
        return this.getFrame(this.videoElLocal);
    }

    async getRemoteCapture(): Promise<Blob> {
        //if video is turned off, create a black canvas element
        if (!this.subscriber?.stream?.hasVideo) {
            const blackCanvas = this.createBlackCanvasForVideo(this.videoElRemote);
            return new Promise((resolve, reject) => {
                blackCanvas.toBlob(blob => {
                    if (blob) {
                        resolve(blob);
                    } else {
                        reject();
                    }
                });
            });
        }
        return this.getFrame(this.videoElRemote);
    }

    getLocalVideoEl(): HTMLVideoElement {
        return this.videoElLocal;
    }

    getRemoteVideoEl(): HTMLVideoElement | HTMLCanvasElement {
        //if video is turned off, create a black canvas element
        if (!this.subscriber?.stream?.hasVideo) {
            return this.createBlackCanvasForVideo(this.videoElRemote);
        }
        return this.videoElRemote;
    }

    createBlackCanvasForVideo(videoEl) {
        const blackCanvas = document.createElement('canvas');
        blackCanvas.width = videoEl.videoWidth || 640;
        blackCanvas.height = videoEl.videoHeight || 480;
        const context = blackCanvas.getContext('2d');
        if (context) {
            context.fillStyle = 'black';
            context.fillRect(0, 0, blackCanvas.width, blackCanvas.height);
        }
        return blackCanvas;
    }

    send(event: ActivityEvent) {
        this.logger.info(
            'VonageDataStream',
            `Data-track out: ${JSON.stringify(event, null, 4)} to ${this.subscriber?.stream?.connection?.connectionId}`,
        );
        //Need to send this data only to the other peer connection and when it is available
        if (this.subscriber?.stream?.connection) {
            this.vonageSession.signal(
                {
                    type: 'data-track',
                    data: JSON.stringify(event),
                    to: this.subscriber.stream.connection,
                },
                async error => {
                    if (error) {
                        this.handleError(error);
                    }
                },
            );
        }
    }

    setMicrophoneState(state: boolean): IVideoStream {
        this.logger.info(
            'VonageVideoStream OpenObserveLoggerTag',
            `Toggle Microphone, state=${state ? 'enabled' : 'disabled'}`,
        );

        if (state) {
            this.publisher.publishAudio(true);
        } else {
            this.publisher.publishAudio(false);
        }

        return this;
    }

    setVideoState(state: boolean): IVideoStream {
        this.logger.info(
            'VonageVideoStream OpenObserveLoggerTag',
            `Toggle Video, state=${state ? 'enabled' : 'disabled'}`,
        );

        if (state) {
            this.startCameraDeepAR();
            this.publisher.publishVideo(true);
        } else {
            this.publisher.publishVideo(false);
            setTimeout(() => {
                this.deepAR?.stopCamera();
            }, 1000);
        }
        this.flipLocalViewX(state);
        return this;
    }

    async switchCamera(): Promise<void> {
        this.logger.info('VonageVideoStream OpenObserveLoggerTag', `Switch Camera from ${this.cameraFacingMode}`);
        if (this.cameraFacingMode === 'user') {
            this.cameraFacingMode = 'environment';
        } else {
            this.cameraFacingMode = 'user';
        }
        if (this.deepAR) {
            this.deepAR.stopCamera();
            await this.startCameraDeepAR();
            this.flipLocalViewX(true);
        }
    }

    private async startCameraDeepAR() {
        try {
            if (this.deepAR) {
                this.logger.info('VonageVideoStream OpenObserveLoggerTag', `Staring Camera with DeepAR`);
                await this.deepAR.startCamera({
                    mirror: false,
                    mediaStreamConstraints: {
                        video: {
                            facingMode: this.cameraFacingMode,
                        },
                    },
                });
            }
        } catch (err) {
            this.shutDownDeepAR();
            this.logAnalyticsEvent.execute(AnalyticsEvent.CameraSourceDidFail);
            throw err;
        }
    }

    private async initialiseDeepAR() {
        this.logger.info('VonageVideoStream OpenObserveLoggerTag', `Initialising DeepAR`);
        this.deepAR = await deepar.initialize({
            licenseKey: environment.deepArConfig.licenseKey,
            canvas: this.deeparCanvasEl,
            additionalOptions: {
                cameraConfig: {
                    disableDefaultCamera: true,
                    facingMode: this.cameraFacingMode,
                },
            },
        });
        await this.startCameraDeepAR();
        const mediaStream = this.deeparCanvasEl.captureStream(30);
        const videoTracks = mediaStream.getVideoTracks();
        return videoTracks[0];
    }

    public async startPhotobooth() {
        this.event$.next({ type: StreamEventType.PhotoboothReady });
    }

    public async shutDownDeepAR() {
        if (this.deepAR) {
            this.logger.info('VonageVideoStream OpenObserveLoggerTag', `Shutting Down DeepAR`);
            this.deepAR.stopCamera();
            this.deepAR.clearEffect('faceMask');
            this.deepAR.shutdown();
            this.deepAR = null;
        }
    }

    public async applyFaceFilter(filter: FaceFilterModel): Promise<void> {
        if (filter.id === 'noneId') {
            this.clearFaceFilter();
            return;
        }
        let effectUrl = filter.filterURL;
        if (this.deepAR) {
            // Clear any previously applied effect and switch to the new effect
            this.deepAR.clearEffect('faceMask');
            await this.deepAR.switchEffect(effectUrl, { slot: 'faceMask' });
        }
    }

    public async clearFaceFilter() {
        if (this.deepAR) {
            this.deepAR.clearEffect('faceMask');
        }
    }

    private flipLocalViewX(state) {
        if (this.localEl) {
            this.localEl.style.transform = state && this.cameraFacingMode === 'user' ? 'scaleX(-1)' : 'unset';
        }
    }

    //For android, track if the app is sent to background and stop video, and then start video when it is brought to foreground
    private trackAppStateChange() {
        App.addListener('appStateChange', async ({ isActive }) => {
            //Toggle the video only if the previous state was different.
            if (!isActive && this.publisher?.stream?.hasVideo) {
                this.videoAutoToggledDueToBackground = true;
                this.deepAR?.stopCamera();
                this.event$.next({ type: StreamEventType.LocalVideoToggled, value: 'OFF' });
            } else if (isActive && !this.publisher?.stream?.hasVideo && this.videoAutoToggledDueToBackground) {
                this.startCameraDeepAR();
                this.event$.next({ type: StreamEventType.LocalVideoToggled, value: 'ON' });
            }
        });
    }

    private handleError(error: any) {
        this.logger.error('VonageVideoStream OpenObserveLoggerTag', `Error: ${error}`);
    }
}
