<div class="modal-clean modal-clean--wide modal-purchase-gift --centered">
    <div class="modal-clean__content --no-outer-padding">
        <div class="modal-clean__header">
            <div class="modal__close" (click)="presenter.onActionClose()"></div>
            <div class="modal__title">Gift Together!</div>
        </div>
        <div class="modal-purchase-gift__container">
            <img class="modal-purchase-gift__gift-box" src="./assets/present-box.svg" />
            <div class="modal-purchase-gift__summary">
                <div class="modal-purchase-gift__summary__item">
                    <fa-icon [icon]="['tg', 'sticker-checked']"></fa-icon>
                    <div><span class="--highlight">Unlimited Hours</span> of video calls each month</div>
                </div>
                <div class="modal-purchase-gift__summary__item">
                    <fa-icon [icon]="['tg', 'sticker-checked']"></fa-icon>
                    <div><span class="--highlight">Incoming & outgoing</span> calls with contacts</div>
                </div>
                <div class="modal-purchase-gift__summary__item">
                    <fa-icon [icon]="['tg', 'sticker-checked']"></fa-icon>
                    <div><span class="--highlight">Access</span> to every game, activity & book</div>
                </div>
            </div>

            <div class="modal-purchase-gift__loading" *ngIf="isLoading">
                <div class="loading-animation">
                    <ng-lottie [options]="animation"></ng-lottie>
                </div>
            </div>
            <div class="modal-purchase-gift__products">
                <button
                    *ngFor="let product of products"
                    class="button-v2 --product-btn --shade-purple --large --wide --rounded"
                    [ngClass]="{ '--shade-purple': !product.isBestValue, '--purple': product.isBestValue }"
                    (click)="presenter.onActionPurchase(product)">
                    <span class="--product-info">
                        <span class="--title">{{ product.title }}</span>
                        <span *ngIf="product.isLifetime" class="--sub-text">One-time payment</span>
                    </span>
                    <span class="--product-price">{{ product.price }}</span>
                    <span *ngIf="product.isBestValue" class="--product-tag">MOST POPULAR</span>
                </button>
            </div>
            <div class="modal-purchase-gift__disclaimer">{{ disclaimerText }}</div>
            <div class="modal-purchase-gift__more-links">
                <a class="--more-info" (click)="presenter.onActionShowLearnMoreGifts()">Learn More</a>
                <a class="" href="mailto:support@togethervideoapp.com">Contact Us</a>
            </div>
        </div>
    </div>
</div>
