<div class="modal-clean modal-clean--wide modal-age-verification">
    <div class="modal-clean__content">
        <div class="modal-clean__header">
            <div class="modal__title">Age Verification</div>
            <div class="modal__close" (click)="close()"></div>
        </div>
        <div class="modal-age-verification__container">
            <img class="modal-age-verification__heading-image" src="./assets/images/shield.svg" alt="" />
            <p>This part is for grown-ups. Please enter the year you were born.</p>
            <form class="form" [formGroup]="ageForm" autocomplete="false" spellcheck="false">
                <div class="modal-age-verification__row">
                    <input
                        #millennium
                        class="modal-age-verification__input"
                        formControlName="millennium"
                        type="number"
                        min="0" />
                    <input
                        #century
                        class="modal-age-verification__input"
                        formControlName="century"
                        type="number"
                        min="0" />
                    <input
                        #decade
                        class="modal-age-verification__input"
                        formControlName="decade"
                        type="number"
                        min="0" />
                    <input #year class="modal-age-verification__input" formControlName="year" type="number" min="0" />
                </div>
            </form>
        </div>
    </div>
</div>
