import { PlayDemoModalComponent } from './../../web/modals/play-demo-modal/play-demo-modal.component';
import { AlertType } from './../../web/modals/alert-modal/alert-modal.component';
import { Injectable } from '@angular/core';
import { GetPlatformInteractor, IsAdultInteractor, PromoCodeModel } from '@together/common';
import { SimpleModalService } from '@looorent/ngx-simple-modal';

import { AlertModalComponent } from '@app/web/modals/alert-modal/alert-modal.component';
import { ConfirmationModalComponent } from '@app/web/modals/confirmation-modal/confirmation-modal.component';
import { ManageGiftsModalComponent } from '@app/web/modals/manage-gifts-modal/manage-gifts-modal.component';
import { PurchaseGiftModalComponent } from '@app/web/modals/purchase-gift-modal/purchase-gift-modal.component';
import { RedeemModalComponent } from '@app/web/modals/redeem-modal/redeem-modal.component';
import { ShareGiftModalComponent } from '@app/web/modals/share-gift-modal/share-gift-modal.component';
// import { StripePaymentModalComponent } from '@app/web/modals/stripe-payment-modal/stripe-payment-modal.component';
import { WebviewModalComponent } from '@app/web/modals/webview-modal/webview-modal.component';
import { UnfavoriteModalComponent } from '@app/web/modals/unfavorite-modal/unfavorite-modal.component';
import { TogglefavoriteItem } from '../components/springboard/springboard.component';
import { ActionType } from '@app/web/modals/alert-modal/alert-modal.component';
import { ManageSubscriptionModalComponent } from '@app/web/modals/manage-subscription-modal/manage-subscription-modal.component';
import { AgeVerificationModalComponent } from '@app/web/modals/age-verification-modal/age-verification-modal.component';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root', // Force it to be a singleton
})
export class ModalsService {
    constructor(
        protected modalService: SimpleModalService,
        protected isAdult: IsAdultInteractor,
        protected getPlatform: GetPlatformInteractor,
    ) {}

    public alert(title: string, message: string, type: AlertType, icon: string, subText?: string): Promise<ActionType> {
        return this.modalService.addModal(AlertModalComponent, { title, message, subText, type, icon }).toPromise();
    }

    public confirm(title: string, message: string): Promise<boolean> {
        return this.modalService.addModal(ConfirmationModalComponent, { title, message }).toPromise();
    }

    public showManageGifts(): void {
        this.modalService.addModal(ManageGiftsModalComponent);
    }

    public showLearnMoreGifts(): void {
        this.modalService.addModal(WebviewModalComponent, {
            title: 'Gifting',
            url: environment.giftExplainerURL,
        });
    }

    public showPurchaseGift(): void {
        this.modalService.addModal(PurchaseGiftModalComponent);
    }

    public showRedeemGift(): void {
        this.modalService.addModal(RedeemModalComponent);
    }

    public showShareGift(promoCode: PromoCodeModel): void {
        this.modalService.addModal(ShareGiftModalComponent, { promoCode });
    }

    public showStripePayment(): void {
        // this.modalService.addModal(StripePaymentModalComponent);
    }

    public showUnfavorite(item: TogglefavoriteItem): void {
        this.modalService.addModal(UnfavoriteModalComponent, { item });
    }

    public showWebview(title: string, url: string): void {
        this.modalService.addModal(WebviewModalComponent, { title, url });
    }

    public playDemo(activity): void {
        this.modalService.addModal(PlayDemoModalComponent, { activity });
    }

    public showCreateAccountAlert(message): Promise<ActionType> {
        return this.modalService
            .addModal(
                AlertModalComponent,
                {
                    title: 'Create an account',
                    message: message,
                    type: 'warning',
                    icon: 'user',
                    primaryActionLabel: 'Sign Up',
                    secondaryActionLabel: 'Cancel',
                },
                {
                    closeOnEscape: false,
                    closeOnClickOutside: false,
                },
            )
            .toPromise();
    }

    public async showManageSubscriptionModal(): Promise<void> {
        const isAdult = await this.isAdult.execute();
        const isWeb = this.getPlatform.isWeb();
        if (isAdult || isWeb) {
            this.modalService.addModal(ManageSubscriptionModalComponent, null, {
                closeOnEscape: false,
                closeOnClickOutside: false,
            });
        } else {
            const hasVerified = await this.modalService.addModal(AgeVerificationModalComponent).toPromise();
            if (hasVerified) {
                this.modalService.addModal(ManageSubscriptionModalComponent, null, {
                    closeOnEscape: false,
                    closeOnClickOutside: false,
                });
            } else {
                this.modalService.addModal(AlertModalComponent, {
                    title: 'Oops!',
                    message: 'You are not allowed to do this action',
                    type: 'warning',
                    icon: 'exclamation',
                });
            }
        }
    }

    public removeAll(): void {
        this.modalService.removeAll();
    }
}
