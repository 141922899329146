<ng-container *ngIf="!isLoading; else loadingView">
    <ng-container
        *ngIf="
            favorites?.books?.length > 0 ||
                favorites?.games?.length > 0 ||
                favorites?.activities?.length > 0 ||
                favorites?.topics?.length > 0;
            else noFavoritesView
        ">
        <div *ngIf="!showAllFavoritesModal">
            <div *ngIf="favorites?.books?.length > 0" class="section">
                <div class="section__title-wrapper">
                    <div class="section__title">Books</div>
                    <div class="section__title-link">
                        <span (click)="showAllFavorites('books')">See All</span>
                    </div>
                </div>
                <div class="section__content">
                    <div class="horizontal-scroll horizontal-scroll">
                        <app-book-thumbnail
                            *ngFor="let item of favorites.books | slice: 0:10"
                            [book]="item"
                            [isContentRestricted]="checkIfContentIsRestricted(item)"
                            (selected)="emitSelect($event, 'books')">
                        </app-book-thumbnail>
                    </div>
                </div>
            </div>
            <div *ngIf="favorites?.games?.length > 0" class="section">
                <div class="section__title-wrapper">
                    <div class="section__title">Games</div>
                    <div class="section__title-link">
                        <span (click)="showAllFavorites('games')">See All</span>
                    </div>
                </div>
                <div class="section__content">
                    <div class="horizontal-scroll horizontal-scroll">
                        <app-activity-thumbnail
                            *ngFor="let item of favorites.games | slice: 0:10"
                            [activity]="item"
                            [isContentRestricted]="checkIfContentIsRestricted(item)"
                            (selected)="emitSelect($event, 'games')">
                        </app-activity-thumbnail>
                    </div>
                </div>
            </div>
            <div *ngIf="favorites?.activities?.length > 0" class="section">
                <div class="section__title-wrapper">
                    <div class="section__title">Activities</div>
                    <div class="section__title-link">
                        <span (click)="showAllFavorites('activities')">See All</span>
                    </div>
                </div>
                <div class="section__content">
                    <div class="horizontal-scroll horizontal-scroll">
                        <app-activity-thumbnail
                            *ngFor="let item of favorites.activities | slice: 0:10"
                            [activity]="item"
                            [isContentRestricted]="checkIfContentIsRestricted(item)"
                            (selected)="emitSelect($event, 'activities')">
                        </app-activity-thumbnail>
                    </div>
                </div>
            </div>
            <div *ngIf="favorites?.topics?.length > 0" class="section">
                <div class="section__title-wrapper">
                    <div class="section__title">Topics</div>
                    <div class="section__title-link">
                        <span (click)="showAllFavorites('topics')">See All</span>
                    </div>
                </div>
                <div class="section__content">
                    <div class="horizontal-scroll horizontal-scroll">
                        <app-activity-thumbnail
                            *ngFor="let item of favorites.topics | slice: 0:10"
                            [activity]="item"
                            [isContentRestricted]="checkIfContentIsRestricted(item)"
                            (selected)="emitSelect($event, 'topics')">
                        </app-activity-thumbnail>
                    </div>
                </div>
            </div>
        </div>
        <div role="dialog" class="modal-wrapper modal-wrapper--f" *ngIf="showAllFavoritesModal">
            <div class="favorites modal-clean modal-clean--full-screen --centered">
                <div class="modal-clean__content --no-outer-padding">
                    <div class="modal-clean__header">
                        <div class="modal__title">Favorite {{ section }}</div>
                        <div class="modal__close --back" (click)="showAllFavoritesModal = false"></div>
                    </div>
                    <div class="favorites__container">
                        <div class="section__result" *ngIf="section === 'books'">
                            <app-book-thumbnail
                                *ngFor="let item of favorites.books"
                                [book]="item"
                                [isContentRestricted]="checkIfContentIsRestricted(item)"
                                (selected)="emitSelect($event, 'books')">
                            </app-book-thumbnail>
                        </div>
                        <div class="section__result" *ngIf="section === 'games'">
                            <app-activity-thumbnail
                                *ngFor="let item of favorites.games"
                                [activity]="item"
                                [isContentRestricted]="checkIfContentIsRestricted(item)"
                                (selected)="emitSelect($event, 'games')">
                            </app-activity-thumbnail>
                        </div>
                        <div class="section__result" *ngIf="section === 'activities'">
                            <app-activity-thumbnail
                                *ngFor="let item of favorites.activities"
                                [activity]="item"
                                [isContentRestricted]="checkIfContentIsRestricted(item)"
                                (selected)="emitSelect($event, 'activities')">
                            </app-activity-thumbnail>
                        </div>
                        <div class="section__result" *ngIf="section === 'topics'">
                            <app-activity-thumbnail
                                *ngFor="let item of favorites.topics"
                                [activity]="item"
                                [isContentRestricted]="checkIfContentIsRestricted(item)"
                                (selected)="emitSelect($event, 'topics')">
                            </app-activity-thumbnail>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #noFavoritesView>
        <div class="favorite-empty-container">
            <img src="/assets/empty-state-favorites.png" />
            <p>Tap the heart icon on your favorite books, games, topics and activities to save them here!</p>
        </div>
    </ng-template>
</ng-container>
<ng-template #loadingView>
    <div class="loading-animation">
        <ng-lottie [options]="animation"></ng-lottie>
    </div>
</ng-template>
