<div class="modal-clean modal-clean--wide modal-settings --centered feedback">
    <div class="modal-clean__content">
        <div class="modal-clean__header">
            <div class="modal__title">Your feedback</div>
            <div class="modal__close" (click)="close()"></div>
        </div>
        <div class="feedback__container">
            <form
                class="form"
                [formGroup]="feedbackForm"
                (ngSubmit)="onSubmit()"
                autocomplete="false"
                spellcheck="false">
                <div class="feedback__row">
                    <label class="feedback__label">How are you liking the app so far?</label>
                    <app-rating (rated)="setRating($event)"></app-rating>
                </div>
                <div class="feedback__row">
                    <label class="feedback__label" for="message"
                        >Please tell us how we can make the app better for you:</label
                    >
                    <textarea id="message" formControlName="message"></textarea>
                </div>
                <button class="button-v2 --purple --large --wide --rounded" type="submit" [disabled]="isLoading">
                    <span *ngIf="!isLoading">Send</span>
                    <span *ngIf="isLoading">Sending…</span>
                </button>
            </form>
        </div>
    </div>
</div>
