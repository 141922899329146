import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleModalComponent, SimpleModalService } from '@looorent/ngx-simple-modal';
import { GetCallFeedbackModalInteractor } from '@together/common';
import { environment } from '@env/environment';
import { AppLauncher } from '@capacitor/app-launcher';

@Component({
    selector: 'app-rating-modal',
    templateUrl: './app-rating-modal.component.html',
    styleUrls: ['./app-rating-modal.component.scss'],
})
export class AppRatingModalComponent extends SimpleModalComponent<void, void> {
    constructor(
        protected getCallFeedbackModal: GetCallFeedbackModalInteractor,
        protected modalService: SimpleModalService,
        protected router: Router,
    ) {
        super();
    }

    public async writeReview(): Promise<void> {
        await this.getCallFeedbackModal.markAppAsReviewed();
        AppLauncher.openUrl({ url: environment.store.native.storeAndroidURL });
        this.close();
    }
}
