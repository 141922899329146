import { AbstractLogger } from '@mobilejazz/harmony-core';
import { Component } from '@angular/core';
import { SimpleModalComponent, SimpleModalService } from '@looorent/ngx-simple-modal';

import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { WaitModalService } from '@app/shared/services/wait-modal.service';
import { LegalAgreementModel, UserService, AcceptLegalAgreementInteractor } from '@together/common';
import { environment } from '@env/environment';
import { WebviewModalComponent } from '../webview-modal/webview-modal.component';

export interface AcceptLegalAgreementData {
    agreement: LegalAgreementModel;
}

@Component({
    selector: 'app-accept-legal-agreement-modal',
    templateUrl: './accept-legal-agreement-modal.component.html',
    styleUrls: ['./accept-legal-agreement-modal.component.scss'],
})
export class AcceptLegalAgreementModalComponent extends SimpleModalComponent<AcceptLegalAgreementData, void> {
    agreement: LegalAgreementModel;

    constructor(
        protected accceptLegalAgreement: AcceptLegalAgreementInteractor,
        protected logger: AbstractLogger,
        protected modalService: SimpleModalService,
        protected userService: UserService,
        protected waitService: WaitModalService,
    ) {
        super();
    }

    async accept() {
        this.waitService.show();

        try {
            await this.accceptLegalAgreement.execute(this.agreement.id, 'USER_GRANTED');
            this.close();
        } catch (err) {
            this.logger.error('AcceptLegalAgreementModalComponent', err);
            const message = err ? err.message : `There was an error!`;
            this.modalService.addModal(AlertModalComponent, {
                title: 'Oops!',
                message: message,
                type: 'error',
                icon: 'error',
            });
        } finally {
            this.waitService.hide();
        }
    }

    showPrivacyModal() {
        this.logger.info('AcceptLegalAgreementModalComponent', 'Show Privacy modal');

        this.modalService.addModal(WebviewModalComponent, {
            title: 'Privacy Policy',
            url: environment.privacyPolicyURL,
        });
    }

    showTermsModal() {
        this.logger.info('AcceptLegalAgreementModalComponent', `Show 'Terms of Service' modal`);

        this.modalService.addModal(WebviewModalComponent, {
            title: 'Terms of Service',
            url: environment.termsOfServiceURL,
        });
    }
}
