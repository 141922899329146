import { VonageVideoStream } from './../vonage/vonage-video-stream.class';
import firebase from 'firebase/app';
import { Inject, Injectable } from '@angular/core';
import { StateNode, assign } from 'xstate';
import { AbstractLogger } from '@mobilejazz/harmony-core';
import { map } from 'rxjs/operators';

import { ActivityHub } from '../activity-hub.class';
import { CallDOMService } from '../../services/call-dom.service';
import { IframeActivity } from '../activities/iframe.class';
import { ReadTogetherActivity } from '../activities/read-together.class';
import { TwilioVideoStream } from '../twilio/video-stream.class';
import {
    CallMetadata,
    ActivityMode,
    LogAnalyticsEventInteractor,
    GetCallConfigurationInteractor,
    CallConfigurationModel,
    UpdateCallConfigurationInteractor,
    UpdateCallLatencyInteractor,
    PutActivityStatisticsInteractor,
    GetFaceFiltersInteractor,
    ProcessPhotoboothImageInteractor,
    GetAllPhotoboothImagesInteractor,
    DeletePhotoboothImagesInteractor,
    GenerateTwilioTokenInteractor,
    GenerateVonageTokenInteractor,
    APP_VERSION_COMPATIBILITY,
} from '@together/common';
import { StreamEventType } from '../video-stream.interface';
import {
    CallContext,
    CallStateSchema,
    CallEvent,
    CallMachine,
    ActivityLaunchIframeEvent,
    ActivityLaunchReadEvent,
    UpdateCallConfigurationEvent,
    ProcessHeartBeatEvent,
    FlipFaceFiltersEvent,
    ApplyFaceFilterRequestEvent,
} from './call.machine';
import { CallDataTrackMonitor } from '../call-data-track-monitor';
import { ActivityStatisticsMonitor } from '../activities/activity-statistics-monitor';
import { Photobooth } from '../photobooth.class';
import { CallRouterService } from '@app/shared/services/call-router.service';

@Injectable()
export class CallMachineFactory {
    constructor(
        protected callDOMService: CallDOMService,
        @Inject('FirebaseFirestore') protected firestore: firebase.firestore.Firestore,
        @Inject('FirebaseRemoteConfig') private readonly remoteConfig: firebase.remoteConfig.RemoteConfig,
        protected logAnalyticsEvent: LogAnalyticsEventInteractor,
        protected getCallConfiguration: GetCallConfigurationInteractor,
        protected updateCallConfiguration: UpdateCallConfigurationInteractor,
        protected updateCallLatencyInteractor: UpdateCallLatencyInteractor,
        protected logger: AbstractLogger,
        protected putActivityStatisticsInteractor: PutActivityStatisticsInteractor,
        protected getFaceFiltersInteractor: GetFaceFiltersInteractor,
        protected processPhotoboothImageInteractor: ProcessPhotoboothImageInteractor,
        protected getPhotoboothImageInteractor: GetAllPhotoboothImagesInteractor,
        protected deletePhotoboothImageInteractor: DeletePhotoboothImagesInteractor,
        protected generateTwilioTokenInteractor: GenerateTwilioTokenInteractor,
        protected generateVonageTokenInteractor: GenerateVonageTokenInteractor,
        protected callRouterService: CallRouterService,
    ) {}

    create(metadata: CallMetadata): StateNode<CallContext, CallStateSchema, CallEvent> {
        const isAdmin = metadata.activityMode === ActivityMode.Admin;
        const canUseVonageAndPhotoBooth =
            this.callRouterService.identifyProvider(metadata.localParticipant, metadata.remoteParticipant) === 'vonage';
        const initialContext: CallContext = {
            callConfiguration: this.getCallConfiguration.execute(),
            hub: null,
            iframeActivity: null,
            isAdmin,
            readActivity: null,
            stream: null,
            videoInputs: [],
            dataTrackMonitor: null,
            photobooth: null,
            canUseVonageAndPhotoBooth,
        };

        this.logger.info('CallMachineFactory', 'Create machine');

        // Create CallMachine with it's dependencies
        return CallMachine.withConfig({
            actions: {
                closeActivity: ({ hub }) => {
                    hub.closeActivity();
                },
                disableMicrophone: ({ stream }) => {
                    stream.setMicrophoneState(false);
                },
                disableVideo: ({ stream }) => {
                    stream.setVideoState(false);
                },
                disconnect: ({ stream }) => {
                    stream.disconnect();
                },
                enableMicrophone: ({ stream }) => {
                    stream.setMicrophoneState(true);
                },
                enableVideo: ({ stream }) => {
                    stream.setVideoState(true);
                },
                switchCamera: ({ stream }) => {
                    stream.switchCamera();
                },
                shutdownDeepAR: ({ stream }) => {
                    stream.shutDownDeepAR();
                },
                launchActivityIframe: ({ hub }, event: ActivityLaunchIframeEvent) => {
                    if (isAdmin) {
                        hub.launchActivityIframe(event.activity);
                    } else {
                        hub.launchActivityIframeAsChild(event.activity);
                    }
                },
                launchActivityRead: ({ hub }, event: ActivityLaunchReadEvent) => {
                    if (isAdmin) {
                        hub.launchActivityRead(event.book);
                    } else {
                        hub.launchActivityReadAsChild(event.book);
                    }
                },
                notifyRequestingPlayTime: ({ hub }) => {
                    hub.requestPlayTime();
                },
                notifySpringboardClose: ({ hub }) => {
                    if (isAdmin) {
                        hub.closeSpringboard();
                    }
                },
                notifySpringboardOpen: ({ callConfiguration, hub }) => {
                    if (callConfiguration.allowChildToStartActivities) {
                        hub.openSpringboard();
                    }
                },
                initialisePhotobooth: (
                    { callConfiguration, photobooth, stream },
                    event: { type: 'SHOW_PHOTOBOOTH'; notifyRemote?: boolean },
                ) => {
                    photobooth.initialisePhotobooth(event.notifyRemote);
                },
                notifyRequestingPhotobooth: ({ photobooth }) => {
                    photobooth.notifyRequestPhotobooth();
                },
                notifyPhotoboothClose: (
                    { callConfiguration, photobooth },
                    event: { type: 'BACK'; notifyRemote?: boolean },
                ) => {
                    photobooth.closePhotobooth(event.notifyRemote);
                },
                notifyFlipFaceFilters: ({ photobooth }, event: FlipFaceFiltersEvent) => {
                    if (event.notifyRemote) {
                        photobooth.notifyFlipFaceFilters();
                    }
                },
                startTakePhotoTimer: ({ photobooth }) => {
                    photobooth.startTakePhotoTimer();
                },
                createPhotoBoothImage: async ({ photobooth }) => {
                    await photobooth.createPhotoBoothImage();
                },
                notifyTakePhotoBoothPhoto: async (
                    { photobooth },
                    event: { type: 'TAKE_PHOTO'; notifyRemote?: boolean },
                ) => {
                    if (event.notifyRemote) {
                        photobooth.notifyTakePhotoBoothPhoto();
                    }
                },
                notifyApplyFilterRequest: ({ photobooth }, event: ApplyFaceFilterRequestEvent) => {
                    photobooth.notifyApplyFilterRequest(event.filter);
                },
                showPhotoboothGallery: ({ photobooth }) => {
                    photobooth.showPhotoboothGallery();
                },
                notifyToggleVideo: ({ hub }, event: { type: 'TOGGLE_VIDEO'; value: string }) => {
                    hub.notifyToggleVideo(event.value);
                },
                notifyToggleMicrophone: ({ hub }, event: { type: 'TOGGLE_MICROPHONE'; value: string }) => {
                    hub.notifyToggleMicrophone(event.value);
                },
                sendCallConfiguration: ({ callConfiguration, hub, isAdmin }) => {
                    if (isAdmin) {
                        hub.notifyCallConfigurationChanged(callConfiguration);
                    }
                },
                toggleAllowChildActivities: assign({
                    callConfiguration: (
                        { callConfiguration, hub },
                        event: { type: 'TOGGLE_ALLOW_CHILD_ACTIVITIES'; toggleSpringboard?: boolean },
                    ) => {
                        const newConfig = new CallConfigurationModel(
                            !callConfiguration.allowChildToStartActivities,
                            callConfiguration.enableCallHighlights,
                        );

                        hub.notifyCallConfigurationChanged(newConfig);
                        this.updateCallConfiguration.execute(newConfig);
                        if (event.toggleSpringboard) {
                            if (newConfig.allowChildToStartActivities) {
                                hub.openSpringboard();
                            } else {
                                hub.closeSpringboard();
                            }
                        }
                        return newConfig;
                    },
                }),
                updateCallConfiguration: assign({
                    callConfiguration: (_, event: UpdateCallConfigurationEvent) => {
                        return event.configuration;
                    },
                }),
                startMonitoring: ({ dataTrackMonitor }) => {
                    if (isAdmin) {
                        dataTrackMonitor.startMonitoring();
                    }
                },
                stopMonitoring: ({ dataTrackMonitor }) => {
                    if (isAdmin) {
                        dataTrackMonitor.stopMonitoring();
                    }
                },
                processHeartbeatEvent: ({ dataTrackMonitor }, event: ProcessHeartBeatEvent) => {
                    dataTrackMonitor.processHeartbeatEvent(event.eventData);
                },
                uploadLatencyData: ({ dataTrackMonitor }) => {
                    if (isAdmin) {
                        dataTrackMonitor.uploadLatencyData();
                    }
                },
            },
            activities: {},
            delays: {},
            guards: {
                isAdmin: ctx => {
                    return ctx.isAdmin;
                },
            },
            services: {
                activityHubEvent$: ({ hub }) => hub.event$,
                connect: ({ stream }) => {
                    return async callback => {
                        try {
                            await stream.connect();
                            callback('DONE');
                        } catch (err) {
                            if ('name' in err && err.name === 'NotAllowedError') {
                                callback('PERMISSIONS_DENIED');
                                this.logger.info(
                                    'CallMachineFactory OpenObserveLoggerTag',
                                    'Permissions denied by the user',
                                );
                            } else {
                                callback('ERROR');
                                this.logger.error('CallMachineFactory', err);
                            }
                        }
                    };
                },
                streamEvent$: ({ stream }) => {
                    return stream.event$.pipe(
                        map(streamEvent => {
                            this.logger.info('CallMachineFactory OpenObserveLoggerTag', `Stream event: ${streamEvent}`);
                            switch (streamEvent.type) {
                                case StreamEventType.RemoteDropped:
                                case StreamEventType.RemoteDisconnected:
                                    // return { type: 'REMOTE_DROPPED' };
                                    return { type: 'REMOTE_DISCONNECTED' };
                                case StreamEventType.RemoteJoined:
                                    return { type: 'REMOTE_CONNECTED' };
                                case StreamEventType.RemoteReconnecting:
                                    return { type: 'REMOTE_RECONNECTING' };
                                case StreamEventType.PhotoboothReady:
                                    return { type: 'PHOTOBOOTH_READY' };
                                case StreamEventType.RemoteVideoPublished:
                                    return { type: 'REMOTE_TRACK_PUBLISHED' };
                                case StreamEventType.RemoteVideoUnpublished:
                                    return { type: 'REMOTE_TRACK_UNPUBLISHED' };
                                case StreamEventType.LocalVideoPublished:
                                    return { type: 'LOCAL_TRACK_PUBLISHED' };
                                case StreamEventType.LocalVideoUnpublished:
                                    return { type: 'LOCAL_TRACK_UNPUBLISHED' };
                                case StreamEventType.LocalVideoToggled:
                                    return { type: 'TOGGLE_VIDEO', value: streamEvent.value };
                            }
                        }),
                    );
                },
                // unblock$: () => {
                //     return this.childGuardService.unblock$.pipe(
                //         map(() => ({ type: 'MENU_UNBLOCK' })),
                //     );
                // },
                waitDOM: async () => {
                    this.logger.info('CallMachineFactory OpenObserveLoggerTag', 'Waiting DOM elements…');

                    const dom = await this.callDOMService.getElements();

                    this.logger.info('CallMachineFactory OpenObserveLoggerTag', 'DOM elements ready');
                    let stream;
                    if (canUseVonageAndPhotoBooth) {
                        this.logger.info('CallMachineFactory OpenObserveLoggerTag', 'Using Vonage for video call');
                        stream = new VonageVideoStream(
                            this.logAnalyticsEvent,
                            this.logger,
                            metadata,
                            dom.iframe,
                            dom.local,
                            dom.remote,
                            dom.deeparCanvas,
                            this.generateVonageTokenInteractor,
                        );
                    } else {
                        this.logger.info('CallMachineFactory OpenObserveLoggerTag', 'Using Twilio for video call');
                        stream = new TwilioVideoStream(
                            this.logAnalyticsEvent,
                            this.logger,
                            metadata,
                            dom.iframe,
                            dom.local,
                            dom.remote,
                            dom.deeparCanvas,
                            this.generateTwilioTokenInteractor,
                        );
                    }

                    const iframeActivity = new IframeActivity(dom.iframe, metadata);
                    const readActivity = new ReadTogetherActivity(this.logger);
                    const activityStatisticsMonitor: ActivityStatisticsMonitor = new ActivityStatisticsMonitor(
                        metadata,
                        this.putActivityStatisticsInteractor,
                        this.logger,
                        this.logAnalyticsEvent,
                    );

                    const photobooth = new Photobooth(
                        this.logger,
                        stream,
                        metadata,
                        this.processPhotoboothImageInteractor,
                        this.getPhotoboothImageInteractor,
                        this.deletePhotoboothImageInteractor,
                        this.logAnalyticsEvent,
                        this.getFaceFiltersInteractor,
                    );

                    const hub = new ActivityHub(
                        this.firestore,
                        iframeActivity,
                        this.logger,
                        metadata,
                        readActivity,
                        stream,
                        activityStatisticsMonitor,
                        photobooth,
                    );
                    const dataTrackMonitor = new CallDataTrackMonitor(
                        this.logger,
                        metadata,
                        stream,
                        this.updateCallLatencyInteractor,
                    );
                    return {
                        hub,
                        iframeActivity,
                        readActivity,
                        stream,
                        dataTrackMonitor,
                        photobooth,
                        canUseVonageAndPhotoBooth,
                    };
                },
            },
        }).withContext(initialContext);
    }
}
