<div class="modal-clean modal-clean--full-screen modal-legal --centered">
    <div class="modal-clean__content">
        <div class="modal-legal__container">
            <div class="modal-legal__text-heading">Important</div>
            <div class="modal-legal__logo-box">
                <img src="/assets/images/kinzoo_logo.svg" />
            </div>
            <div class="modal-legal__sub-heading">We’re updating our Terms of Use and Privacy Policy</div>
            <div class="modal-legal__text-body">
                <p>
                    Kinzoo is making updates to our <a (click)="showPrivacyModal()">Privacy Policy</a> and
                    <a (click)="showTermsModal()">Terms of Use</a> to, among other things, make them easier to read and
                    reflective of our evolving products and practices and update our arbitration agreement and policies
                    for handling user disputes.
                </p>
                <p>
                    Please take some time to read them to make sure we are on the same page regarding your personal data
                    and our practices and rules.
                </p>
                <p>
                    Once you have read the updated Privacy Policy and Terms of Service, please select “Accept” to agree
                    to and acknowledge those updated documents so that you and your child(ren) can continue enjoying
                    Kinzoo.
                </p>
                <p>
                    Please feel free to contact us by email at
                    <a href="mailto:support@kinzoo.com">support&#64;kinzoo.com</a> if you have any questions or
                    concerns.
                </p>
            </div>
        </div>
    </div>
    <div class="modal-legal__accept-action">
        <button class="button-v2 --purple --large --rounded --wide" (click)="accept()">Accept</button>
    </div>
</div>
