import { IframeActivityDOMEls, VideoStreamDOMEls } from '../together/machines/call.machine';
import { Injectable } from '@angular/core';

type DOMEls = IframeActivityDOMEls & VideoStreamDOMEls;

@Injectable({
    providedIn: 'root', // Force it to be a singleton
})
export class CallDOMService {
    protected promise: Promise<DOMEls>;
    protected resolveIframe: (els: IframeActivityDOMEls) => void;
    protected resolveVideo: (els: VideoStreamDOMEls) => void;

    constructor() {
        this.reset();
    }

    reset() {
        // Create deferred promise
        const promiseIframe: Promise<IframeActivityDOMEls> = new Promise((resolve) => {
            this.resolveIframe = resolve;
        });

        const promiseVideo: Promise<VideoStreamDOMEls> = new Promise((resolve) => {
            this.resolveVideo = resolve;
        });

        // Create promise that will wait to video & read to resolve
        this.promise = Promise.all([promiseIframe, promiseVideo]).then(([iframe, video]) => ({
            ...iframe,
            ...video,
        }));
    }

    async getElements(): Promise<DOMEls> {
        return this.promise;
    }

    setIframeActivityEls(els: IframeActivityDOMEls) {
        this.resolveIframe(els);
    }

    setVideoStreamEls(els: VideoStreamDOMEls) {
        this.resolveVideo(els);
    }
}
