import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { sortBooksForUser } from '../../utilities';
import { UserModel, BookModel, GetUserBooksInteractor } from '@together/common';

@Component({
    selector: 'app-books-catalog',
    templateUrl: './books-catalog.component.html',
    styleUrls: ['./books-catalog.component.scss'],
})
export class BooksCatalogComponent implements OnInit, OnChanges {
    @Input() public addSafeMargin = false;
    @Input() public users: UserModel[];
    @Input() public showClose: boolean;

    @Output() public readonly bookClick: EventEmitter<void> = new EventEmitter<void>();
    @Output() public readonly closeClick: EventEmitter<void> = new EventEmitter<void>();

    public books: BookModel[] | false = false;

    constructor(protected getUserBooks: GetUserBooksInteractor) {}

    ngOnInit() {}

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('users')) {
            const hasUsersInput = typeof this.users !== 'undefined' && this.users !== null;

            if (hasUsersInput) {
                const userIds = this.users.map(u => u.id);
                const books = await this.getUserBooks.execute(userIds);

                if (this.users.length) {
                    this.books = sortBooksForUser(books, this.users[0]);
                } else {
                    this.books = books;
                }
            }
        }
    }
}
