<div class="modal-clean modal-clean--wide modal-cancel-subscription --centered">
    <div class="modal-clean__content --no-outer-padding" #container>
        <div class="modal-clean__header">
            <div *ngIf="activeSection !== 'finish'" class="modal__close --back" (click)="previousSection()"></div>
            <div *ngIf="activeSection == 'finish'" class="modal__close" (click)="close()"></div>
            <div class="modal__title">Cancel Membership</div>
        </div>
        <div class="modal-cancel-subscription__container" *ngIf="activeSection == 'benefits'">
            <div class="modal-cancel-subscription__container__title">
                Are you sure you want to cancel your membership?
            </div>
            <ng-container *ngIf="!user?.isLimitedContentCohort()">
                <div class="modal-cancel-subscription__container__subtitle">
                    You’ll lose out on your subscriber benefits:
                </div>
                <div class="value-list-container">
                    <div class="value-box --blue">
                        <div class="value-box__icon"><img src="/assets/images/unlimited.png" /></div>
                        <div class="value-box__content">
                            <span class="--highlight">Unlimited</span> video calling each month
                        </div>
                    </div>
                    <div class="value-box --yellow">
                        <div class="value-box__icon"><img src="/assets/images/incoming-ougoing-icon.png" /></div>
                        <div class="value-box__content">
                            <span class="--highlight">Incoming & outgoing</span> calls with contacts
                        </div>
                    </div>
                    <div class="value-box --pink">
                        <div class="value-box__icon"><img src="/assets/images/books-activities.png" /></div>
                        <div class="value-box__content">
                            <span class="--highlight">Access</span> to games, books & activities
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="user?.isLimitedContentCohort() && user.isBasicSubscription()">
                <div class="modal-cancel-subscription__container__subtitle">You’ll lose access to all this:</div>
                <div class="content-image-container" *ngIf="!isLoadingContent; else loadingView">
                    <ng-container *ngFor="let item of content?.books">
                        <div class="image" *ngIf="item.coverImageURL">
                            <img [src]="item.coverImageURL" />
                            <span class="label"> Book </span>
                        </div>
                    </ng-container>
                    <ng-container *ngFor="let item of content?.games">
                        <div class="image" *ngIf="item.coverImageURL">
                            <img [src]="item.coverImageURL" />
                            <span class="label"> Game </span>
                        </div>
                    </ng-container>
                    <ng-container *ngFor="let item of content?.activities">
                        <div class="image" *ngIf="item.coverImageURL">
                            <img [src]="item.coverImageURL" />
                            <span class="label"> Activity </span>
                        </div>
                    </ng-container>
                    <ng-container *ngFor="let item of content?.topics">
                        <div class="image" *ngIf="item.coverImageURL">
                            <img [src]="item.coverImageURL" />
                            <span class="label"> Topic </span>
                        </div>
                    </ng-container>
                    <ng-container *ngFor="let item of content?.photoboothFilters">
                        <div class="image" *ngIf="item.thumbnailURL">
                            <img [src]="item.thumbnailURL" />
                            <span class="label"> Filter </span>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="user?.isLimitedContentCohort() && user.isPremiumSubscription()">
                <div class="modal-cancel-subscription__container__subtitle">You’ll lose access to all this:</div>
                <div class="features-box" *ngIf="subscribedTier">
                    <div class="features-box__features__item" *ngFor="let content of subscribedTier.contentIncluded">
                        <div class="features-box__features__item__count">{{ content.value }}</div>
                        <div class="features-box__features__item__text">{{ content.label }}</div>
                    </div>
                </div>
            </ng-container>
            <div class="showcase-footer">
                <button class="button-v2 --shade-purple --medium --wide --rounded" (click)="nextSection()">
                    Yes, Cancel
                </button>
                <button class="button-v2 --purple --medium --wide --rounded" (click)="close()">No, Don’t Cancel</button>
            </div>
            <ng-template #loadingView>
                <div class="loading-animation">
                    <ng-lottie [options]="animation"></ng-lottie>
                </div>
            </ng-template>
        </div>
        <div class="modal-cancel-subscription__container" *ngIf="activeSection == 'cancel_survey'">
            <div class="modal-cancel-subscription__container__title">Please take a moment to help us improve.</div>
            <div class="feedback__container">
                <form
                    *ngIf="cancelSurveyForm"
                    class="form"
                    [formGroup]="cancelSurveyForm"
                    autocomplete="false"
                    spellcheck="false">
                    <div class="feedback__row">
                        <label class="feedback__label" for="feedback">Why are you canceling your subscription?</label>
                        <textarea
                            id="reason"
                            placeholder="Enter your answer here"
                            formControlName="reason"
                            autocorrect="off"
                            autocapitalize="none"
                            autocomplete="off"></textarea>
                    </div>
                    <div class="feedback__row">
                        <label class="feedback__label" for="feedback">What can we do better?</label>
                        <textarea
                            id="feedback"
                            placeholder="Enter your answer here"
                            formControlName="feedback"
                            autocorrect="off"
                            autocapitalize="none"
                            autocomplete="off"></textarea>
                    </div>
                </form>
            </div>
            <div class="action-container">
                <button
                    class="button-v2 --shade-purple --large --wide --rounded"
                    (click)="submitSurvey()"
                    [disabled]="!cancelSurveyForm.valid">
                    Submit and Cancel
                </button>
                <div class="action-link" (click)="close()">Keep Together</div>
            </div>
        </div>
        <div class="modal-cancel-subscription__container" *ngIf="activeSection == 'finish'">
            <div class="confirmation-container">
                <div class="confirmation-container__image">
                    <img src="/assets/images/crying-red-panda.png" />
                </div>
                <div class="confirmation-container__title">We’re sad to see you go!</div>
                <div class="confirmation-container__content" *ngIf="isAndroid">
                    To finish canceling, go to your device settings and deactivate your subscription.
                </div>
                <div class="confirmation-container__content" *ngIf="!isAndroid">
                    To finish canceling, go to the cancelation page and deactivate your subscription
                </div>
            </div>
            <div class="action-container">
                <button class="button-v2 --purple --large --wide --rounded" (click)="openManageSubscription()">
                    {{ isAndroid ? 'Go to Settings' : 'Go to Cancelation' }}
                </button>
            </div>
        </div>
    </div>
</div>
