<div class="modal-clean modal-clean--wide modal-permissions --centered">
    <div class="modal-clean__content">
        <div class="modal-clean__header">
            <div class="modal__title">Permissions Needed</div>
            <div class="modal__close" (click)="continue()"></div>
        </div>
        <div class="modal-permissions__container">
            <p class="modal-permissions__message">
                Together is a video chat app that needs access to your camera and microphone during calls. Continue to
                learn how to give permissions.
            </p>
            <div class="modal-permissions__box">
                <span class="permission-icon">
                    <img src="/assets/video-camera-trimmed.png" />
                </span>
                <div class="modal-permissions__box__content">
                    <div class="modal-permissions__box__content__title">Camera and Microphone</div>
                    <div class="modal-permissions__box__content__text">
                        So your family can see and hear you when you video chat with them
                    </div>
                </div>
                <div class="modal-permissions__box__denied-icon">
                    <fa-icon [icon]="['tg', 'error']"></fa-icon>
                </div>
            </div>
            <button
                class="modal-permissions__action-btn button-v2 --purple --large --wide --rounded"
                (click)="continue()">
                Continue
            </button>
            <button class="modal-permissions__help-link button-v2 --empty --wide">
                <a href="mailto:support@togethervideoapp.com">Need Help?</a>
            </button>
        </div>
    </div>
</div>
