import { CallRouterService } from '@app/shared/services/call-router.service';
import { filter } from 'rxjs/operators';
import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscripionPromotion } from '@env/types';
import firebase from 'firebase/app';
import { AnimationOptions } from 'ngx-lottie';
import { SimpleModalComponent, SimpleModalService } from '@looorent/ngx-simple-modal';
import { Subscription } from 'rxjs';
import { ModalsService } from '../../../shared/services/modals.service';

import { environment } from '@env/environment';

import {
    DiscountConfig,
    IStoreService,
    LimitedContentCohortConfig,
    SubscriptionProduct,
    SubscriptionState,
    SubscriptionTier,
} from '@app/shared/services/store/store-service.interface';
import { WaitModalService } from '@app/shared/services/wait-modal.service';
import { waitPromise } from '@app/shared/utilities';
import {
    AnalyticsEvent,
    SingularEvent,
    GetPlatformInteractor,
    LogAnalyticsEventInteractor,
    UserModel,
    UserService,
    CampaignModel,
} from '@together/common';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { WebviewModalComponent } from '../webview-modal/webview-modal.component';
import { ContentByTierModalComponent } from '../content-by-tier-modal/content-by-tier-modal.component';

@Component({
    selector: 'app-upgrade-tier-based-modal',
    templateUrl: './upgrade-tier-based-modal.component.html',
    styleUrls: ['./upgrade-tier-based-modal.component.scss'],
})
export class UpgradeTierBasedModalComponent extends SimpleModalComponent<void, void> implements OnInit, OnDestroy {
    public disablePurchase: boolean = false;
    public selectedProductToPurchase: SubscriptionProduct;
    public selectedTier: SubscriptionTier;
    public tiers: SubscriptionTier[];
    public subscriptionPromotion: SubscripionPromotion;
    public isSubscribed: boolean;
    public animation: AnimationOptions = {
        path: './assets/animations/super-hero-panda.json',
    };

    protected user: UserModel;
    protected subs = new Subscription();
    protected limitedContentCohortConfig: LimitedContentCohortConfig;

    constructor(
        protected logAnalyticsEvent: LogAnalyticsEventInteractor,
        @Inject('FirebaseFunctions') protected functions: firebase.functions.Functions,
        protected getPlatform: GetPlatformInteractor,
        protected modalService: SimpleModalService,
        @Inject('FirebaseRemoteConfig') protected remoteConfig: firebase.remoteConfig.RemoteConfig,
        protected router: Router,
        @Inject('StoreService') protected storeService: IStoreService,
        protected userService: UserService,
        protected waitService: WaitModalService,
        protected modals: ModalsService,
        protected callRouterService: CallRouterService,
        protected zone: NgZone,
    ) {
        super();
    }

    public async ngOnInit(): Promise<void> {
        [this.user, this.limitedContentCohortConfig, this.subscriptionPromotion] = await Promise.all([
            this.userService.getUser(),
            this.storeService.getLimitedContentCohortConfig(true),
            this.storeService.getSubscriptionPromotion(),
        ]);

        this.tiers = this.limitedContentCohortConfig.tiers;

        this.logAnalyticsEvent.execute(AnalyticsEvent.SubscriptionScreenDisplayed, {
            subscription_status: this.user.analyticsType,
        });

        this.logAnalyticsEvent.execute(SingularEvent.SngCheckoutInitiated);

        this.selectBestValue();

        // Get subscription state & watch for changes
        this.isSubscribed = this.user.isSubscribed();

        this.subs.add(
            this.storeService.getSubscriptionState$().subscribe(state => {
                this.isSubscribed = state.isSubscribed;
            }),
        );
        this.subs.add(
            this.callRouterService.state$.subscribe(([state]) => {
                this.zone.run(() => {
                    if (state?.matches('busy.calling')) {
                        this.disablePurchase = true;
                    }
                });
            }),
        );
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    async openManageSubscriptionModal(): Promise<void> {
        await this.modals.showManageSubscriptionModal();
    }

    public async purchase(product): Promise<void> {
        this.selectedProductToPurchase = product;
        let result: SubscriptionState;

        this.waitService.show();
        this.logAnalyticsEvent.execute(AnalyticsEvent.SubscriptionClickBuy, {
            product_id: this.selectedProductToPurchase.id,
            subscription_status: this.user.analyticsType,
        });

        try {
            result = await this.storeService.purchaseSubscription(this.selectedProductToPurchase);
            if (result.isSubscribed) {
                this.close();
                this.storeService.showBuySubscriptionSuccessModal();
                this.logAnalyticsEvent.execute(AnalyticsEvent.SubscriptionPurchased, {
                    product_id: this.selectedProductToPurchase.id,
                    subscription_status: this.user.analyticsType,
                });
                this.logAnalyticsEvent.execute(SingularEvent.SngSubscribe);
            }
        } finally {
            this.waitService.hide();
        }
    }

    public selectProduct(tier, product) {
        tier.productToShowAsSelected = product;
        this.selectedTier = tier;
    }

    public selectTier(tier) {
        this.selectedTier = tier;
    }

    public selectBestValue() {
        const bestValueTier = this.tiers.find(p => p.isBestValue);
        this.selectedTier = bestValueTier ? bestValueTier : this.tiers[0];
        this.tiers.forEach(tier => {
            const bestValueProduct = tier.products.find(p => p.isBestValue);
            tier.productToShowAsSelected = bestValueProduct ? bestValueProduct : tier.products[0];
        });
    }

    public showPrivacyModal() {
        this.modalService.addModal(WebviewModalComponent, {
            title: 'Privacy Policy',
            url: environment.privacyPolicyURL,
        });
    }

    public showTermsModal() {
        this.modalService.addModal(WebviewModalComponent, {
            title: 'Terms of Service',
            url: environment.termsOfServiceURL,
        });
    }

    public async restorePurchases(): Promise<void> {
        try {
            this.waitService.show();
            await waitPromise(this.storeService.restorePurchases(), 4000);
        } catch (err) {
            this.modalService.addModal(AlertModalComponent, {
                title: 'Oops!',
                message: `There was an issue restoring your purchases. Please try again.`,
                type: 'error',
                icon: 'error',
            });
        } finally {
            this.waitService.hide();
        }
    }

    public async showContentIncludedModal(tier: SubscriptionTier) {
        const result = await this.modalService
            .addModal(ContentByTierModalComponent, {
                tier: tier.tierId,
                tierName: tier.tierName,
                displayPrice: `${tier.productToShowAsSelected.price} / ${tier.productToShowAsSelected.period}`,
            })
            .toPromise();
        if (result) {
            this.purchase(tier.productToShowAsSelected);
        }
    }

    public getDiscountProductConfig(tier): DiscountConfig {
        const productWithDiscount = tier.products.find(product => !!product.discountConfig);
        return productWithDiscount ? productWithDiscount.discountConfig : null;
    }

    public hasPromotionForTiers(): boolean {
        return this.tiers.some(tier => {
            return this.getDiscountProductConfig(tier) !== null;
        });
    }

    public getCharacterCount(price): number {
        return price.length;
    }
}
