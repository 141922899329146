import { AbstractLogger } from '@mobilejazz/harmony-core';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SimpleModalComponent, SimpleModalService } from '@looorent/ngx-simple-modal';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import {
    ActivityMode,
    avatars,
    UpdateProfileInteractor,
    UserModel,
    UserService,
    MixpanelService,
} from '@together/common';

export interface ProfileModalComponentData {
    /** Set this to true to signal the component that it was opened as part of the sign-up flow */
    isSignUpFlow: boolean;
}

@Component({
    selector: 'app-profile-modal',
    templateUrl: './profile-modal.component.html',
    styleUrls: ['./profile-modal.component.scss'],
})
export class ProfileModalComponent
    extends SimpleModalComponent<ProfileModalComponentData, void>
    implements OnInit, OnDestroy
{
    public user: UserModel;
    public isLoading = false;
    public settingsForm: UntypedFormGroup;
    public avatars = avatars;
    public isSignUpFlow = false;

    protected subs = new Subscription();

    constructor(
        protected fb: UntypedFormBuilder,
        protected logger: AbstractLogger,
        protected modalService: SimpleModalService,
        protected router: Router,
        protected updateProfile: UpdateProfileInteractor,
        protected userService: UserService,
        protected mixpanelService: MixpanelService,
    ) {
        super();
        this.logger.info('ProfileModalComponent', 'Created');
    }

    async ngOnInit() {
        this.logger.info('ProfileModalComponent', 'Init');

        this.user = await this.userService.getUser();

        this.initFormData();

        this.subs.add(
            this.settingsForm.get('preferredActivityMode').valueChanges.subscribe(activityMode => {
                if (activityMode === 0) {
                    this.settingsForm.get('adultDisplayName').setValidators(null);
                } else {
                    this.settingsForm.get('adultDisplayName').setValidators([Validators.required]);
                }

                this.settingsForm.get('adultDisplayName').updateValueAndValidity();
            }),
        );
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    private initFormData(): void {
        this.settingsForm = this.fb.group({
            preferredActivityMode: [this.user.preferredActivityMode],
            displayName: [this.user.displayName || '', [Validators.required]],
            adultDisplayName: [this.user.adultDisplayName || ''],
            avatar: [this.user.avatar || ''],
        });
    }

    public selectAvatar(avatar) {
        this.settingsForm.patchValue({ avatar });
    }

    public isAdultActive(): boolean {
        return this.settingsForm.value.preferredActivityMode === 0;
    }

    public isChildActive(): boolean {
        return this.settingsForm.value.preferredActivityMode === 1;
    }

    public async onSubmit() {
        const payload = this.settingsForm.value;
        this.isLoading = true;

        try {
            await this.updateProfile.execute(this.user, payload);
            this.userService.reload();
            this.mixpanelService.setUserProperty('user_type', this.isAdultActive() ? 'adult' : 'child');
            this.close();
        } catch (err) {
            this.logger.error('ProfileModal', err);

            this.modalService.addModal(AlertModalComponent, {
                title: 'Oops!',
                message: 'There was an issue while saving your profile. Please try again.',
                type: 'error',
                icon: 'error',
            });
        } finally {
            this.isLoading = false;
        }
    }

    public setActivityMode(activityMode: number): void {
        this.settingsForm.patchValue({
            preferredActivityMode: activityMode,
        });
    }
}
