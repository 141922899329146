<div class="page activity-catalog" [ngClass]="{ 'page--safe': addSafeMargin }">
    <div class="header">
        <div class="header__close" (click)="closeClick.emit()">Close</div>
        <div class="header__title">Activity Catalog</div>
    </div>
    <div class="page__content">
        <div class="grid grid--thumbnails" *ngIf="activities === false">
            <app-thumbnail-placeholder></app-thumbnail-placeholder>
            <app-thumbnail-placeholder></app-thumbnail-placeholder>
            <app-thumbnail-placeholder></app-thumbnail-placeholder>
            <app-thumbnail-placeholder></app-thumbnail-placeholder>
            <app-thumbnail-placeholder></app-thumbnail-placeholder>
            <app-thumbnail-placeholder></app-thumbnail-placeholder>
            <app-thumbnail-placeholder></app-thumbnail-placeholder>
            <app-thumbnail-placeholder></app-thumbnail-placeholder>
            <app-thumbnail-placeholder></app-thumbnail-placeholder>
            <app-thumbnail-placeholder></app-thumbnail-placeholder>
            <app-thumbnail-placeholder></app-thumbnail-placeholder>
            <app-thumbnail-placeholder></app-thumbnail-placeholder>
            <app-thumbnail-placeholder></app-thumbnail-placeholder>
        </div>
        <div class="grid grid--thumbnails" *ngIf="activities">
            <div *ngFor="let activity of activities">
                <app-activity-thumbnail
                    [activity]="activity"
                    (click)="activityClick.emit(activity)">
                </app-activity-thumbnail>
            </div>
        </div>
    </div>
</div>
