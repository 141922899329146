<div class="modal-clean modal-clean--wide modal-activity --centered">
    <div class="modal-clean__content --no-outer-padding">
        <div class="modal-clean__header">
            <div class="modal__close" (click)="close()"></div>
            <div class="modal__title">{{ activity.title }}</div>
            <div class="modal__btn-action" (click)="toggleActivityAsFavorite()" *ngIf="isLoggedIn">
                <button *ngIf="isFav" class="modal__btn-action__user-fav button-v2 --icon-only --red">
                    <fa-icon [icon]="['tg', 'heart-solid']"></fa-icon>
                </button>
                <button *ngIf="!isFav" class="modal__btn-action__user-fav button-v2 --icon-only --red">
                    <fa-icon class="v-center" [icon]="['tg', 'heart-empty']"></fa-icon>
                </button>
            </div>
        </div>
        <div class="modal-activity__summary-row">
            <div class="modal-activity__detail">
                <div class="modal-activity__image">
                    <app-activity-thumbnail [activity]="activity" [onlyImage]="true"></app-activity-thumbnail>
                </div>
                <div class="modal-activity__description">{{ activity.description }}</div>
            </div>
        </div>
        <div class="modal-activity__screenshots">
            <img class="modal-activity__screenshot" [src]="url" *ngFor="let url of activity.screenshotUrls" />
        </div>

        <div *ngIf="activity.allowsDemoMode" class="modal-activity__action-button --text-center">
            <button class="button-v2 --purple --rounded --large --wide" (click)="playDemo()">Play Demo</button>
        </div>
        <div *ngIf="activity.instructionsURL" class="modal-activity__link --text-center">
            <a (click)="showInstructions()">See instructions</a>
        </div>
    </div>
</div>
