<div class="modal-clean modal-clean--wide modal-settings --centered feedback">
    <div class="modal-clean__content">
        <div class="modal-clean__header">
            <div class="modal__title">How was your call?</div>
            <div class="modal__close" (click)="close()"></div>
        </div>
        <div class="feedback__container">
            <form
                class="form"
                [formGroup]="callFeedbackForm"
                (ngSubmit)="onSubmit()"
                autocomplete="false"
                spellcheck="false">
                <div class="feedback__row">
                    <label class="feedback__label">Video quality:</label>
                    <app-rating (rated)="setVideoQualityRating($event)"></app-rating>
                </div>
                <div class="feedback__row">
                    <label class="feedback__label">Activities offered:</label>
                    <app-rating (rated)="setActivitiesRating($event)"></app-rating>
                </div>
                <div class="feedback__row">
                    <label class="feedback__label" for="feedback">Any comments or suggestions?</label>
                    <textarea id="feedback" formControlName="feedback"></textarea>
                </div>
                <button class="button-v2 --purple --large --wide --rounded" type="submit" [disabled]="isLoading">
                    <span *ngIf="!isLoading">Send</span>
                    <span *ngIf="isLoading">Sending…</span>
                </button>
            </form>
        </div>
    </div>
</div>
