import firebase from 'firebase/app';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleModalComponent, SimpleModalService } from '@looorent/ngx-simple-modal';
import { AnimationOptions } from 'ngx-lottie';
import { Subscription } from 'rxjs';
import { environment } from '@env/environment';
import {
    IStoreService,
    SubscriptionProduct,
    SubscriptionState,
} from '@app/shared/services/store/store-service.interface';
import { WaitModalService } from '@app/shared/services/wait-modal.service';
import { WebviewModalComponent } from '../webview-modal/webview-modal.component';
import {
    AnalyticsEvent,
    SingularEvent,
    GetPlatformInteractor,
    LogAnalyticsEventInteractor,
    UserModel,
    UserService,
} from '@together/common';
import { getCurrencySymbol } from '@angular/common';

@Component({
    selector: 'app-change-subscription-modal',
    templateUrl: './change-subscription-modal.component.html',
    styleUrls: ['./change-subscription-modal.component.scss'],
})
export class ChangeSubscriptionModalComponent extends SimpleModalComponent<void, void> implements OnInit, OnDestroy {
    public selectedProductId: string;
    public upgradeProduct: SubscriptionProduct;
    public isSubscribed: boolean;
    public showAndroidNotice: boolean;
    public disclaimerPolicyText: string;
    public animation: AnimationOptions = {
        path: './assets/animations/super-hero-panda.json',
    };

    public user: UserModel;
    protected subs = new Subscription();

    constructor(
        protected logAnalyticsEvent: LogAnalyticsEventInteractor,
        @Inject('FirebaseFunctions') protected functions: firebase.functions.Functions,
        protected getPlatform: GetPlatformInteractor,
        protected modalService: SimpleModalService,
        @Inject('FirebaseRemoteConfig') protected remoteConfig: firebase.remoteConfig.RemoteConfig,
        protected router: Router,
        @Inject('StoreService') protected storeService: IStoreService,
        protected userService: UserService,
        protected waitService: WaitModalService,
    ) {
        super();
    }

    public async ngOnInit(): Promise<void> {
        let upgradeProducts;
        [this.user, upgradeProducts] = await Promise.all([
            this.userService.getUser(),
            await this.storeService.getUpgradeSubscriptionProducts(),
        ]);
        if (upgradeProducts?.length) {
            this.upgradeProduct = upgradeProducts.find(p => p.periodInMonths === 12);
        }
        this.logAnalyticsEvent.execute(AnalyticsEvent.SubscriptionScreenDisplayed, {
            subscription_status: this.user.analyticsType,
        });
        // Decide which notices to show based on the platform
        this.showAndroidNotice = this.getPlatform.isAndroid();

        // Get RemoteConfig text
        this.disclaimerPolicyText = this.remoteConfig.getString('disclaimerPolicyText').trim();

        // Get subscription state & watch for changes
        this.isSubscribed = this.user.isSubscribed();

        this.subs.add(
            this.storeService.getSubscriptionState$().subscribe(state => {
                this.isSubscribed = state.isSubscribed;
            }),
        );
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    public async purchase(id: string, inApp: boolean = true): Promise<void> {
        this.selectedProductId = id;
        let result: SubscriptionState;

        this.waitService.show();
        this.logAnalyticsEvent.execute(AnalyticsEvent.SubscriptionClickBuy, {
            product_id: this.selectedProductId,
            subscription_status: this.user.analyticsType,
        });

        try {
            result = await this.storeService.upgradeSubscription(this.upgradeProduct, inApp);
            if (result.isUpgraded) {
                this.close();
                this.storeService.showBuySubscriptionSuccessModal();
                this.logAnalyticsEvent.execute(AnalyticsEvent.SubscriptionPurchased, {
                    product_id: this.selectedProductId,
                    subscription_status: this.user.analyticsType,
                });
                this.logAnalyticsEvent.execute(SingularEvent.SngSubscribe);
            }
        } finally {
            this.waitService.hide();
        }
    }

    showPrivacyModal() {
        this.modalService.addModal(WebviewModalComponent, {
            title: 'Privacy Policy',
            url: environment.privacyPolicyURL,
        });
    }

    showTermsModal() {
        this.modalService.addModal(WebviewModalComponent, {
            title: 'Terms of Service',
            url: environment.termsOfServiceURL,
        });
    }

    getCurrencySymbol(currencyCode) {
        return currencyCode ? getCurrencySymbol(currencyCode, 'narrow') : '';
    }
}
