import firebase from 'firebase/app';
import { Component, Inject, OnInit } from '@angular/core';
import { SimpleModalComponent } from '@looorent/ngx-simple-modal';

import { PurchaseGiftModalPresenter } from './purchase-gift-modal.presenter';
import { GiftProduct } from '@app/shared/services/store/store-service.interface';
import { AnimationOptions } from 'ngx-lottie';

export interface PurchaseGiftModalView {
    onDisplayLoading: (isLoading: boolean) => void;
    onDisplayProducts: (products: GiftProduct[]) => void;
    onNotifyClose: () => void;
}

export interface PurchaseGiftModalData {
    code?: string;
}

@Component({
    selector: 'app-purchase-gift-modal',
    templateUrl: './purchase-gift-modal.component.html',
    styleUrls: ['./purchase-gift-modal.component.scss'],
})
export class PurchaseGiftModalComponent
    extends SimpleModalComponent<PurchaseGiftModalData, void>
    implements PurchaseGiftModalView, OnInit
{
    public isLoading = true;
    public products: GiftProduct[] = [];
    public disclaimerText: string;
    public animation: AnimationOptions = {
        path: './assets/animations/loading.json',
    };

    constructor(
        @Inject('PurchaseGiftModalPresenter')
        public readonly presenter: PurchaseGiftModalPresenter,
        @Inject('FirebaseRemoteConfig')
        private readonly remoteConfig: firebase.remoteConfig.RemoteConfig,
    ) {
        super();
        this.presenter.attachView(this);

        try {
            this.disclaimerText = JSON.parse(
                this.remoteConfig.getString('giftDisclaimerLocalizedText'),
            ).localizations.en;
        } catch {
            this.disclaimerText =
                'Gift subscriptions can only be redeemed by someone without an active Together subscription.';
        }
    }

    public ngOnInit(): void {
        this.presenter.onViewNgOnInit();
    }

    public onDisplayProducts(products: GiftProduct[]): void {
        this.products = products;
    }

    public onDisplayLoading(isLoading: boolean): void {
        this.isLoading = isLoading;
    }

    public onNotifyClose(): void {
        this.close();
    }
}
