import { Component, Inject, OnInit } from '@angular/core';
import { SimpleModalComponent } from '@looorent/ngx-simple-modal';

import { ManageGiftsPresenter } from './manage-gifts-modal.presenter';
import { WaitModalService } from '@app/shared/services/wait-modal.service';
import { PromoCodeModel } from '@together/common';

export interface ManageGiftsView {
    onDisplayLoading(isLoading: boolean): void;
    onDisplayPromoCodes(promoCodes: PromoCodeModel[]);
    onDisplayRedeem(display: boolean): void;
    onNotifyClose(): void;
}

@Component({
    selector: 'app-manage-gifts-modal',
    templateUrl: './manage-gifts-modal.component.html',
    styleUrls: ['./manage-gifts-modal.component.scss'],
})
export class ManageGiftsModalComponent extends SimpleModalComponent<void, void> implements ManageGiftsView, OnInit {
    public isLoading = false;
    public promoCodes: PromoCodeModel[];
    public showRedeem = true;

    constructor(
        @Inject('ManageGiftsPresenter')
        public readonly presenter: ManageGiftsPresenter,
        private readonly waitModal: WaitModalService,
    ) {
        super();
        this.presenter.attachView(this);
    }

    public ngOnInit(): void {
        this.presenter.onViewNgOnInit();
    }

    public onDisplayLoading(isLoading: boolean): void {
        this.isLoading = isLoading;
        this.waitModal.setVisible(isLoading);
    }

    public onDisplayPromoCodes(promoCodes: PromoCodeModel[]): void {
        this.promoCodes = promoCodes;
    }

    public onDisplayRedeem(showRedeem: boolean): void {
        this.showRedeem = showRedeem;
    }

    public onNotifyClose(): void {
        this.close();
    }
}
