<div class="modal-clean modal-clean--ultra-wide-xl modal-upgrade-tier --centered">
    <div class="modal-clean__content --no-outer-padding">
        <div class="modal-clean__header">
            <div class="modal__close" (click)="close()"></div>
            <div class="modal__title">Upgrade</div>
        </div>
        <!-- <div class="modal-upgrade-tier__discount-banner" *ngIf="!isSubscribed && subscriptionPromotion?.title">
            <div class="modal-upgrade-tier__discount-banner__content">{{ subscriptionPromotion.title }}</div>
        </div> -->
        <div class="modal-upgrade-tier__description">
            {{ limitedContentCohortConfig?.description }}
        </div>

        <div class="modal-upgrade-tier__container --tablet" *ngIf="!isSubscribed">
            <div class="modal-upgrade-tier__plans">
                <div
                    *ngFor="let tier of tiers; index as i"
                    class="plan-info-box"
                    [ngClass]="{ '--premium': tier.tierType === 'FULL' }"
                    (click)="selectTier(tier)">
                    <div class="plan-info-box__plan-header">
                        <div class="plan-info-box__plan-header__title">
                            {{ tier.tierName }}
                        </div>
                        <div class="plan-period-toggle" *ngIf="tier.products?.length == 2">
                            <ng-container *ngFor="let product of tier.products; i as index">
                                <div
                                    class="toggle-item"
                                    (click)="selectProduct(tier, product)"
                                    *ngIf="i === 0"
                                    [ngClass]="{ '--selected': product === tier?.productToShowAsSelected }">
                                    {{ product.title }}
                                </div>
                                <div
                                    class="toggle-item"
                                    (click)="selectProduct(tier, product)"
                                    *ngIf="i === 1"
                                    [ngClass]="{ '--selected': product === tier?.productToShowAsSelected }">
                                    {{ product.title }}
                                </div>
                            </ng-container>
                        </div>
                        <div class="price-box" *ngIf="tier.products?.length == 1">
                            <span class="price-box__value"> {{ tier?.productToShowAsSelected?.price }} </span> /
                            <span class="price-box__period"> {{ tier?.productToShowAsSelected?.period }}</span>
                        </div>
                    </div>
                    <div class="plan-info-box__plan-sub-header">
                        <div
                            class="plan-info-box__plan-sub-header__info"
                            [ngClass]="{ '--link': tier.tierType === 'LIMITED' }">
                            <ng-container *ngIf="tier.tierType === 'LIMITED'">
                                <span (click)="showContentIncludedModal(tier)"
                                    >What's included <fa-icon [icon]="['tg', 'chevron-right-solid']"></fa-icon
                                ></span>
                            </ng-container>
                            <ng-container *ngIf="tier.tierType === 'FULL'">Get Everything!</ng-container>
                        </div>
                        <div class="price-box" *ngIf="tier.products?.length == 2">
                            <span class="price-box__value"> {{ tier?.productToShowAsSelected?.price }} </span> /
                            <span class="price-box__period"> {{ tier?.productToShowAsSelected?.period }}</span>
                        </div>
                    </div>
                    <div class="plan-info-box__features">
                        <div class="plan-info-box__features__item" *ngFor="let content of tier.contentIncluded">
                            <div class="plan-info-box__features__item__count">{{ content.value }}</div>
                            <div class="plan-info-box__features__item__text">{{ content.label }}</div>
                        </div>
                    </div>
                    <div class="plan-info-box__action-container">
                        <button
                            class="button-v2 --light-purple --wide --rounded"
                            (click)="purchase(tier.productToShowAsSelected)"
                            [ngClass]="{ '--selected': selectedTier.tierId === tier.tierId }">
                            Subscribe for {{ tier?.productToShowAsSelected?.price }} /
                            {{ tier?.productToShowAsSelected?.period }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-upgrade-tier__terms">
                <span class="--heading">Terms & conditions:</span> {{ limitedContentCohortConfig?.disclaimer }}
            </div>
        </div>
        <div class="modal-upgrade-tier__container --mobile" *ngIf="!isSubscribed">
            <div class="modal-upgrade-tier__plans">
                <div
                    *ngFor="let tier of tiers; index as i"
                    class="plan-info-box"
                    [ngClass]="{ '--premium': tier.tierType === 'FULL' }"
                    (click)="selectTier(tier)">
                    <div class="plan-info-box__plan-sub-header">
                        <div class="plan-info-box__plan-sub-header__info">{{ tier.tierDescription }}</div>
                    </div>
                    <div
                        class="plan-info-box__plan-header"
                        [ngClass]="{
                            '--selected': selectedTier.tierId == tier.tierId
                        }">
                        <div class="plan-info-box__plan-header__title">
                            {{ tier.tierName }}
                        </div>
                        <div class="price-box">
                            <span class="price-box__value"> {{ tier?.productToShowAsSelected?.price }} </span>
                            <span class="price-box__period">/{{ tier?.productToShowAsSelected?.period }}</span>
                        </div>
                        <div class="plan-checkbox">
                            <span *ngIf="selectedTier.tierId != tier.tierId" class="plan-checkbox__unchecked"></span>
                            <fa-icon
                                *ngIf="selectedTier.tierId == tier.tierId"
                                [icon]="['tg', 'checked-round']"></fa-icon>
                        </div>
                    </div>
                </div>
                <div class="features-box">
                    <ng-container *ngFor="let tier of tiers; index as i">
                        <div
                            class="features-box__features"
                            [ngClass]="{
                                '--selected': selectedTier.tierId == tier.tierId,
                                '--premium': tier.tierType === 'FULL'
                            }">
                            <div class="features-box__features__heading">
                                <div class="features-box__features__heading__text">
                                    {{ selectedTier.tierName }} Subscriber Benefits
                                </div>
                                <ng-container *ngIf="tier.tierType === 'LIMITED'">
                                    <div
                                        class="features-box__features__heading__link"
                                        (click)="showContentIncludedModal(tier)">
                                        What's included
                                    </div>
                                </ng-container>
                                <!--  <ng-container *ngIf="tier.tierType === 'FULL'">
                                    <div class="features-box__features__heading__link">Get Everything!</div>
                                </ng-container> -->
                            </div>
                            <ng-container *ngFor="let content of tier.contentIncluded">
                                <div class="features-box__features__item">
                                    <div class="features-box__features__item__count">{{ content.value }}</div>
                                    <div class="features-box__features__item__text">{{ content.label }}</div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="showcase-footer">
                <div class="plan-period-toggle" *ngIf="selectedTier && selectedTier.products?.length == 2">
                    <ng-container *ngFor="let product of selectedTier.products; i as index">
                        <div
                            class="toggle-item"
                            (click)="selectProduct(selectedTier, product)"
                            [ngClass]="{ '--selected': product === selectedTier.productToShowAsSelected }">
                            {{ product.title }}
                        </div>
                    </ng-container>
                </div>
                <div class="showcase-footer__action-container">
                    <button
                        *ngIf="selectedTier"
                        class="button-v2 --purple --rounded --medium"
                        (click)="purchase(selectedTier.productToShowAsSelected)">
                        Subscribe for {{ selectedTier.productToShowAsSelected?.price }} /
                        {{ selectedTier.productToShowAsSelected?.period }}
                    </button>
                </div>
                <div class="showcase-footer__terms">
                    <span class="--heading">Terms & conditions: </span> {{ limitedContentCohortConfig?.disclaimer }}
                </div>
            </div>
        </div>
    </div>
</div>
