<div class="modal-clean modal-clean--wide modal-book --centered">
    <div class="modal-clean__content --no-outer-padding">
        <div class="modal-clean__header">
            <div class="modal__close" (click)="close()"></div>
            <div class="modal__title">{{ book.title }}</div>
        </div>
        <div class="modal-book__premium-info" *ngIf="book.isForSale && !book.isPurchased && !book.isRented">
            <div class="alert --wide --yellow --rounded" *ngIf="allowPurchase && isLoggedIn">
                <fa-icon [icon]="['tg', 'exclamation-note']"></fa-icon> This is a premium book
            </div>
            <div class="alert --wide --yellow --rounded" *ngIf="!isLoggedIn">
                <fa-icon [icon]="['tg', 'exclamation-note']"></fa-icon>
                This is a premium book. <br />
                Please login to see purchase options.
            </div>
            <div class="alert --wide --yellow --rounded" *ngIf="!allowPurchase && isLoggedIn">
                <fa-icon [icon]="['tg', 'exclamation-note']"></fa-icon>
                You can’t rent or buy premium books while on a call.
            </div>
        </div>
        <ng-container *ngIf="book.isForSale && (book.isPurchased || book.isRented)">
            <div class="modal-book__purchase-info" *ngIf="book.isRented && book.isRentalActive">
                <div class="alert --wide --yellow --rounded">
                    <fa-icon [icon]="['tg', 'clock']"></fa-icon>
                    {{ getRentalDaysLeft() }} days left for your rental
                </div>
            </div>
            <div class="modal-book__purchase-info" *ngIf="book.isRented && !book.isRentalActive">
                <div class="alert --wide --orange --rounded">
                    <fa-icon [icon]="['tg', 'exclamation-round']"></fa-icon>
                    Your rental expired on {{ getRentalExpiredDate() | date: 'dd/MM/yyyy' }}
                </div>
            </div>
            <div class="modal-book__purchase-info" *ngIf="book.isPurchased">
                <div class="alert --wide --yellow --rounded">
                    <fa-icon [icon]="['tg', 'checked-round']"></fa-icon>
                    Purchased on {{ purchase?.purchaseDate | date: 'dd/MM/yyyy' }}
                </div>
            </div>
        </ng-container>
        <div class="modal-book__summary-row" [ngClass]="{ '--no-info': canDeleteBook }">
            <div class="modal-book__detail">
                <div class="modal-book__image">
                    <app-book-thumbnail [book]="book" [onlyImage]="true" [isFav]="isFav"></app-book-thumbnail>
                </div>
                <div class="modal-book__authors">{{ book.authors }}</div>
            </div>

            <div class="modal-book__info" *ngIf="!canDeleteBook">
                <div class="modal-book__categories">
                    <span class="badge --grey" *ngFor="let category of book.subCategories">
                        {{ getCategoryLabel(category) }}
                    </span>
                </div>
                <div class="modal-book__description">{{ book.description }}</div>
            </div>
        </div>
        <div class="modal-book__link --text-center">
            <a *ngIf="book.externalLink" [href]="getLink()">{{ book.externalLink }}</a>
        </div>

        <ng-container *ngIf="allowPurchase && book.isForSale && !book.isPurchased && !book.isRentalActive">
            <div class="modal-book__purchase-action-container --logged" *ngIf="isLoggedIn">
                <ng-container *ngIf="getBookSaleProduct()">
                    <button class="button-v2 --purple --large --wide --rounded --mb-2" (click)="purchaseBook()">
                        Buy now for {{ getBookSaleProduct()?.price }}
                    </button>
                </ng-container>
                <ng-container *ngIf="getBookRentProduct()">
                    <button class="button-v2 --shade-purple --large --wide --rounded --mb-2" (click)="rentBook()">
                        Rent now for {{ getBookRentProduct()?.price }} / {{ book.rentalDuration }} days
                    </button>
                </ng-container>
                <div class="modal-book__more-links">
                    <a class="--more-info" (click)="showMoreInfo()">About Premium Books</a>
                    <a class="" href="mailto:support@togethervideoapp.com">Contact Us</a>
                </div>
            </div>
        </ng-container>
        <div class="modal-book__delete-action --mb-2" *ngIf="canDeleteBook">
            <button class="button-v2 --red --wide --rounded" (click)="onDelete()">Delete Book</button>
        </div>
    </div>
</div>
