<div class="modal-clean modal-clean--wide modal-change-tier-subscription --centered">
    <div class="modal-clean__content --no-outer-padding">
        <div class="modal-clean__header">
            <div class="modal__close" (click)="close()"></div>
        </div>
        <div class="modal-change-tier-subscription__container" *ngIf="premiumTier">
            <div class="modal-change-tier-subscription__heading">
                Read, play and explore more with
                <span class="modal-change-tier-subscription__heading__plan-text">{{ premiumTier?.tierName }}</span>
            </div>
            <div class="modal-change-tier-subscription__description">Access to every single thing...</div>
            <div class="modal-change-tier-subscription__promos" *ngIf="premiumTier?.products?.length">
                <div class="plan-info-box" *ngFor="let product of premiumTier.products">
                    <div class="plan-info-box__content">
                        <div class="plan-info-box__content__title">
                            <span class="--product-name">
                                {{ product.title }}
                            </span>
                            <!-- <span *ngIf="product.isBestValue" class="--product-tag"> Best Value </span> -->
                        </div>
                        <div
                            class="plan-info-box__content__product-price-text"
                            *ngIf="product.projectedStrikethroughPrice">
                            <span class="--product-striked-savings">{{ product.projectedStrikethroughPrice }}</span>
                            <span class="--product-price">{{ product.price }}/{{ product.period }}</span>
                            <!-- <span class="--product-price-savings" *ngIf="product.savingPercentage"
                                >SAVE {{ product.savingPercentage }}</span
                            > -->
                        </div>
                    </div>
                    <div class="plan-info-box__price-box" (click)="selectProduct(product)">
                        <div class="plan-info-box__price-box__product-price" *ngIf="product.periodInMonths === 12">
                            <span class="--price">{{ product.pricePerMonth }}</span
                            ><span class="--period">/month</span>
                        </div>
                        <div class="plan-info-box__price-box__product-price" *ngIf="product.periodInMonths !== 12">
                            <span class="--price">{{ product.price }}</span
                            ><span class="--period">/{{ product.period }}</span>
                        </div>
                        <div class="plan-info-box__price-box__plan-checkbox">
                            <span
                                *ngIf="selectedProduct.id != product.id"
                                class="plan-info-box__price-box__plan-checkbox__unchecked"></span>
                            <fa-icon
                                *ngIf="selectedProduct.id == product.id"
                                [icon]="['tg', 'checked-round']"></fa-icon>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-change-tier-subscription__features-box">
                <div class="modal-change-tier-subscription__features-box__features">
                    <div class="modal-change-tier-subscription__features-box__features__heading">
                        <div class="modal-change-tier-subscription__features-box__features__heading__text">
                            {{ premiumTier?.tierName }} includes:
                        </div>
                    </div>
                    <ng-container *ngFor="let content of premiumTier?.contentIncluded">
                        <div class="modal-change-tier-subscription__features-box__features__item">
                            <div class="modal-change-tier-subscription__features-box__features__item__count">
                                {{ content.value }}
                            </div>
                            <div class="modal-change-tier-subscription__features-box__features__item__text">
                                {{ content.label }}
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="modal-change-tier-subscription__showcase-footer">
                <div class="modal-change-tier-subscription__showcase-footer__action-container" *ngIf="!disablePurchase">
                    <button class="button-v2 --purple --rounded --medium --wide" (click)="purchase()">
                        Upgrade for {{ premiumTier?.productToShowAsSelected?.price }} /
                        {{ premiumTier?.productToShowAsSelected?.period }}
                    </button>
                </div>
                <div
                    class="modal-change-tier-subscription__showcase-footer__purchase-instruction"
                    *ngIf="disablePurchase">
                    Upgrade in your settings once you’ve finished your call!
                </div>
                <div class="modal-change-tier-subscription__showcase-footer__terms">
                    <span class="--heading">Terms & conditions: </span> {{ limitedContentCohortConfig?.disclaimer }}
                </div>
            </div>
        </div>
    </div>
</div>
