import { Component, OnInit, Inject } from '@angular/core';
import { SimpleModalComponent } from '@looorent/ngx-simple-modal';
import { IStoreService, ExtraTimeProduct } from '@app/shared/services/store/store-service.interface';
import { WaitModalService } from '@app/shared/services/wait-modal.service';

@Component({
    selector: 'app-purchase-time-modal',
    templateUrl: './purchase-time-modal.component.html',
    styleUrls: ['./purchase-time-modal.component.scss'],
})
export class PurchaseTimeModalComponent extends SimpleModalComponent<void, void> implements OnInit {
    products: ExtraTimeProduct[];

    constructor(
        @Inject('StoreService') protected storeService: IStoreService,
        protected waitService: WaitModalService,
    ) {
        super();
    }

    async ngOnInit() {
        this.products = await this.storeService.getExtraTimeProducts();
    }

    async purchaseTime(product: ExtraTimeProduct): Promise<void> {
        try {
            this.waitService.show();

            if (await this.storeService.purchaseExtraTime(product)) {
                this.close();
                this.storeService.showBuyExtraTimeSuccessModal();
            }
        } finally {
            this.waitService.hide();
        }
    }
}
