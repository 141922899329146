import {
    AnalyticsEvent,
    GetUserByIdInteractor,
    LogAnalyticsEventInteractor,
    RedeemPromoCodeInteractor,
    UserModel,
    UserService,
} from '@together/common';
import { wait } from '@app/shared/utilities';
import { RedeemModalView } from './redeem-modal.component';

export interface RedeemModalPresenter {
    attachView(view: RedeemModalView): void;
    onActionClose(): void;
    onActionRedeem(code: string): void;
    onViewNgOnInit(isFromLink: boolean): void;
}

export class DefaultRedeemModalPresenter implements RedeemModalPresenter {
    private view: RedeemModalView;
    private isFromLink: boolean;

    constructor(
        private readonly getUserById: GetUserByIdInteractor,
        private readonly logAnalyticsEvent: LogAnalyticsEventInteractor,
        private readonly redeemPromoCode: RedeemPromoCodeInteractor,
        private readonly userService: UserService,
    ) {}

    public attachView(view: RedeemModalView): void {
        this.view = view;
    }

    public onViewNgOnInit(isFromLink: boolean): void {
        this.isFromLink = isFromLink;
    }

    public onActionClose(): void {
        this.view.onNotifyClose();
    }

    public async onActionRedeem(code: string): Promise<void> {
        this.view.onDisplayLoading(true);

        try {
            await this.redeemPromoCode.execute(code);

            if (this.isFromLink) {
                this.logAnalyticsEvent.execute(AnalyticsEvent.GiftRedeemedViaLink);
            } else {
                this.logAnalyticsEvent.execute(AnalyticsEvent.GiftRedeemedManually);
            }

            const user = await this.reloadUser();
            const endDate = user.subscriptionEndDate.toISOString().slice(0, 10);

            // Close modal
            this.view.onNotifyClose();

            // TODO: THIS CODE WAS RETURNING AN ERROR
            // ------------------------------------------
            /* if (promoCode.isUserGift()) {
                // Initialize with placeholder user in case the promoCode doesn't have the ID
                let generatedBy: UserModel = new UserModel();
                generatedBy.displayName = '???';

                try {
                    generatedBy = await this.getUserById.execute(promoCode.generatedByUserId);
                } catch (err) {}

                this.view.onDisplayMessage(
                    'Success!',
                    [
                        `You redeemed a gift from ${generatedBy.displayName}`,
                        `Your subscription is valid until: ${endDate}.`,
                    ].join(' '),
                );
            } else {*/
            // ------------------------------------------
            this.view.onDisplayMessage(
                'Success!',
                [`You redeemed your gift subscription!`, `It’s valid until ${endDate}.`].join(' '),
                'success',
                'checked-round',
            );
            this.userService.triggerSuccessConfetti();
            //}
        } catch (err) {
            if (err.message) {
                this.view.onDisplayMessage('Oops!', err.message, 'error', 'error');
            } else {
                this.view.onDisplayMessage(
                    'Oops!',
                    `There was an issue while processing the promo code link, please try again.`,
                    'error',
                    'error',
                    '(gift_007)',
                );
            }
        } finally {
            this.view.onDisplayLoading(false);
        }
    }

    /**
     * Reloads the user
     * It'll keep trying until we see that the user is subscribed.
     */
    private async reloadUser(): Promise<UserModel> {
        let user = await this.userService.getUser();

        while (!user.isSubscribed()) {
            await this.userService.reload();
            user = await this.userService.getUser();
            await wait(350);
        }

        return user;
    }
}
