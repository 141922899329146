import { Injectable } from '@angular/core';
import { SimpleModalService } from '@looorent/ngx-simple-modal';
import {
    AnalyticsEvent,
    GetPromoCodeFromURLInteractor,
    IsPromoCodeURLInteractor,
    LogAnalyticsEventInteractor,
    UserService,
} from '@together/common';

import { AlertModalComponent } from '@app/web/modals/alert-modal/alert-modal.component';
import { RedeemModalComponent } from '@app/web/modals/redeem-modal/redeem-modal.component';

@Injectable({
    providedIn: 'root', // Force it to be a singleton
})
export class PromoCodeDeepLinkService {
    constructor(
        private logAnalyticsEvent: LogAnalyticsEventInteractor,
        private getPromoCodeFromURL: GetPromoCodeFromURLInteractor,
        private isPromoCodeURL: IsPromoCodeURLInteractor,
        private modalService: SimpleModalService,
        private userService: UserService,
    ) {}

    async handleURL(url: URL): Promise<void> {
        if (!this.isPromoCodeURL.execute(url)) {
            return;
        }

        if (!(await this.userService.isLoggedIn())) {
            this.modalService.addModal(AlertModalComponent, {
                title: 'Oops!',
                message: `Please login or register to accept your gift.`,
                type: 'warning',
                icon: 'gift',
            });

            return;
        }

        try {
            const code = this.getPromoCodeFromURL.execute(url);
            const user = await this.userService.getUser();
            this.logAnalyticsEvent.execute(AnalyticsEvent.PromoCodeLinkOpenedByRegisteredUser, {
                subscription_status: user.analyticsType,
            });
            this.modalService.addModal(RedeemModalComponent, { code, isFromLink: true });
        } catch (err) {
            this.modalService.addModal(AlertModalComponent, {
                title: 'Oops!',
                message: `That's an invalid code. Double-check it and try again.`,
                type: 'warning',
                icon: 'gift',
            });
        }
    }
}
