// tslint:disable:max-line-length
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { Environment } from './types';

export const environment: Environment = {
    production: false,
    bugfenderAppKey: 'rhUJeBnq7AdGlwJQtYHT3D9oe9G9TlCp',
    googleOAuthClientId: '27816748470-nv0a5h87j9lhkqetpcqqpeu9r35778g8.apps.googleusercontent.com',
    permissionsURL: 'https://www.togethervideoapp.com/permissions-web/',
    privacyPolicyURL: 'https://www.iubenda.com/privacy-policy/67686776',
    termsOfServiceURL: 'https://www.iubenda.com/terms-and-conditions/67686776',
    twilioTokenURL: 'https://pacific-citadel-29826.herokuapp.com/dev',
    uploadBooksTutorialURL: 'https://www.togethervideoapp.com/upload-pdf-files-2-0/',
    purchaseFaqsURL: 'https://www.togethervideoapp.com/together-faq/',
    giftUrlPrefix: 'https://app-stg.togethervideoapp.com/',
    store: {
        isEnabled: true,
        native: {
            storeAndroidURL: 'https://play.google.com/store/apps/details?id=com.togethervideoapp.webapp',
            storeIOSURL: 'https://itunes.apple.com/us/app/together-family-video-chat/id1405515604?ls=1&mt=8',
            validatorURL:
                'https://validator.fovea.cc/v1/validate?appName=com.togethervideoapp.webapp&apiKey=671a56c7-a187-41a1-ab65-3f0c442d0701',
            // ----------------------
            // REMOTE CONFIG FALLBACK
            // ----------------------
            oldSubscriptionProductIds: [
                'com.bitwisesl.android.together.sub.monthly',
                'com.bitwisesl.android.together.sub.halfyear',
            ],
            subscriptionProducts: [
                {
                    __comment__: 'REGULAR monthly subscription product',
                    isEnabled: true,
                    id: 'dev.togethervideoapp.android.sub.monthly1',
                    period: { inMonths: 1, text: '1 Month' },
                    description: 'Billed monthly. Cancel Anytime.',
                    title: '1 month',
                    featuredTitle: '',
                },
                {
                    __comment__: 'REGULAR yearly subscription product',
                    isEnabled: true,
                    id: 'dev.togethervideoapp.android.sub.yearly',
                    period: { inMonths: 12, text: '1 Year' },
                    description: 'Billed annually. 3 months+ free.',
                    title: '1 year',
                    featuredTitle: '',
                    isBestValue: true,
                },
            ],
            subscriptionUpgradeProducts: [
                {
                    __comment__: 'UPGRADE to yearly subscription product for monthly subscription users',
                    isEnabled: true,
                    id: 'dev.togethervideoapp.yearly.upgrade',
                    period: { inMonths: 12, text: '1 Year' },
                    description: 'Billed annually. 3 months+ free.',
                    title: '1 year',
                    isBestValue: true,
                },
            ],
            lifetimeProducts: [
                {
                    __comment__: 'REGULAR Lifetime product',
                    isEnabled: true,
                    id: 'dev.togethervideoapp.android.lifetime',
                    description: 'Lifetime calling Together!',
                    featuredTitle: '',
                    isBestValue: false,
                },
            ],
            giftProducts: [
                {
                    __comment__: 'REGULAR 1 Month',
                    isEnabled: true,
                    id: 'dev.togethervideoapp.android.gift.1month',
                    title: '1 Month',
                    isBestValue: false,
                    isLifetime: false,
                    durationDays: 30,
                },
                {
                    __comment__: 'REGULAR 1 Year',
                    isEnabled: true,
                    id: 'dev.togethervideoapp.android.gift.1year',
                    title: '1 Year',
                    isBestValue: true,
                    isLifetime: false,
                    durationDays: 365,
                },
                {
                    __comment__: 'REGULAR Lifetime',
                    isEnabled: true,
                    id: 'dev.togethervideoapp.android.gift.lifetime',
                    title: 'Lifetime',
                    isBestValue: false,
                    isLifetime: true,
                },
            ],
            consumableProducts: {
                extraTimeProducts: [
                    { id: 'dev.togethervideoapp.android.extratime.5hrs', hours: 5 },
                    { id: 'dev.togethervideoapp.android.extratime.10hrs', hours: 10 },
                    { id: 'dev.togethervideoapp.android.extratime.20hrs', hours: 20 },
                ],
                bookPurchases: {
                    bookSaleProducts: [
                        {
                            id: 'dev.togethervideoapp.bookstore.sale.tier1',
                            tierId: 'com.togethervideoapp.bookstore.sale.tier1',
                        },
                        {
                            id: 'dev.togethervideoapp.bookstore.sale.tier2',
                            tierId: 'com.togethervideoapp.bookstore.sale.tier2',
                        },
                        {
                            id: 'dev.togethervideoapp.bookstore.sale.tier3',
                            tierId: 'com.togethervideoapp.bookstore.sale.tier3',
                        },
                        {
                            id: 'dev.togethervideoapp.bookstore.sale.tier4',
                            tierId: 'com.togethervideoapp.bookstore.sale.tier4',
                        },
                        {
                            id: 'dev.togethervideoapp.bookstore.sale.tier5',
                            tierId: 'com.togethervideoapp.bookstore.sale.tier5',
                        },
                        {
                            id: 'dev.togethervideoapp.bookstore.sale.tier6',
                            tierId: 'com.togethervideoapp.bookstore.sale.tier6',
                        },
                        {
                            id: 'dev.togethervideoapp.bookstore.sale.tier7',
                            tierId: 'com.togethervideoapp.bookstore.sale.tier7',
                        },
                        {
                            id: 'dev.togethervideoapp.bookstore.sale.tier8',
                            tierId: 'com.togethervideoapp.bookstore.sale.tier8',
                        },
                        {
                            id: 'dev.togethervideoapp.bookstore.sale.tier9',
                            tierId: 'com.togethervideoapp.bookstore.sale.tier9',
                        },
                    ],
                    bookRentalProducts: [
                        {
                            id: 'dev.togethervideoapp.bookstore.rental.tier1',
                            tierId: 'com.togethervideoapp.bookstore.rental.tier1',
                        },
                        {
                            id: 'dev.togethervideoapp.bookstore.rental.tier2',
                            tierId: 'com.togethervideoapp.bookstore.rental.tier2',
                        },
                    ],
                },
            },
            limitedContentCohort: {
                disclaimer:
                    'Your Together membership is a recurring subscription, which means will auto-renew monthly (plus tax where applicable). You can cancel at least 24 hours before your membership expires to avoid getting charged for the next membership period, as there are no partial refunds.',
                description: 'Here goes some presentation about the different plans',
                tiers: [
                    {
                        tierId: 'ENTRY_LEVEL',
                        tierName: 'Basic',
                        tierType: 'LIMITED',
                        tierDescription: 'What is Basic?',
                        contentIncluded: [
                            {
                                label: 'Books',
                                value: '10',
                            },
                            {
                                label: 'Games',
                                value: '3',
                            },
                            {
                                label: 'Activities',
                                value: '2',
                            },
                            {
                                label: 'Topic',
                                value: '1',
                            },
                            {
                                label: 'Photo Booth Filters',
                                value: '3',
                            },
                        ],
                        isBestValue: true,
                        products: [
                            {
                                __comment__: 'ENTRY LEVEL monthly subscription product',
                                isEnabled: true,
                                id: 'dev.togethervideoapp.android.elmonthly',
                                period: { inMonths: 1, text: 'month' },
                                description: 'Billed monthly. Cancel Anytime.',
                                title: 'Entry Level',
                                featuredTitle: '',
                            },
                        ],
                    },
                    {
                        tierId: 'PRO',
                        tierName: 'Premium',
                        tierType: 'FULL',
                        tierDescription: 'What is Premium?',
                        contentIncluded: [
                            {
                                label: 'Books',
                                value: '100+',
                            },
                            {
                                label: 'Games',
                                value: '37',
                            },
                            {
                                label: 'Activities',
                                value: '11',
                            },
                            {
                                label: 'Topics',
                                value: '4',
                            },
                            {
                                label: 'Photo Booth Filters',
                                value: '10',
                            },
                        ],
                        isBestValue: false,
                        products: [
                            {
                                __comment__: 'REGULAR monthly subscription product',
                                isEnabled: true,
                                id: 'dev.togethervideoapp.android.sub.monthly1',
                                period: { inMonths: 1, text: 'month' },
                                description: 'Billed monthly. Cancel Anytime.',
                                title: 'Monthly',
                                featuredTitle: '',
                            },
                            {
                                __comment__: 'REGULAR yearly subscription product',
                                isEnabled: true,
                                id: 'dev.togethervideoapp.android.sub.yearly',
                                period: { inMonths: 1, text: 'year' },
                                description: 'Billed annually. Cancel Anytime.',
                                title: 'Yearly',
                                featuredTitle: '',
                            },
                        ],
                    },
                ],
            },
            // ^^^ REMOTE CONFIG FALLBACK END
        },
        stripe: {
            publishableKey:
                'pk_test_51Iw5fAFGQQnOwW2r8AV40hfB6kHrR2sPxrJ8Cg4AsgnRkj0joZToxgvg0w1uulDwWs6MwSOoC8Pv2j96e57e1xXU00shC4EMzO',
            // ----------------------
            // REMOTE CONFIG FALLBACK
            // ----------------------
            subscriptionProducts: [
                {
                    // Black Friday offer
                    isEnabled: false,
                    id: 'price_1J5tZRFGQQnOwW2rYe9YgFkH',
                    price: { amount: 0.2, currency: '$', currencyCode: 'USD' },
                    period: { inMonths: 0.033, text: '1 Day' },
                    title: '',
                    featuredTitle: '',
                    description: 'Billed daily',
                    isBestValue: false,
                    discount: null,
                    retailPriceProductId: null,
                },
                {
                    // Black Friday offer
                    isEnabled: true,
                    id: 'price_1J5tWXFGQQnOwW2rFcsL5J93',
                    price: { amount: 6.99, currency: '$', currencyCode: 'USD' },
                    title: '',
                    featuredTitle: '',
                    period: { inMonths: 1, text: '1 Month' },
                    description: 'Billed monthly. Cancel Anytime.',
                    isBestValue: false,
                    discount: null,
                    retailPriceProductId: null,
                },
                {
                    // 6 months product - Disabled for now
                    isEnabled: true,
                    id: 'price_1J5tX5FGQQnOwW2r01uAZYGd',
                    price: { amount: 34.99, currency: '$', currencyCode: 'USD' },
                    period: { inMonths: 6, text: '6 Months' },
                    title: '',
                    featuredTitle: '',
                    description: '{{currency}}{{pricePerMonth}} / Month billed every six months',
                    isBestValue: false,
                    discount: null,
                    retailPriceProductId: null,
                },
                {
                    // Regular pricing for Yearly option
                    isEnabled: true,
                    id: 'price_1Jk7mcFGQQnOwW2rm1WNLgTb',
                    price: { amount: 59.99, currency: '$', currencyCode: 'USD' },
                    period: { inMonths: 12, text: '1 Year' },
                    title: '',
                    featuredTitle: 'Best Value',
                    description: 'Billed annually. 3 months+ free.',
                    isBestValue: true,
                    discount: null,
                    retailPriceProductId: null,
                },
                {
                    // Black Friday offer
                    isEnabled: true,
                    id: 'price_1Jw2H5FGQQnOwW2r2eIUqunt',
                    price: { amount: 41.99, currency: '$', currencyCode: 'USD' },
                    period: { inMonths: 12, text: '1 Year' },
                    title: '$41.99 for the first year',
                    featuredTitle: '',
                    description: 'SPECIAL OFFER! {{currency}}{{pricePerMonth}} / Month. Billed annually',
                    isBestValue: true,
                    discount: null,
                    retailPriceProductId: null,
                },
            ],
            subscriptionUpgradeProducts: [
                {
                    isEnabled: true,
                    id: 'price_1LvGrpFGQQnOwW2r2Kp6QA67',
                    price: { amount: 59.99, currency: '$', currencyCode: 'USD' },
                    period: { inMonths: 12, text: '1 Year' },
                    description: 'Billed annually. 3 months+ free.',
                    title: '1 year',
                    featuredTitle: '',
                    isBestValue: false,
                    discount: null,
                    retailPriceProductId: null,
                },
            ],
            lifetimeProducts: [
                {
                    // Regular price for lifetime
                    isEnabled: false,
                    id: 'price_1Jfig8FGQQnOwW2r8cSyNUg0',
                    price: { amount: 149.99, currency: '$', currencyCode: 'USD' },
                    title: '',
                    description: 'Lifetime calling Together!',
                    featuredTitle: '',
                    isBestValue: false,
                    retailPriceProductId: null,
                },
                {
                    // Black Friday offer
                    isEnabled: true,
                    id: 'price_1Jw2LgFGQQnOwW2ry6Qf1cq0',
                    price: { amount: 89.99, currency: '$', currencyCode: 'USD' },
                    title: 'Lifetime',
                    description: '50% off one-time payment',
                    featuredTitle: 'ON SALE',
                    isBestValue: true,
                    retailPriceProductId: null,
                },
            ],
            giftProducts: [
                {
                    isEnabled: true,
                    id: 'price_1K7eUFFGQQnOwW2rjYj5D4d8',
                    price: { amount: 6.99, currency: '$', currencyCode: 'USD' },
                    title: '1 month',
                    isBestValue: false,
                    isLifetime: false,
                    durationDays: 30,
                },
                {
                    isEnabled: true,
                    id: 'price_1K7eWYFGQQnOwW2rrCQyenI4',
                    price: { amount: 59.99, currency: '$', currencyCode: 'USD' },
                    title: '1 year',
                    isBestValue: true,
                    isLifetime: false,
                    durationDays: 365,
                },
                {
                    isEnabled: true,
                    id: 'price_1K7eX2FGQQnOwW2r474sCHd6',
                    price: { amount: 149.99, currency: '$', currencyCode: 'USD' },
                    title: 'Lifetime',
                    isBestValue: false,
                    isLifetime: true,
                },
            ],
            consumableProducts: {
                extraTimeProducts: [
                    {
                        id: 'price_1J5tbKFGQQnOwW2rqlja9nbR',
                        price: { amount: 3.99, currency: '€', currencyCode: 'EUR' },
                        hours: 5,
                    },
                    {
                        id: 'price_1J5tbiFGQQnOwW2rgsMKvALP',
                        price: { amount: 6.99, currency: '€', currencyCode: 'EUR' },
                        hours: 10,
                    },
                ],
                bookPurchases: {
                    bookSaleProducts: [
                        {
                            id: 'price_1J6D6vFGQQnOwW2rrzFs7FIl',
                            tierId: 'com.togethervideoapp.bookstore.sale.tier1',
                            price: { amount: 0.99, currency: '$', currencyCode: 'USD' },
                        },
                        {
                            id: 'price_1J6D7CFGQQnOwW2rOMOhTJwq',
                            tierId: 'com.togethervideoapp.bookstore.sale.tier2',
                            price: { amount: 1.99, currency: '$', currencyCode: 'USD' },
                        },
                        {
                            id: 'price_1ISfVHBg1HxE1EOQZdraOWqw',
                            tierId: 'com.togethervideoapp.bookstore.sale.tier3',
                            price: { amount: 3.99, currency: '€', currencyCode: 'EUR' },
                        },
                        {
                            id: 'price_1ISfVcBg1HxE1EOQ5zBXftTV',
                            tierId: 'com.togethervideoapp.bookstore.sale.tier4',
                            price: { amount: 4.99, currency: '€', currencyCode: 'EUR' },
                        },
                    ],
                    bookRentalProducts: [
                        {
                            id: 'price_1J6D6JFGQQnOwW2rtRVKU6kh',
                            tierId: 'com.togethervideoapp.bookstore.rental.tier1',
                            price: { amount: 0.99, currency: '$', currencyCode: 'USD' },
                        },
                        {
                            id: 'price_1J6D6cFGQQnOwW2r5nGP56J3',
                            tierId: 'com.togethervideoapp.bookstore.rental.tier2',
                            price: { amount: 1.99, currency: '$', currencyCode: 'USD' },
                        },
                    ],
                },
            },
            subscriptionPromotion: {
                title: 'BLACK FRIDAY OFFERS',
                activePeriod: {
                    startDate: '2022-11-21T00:00:00.000Z',
                    endDate: '2022-12-01T00:00:00.000Z',
                },
            },
            limitedContentCohort: {
                disclaimer:
                    'Your Together membership is a recurring subscription, which means will auto-renew monthly (plus tax where applicable). You can cancel at least 24 hours before your membership expires to avoid getting charged for the next membership period, as there are no partial refunds.',
                description: 'Here goes some presentation about the different plans',
                tiers: [
                    {
                        tierId: 'ENTRY_LEVEL',
                        tierName: 'Basic',
                        tierType: 'LIMITED',
                        tierDescription: 'What is Basic?',
                        isBestValue: true,
                        contentIncluded: [
                            {
                                label: 'Books',
                                value: '10',
                            },
                            {
                                label: 'Games',
                                value: '3',
                            },
                            {
                                label: 'Activities',
                                value: '2',
                            },
                            {
                                label: 'Topic',
                                value: '1',
                            },
                            {
                                label: 'Photo Booth Filters',
                                value: '3',
                            },
                        ],
                        products: [
                            {
                                isEnabled: true,
                                id: 'price_1Q7AyZFGQQnOwW2rS5IbQ0Yf',
                                price: { amount: 2.49, currency: '$', currencyCode: 'USD' },
                                title: 'Entry Level',
                                featuredTitle: '',
                                period: { inMonths: 1, text: 'month' },
                                description: 'Billed monthly. Cancel Anytime.',
                                isBestValue: false,
                                discount: null,
                                retailPriceProductId: null,
                            },
                        ],
                    },
                    {
                        tierId: 'PRO',
                        tierName: 'Premium',
                        tierType: 'FULL',
                        tierDescription: 'What is Premium?',
                        isBestValue: false,
                        contentIncluded: [
                            {
                                label: 'Books',
                                value: '100+',
                            },
                            {
                                label: 'Games',
                                value: '37',
                            },
                            {
                                label: 'Activities',
                                value: '11',
                            },
                            {
                                label: 'Topics',
                                value: '4',
                            },
                            {
                                label: 'Photo Booth Filters',
                                value: '10',
                            },
                        ],
                        products: [
                            {
                                isEnabled: true,
                                id: 'price_1J5tWXFGQQnOwW2rFcsL5J93',
                                price: { amount: 6.99, currency: '$', currencyCode: 'USD' },
                                title: 'Monthly',
                                featuredTitle: '',
                                period: { inMonths: 1, text: 'month' },
                                description: 'Billed monthly. Cancel Anytime.',
                                isBestValue: false,
                                discount: null,
                                retailPriceProductId: null,
                            },
                            {
                                // Regular pricing for Yearly option
                                isEnabled: true,
                                id: 'price_1Jk7mcFGQQnOwW2rm1WNLgTb',
                                price: { amount: 59.99, currency: '$', currencyCode: 'USD' },
                                period: { inMonths: 12, text: 'year' },
                                title: 'Yearly',
                                featuredTitle: 'Best Value',
                                description: 'Billed annually. 3 months+ free.',
                                isBestValue: true,
                                discount: null,
                                retailPriceProductId: null,
                            },
                        ],
                    },
                ],
            },
            // ^^^ REMOTE CONFIG FALLBACK END
        },
    },
    firebaseConfig: {
        apiKey: 'AIzaSyD9fjjF2F5_bbtEohBxlWAvOVZ20Znv8R4',
        authDomain: 'together-dev-9c322.firebaseapp.com',
        databaseURL: 'https://together-dev-9c322.firebaseio.com',
        projectId: 'together-dev-9c322',
        storageBucket: 'together-dev-9c322.appspot.com',
        messagingSenderId: '27816748470',
        appId: '1:27816748470:web:0a4b8fda29c48d4c',
        measurementId: 'G-8VZKBSH6WQ',
    },
    mixpanelConfig: {
        token: 'e979cf5e6f3ef6b6e71374a1fdae3e32',
    },
    revenueCat: {
        apiKey: 'goog_YTaJlySbabQZRuoZuSceheYnMDX',
    },
    singularConfig: {
        sdkKey: 'kinzoo_e5e84aab',
        sdkSecret: '2311a515f1f87e80ee9db11b1ce6c504',
        webProductId: 'app-stg.togethervideoapp.com',
        androidProductId: 'com.togethervideoapp.webapp.dev',
    },
    deepArConfig: {
        licenseKey: '565c9e06726f137ff51e9c7a37d4f082f7de6c7a1244522bb2ce6d37eba193e90dc68b556df55ad9',
    },
    vonageConfig: {
        applicationId: '7aecb468-c740-4d97-af2a-72cd3197a7e6',
    },
    openObserveConfig: {
        deviceLogsURL: 'https://device-logs.kinzoo.com:5080/api/default/kt_staging/_json',
        logIngestionAuthorizationHeader: 'km_ingestion@kinzoo.com:pTaORUhCUzKCRh9r',
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
