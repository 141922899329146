import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscripionPromotion } from '@env/types';
import firebase from 'firebase/app';
import { AnimationOptions } from 'ngx-lottie';
import { SimpleModalComponent, SimpleModalService } from '@looorent/ngx-simple-modal';
import { Subscription } from 'rxjs';
import { ModalsService } from './../../../shared/services/modals.service';

import { environment } from '@env/environment';

import {
    IStoreService,
    SubscriptionProduct,
    SubscriptionState,
} from '@app/shared/services/store/store-service.interface';
import { WaitModalService } from '@app/shared/services/wait-modal.service';
import { waitPromise } from '@app/shared/utilities';
import {
    AnalyticsEvent,
    SingularEvent,
    GetPlatformInteractor,
    LogAnalyticsEventInteractor,
    UserModel,
    UserService,
    CampaignModel,
} from '@together/common';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { WebviewModalComponent } from '../webview-modal/webview-modal.component';

@Component({
    selector: 'app-upgrade-modal',
    templateUrl: './upgrade-modal.component.html',
    styleUrls: ['./upgrade-modal.component.scss'],
})
export class UpgradeModalComponent extends SimpleModalComponent<void, void> implements OnInit, OnDestroy {
    public selectedProductId: string;
    public products: SubscriptionProduct[];
    public subscriptionPromotion: SubscripionPromotion;
    public isSubscribed: boolean;
    public showAndroidNotice: boolean;
    public upgradeDisclaimerText: string;
    public upgradeText: string;
    public campaignDetails: CampaignModel;
    public animation: AnimationOptions = {
        path: './assets/animations/super-hero-panda.json',
    };

    protected user: UserModel;
    protected subs = new Subscription();

    constructor(
        protected logAnalyticsEvent: LogAnalyticsEventInteractor,
        @Inject('FirebaseFunctions') protected functions: firebase.functions.Functions,
        protected getPlatform: GetPlatformInteractor,
        protected modalService: SimpleModalService,
        @Inject('FirebaseRemoteConfig') protected remoteConfig: firebase.remoteConfig.RemoteConfig,
        protected router: Router,
        @Inject('StoreService') protected storeService: IStoreService,
        protected userService: UserService,
        protected waitService: WaitModalService,
        protected modals: ModalsService,
    ) {
        super();
    }

    public async ngOnInit(): Promise<void> {
        [this.user, this.products, this.subscriptionPromotion] = await Promise.all([
            this.userService.getUser(),
            this.storeService.getSubscriptionProducts(),
            this.storeService.getSubscriptionPromotion(),
        ]);
        this.fetchCampaignDetails();

        this.logAnalyticsEvent.execute(AnalyticsEvent.SubscriptionScreenDisplayed, {
            subscription_status: this.user.analyticsType,
        });

        this.logAnalyticsEvent.execute(SingularEvent.SngCheckoutInitiated);

        // Choose `selectedProductId`
        const bestValueProduct = this.products.find(p => p.isBestValue);
        this.selectedProductId = bestValueProduct ? bestValueProduct.id : this.products[0].id;

        // Decide which notices to show based on the platform
        this.showAndroidNotice = this.getPlatform.isAndroid();

        // Get RemoteConfig text
        this.upgradeText = this.remoteConfig.getString('upgradeText').trim();
        this.upgradeDisclaimerText = this.remoteConfig.getString('upgradeDisclaimerText').trim();

        // Get subscription state & watch for changes
        this.isSubscribed = this.user.isSubscribed();

        this.subs.add(
            this.storeService.getSubscriptionState$().subscribe(state => {
                this.isSubscribed = state.isSubscribed;
            }),
        );
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    async openManageSubscriptionModal(): Promise<void> {
        await this.modals.showManageSubscriptionModal();
    }

    public async purchase(id: string, inApp: boolean = true): Promise<void> {
        this.selectedProductId = id;
        let result: SubscriptionState;

        this.waitService.show();
        this.logAnalyticsEvent.execute(AnalyticsEvent.SubscriptionClickBuy, {
            product_id: this.selectedProductId,
            subscription_status: this.user.analyticsType,
        });

        try {
            const product = this.products.find(p => p.id === this.selectedProductId);

            if (product.isLifetime) {
                result = await this.storeService.purchaseLifetime(product, inApp);
            } else {
                result = await this.storeService.purchaseSubscription(product, inApp);
            }
            //This will be called only for in app purchase mode(android and stripe embed) instead of redirect
            if (result.isSubscribed) {
                this.close();
                this.storeService.showBuySubscriptionSuccessModal();
                this.logAnalyticsEvent.execute(AnalyticsEvent.SubscriptionPurchased, {
                    product_id: this.selectedProductId,
                    subscription_status: this.user.analyticsType,
                });
                this.logAnalyticsEvent.execute(SingularEvent.SngSubscribe);
            }
        } finally {
            this.waitService.hide();
        }
    }

    public selectProduct(id: string): void {
        this.selectedProductId = id;
    }

    public showPrivacyModal() {
        this.modalService.addModal(WebviewModalComponent, {
            title: 'Privacy Policy',
            url: environment.privacyPolicyURL,
        });
    }

    public showTermsModal() {
        this.modalService.addModal(WebviewModalComponent, {
            title: 'Terms of Service',
            url: environment.termsOfServiceURL,
        });
    }

    public async restorePurchases(): Promise<void> {
        try {
            this.waitService.show();
            await waitPromise(this.storeService.restorePurchases(), 4000);
        } catch (err) {
            this.modalService.addModal(AlertModalComponent, {
                title: 'Oops!',
                message: `There was an issue restoring your purchases. Please try again.`,
                type: 'error',
                icon: 'error',
            });
        } finally {
            this.waitService.hide();
        }
    }

    public dismissCampaignBanner() {
        this.campaignDetails = null;
    }

    private async fetchCampaignDetails() {
        //Show the code only once
        if (localStorage.getItem('campaignActivationDetails')) {
            try {
                this.campaignDetails = JSON.parse(localStorage.getItem('campaignActivationDetails'));
                localStorage.removeItem('campaignActivationDetails');
            } catch (err) {
                this.campaignDetails = null;
            }
        }
    }
}
