import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { VerifyAgeInteractor } from '@together/common';
import { SimpleModalComponent, SimpleModalService } from '@looorent/ngx-simple-modal';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-age-verification-modal',
    templateUrl: './age-verification-modal.component.html',
    styleUrls: ['./age-verification-modal.component.scss'],
})
export class AgeVerificationModalComponent
    extends SimpleModalComponent<void, boolean>
    implements AfterViewInit, OnDestroy
{
    isLoading = false;
    subscription = new Subscription();

    @ViewChild('millennium') millenniumRef: ElementRef;
    @ViewChild('century') centuryRef: ElementRef;
    @ViewChild('decade') decadeRef: ElementRef;
    @ViewChild('year') yearRef: ElementRef;

    ageForm = this.fb.group({
        millennium: [null],
        century: [{ value: null, disabled: true }],
        decade: [{ value: null, disabled: true }],
        year: [{ value: null, disabled: true }],
    });

    constructor(
        protected fb: UntypedFormBuilder,
        protected modalService: SimpleModalService,
        protected verifyAge: VerifyAgeInteractor,
    ) {
        super();

        const millenniumChangesSubscription = this.ageForm.get('millennium').valueChanges.subscribe(millennium => {
            if (millennium) {
                if (millennium > 9) {
                    this.ageForm.controls.millennium.setValue(millennium % 10);
                }
                this.ageForm.controls.century.enable();
                this.centuryRef.nativeElement.focus();
            }
        });

        const centuryChangesSubscription = this.ageForm.get('century').valueChanges.subscribe(century => {
            if (typeof century === 'number') {
                if (century > 9) {
                    this.ageForm.controls.century.setValue(century % 10);
                }
                this.ageForm.controls.decade.enable();
                this.decadeRef.nativeElement.focus();
            } else if (this.ageForm.get('century').value) {
                this.ageForm.controls.century.disable();
                this.millenniumRef.nativeElement.focus();
            }
        });

        const decadeChangesSubscription = this.ageForm.get('decade').valueChanges.subscribe(decade => {
            if (typeof decade === 'number') {
                if (decade > 9) {
                    this.ageForm.controls.decade.setValue(decade % 10);
                }
                this.ageForm.controls.year.enable();
                this.yearRef.nativeElement.focus();
            } else if (this.ageForm.get('decade').value) {
                this.ageForm.controls.decade.disable();
                this.centuryRef.nativeElement.focus();
            }
        });

        const yearChangesSubscription = this.ageForm.get('year').valueChanges.subscribe(year => {
            if (typeof year === 'number') {
                this.yearRef.nativeElement.blur();
                if (typeof this.ageForm.get('year').value === 'number') {
                    this.validateAge();
                }
            }
        });

        this.subscription.add(millenniumChangesSubscription);
        this.subscription.add(centuryChangesSubscription);
        this.subscription.add(decadeChangesSubscription);
        this.subscription.add(yearChangesSubscription);
    }

    ngAfterViewInit(): void {
        this.millenniumRef.nativeElement.focus();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private async validateAge(): Promise<void> {
        const yearString = [
            this.ageForm.get('millennium').value,
            this.ageForm.get('century').value,
            this.ageForm.get('decade').value,
            this.ageForm.get('year').value,
        ].join('');

        const year = parseInt(yearString, 10);
        this.result = await this.verifyAge.execute(year);
        this.close();
    }
}
