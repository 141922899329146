<div class="modal-clean modal-clean--wide modal-add-book">
    <div class="modal-clean__content">
        <div class="modal-clean__header">
            <div class="modal__close" (click)="close()"></div>
            <div class="modal__title" *ngIf="!submitStep">Add Your Own Books</div>
            <div class="modal__title" *ngIf="submitStep == 'title'">Your Book Has Been Added!</div>
            <div class="modal__title" *ngIf="submitStep == 'cover' || submitStep == 'file'">Uploading Your Book</div>
            <div class="modal__title" *ngIf="submitStep == 'success'">Success!</div>
        </div>

        <img class="modal-add-book__image" src="../../../../assets/hero-cat-books.svg" alt="" />
        <form
            class="form modal-add-book__form"
            [formGroup]="uploadBookForm"
            *ngIf="!submitStep || submitStep == 'title'">
            <ng-container *ngIf="!submitStep">
                <p>You can upload books to your personal library and enjoy reading them during your next video call!</p>
                <button class="button-v2 --purple --large --wide --rounded --mb-2" (click)="file.click()" tabindex="1">
                    Upload a PDF
                </button>
                <input
                    #file
                    type="file"
                    formControlName="file"
                    tabindex="1"
                    (change)="fileAdded($event)"
                    accept="application/pdf"
                    hidden
                    required />
                <span class="modal-add-book__tutorial-info" (click)="showTutorial()"> Upload Books Tutorial </span>
                <div class="modal-add-book__more-info">
                    <img src="/assets/icons/svgs/warning.svg" />
                    <p>
                        When you add your own books, they are only visible to you and your contacts. They are not added
                        to the Together library.
                    </p>
                </div>
            </ng-container>

            <ng-container *ngIf="submitStep == 'title'">
                <div class="modal-add-book__file-details">
                    <span class="modal-add-book__file-details__name" *ngIf="selectedFile">{{
                        selectedFile?.name
                    }}</span>
                    <span class="modal-add-book__file-details__size" *ngIf="selectedFile">
                        Size:
                        <span class="modal-add-book__file-details__size__value">
                            {{ selectedFile?.size / 1000000 | number: '1.1-1' }}MB</span
                        ></span
                    >
                </div>
                <span class="modal-add-book__title">Add a title</span>
                <input
                    matInput
                    class="modal-add-book__form__input"
                    type="text"
                    formControlName="name"
                    trim="blur"
                    placeholder="My New Book"
                    autocorrect="off"
                    autocapitalize="none"
                    autocomplete="off"
                    tabindex="-1" />
                <span
                    *ngIf="uploadBookForm.get('name').touched && uploadBookForm.get('name').errors?.required"
                    class="modal-add-book__form__error">
                    Book name is required
                </span>
                <button
                    class="button-v2 --purple --large --wide --rounded"
                    (click)="submit()"
                    [disabled]="!selectedFile || isUploading">
                    Done
                </button>
            </ng-container>
        </form>

        <ng-container *ngIf="submitStep === 'cover' || submitStep === 'file'">
            <ion-progress-bar class="progress-bar" [value]="uploadProgress" color="kinzoo-purple"></ion-progress-bar>
            <div class="modal-add-book__progress-info">{{ getProgressPercentage() }} %</div>
            <span class="modal-add-book__step-info" *ngIf="submitStep === 'cover'">Uploading cover image</span>
            <span class="modal-add-book__step-info" *ngIf="submitStep === 'file'">Uploading file</span>
        </ng-container>

        <ng-container *ngIf="submitStep === 'success'">
            <p>Your book has been added to your library! It’s ready to read on your next video call.</p>
            <button class="button-v2 --purple --large --wide --rounded --mb-2" (click)="addAnotherBook()">
                Add Another Book
            </button>
            <button class="button-v2 --shade-purple --large --wide --rounded" (click)="goToHome()">Go Back Home</button>
        </ng-container>
    </div>
</div>
