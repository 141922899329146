import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, SimpleChanges } from '@angular/core';
import { SpringboardService } from '@app/shared/services/springboard.service';
import { IStoreService } from '@app/shared/services/store/store-service.interface';
import { ProfileModalComponent } from '@app/web/modals/profile-modal/profile-modal.component';
import { WebviewModalComponent } from '@app/web/modals/webview-modal/webview-modal.component';
import { environment } from '@env/environment';
import { UserModel, UserService } from '@together/common';
import { SimpleModalService } from '@looorent/ngx-simple-modal';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.component.scss'],
})
export class AppHeaderComponent implements OnDestroy {
    @Input() title: string = '';
    @Input() public users: UserModel[];
    public isSubscribed = false;
    public upgradeBannerText: string;
    public hasUnreadInboxMessage: boolean;
    protected inboxMessageSubscription: Subscription;

    constructor(
        @Inject('StoreService') protected storeService: IStoreService,
        protected modalService: SimpleModalService,
        protected userService: UserService,
        private readonly cdr: ChangeDetectorRef,
        private springboardService: SpringboardService,
        private router: Router,
    ) {}

    public async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.hasOwnProperty('users')) {
            this.isSubscribed = changes.users?.currentValue ? changes.users.currentValue[0]?.isSubscribed() : false;
            this.upgradeBannerText = changes.users?.currentValue
                ? changes.users.currentValue[0]?.getUpgradeBannerText()
                : '';
            if (!this.inboxMessageSubscription) {
                await this.getRecentInboxMessage();
            }
        }
    }

    public ngOnDestroy() {
        //Unsubscribe from inbox listener
        this.inboxMessageSubscription.unsubscribe();
        this.inboxMessageSubscription = null;
    }

    public showUpgradeModal(): void {
        this.storeService.showUpgradeModal();
    }

    public showProfileModal(): void {
        this.modalService.addModal(ProfileModalComponent);
    }

    public showHelpModal(): void {
        this.modalService.addModal(WebviewModalComponent, {
            title: 'FAQ',
            url: environment.faqURL,
        });
    }

    public async getRecentInboxMessage() {
        this.inboxMessageSubscription = new Subscription();
        this.inboxMessageSubscription.add(
            this.userService.getInboxMessageNotifier$().subscribe(hasUnreadInboxMessage => {
                this.hasUnreadInboxMessage = hasUnreadInboxMessage;
                this.cdr.detectChanges();
            }),
        );
    }

    public async openInboxModal() {
        this.hasUnreadInboxMessage = false;
        this.cdr.detectChanges();
        this.springboardService.openInbox();
    }

    public canShowUpgradeBanner(): boolean {
        //Show banner is not subscribed. Or show only in settings if subscribed to basic plan
        if (!this.isSubscribed && this.upgradeBannerText) {
            return true;
        } else if (
            this.router.url.includes('settings') &&
            this.isSubscribed &&
            this.users[0]?.subscriptionProduct?.subscriptionState === 'active' &&
            this.users[0]?.isLimitedContentCohort() &&
            this.users[0]?.isBasicSubscription() &&
            this.upgradeBannerText
        ) {
            return true;
        } else {
            return false;
        }
    }
}
