import { ModalsService } from '@app/shared/services/modals.service';
import { GetUserPromoCodesInteractor, PromoCodeModel, UserService } from '@together/common';
import { ManageGiftsView } from './manage-gifts-modal.component';

export interface ManageGiftsPresenter {
    attachView(view: ManageGiftsView): void;
    onViewNgOnInit(): Promise<void>;
    onActionClose(): void;
    onActionViewPromoCode(promoCode: PromoCodeModel): void;
    onActionShowPurchaseGift(): void;
    onActionShowRedeemGift(): void;
}

export class DefaultManageGiftsPresenter implements ManageGiftsPresenter {
    private view: ManageGiftsView;

    constructor(
        private getUserPromoCodes: GetUserPromoCodesInteractor,
        private modals: ModalsService,
        private userService: UserService,
    ) {}

    public attachView(view: ManageGiftsView): void {
        this.view = view;
    }

    public async onViewNgOnInit(): Promise<void> {
        this.view.onDisplayLoading(true);

        const user = await this.userService.getUser();
        const promoCodes = await this.getUserPromoCodes.execute(user.id);

        this.view.onDisplayRedeem(!user.isSubscribed());
        this.view.onDisplayPromoCodes(promoCodes);
        this.view.onDisplayLoading(false);
    }

    public onActionClose(): void {
        this.view.onNotifyClose();
    }

    public onActionViewPromoCode(promoCode: PromoCodeModel): void {
        if (!promoCode.hasBeenRedeemed) {
            this.modals.showShareGift(promoCode);
        }
    }

    public onActionShowPurchaseGift(): void {
        this.onActionClose();
        this.modals.showPurchaseGift();
    }

    public onActionShowRedeemGift(): void {
        this.onActionClose();
        this.modals.showRedeemGift();
    }
}
