import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SimpleModalComponent } from '@looorent/ngx-simple-modal';
import { AbstractLogger } from '@mobilejazz/harmony-core';

import {
    APP_VERSION_COMPATIBILITY,
    AnalyticsEvent,
    CallHighlightModel,
    DownloadCallHighlightInteractor,
    DownloadPhotoboothImageInteractor,
    GetAllCallHighlightsInteractor,
    GetAllPhotoboothImagesInteractor,
    GetPlatformInteractor,
    LogAnalyticsEventInteractor,
    PhotoboothImageModel,
    ProcessCallHighlightsInteractor,
    RankCallHighlightsInteractor,
    ShareCallHighlightInteractor,
    UserModel,
    UserService,
} from '@together/common';

@Component({
    selector: 'app-call-highlight-modal',
    templateUrl: './call-highlight-modal.component.html',
    styleUrls: ['./call-highlight-modal.component.scss'],
})
export class CallHighlightModalComponent extends SimpleModalComponent<void, void> implements OnInit {
    isLoading = true;
    highlights: CallHighlightModel[] = [];
    progress = 0;
    showShare: boolean;

    @ViewChild('container') container: ElementRef<HTMLDivElement>;

    protected queue: CallHighlightModel[]; // Array of unprocessed CallHighlights
    protected isLoadingHighlight = false;
    protected user: UserModel;
    protected selectedTab: string = 'highlights';
    protected photoboothImages: PhotoboothImageModel[] = [];

    constructor(
        protected downloadCallHighlight: DownloadCallHighlightInteractor,
        protected downloadPhotoboothImageInteractor: DownloadPhotoboothImageInteractor,
        protected getAllCallHighlights: GetAllCallHighlightsInteractor,
        protected getAllPhotoboothImages: GetAllPhotoboothImagesInteractor,
        protected getPlatform: GetPlatformInteractor,
        protected logAnalyticsEvent: LogAnalyticsEventInteractor,
        protected logger: AbstractLogger,
        protected processCallHighlights: ProcessCallHighlightsInteractor,
        protected shareCallHighlight: ShareCallHighlightInteractor,
        protected userService: UserService,
    ) {
        super();
    }

    public async ngOnInit(): Promise<void> {
        this.user = await this.userService.getUser();
        this.showShare = this.getPlatform.isAndroid();
        await this.initCallHighlights();
        await this.initPhotoboothImages();
    }

    public async initCallHighlights() {
        // Process call highlights
        this.queue = await this.getAllCallHighlights.execute();

        const maxHighlights = Math.min(10, this.queue.length);
        const initialLoadCount = Math.min(4, maxHighlights);

        // Progress
        const fakeProgress = 8;
        const progressSteps = initialLoadCount + this.queue.length + fakeProgress;
        let progress = 0;
        let fakeProgressCount = 0;
        const progressStep = () => {
            progress++;
            this.progress = progress / progressSteps;
        };

        // Fake progress
        function fakeProgressStep() {
            if (fakeProgressCount < fakeProgress) {
                fakeProgressCount++;
                progressStep();
                setTimeout(fakeProgressStep, 250);
            }
        }

        fakeProgressStep();

        // Update call highlights score
        const ranker = new RankCallHighlightsInteractor(this.logger);

        for (let i = 0; i < this.queue.length; i++) {
            const entry = this.queue[i];
            this.logger.info('CallHighlightModalComponent', `Calculating score ${i + 1}/${this.queue.length}...`);
            progressStep(); // First feedback, then work
            entry.score = await ranker.getScore(entry);
        }

        this.logger.info('CallHighlightModalComponent', 'Sorting...');
        this.queue = ranker.sortData(this.queue, maxHighlights);

        // Load first 5 pictures
        for (let i = 0; i < initialLoadCount; i++) {
            this.logger.info('CallHighlightModalComponent', `Generating picture ${i + 1}/${initialLoadCount}...`);
            progressStep(); // First feedback, then work
            await this.loadNextHighlight();
        }

        this.logger.info('CallHighlightModalComponent', 'Done loading.');
    }

    public async initPhotoboothImages() {
        if (this.canShowPhotoBooth()) {
            this.photoboothImages = await this.getAllPhotoboothImages.execute();
            if (this.highlights?.length === 0) {
                this.selectTab('photobooth');
            }
        }
        this.isLoading = false;
    }

    public async saveAll() {
        if (this.selectedTab === 'highlights' && this.highlights.length > 0) {
            this.logAnalyticsEvent.execute(AnalyticsEvent.HighlightsSaveAll, {
                subscription_status: this.user.analyticsType,
            });
            for (let i = 0; i < this.highlights.length; i++) {
                if (!this.getPlatform.isAndroid() && i % 10 === 0) {
                    await new Promise(resolve => setTimeout(resolve, 1000));
                }
                await this.downloadCallHighlight.execute(this.highlights[i]);
            }
        }
        if (this.selectedTab === 'photobooth' && this.photoboothImages.length > 0) {
            this.logAnalyticsEvent.execute(AnalyticsEvent.PhotoboothSaveAll, {
                subscription_status: this.user.analyticsType,
            });
            for (let i = 0; i < this.photoboothImages.length; i++) {
                if (!this.getPlatform.isAndroid() && i % 10 === 0) {
                    await new Promise(resolve => setTimeout(resolve, 1000));
                }
                await this.downloadPhotoboothImageInteractor.execute(this.photoboothImages[i]);
            }
        }
    }

    public downloadHighlight(highlight: CallHighlightModel): void {
        this.logAnalyticsEvent.execute(AnalyticsEvent.HighlightsSaveOne, {
            subscription_status: this.user.analyticsType,
        });
        this.downloadCallHighlight.execute(highlight);
    }

    public downloadPhotoboothImage(image: PhotoboothImageModel): void {
        this.logAnalyticsEvent.execute(AnalyticsEvent.PhotoboothSaveSingle, {
            subscription_status: this.user.analyticsType,
        });
        this.downloadPhotoboothImageInteractor.execute(image);
    }

    public selectTab(tabName) {
        this.selectedTab = tabName;
    }

    protected async loadNextHighlight(): Promise<void> {
        if (this.queue.length > 0) {
            const highlight = this.queue.splice(0, 1)[0];

            try {
                highlight.pictureStr = await this.processCallHighlights.execute(highlight);
                this.highlights.push(highlight);
            } catch (err) {
                this.logger.error('CallHighlightModalComponent', `Error while generating highlight: ${err.toString()}`);
            }
        }
    }

    public async loadHighlights(): Promise<void> {
        const el = this.container.nativeElement;
        const targetBuffer = 1000;
        const currentBuffer = el.scrollHeight - (el.scrollTop + el.clientHeight);
        const shouldLoadNextHighlight = currentBuffer < targetBuffer;
        const hasMoreData = this.queue.length > 0;

        if (!this.isLoadingHighlight && hasMoreData && shouldLoadNextHighlight) {
            this.isLoadingHighlight = true;
            await this.loadNextHighlight();
            this.isLoadingHighlight = false;
            this.loadHighlights();
        }
    }

    public shareHighlight(highlight: CallHighlightModel): void {
        this.logAnalyticsEvent.execute(AnalyticsEvent.HighlightsShare, {
            subscription_status: this.user.analyticsType,
        });
        this.shareCallHighlight.execute(highlight);
    }

    public sharePhotoboothImage(image: PhotoboothImageModel): void {
        this.logAnalyticsEvent.execute(AnalyticsEvent.PhotoboothShared, {
            subscription_status: this.user.analyticsType,
        });
        this.downloadPhotoboothImageInteractor.execute(image, true);
    }

    public canShowPhotoBooth() {
        if (this.user?.currentDevice?.appBuildNumber >= APP_VERSION_COMPATIBILITY.PHOTO_BOOTH_ENABLED) {
            return true;
        }
        return false;
    }
}
