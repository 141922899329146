import { Injectable } from '@angular/core';
import { WaitModalComponent } from '@app/web/modals/wait-modal/wait-modal.component';
import { SimpleModalService } from '@looorent/ngx-simple-modal';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root', // Force it to be a singleton
})
export class WaitModalService {
    protected waitModal: Subscription;

    constructor(protected modalService: SimpleModalService) {}

    public hide(): void {
        if (this.waitModal) {
            this.waitModal.unsubscribe();
            this.waitModal = null;
        }
    }

    public show(): void {
        if (!this.waitModal) {
            this.waitModal = this.modalService
                .addModal(WaitModalComponent, null, {
                    wrapperDefaultClasses: `modal-wrapper modal-wrapper--fade modal--wait`,
                })
                .subscribe();
        }
    }

    public setVisible(isVisible: boolean): void {
        if (isVisible) {
            this.show();
        } else {
            this.hide();
        }
    }
}
