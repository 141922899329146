<div class="rating">
    <div
        class="rating__star"
        *ngFor="let value of [1, 2, 3, 4, 5]"
        [ngClass]="{ 'rating__star--rated': isRated(value) }"
        (click)="setRating(value)">
        <fa-icon *ngIf="isRated(value)" [icon]="['tg', 'star']"></fa-icon>
        <fa-icon *ngIf="!isRated(value)" [icon]="['tg', 'star-light']"></fa-icon>
    </div>
</div>
