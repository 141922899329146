import firebase from 'firebase/app';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleModalComponent, SimpleModalService } from '@looorent/ngx-simple-modal';
import { AnimationOptions } from 'ngx-lottie';
import { Subscription } from 'rxjs';
import {
    IStoreService,
    LimitedContentCohortConfig,
    SubscriptionProduct,
    SubscriptionState,
    SubscriptionTier,
} from '@app/shared/services/store/store-service.interface';
import { WaitModalService } from '@app/shared/services/wait-modal.service';
import {
    AnalyticsEvent,
    SingularEvent,
    GetPlatformInteractor,
    LogAnalyticsEventInteractor,
    UserModel,
    UserService,
} from '@together/common';
import { getCurrencySymbol } from '@angular/common';

@Component({
    selector: 'app-change-tier-based-subscription-modal',
    templateUrl: './change-tier-based-subscription-modal.component.html',
    styleUrls: ['./change-tier-based-subscription-modal.component.scss'],
})
export class ChangeTierBasedSubscriptionModalComponent
    extends SimpleModalComponent<void, void>
    implements OnInit, OnDestroy
{
    public selectedProduct: SubscriptionProduct;
    public isSubscribed: boolean;
    public animation: AnimationOptions = {
        path: './assets/animations/super-hero-panda.json',
    };
    public user: UserModel;
    protected subs = new Subscription();
    protected limitedContentCohortConfig: LimitedContentCohortConfig;
    public premiumTier: SubscriptionTier;

    constructor(
        protected logAnalyticsEvent: LogAnalyticsEventInteractor,
        @Inject('FirebaseFunctions') protected functions: firebase.functions.Functions,
        protected getPlatform: GetPlatformInteractor,
        protected modalService: SimpleModalService,
        @Inject('FirebaseRemoteConfig') protected remoteConfig: firebase.remoteConfig.RemoteConfig,
        protected router: Router,
        @Inject('StoreService') protected storeService: IStoreService,
        protected userService: UserService,
        protected waitService: WaitModalService,
    ) {
        super();
    }

    public async ngOnInit(): Promise<void> {
        [this.user, this.limitedContentCohortConfig] = await Promise.all([
            this.userService.getUser(),
            this.storeService.getLimitedContentCohortConfig(),
        ]);
        this.premiumTier = this.limitedContentCohortConfig.tiers
            ? this.limitedContentCohortConfig.tiers.find(t => t.tierId === 'PRO')
            : null;
        if (this.premiumTier?.products?.length) {
            //Sort products by isBestValue
            this.premiumTier.products.sort((a, b) => (a.isBestValue === b.isBestValue ? 0 : a.isBestValue ? -1 : 1));
            const bestValueProduct = this.premiumTier.products.find(p => p.isBestValue);
            this.premiumTier.productToShowAsSelected = bestValueProduct
                ? bestValueProduct
                : this.premiumTier.products[0];
            this.selectedProduct = this.premiumTier.productToShowAsSelected;
        }
        this.logAnalyticsEvent.execute(AnalyticsEvent.SubscriptionScreenDisplayed, {
            subscription_status: this.user.analyticsType,
        });

        // Get subscription state & watch for changes
        this.isSubscribed = this.user.isSubscribed();

        this.subs.add(
            this.storeService.getSubscriptionState$().subscribe(state => {
                this.isSubscribed = state.isSubscribed;
            }),
        );
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    public selectProduct(product) {
        this.premiumTier.productToShowAsSelected = product;
        this.selectedProduct = product;
    }

    public async purchase(): Promise<void> {
        let result: SubscriptionState;

        this.waitService.show();
        this.logAnalyticsEvent.execute(AnalyticsEvent.SubscriptionClickBuy, {
            product_id: this.selectedProduct.id,
            subscription_status: this.user.analyticsType,
        });

        try {
            result = await this.storeService.upgradeSubscription(this.selectedProduct);
            if (result.isUpgraded) {
                this.close();
                this.storeService.showBuySubscriptionSuccessModal();
                this.logAnalyticsEvent.execute(AnalyticsEvent.SubscriptionPurchased, {
                    product_id: this.selectedProduct.id,
                    subscription_status: this.user.analyticsType,
                });
                this.logAnalyticsEvent.execute(SingularEvent.SngSubscribe);
            }
        } finally {
            this.waitService.hide();
        }
    }

    getCurrencySymbol(currencyCode) {
        return currencyCode ? getCurrencySymbol(currencyCode, 'narrow') : '';
    }
}
