<div class="modal-clean modal-purchase-time">
    <div class="modal-clean__close" (click)="close()">
        <fa-icon [icon]="['tg', 'times-alt']"></fa-icon>
    </div>
    <div class="modal-clean__content">
        <img class="modal-purchase-time__hero" src="./assets/hero-panda.svg" />
        <h2 class="modal-purchase-time__title">Purchase extra time</h2>
        <p>If you ran out of time from your monthly subscription you can purchase packs of extra time right here:</p>
        <div>
            <button
                *ngFor="let product of products"
                class="modal-purchase-time__buy button --wide --orange"
                (click)="purchaseTime(product)">{{ product.hours }} hours / {{ product.price }}</button>
        </div>
        <p>Additional time purchased is consumed  when you run out of your subscription time</p>
    </div>
</div>
