import { Router } from '@angular/router';
import { ActionType, AlertModalComponent } from './../alert-modal/alert-modal.component';
import * as pdfjs from 'pdfjs-dist/legacy/build/pdf';
import { Component, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { SimpleModalComponent } from '@looorent/ngx-simple-modal';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
    AnalyticsEvent,
    BookModel,
    FileStorageModel,
    GetLoggedUserInteractor,
    LogAnalyticsEventInteractor,
    PutFileStorageInteractor,
    PutUserBookInteractor,
    UserModel,
    UserService,
} from '@together/common';

import { WebviewModalComponent } from '@app/web/modals/webview-modal/webview-modal.component';
import { b64DataToFile } from '../../../shared/utilities';
import { environment } from '@env/environment';
import { ModalsService } from '@app/shared/services/modals.service';

@Component({
    selector: 'app-add-book-modal',
    templateUrl: './add-book-modal.component.html',
    styleUrls: ['./add-book-modal.component.scss'],
})
export class AddBookModalComponent extends SimpleModalComponent<void, boolean> implements OnDestroy {
    public uploadBookForm = this.fb.group({
        name: ['', [Validators.required]],
        file: ['', [Validators.required]],
    });

    submitStep: 'title' | 'cover' | 'file' | 'success' = null;
    selectedFile: File;
    uploadProgress: number;
    currentUser: UserModel;
    isUploading = false;

    private subscription = new Subscription();

    constructor(
        protected fb: UntypedFormBuilder,
        protected getLoggedUser: GetLoggedUserInteractor,
        protected logAnalyticsEvent: LogAnalyticsEventInteractor,
        protected uploadFileInteractor: PutFileStorageInteractor,
        protected registerBookInteractor: PutUserBookInteractor,
        protected modalService: ModalsService,
        protected userService: UserService,
        private router: Router,
    ) {
        super();

        this.getLoggedUser
            .execute()
            .then(user => {
                this.currentUser = user;
            })
            .catch(() => {
                this.modalService
                    .showCreateAccountAlert('You can scan your own books when you are logged in.')
                    .then(actionType => {
                        this.close();
                        if (actionType === ActionType.Primary) {
                            this.router.navigate(['/']);
                        }
                    });
            });
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    fileAdded(event): void {
        this.selectedFile = event.target.files[0];
        this.submitStep = 'title';
    }

    createPdfPreview(pdfFile: File): Promise<File> {
        const fileReader = new FileReader();
        const canvas = document.createElement('canvas');

        const thisRef = this;

        return new Promise((resolve, reject) => {
            fileReader.onload = function () {
                const typedarray = new Uint8Array(this.result as ArrayBuffer);

                pdfjs.getDocument(typedarray).promise.then((loadedpdf: pdfjs.PDFDocumentProxy) => {
                    loadedpdf.getPage(1).then(pageOne => {
                        const vp = pageOne.getViewport({ scale: 1 });

                        const context = canvas.getContext('2d');
                        canvas.height = vp.height;
                        canvas.width = vp.width;

                        const task = pageOne.render({ canvasContext: context, viewport: vp });
                        task.promise.then(() => {
                            const bookName = thisRef.getBookName();
                            const preview = b64DataToFile(canvas.toDataURL('image/jpeg'), 'image/jpeg', bookName);
                            resolve(preview);
                        });
                    });
                });
            };

            fileReader.readAsArrayBuffer(pdfFile);
        });
    }

    getBookName(): string {
        return this.uploadBookForm.get('name').value;
    }

    async submit() {
        this.uploadBookForm.markAllAsTouched();

        if (this.uploadBookForm.valid) {
            this.isUploading = true;
            const bookName = this.getBookName();
            const partialUrl = ['user-books', this.currentUser.id, 'books', bookName].join('/');

            const preview = await this.createPdfPreview(this.selectedFile);

            const uploaderProgress = new BehaviorSubject(0);
            const uploaderSubscription = uploaderProgress.subscribe(progress => {
                this.uploadProgress = progress;
            });
            this.subscription.add(uploaderSubscription);

            this.submitStep = 'cover';

            let cover = new FileStorageModel(preview, `${partialUrl}.jpg`);
            cover = await this.uploadFileInteractor.execute(cover, uploaderProgress);

            this.submitStep = 'file';

            let pdf = new FileStorageModel(this.selectedFile, `${partialUrl}.pdf`);
            pdf = await this.uploadFileInteractor.execute(pdf, uploaderProgress);

            const book: BookModel = new BookModel();
            book.coverImageURL = cover.fileUrl;
            book.pdfURL = pdf.fileUrl;
            book.title = bookName;

            await this.registerBookInteractor.execute(this.currentUser.id, book);
            this.logAnalyticsEvent.execute(AnalyticsEvent.BookUploaded, {
                subscription_status: this.currentUser.analyticsType,
            });
            this.isUploading = false;
            this.userService.triggerSuccessConfetti();
            this.result = true;
            this.submitStep = 'success';
        }
    }

    public showTutorial(): void {
        this.modalService.showWebview('Upload Books Tutorial', environment.uploadBooksTutorialURL);
    }

    public addAnotherBook(): void {
        this.uploadBookForm.reset();
        this.selectedFile = null;
        this.submitStep = null;
        this.isUploading = false;
    }

    public goToHome(): void {
        this.close();
    }

    public getProgressPercentage() {
        return this.uploadProgress ? ((this.uploadProgress / 1) * 100).toFixed(0) : 0;
    }
}
