import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent, SimpleModalService } from '@looorent/ngx-simple-modal';

import { WebviewModalComponent } from '../webview-modal/webview-modal.component';
import { environment } from '@env/environment';
import { GetPlatformInteractor } from '@together/common';

@Component({
    selector: 'app-permissions-denied-modal',
    templateUrl: './permissions-denied-modal.component.html',
    styleUrls: ['./permissions-denied-modal.component.scss'],
})
export class PermissionsDeniedModalComponent extends SimpleModalComponent<void, void> implements OnInit {
    constructor(protected modalService: SimpleModalService, protected getPlatform: GetPlatformInteractor) {
        super();
    }

    ngOnInit() {}

    continue() {
        this.close();
        this.modalService.addModal(WebviewModalComponent, {
            title: 'How to Grant Permissions',
            url: `${environment.permissionsURL}#${this.getPlatform.execute()}`,
        });
    }
}
