import { UserService } from '@together/common';
import { AbstractLogger, LogLevel } from '@mobilejazz/harmony-core';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { interval, Observable, Subscription } from 'rxjs';
import { environment } from '@env/environment';

interface LogEntry {
    message: string;
    identifier?: string;
    userId?: string;
    type: EntryType;
    _timestamp: number; // In nanoseconds
    platform: EntryPlatform;
}

enum EntryType {
    VideoCall = 'video_call',
    Other = 'other',
}

enum EntryPlatform {
    Web = 'WEB',
    Android = 'AND',
}

@Injectable({
    providedIn: 'root',
})
export class OpenObserveLogger extends AbstractLogger {
    private ingestionUrl = environment.openObserveConfig.deviceLogsURL;
    private authToken = window.btoa(`${environment.openObserveConfig.logIngestionAuthorizationHeader}`);

    private platform = EntryPlatform.Web; // You can set this dynamically based on the platform
    private userId?: string;
    private logs: LogEntry[] = [];
    private ingestionInterval: number = 10000; // 10 seconds
    private ingestionTimer?: Subscription;
    private static tag = 'OpenObserveLoggerTag';
    private identifier?: string;
    private logType: EntryType = EntryType.Other;
    private isIngestingLogs = false; // Flag to prevent concurrent ingestion

    constructor(private http: HttpClient) {
        super();
    }

    initialiseOpenObserve(options): void {
        this.userId = options.userId;
        if (options.isAndroid) {
            this.platform = EntryPlatform.Android;
        }
        console.log('Begin OpenObserve Logging for user: ', this.userId);
    }

    restart(identifier, userId) {
        this.identifier = identifier;
        this.userId = userId;
        console.log('OpenObserve Logging for session ID: ', this.identifier);
    }

    /**
     * Log key-value pairs.
     */
    logKeyValue(key: string, value: unknown): void {
        const message = `${key}: ${JSON.stringify(value)}`;
        this._log(LogLevel.Info, OpenObserveLogger.tag, message);
    }

    /**
     * Main logging method.
     */
    log(level: LogLevel, message: string): void;
    log(level: LogLevel, tag: string, message: string): void;
    log(level: LogLevel, tagOrMessage: string, message?: string): void {
        let tagToLog;
        let messageToLog;
        if (message && message !== undefined) {
            // This means `tagOrMessage` is actually the `tag`, and `message` is the `message`
            tagToLog = tagOrMessage; // Assign `tag`
            messageToLog = message; // Assign `message`
        } else {
            // This means the second overload (with only `message`) is called
            tagToLog = ''; // No tag
            messageToLog = tagOrMessage; // The whole argument is the message
        }
        //Log all errors to OpenObserve
        if (level === LogLevel.Error) {
            tagToLog = tagToLog
                ? tagToLog.concat(' ', OpenObserveLogger.tag)
                : 'Untagged '.concat(OpenObserveLogger.tag);
        }
        //Other logs will be logged to OpenObserve only if they have the OpenObserveLoggerTag
        if (tagToLog.includes('OpenObserveLoggerTag')) {
            tagToLog = tagToLog.replace(' OpenObserveLoggerTag', '');
            this._log(level, tagToLog, messageToLog);
        } else {
            //Log all other logs to console
            console.log(`[${tagToLog}] ${messageToLog}`);
        }
    }

    /**
     * Core logging logic to capture logs.
     */
    protected _log(level: LogLevel, tag: string, message: string): void {
        // Prepare a new log entry
        const logEntry: LogEntry = {
            message: `[${tag}] ${message}`,
            identifier: this.identifier,
            userId: this.getUserId(),
            type: this.logType,
            _timestamp: this.getCurrentTimestamp(),
            platform: this.platform,
        };

        // If ingestion is happening, wait to append new logs
        if (!this.isIngestingLogs) {
            this.logs.push(logEntry);
        } else {
            // If ingestion is in progress, queue the log to be appended later
            setTimeout(() => this.logs.push(logEntry), this.ingestionInterval);
        }

        // Start the ingestion timer if not already started
        if (!this.ingestionTimer) {
            this.startIngestion();
        }
    }

    /**
     * Start the ingestion timer.
     */
    private startIngestion(): void {
        this.ingestionTimer = interval(this.ingestionInterval).subscribe(() => {
            if (this.logs.length > 0 && !this.isIngestingLogs) {
                this.ingestLogs();
            }
        });
    }

    /**
     * Perform the log ingestion.
     */
    private ingestLogs(): void {
        this.isIngestingLogs = true;
        const logsToSend = [...this.logs];
        this.logs = [];

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Basic ${this.authToken}`,
        });

        this.http.post(this.ingestionUrl, logsToSend, { headers }).subscribe({
            next: response => {
                console.log('Logs successfully ingested:', response);
                this.isIngestingLogs = false; // Allow new logs to be added
            },
            error: error => {
                console.error('Log ingestion failed:', error);
                this.isIngestingLogs = false; // Allow new logs to be added
            },
        });
    }

    /**
     * Utility function to get the current timestamp in nanoseconds.
     */
    private getCurrentTimestamp(): number {
        return Number(BigInt(Date.now()) * BigInt(1000000)); // Convert milliseconds to nanoseconds
    }

    /**
     * Example method to get the current user ID (can be implemented based on the application's logic).
     */
    private getUserId(): string | undefined {
        return this.userId; // Placeholder: Replace with actual user ID fetch logic
    }

    // Implementing all the logger methods from AbstractLogger (for trace, debug, info, etc.)

    trace(message: string): void;
    trace(tag: string, message: string): void;
    trace(tagOrMessage: string, message?: string): void {
        this.log(LogLevel.Trace, tagOrMessage, message);
    }

    debug(message: string): void;
    debug(tag: string, message: string): void;
    debug(tagOrMessage: string, message?: string): void {
        this.log(LogLevel.Debug, tagOrMessage, message);
    }

    info(message: string): void;
    info(tag: string, message: string): void;
    info(tagOrMessage: string, message?: string): void {
        this.log(LogLevel.Info, tagOrMessage, message);
    }

    warning(message: string): void;
    warning(tag: string, message: string): void;
    warning(tagOrMessage: string, message?: string): void {
        this.log(LogLevel.Warning, tagOrMessage, message);
    }

    error(message: string): void;
    error(tag: string, message: string): void;
    error(tagOrMessage: string, message?: string): void {
        this.log(LogLevel.Error, tagOrMessage, message);
    }

    fatal(message: string): void;
    fatal(tag: string, message: string): void;
    fatal(tagOrMessage: string, message?: string): void {
        this.log(LogLevel.Fatal, tagOrMessage, message);
    }
}
