import { SpringboardSection, SpringboardState } from '@app/shared/components/springboard/springboard.component';
import { PurchaseModel } from './../../../../../common/common/src/models/purchase.model';
import { InboxMessageAction, InboxUserMessageModel, UserModel } from '@together/common';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BookPurchaseTransactionType } from './store/base-store.service';

export enum SpringBoardActionTypes {
    Reload = 'reload',
    RefreshUser = 'refreshUser',
    BookPurchased = 'bookPurchased',
    StateChanged = 'stateChanged',
    InboxActionPerformed = 'inboxActionPerformed',
    OpenInbox = 'openInbox',
    SwitchSection = 'switchSection',
}

interface SpringboardReload {
    type: SpringBoardActionTypes.Reload;
}

interface SpringboardRefreshUser {
    type: SpringBoardActionTypes.RefreshUser;
    value: UserModel;
}

export interface SpringboardBookPurchased {
    type: SpringBoardActionTypes.BookPurchased;
    value: PurchaseModel;
    purchaseType: BookPurchaseTransactionType;
    showSuccessModal: boolean;
}

export interface SpringboardStateChanged {
    type: SpringBoardActionTypes.StateChanged;
    value: SpringboardState;
}

export interface SpringboardInboxActionPerformed {
    type: SpringBoardActionTypes.InboxActionPerformed;
    value: InboxMessageAction;
}

export interface SpringboardOpenInbox {
    type: SpringBoardActionTypes.OpenInbox;
    messageId?: string;
}

export interface SpringboardSwitchSection {
    type: SpringBoardActionTypes.SwitchSection;
    section: SpringboardSection;
}

export type SpringboardAction =
    | SpringboardReload
    | SpringboardRefreshUser
    | SpringboardBookPurchased
    | SpringboardStateChanged
    | SpringboardInboxActionPerformed
    | SpringboardOpenInbox
    | SpringboardSwitchSection;

@Injectable({
    providedIn: 'root',
})
export class SpringboardService {
    private actions: Subject<SpringboardAction> = new Subject<SpringboardAction>();

    public actions$(): Observable<SpringboardAction> {
        return this.actions.asObservable();
    }

    public reload(): void {
        this.actions.next({ type: SpringBoardActionTypes.Reload });
    }

    public refreshUser(user): void {
        this.actions.next({ type: SpringBoardActionTypes.RefreshUser, value: user });
    }

    public bookPurchased(
        bookPurchase: PurchaseModel,
        purchaseType: BookPurchaseTransactionType,
        showSuccessModal = false,
    ): void {
        this.actions.next({
            type: SpringBoardActionTypes.BookPurchased,
            value: bookPurchase,
            purchaseType,
            showSuccessModal,
        });
    }

    public stateChanged(state: SpringboardState): void {
        this.actions.next({ type: SpringBoardActionTypes.StateChanged, value: state });
    }

    public inboxActionPerformed(action: InboxMessageAction): void {
        this.actions.next({ type: SpringBoardActionTypes.InboxActionPerformed, value: action });
    }

    public openInbox(messageId?: string): void {
        this.actions.next({ type: SpringBoardActionTypes.OpenInbox, messageId: messageId });
    }

    public switchSection(section: SpringboardSection): void {
        this.actions.next({ type: SpringBoardActionTypes.SwitchSection, section: section });
    }
}
