<div class="modal-clean modal-clean--wide modal-manage-subscription --centered">
    <div class="modal-clean__content --no-outer-padding">
        <div class="modal-clean__header">
            <div class="modal__close" (click)="close()"></div>
            <div class="modal__title">Manage Subscription</div>
        </div>
        <div class="modal-manage-subscription__container">
            <div class="plan-info-container">
                <div class="plan-info-container__details" *ngIf="user?.subscriptionProduct">
                    <div class="plan-info-container__title">STATUS</div>
                    <div class="plan-info-container__content">
                        <div class="top-title" *ngIf="!user?.isLimitedContentCohort()">Your Plan</div>
                        <div class="top-title" *ngIf="user?.isLimitedContentCohort()">
                            {{ userSubscribedTier?.tierName }}
                        </div>
                        <div class="dot-divider">
                            <span class="dot"></span>
                        </div>
                        <div class="top-plan">
                            {{ getCurrencySymbol(user?.subscriptionProduct?.currency)
                            }}{{ user?.subscriptionProduct?.price }}/{{ getSubscriptionPeriodText() }}
                        </div>
                        <div
                            *ngIf="isSubscribed && user?.subscriptionProduct?.subscriptionState === 'active'"
                            class="top-status --active">
                            <fa-icon [icon]="['tg', 'checked-round']"></fa-icon> Active
                        </div>
                        <div
                            *ngIf="isSubscribed && user?.subscriptionProduct?.subscriptionState === 'cancelled'"
                            class="top-status --cancelled">
                            Cancelled
                        </div>
                        <div *ngIf="!isSubscribed && user?.hasExpiredSubscription()" class="top-status --expired">
                            Expired
                        </div>
                    </div>
                    <div class="plan-info-container__content-wrap">
                        <div class="dot-divider">
                            <span class="dot"></span>
                        </div>
                        <div class="top-plan">
                            {{ getCurrencySymbol(user?.subscriptionProduct?.currency)
                            }}{{ user?.subscriptionProduct?.price }}/{{ getSubscriptionPeriodText() }}
                        </div>
                    </div>
                </div>
                <div class="plan-info-container__desc" *ngIf="!user?.isLimitedContentCohort()">
                    Unlimited hours of video calls with access to games, activities and books.
                </div>
                <div
                    class="plan-info-container__desc"
                    *ngIf="user?.isLimitedContentCohort() && user.isBasicSubscription()">
                    Unlimited video call time with a selection of books, games, activities and filters.
                </div>
                <div
                    class="plan-info-container__desc"
                    *ngIf="user?.isLimitedContentCohort() && user.isPremiumSubscription()">
                    Unlimited video call time with access to every book, game, activity and filter.
                </div>
                <div class="plan-info-container__duration" *ngIf="isSubscribed">
                    <ng-container *ngIf="user?.subscriptionProduct?.subscriptionState === 'active'; else cancelled">
                        Renews on {{ user?.getComputedSubscriptionEndDate() | date: 'longDate' }}
                    </ng-container>
                    <ng-template #cancelled>
                        Subscription expires on {{ user?.getComputedSubscriptionEndDate() | date: 'longDate' }}
                    </ng-template>
                </div>
                <div class="plan-info-container__duration" *ngIf="!isSubscribed && user?.hasExpiredSubscription()">
                    Subscription expired on {{ user?.getComputedSubscriptionEndDate() | date: 'longDate' }}
                </div>
                <div
                    class="plan-info-container__upgrade-row"
                    *ngIf="
                        isSubscribed &&
                        user?.isChangeSubscriptionAllowed() &&
                        user?.isLimitedContentCohort() &&
                        user?.isBasicSubscription() &&
                        premiumTier
                    ">
                    <div class="plan-info-container__upgrade-row__content">
                        <div class="__heading">Upgrade to {{ premiumTier?.tierName }}</div>
                        <div class="__subheading">Your all-access pass to every book, game, activity and filter!</div>
                    </div>
                    <div class="plan-info-container__upgrade-row__action">
                        <button class="__action button-v2 --purple --rounded" (click)="showUpgradeModal()">
                            Upgrade
                        </button>
                    </div>
                </div>
                <div class="plan-info-container__upgrade-row" *ngIf="!isSubscribed && user?.hasExpiredSubscription()">
                    <div class="plan-info-container__upgrade-row__content">
                        <div class="__heading">Keep Making Memories</div>
                        <div class="__subheading">Get access to your favorite books, games and activties!</div>
                    </div>
                    <div class="plan-info-container__upgrade-row__action">
                        <button class="__action button-v2 --purple --rounded" (click)="showUpgradeModal()">
                            Subscribe
                        </button>
                    </div>
                </div>
                <div class="plan-info-container__links-row">
                    <div class="action-container" *ngIf="user?.subscriptionProduct?.subscriptionState === 'active'">
                        <div class="action-link" (click)="goToStore()">Update Payment Information</div>
                    </div>
                    <div class="action-container" *ngIf="user?.subscriptionProduct?.subscriptionState === 'active'">
                        <div class="action-link" (click)="goToCancel()">Cancel Subscription</div>
                    </div>
                    <div class="action-container" *ngIf="user?.subscriptionProduct?.subscriptionState === 'cancelled'">
                        <div class="action-link" (click)="goToRenew()">Renew Subscription</div>
                    </div>
                </div>
            </div>
            <div class="footer-link">
                <div class="action-link"><a href="mailto:support@togethervideoapp.com">Need Help?</a></div>
            </div>
        </div>
    </div>
</div>
