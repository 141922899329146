import { NativeStoreConfig, StripeStoreConfig, SubscripionPromotion } from './../../../../environments/types';
import { BookPurchaseTransactionType } from './base-store.service';
import { PaymentProviderType } from '@env/types';
import { Observable } from 'rxjs';
import { PromoCodeModel, BookModel, UserModel } from '@together/common';
import { PurchasesError, PurchasesStoreProduct } from '@revenuecat/purchases-capacitor';

export interface SubscriptionState {
    isSubscribed: boolean;
    isUpgraded?: boolean;
    error?: PurchasesError | Error;
}

export interface SubscriptionProduct {
    id: string;
    description?: string;
    title?: string;
    featuredTitle?: string;
    price: string;
    period: string;
    periodInMonths?: number;
    isBestValue: boolean;
    isLifetime: boolean;
    savingAmount?: string;
    savingPercentage?: string;
    storeProduct?: PurchasesStoreProduct; //Only for android
    strikethroughPrice?: string;
    pricePerMonth?: string;
    projectedStrikethroughPrice?: string;
    discountConfig?: DiscountConfig;
}

export interface BookProduct {
    id: string;
    tierId: string;
    price: string;
    storeProduct?: PurchasesStoreProduct; //Only for android
}

export interface GiftProduct {
    id: string;
    title: string;
    price: string;
    isBestValue: boolean;
    isLifetime: boolean;
    storeProduct?: PurchasesStoreProduct; //Only for android
}

export interface ExtraTimeProduct {
    id: string;
    price: string;
    hours: number;
    storeProduct?: PurchasesStoreProduct; //Only for android
}

export interface SubscriptionTier {
    tierId?: string;
    tierName?: string;
    tierType?: string; // 'LIMITED | FULL'
    tierDescription?: string;
    contentIncluded?: Record<string, string>[];
    isBestValue: boolean;
    products: SubscriptionProduct[];
    productToShowAsSelected?: SubscriptionProduct;
    isActive: boolean;
}

export interface LimitedContentCohortConfig {
    disclaimer: string;
    description: string;
    tiers: SubscriptionTier[];
}

export interface DiscountConfig {
    discountTitle: string;
    discountDescription: string;
    promotionTitle: string;
    couponId?: string; //Only for stripe
    regularPrice?: string; //Only for stripe
}

export interface IStoreService {
    getExtraTimeProducts(): Promise<ExtraTimeProduct[]>;
    getGiftProducts(): Promise<GiftProduct[]>;
    getProvider(): PaymentProviderType;
    getStoreConfig(): StripeStoreConfig | NativeStoreConfig;
    getSubscriptionProducts(): Promise<SubscriptionProduct[]>;
    getUpgradeSubscriptionProducts(): Promise<SubscriptionProduct[]>;
    getSubscriptionPromotion(): Promise<SubscripionPromotion>;
    getLimitedContentCohortConfig(excludeInActive?: boolean): Promise<LimitedContentCohortConfig>;
    getBookRentalProducts(): Promise<BookProduct[]>;
    getBookSaleProducts(): Promise<BookProduct[]>;
    getSubscriptionState$(): Observable<SubscriptionState>;
    init(): void;
    openManageSubscription(): Promise<void>;
    purchaseExtraTime(product: ExtraTimeProduct): Promise<boolean>;
    purchaseBook(product: BookProduct): Promise<boolean>;
    rentBook(product: BookProduct, book: BookModel): Promise<boolean>;
    purchaseGift(product: GiftProduct): Promise<PromoCodeModel>;
    purchaseLifetime(product: SubscriptionProduct): Promise<SubscriptionState>;
    purchaseSubscription(product: SubscriptionProduct): Promise<SubscriptionState>;
    purchasePromoSubscription(promoToken: string): Promise<SubscriptionState>;
    syncPurchases(): Promise<void>;
    restorePurchases(): Promise<void>;
    showBuyGiftSuccessModal(code: string): void;
    showBuyExtraTimeSuccessModal(): void;
    showBuyBookSuccessModal(book: BookModel): void;
    showRentBookSuccessModal(book: BookModel): void;
    showBuySubscriptionSuccessModal(): void;
    showUpgradeModal(): void;
    startBookPurchase(bookId: string, transactionType: BookPurchaseTransactionType): Promise<boolean>;
    upgradeSubscription(product: SubscriptionProduct): Promise<SubscriptionState>;
    loginWithProvider(user: UserModel): Promise<void>;
    logoutWithProvider(): Promise<void>;
    shouldRefreshUserAfterManageSubscription: boolean;
    getRedirectTo(): object;
}
