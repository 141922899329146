import {
    BookModel,
    CohortTypes,
    GetPlatformInteractor,
    IsAdultInteractor,
    LogAnalyticsEventInteractor,
    PromoCodeModel,
    SubscriptionPeriod,
    UserService,
} from '@together/common';
import firebase from 'firebase/app';
import { SimpleModalService } from '@looorent/ngx-simple-modal';
import { AbstractLogger } from '@mobilejazz/harmony-core';

import {
    IStoreService,
    SubscriptionProduct,
    SubscriptionState,
    ExtraTimeProduct,
    BookProduct,
    GiftProduct,
    SubscriptionTier,
    LimitedContentCohortConfig,
} from './store-service.interface';
import {
    PaymentProviderType,
    StoreLifetimeProduct,
    StoreSubscriptionProduct,
    StripeStoreConfig,
    SubscripionPromotion,
} from '@env/types';
import { BaseStoreService, BookPurchaseTransactionType } from './base-store.service';
import { WaitModalService } from '../wait-modal.service';
import { AlertModalComponent } from '@app/web/modals/alert-modal/alert-modal.component';
import { Observable, ReplaySubject } from 'rxjs';

interface BaseCheckoutSessionReq {
    priceId: string;
    type: string;
    couponId?: string;
}

interface SubscriptionCheckoutSessionReq extends BaseCheckoutSessionReq {
    type: 'subscription';
}

interface UpdateSubscriptionCheckoutSessionReq extends BaseCheckoutSessionReq {
    type: 'update-subscription';
}

interface ExtraTimeCheckoutSessionReq extends BaseCheckoutSessionReq {
    type: 'extra-time';
    amount: number;
}

interface BookRentCheckoutSessionReq extends BaseCheckoutSessionReq {
    type: 'book-rent';
}

interface BookSaleCheckoutSessionReq extends BaseCheckoutSessionReq {
    type: 'book-sale';
}

interface LifetimeCheckoutSessionReq extends BaseCheckoutSessionReq {
    type: 'lifetime';
}

interface GiftCheckoutSessionReq extends BaseCheckoutSessionReq {
    type: 'gift';
}

interface SubscriptionPromoCheckoutSessionReq {
    promoToken: string;
    type: 'promo-subscription';
}

export const STORAGE_GIFT_PRODUCT_ID_KEY = 'tg_gift_product_id';
export const STORAGE_SUBSCRIPTION_PRODUCT_ID_KEY = 'tg_subscription_product_id';
export const STORAGE_BOOK_PURCHASE_ID_KEY = 'tg_book_purchase_id';
export const STORAGE_BOOK_PRODUCT_ID_KEY = 'tg_book_product_id';
export const STORAGE_BOOK_DETAILS_KEY = 'tg_book_details';

type GetCheckoutSessionReq =
    | SubscriptionCheckoutSessionReq
    | UpdateSubscriptionCheckoutSessionReq
    | SubscriptionPromoCheckoutSessionReq
    | ExtraTimeCheckoutSessionReq
    | BookRentCheckoutSessionReq
    | BookSaleCheckoutSessionReq
    | LifetimeCheckoutSessionReq
    | GiftCheckoutSessionReq;

export class StripeStore extends BaseStoreService implements IStoreService {
    protected stripe: stripe.Stripe;
    protected lifetimeCfg: StoreLifetimeProduct;
    protected subscriptionState$: ReplaySubject<SubscriptionState>;
    public shouldRefreshUserAfterManageSubscription = false;

    constructor(
        protected config: StripeStoreConfig,
        protected functions: firebase.functions.Functions,
        protected getPlatform: GetPlatformInteractor,
        protected logAnalyticsEvent: LogAnalyticsEventInteractor,
        protected logger: AbstractLogger,
        protected modalService: SimpleModalService,
        protected isAdult: IsAdultInteractor,
        protected waitModal: WaitModalService,
        protected userService: UserService,
    ) {
        super(logAnalyticsEvent, modalService, getPlatform, isAdult, waitModal, userService);
        this.lifetimeCfg = this.config.lifetimeProducts.find(p => p.isEnabled);
        this.subscriptionState$ = new ReplaySubject();
    }

    public async init() {
        const user = await this.userService.getUser();
        if (user.isSubscribed()) {
            const subscriptionPlatform = user.getSubscriptionPlatform();
            const isSubscriptionPlatform = this.getPlatform.execute() === subscriptionPlatform;
            const hasSubscriptionPeriod = !!user.subscriptionProduct?.subscriptionPeriod;
            if (user.stripeCustomerId && isSubscriptionPlatform && !hasSubscriptionPeriod) {
                const stripeGetSubscription = this.functions.httpsCallable('stripeGetSubscription');
                const subscriptionRes = await stripeGetSubscription();
                if (subscriptionRes?.data && subscriptionRes.data) {
                    user.subscriptionProduct = subscriptionRes.data;
                    this.subscriptionState$.next({ isSubscribed: true });
                }
            }
        }
    }

    public async loginWithProvider(user) {
        //Do Nothing for Stripe
    }

    public async logoutWithProvider() {
        //Do Nothing for Stripe
    }

    getSubscriptionState$(): Observable<SubscriptionState> {
        return this.subscriptionState$.asObservable();
    }

    protected async waitStripe(): Promise<void> {
        return new Promise((resolve, reject) => {
            let tries = 0;
            const intervalId = setInterval(() => {
                tries++;

                if ('Stripe' in window) {
                    this.logger.info('StripeStore', `window.Stripe is ready.`);
                    clearInterval(intervalId);
                    return resolve();
                }

                if (tries === 10) {
                    this.logger.info('StripeStore', `window.Stripe not available yet…`);
                    clearInterval(intervalId);
                    return reject();
                }
            }, 200);
        });
    }

    protected async getStripe(): Promise<stripe.Stripe> {
        if (!this.stripe) {
            this.logger.info('StripeStore', `Creating Stripe instance…`);
            await this.waitStripe();
            this.stripe = (window as any).Stripe(this.config.publishableKey);
        }

        return this.stripe;
    }

    protected async getCheckout(req: GetCheckoutSessionReq): Promise<{ error: stripe.Error }> {
        const stripeGetCheckoutSession = this.functions.httpsCallable('stripeGetCheckoutSession');
        const [stripe, sessionRes] = await Promise.all([
            this.getStripe(),
            stripeGetCheckoutSession({ ...this.getRedirectTo(), ...req }),
        ]);

        return stripe.redirectToCheckout({
            sessionId: sessionRes.data.id,
        });
    }

    protected async getCheckoutPromo(req: GetCheckoutSessionReq): Promise<{ error: stripe.Error }> {
        const stripeGetCheckoutPromoSession = this.functions.httpsCallable('stripeGetCheckoutPromoSession');
        const [stripe, sessionRes] = await Promise.all([
            this.getStripe(),
            stripeGetCheckoutPromoSession({ ...this.getRedirectTo(), ...req }),
        ]);

        return stripe.redirectToCheckout({
            sessionId: sessionRes.data.id,
        });
    }

    async getExtraTimeProducts(): Promise<ExtraTimeProduct[]> {
        return this.config.consumableProducts.extraTimeProducts.map(entry => ({
            id: entry.id,
            price: `${entry.price.currency}${entry.price.amount}`,
            hours: entry.hours,
        }));
    }

    getProvider(): PaymentProviderType {
        return PaymentProviderType.Stripe;
    }

    getStoreConfig(): StripeStoreConfig {
        return this.config;
    }

    public async getGiftProducts(): Promise<GiftProduct[]> {
        return this.config.giftProducts
            .filter(e => e.isEnabled)
            .map(entry => {
                return {
                    id: entry.id,
                    title: entry.title,
                    price: `${entry.price.currency}${entry.price.amount}`,
                    isBestValue: entry.isBestValue,
                    isLifetime: entry.isLifetime,
                };
            });
    }

    async getSubscriptionProducts(): Promise<SubscriptionProduct[]> {
        const strikethroughPriceLifetime = this.lifetimeCfg?.retailPriceProductId
            ? this.config.lifetimeProducts.find(p => p.id === this.lifetimeCfg?.retailPriceProductId)?.price
            : null;
        const lifetimeProduct: SubscriptionProduct = {
            id: this.lifetimeCfg.id,
            price: `${this.lifetimeCfg.price.currency}${this.lifetimeCfg.price.amount}`,
            period: 'Lifetime',
            title: this.lifetimeCfg.title,
            description: this.lifetimeCfg.description,
            isBestValue: this.lifetimeCfg.isBestValue,
            featuredTitle: this.lifetimeCfg.featuredTitle,
            isLifetime: true,
            strikethroughPrice: strikethroughPriceLifetime
                ? `${strikethroughPriceLifetime.currency}${strikethroughPriceLifetime.amount}`
                : null,
        };

        const subscriptionProducts = this.constructSubscriptionProduct(this.config.subscriptionProducts, 1);
        return [...subscriptionProducts, lifetimeProduct];
    }

    async getLimitedContentCohortConfig(): Promise<LimitedContentCohortConfig> {
        const tiers = await this.getTierBasedSubscriptionProducts();
        const limitedContentCohort: LimitedContentCohortConfig = {
            disclaimer: this.config.limitedContentCohort?.disclaimer,
            description: this.config.limitedContentCohort?.description,
            tiers,
        };
        return limitedContentCohort;
    }

    private async getTierBasedSubscriptionProducts(): Promise<SubscriptionTier[]> {
        const user = await this.userService.getUser();
        const subscriptionTiers: SubscriptionTier[] = this.config.limitedContentCohort?.tiers.map(entry => {
            const tier = {
                tierId: entry.tierId,
                tierName: entry.tierName,
                tierType: entry.tierType,
                tierDescription: entry.tierDescription,
                contentIncluded: entry.contentIncluded,
                isBestValue: entry.isBestValue,
                products: this.constructSubscriptionProduct(entry.products, 1),
            };
            return tier;
        });
        return subscriptionTiers;
    }

    async getUpgradeSubscriptionProducts(): Promise<SubscriptionProduct[]> {
        const user = await this.userService.getUser();
        const compareProductPeriod =
            user?.subscriptionProduct?.subscriptionPeriod === SubscriptionPeriod.SIX_MONTH ? 6 : 1;
        const subscriptionUpgradeProducts = this.constructSubscriptionProduct(
            this.config.subscriptionUpgradeProducts,
            compareProductPeriod,
        );
        return subscriptionUpgradeProducts;
    }

    private constructSubscriptionProduct(
        products: StoreSubscriptionProduct[],
        compareProductPeriod: number,
    ): SubscriptionProduct[] {
        const compareProduct = this.config.subscriptionProducts.find(
            entry => entry.isEnabled && entry.period.inMonths === compareProductPeriod,
        );
        return products
            .filter(e => e.isEnabled)
            .map(entry => {
                const strikethroughPrice =
                    this.config.subscriptionProducts?.find(p => p.id === p.retailPriceProductId)?.price || null;
                return {
                    id: entry.id,
                    price: `${entry.price.currency}${entry.price.amount}`,
                    period: entry.period.text,
                    periodInMonths: entry.period.inMonths,
                    title: entry.title,
                    featuredTitle: entry.featuredTitle,
                    description: entry.description
                        ? this.renderTemplate(entry.description, entry.period, entry.price, entry.price)
                        : undefined,
                    isBestValue: entry.isBestValue ?? false,
                    isLifetime: false,
                    saving: `${entry.price.currency}${this.calculateSaving(
                        compareProduct.price.amount,
                        entry.price.amount,
                        entry.period.inMonths / compareProductPeriod,
                    )}`,
                    discountCouponId: this.getProductDiscountCoupon(entry),
                    strikethroughPrice: strikethroughPrice
                        ? `${strikethroughPrice.currency}${strikethroughPrice.amount}`
                        : null,
                } as SubscriptionProduct;
            });
    }

    private getProductDiscountCoupon(entry) {
        if (entry?.discount && entry?.discount?.couponId && this.isActiveDiscount(entry.discount)) {
            return entry.discount.couponId;
        }
        return null;
    }

    async openManageSubscription(): Promise<void> {
        const stripeGetBillingPortalSession = this.functions.httpsCallable('stripeGetBillingPortalSession');
        const sessionRes = await stripeGetBillingPortalSession(this.getRedirectTo());

        location.href = sessionRes.data.url;
    }

    async purchaseExtraTime(product: ExtraTimeProduct): Promise<boolean> {
        const req: ExtraTimeCheckoutSessionReq = {
            type: 'extra-time',
            priceId: product.id,
            amount: product.hours * 60 * 60, // Convert to seconds
        };

        return this.getCheckout(req).then(res => {
            return !res.error;
        });
    }

    public async purchaseGift(product: GiftProduct): Promise<PromoCodeModel> {
        const req: GiftCheckoutSessionReq = {
            type: 'gift',
            priceId: product.id,
        };

        function throwError() {
            localStorage.removeItem(STORAGE_GIFT_PRODUCT_ID_KEY);
            throw new Error('There was an issue while creating the Gift purchase checkout session');
        }

        try {
            localStorage.setItem(STORAGE_GIFT_PRODUCT_ID_KEY, product.id);
            const res = await this.getCheckout(req);

            if (res.error) {
                throwError();
            }
        } catch {
            throwError();
        }

        return PromoCodeModel.empty();
    }

    private purchaseSubscriptionReq(
        req: LifetimeCheckoutSessionReq | SubscriptionCheckoutSessionReq,
    ): Promise<SubscriptionState> {
        localStorage.setItem(STORAGE_SUBSCRIPTION_PRODUCT_ID_KEY, req.priceId);

        return this.getCheckout(req).then(res => {
            if (res.error) {
                localStorage.removeItem(STORAGE_SUBSCRIPTION_PRODUCT_ID_KEY);
            }

            // If `redirectToCheckout` fails due to a browser or network
            // error, return the SubscriptionState with the error
            return { isSubscribed: false, error: res.error as unknown as Error };
        });
    }

    private purchaseSubscriptionPromoReq(req: SubscriptionPromoCheckoutSessionReq): Promise<SubscriptionState> {
        return this.getCheckoutPromo(req).then(res => {
            // If `redirectToCheckout` fails due to a browser or network
            // error, return the SubscriptionState with the error
            return { isSubscribed: false, error: res.error as unknown as Error };
        });
    }

    private updateSubscriptionReq(req: UpdateSubscriptionCheckoutSessionReq): Promise<SubscriptionState> {
        localStorage.setItem(STORAGE_SUBSCRIPTION_PRODUCT_ID_KEY, req.priceId);

        return this.getCheckout(req).then(res => {
            if (res.error) {
                localStorage.removeItem(STORAGE_SUBSCRIPTION_PRODUCT_ID_KEY);
            }

            // If `redirectToCheckout` fails due to a browser or network
            // error, return the SubscriptionState with the error
            return { isSubscribed: true, isUpgraded: false, error: res.error as unknown as Error };
        });
    }

    public async purchaseLifetime(product: SubscriptionProduct): Promise<SubscriptionState> {
        return this.purchaseSubscriptionReq({
            type: 'lifetime',
            priceId: this.lifetimeCfg.id,
        });
    }

    async purchaseSubscription(product: SubscriptionProduct): Promise<SubscriptionState> {
        return this.purchaseSubscriptionReq({
            type: 'subscription',
            priceId: product.id,
            couponId: product.discountCouponId,
        });
    }

    async purchasePromoSubscription(promoToken?: string): Promise<SubscriptionState> {
        return this.purchaseSubscriptionPromoReq({
            type: 'promo-subscription',
            promoToken: promoToken,
        });
    }

    async startBookPurchase(bookId: string, transactionType: BookPurchaseTransactionType): Promise<boolean> {
        const purchaseBook = this.functions.httpsCallable('startBookPurchase');
        return purchaseBook({
            platform: this.getPlatform.execute(),
            bookId,
            transactionType,
        })
            .then(result => {
                if (result.data) {
                    localStorage.setItem(STORAGE_BOOK_PURCHASE_ID_KEY, result.data);
                    return true;
                }
                return false;
            })
            .catch(error => {
                this.modalService.addModal(AlertModalComponent, {
                    title: 'Oops!',
                    message: error,
                    type: 'error',
                    icon: 'error',
                });
                return false;
            });
    }

    async purchaseBook(product: BookProduct): Promise<boolean> {
        localStorage.setItem(STORAGE_BOOK_PRODUCT_ID_KEY, product.id);
        const req: BookSaleCheckoutSessionReq = {
            type: 'book-sale',
            priceId: product.id,
        };

        return this.getCheckout(req).then(res => {
            if (res.error) {
                localStorage.removeItem(STORAGE_BOOK_PRODUCT_ID_KEY);
                localStorage.removeItem(STORAGE_BOOK_PURCHASE_ID_KEY);
            }
            return !res.error;
        });
    }

    async rentBook(product: BookProduct, book: BookModel): Promise<boolean> {
        localStorage.setItem(STORAGE_BOOK_PRODUCT_ID_KEY, product.id);
        localStorage.setItem(STORAGE_BOOK_DETAILS_KEY, JSON.stringify(book));
        const req: BookRentCheckoutSessionReq = {
            type: 'book-rent',
            priceId: product.id,
        };

        return this.getCheckout(req).then(res => {
            if (res.error) {
                localStorage.removeItem(STORAGE_BOOK_PRODUCT_ID_KEY);
                localStorage.removeItem(STORAGE_BOOK_PURCHASE_ID_KEY);
                localStorage.removeItem(STORAGE_BOOK_DETAILS_KEY);
            }
            return !res.error;
        });
    }

    async getBookRentalProducts(): Promise<BookProduct[]> {
        return this.config.consumableProducts.bookPurchases.bookRentalProducts.map(entry => ({
            id: entry.id,
            tierId: entry.tierId,
            price: `${entry.price.currency}${entry.price.amount}`,
        }));
    }

    async getBookSaleProducts(): Promise<BookProduct[]> {
        return this.config.consumableProducts.bookPurchases.bookSaleProducts.map(entry => ({
            id: entry.id,
            tierId: entry.tierId,
            price: `${entry.price.currency}${entry.price.amount}`,
        }));
    }

    async getSubscriptionPromotion(): Promise<SubscripionPromotion> {
        return this.isActivePromotion(this.config.subscriptionPromotion) ? this.config.subscriptionPromotion : null;
    }

    public async upgradeSubscription(product: SubscriptionProduct): Promise<SubscriptionState> {
        this.logger.info('StripeStoreService', `Order:Init (Product=${product.id})`);
        return this.updateSubscriptionReq({
            type: 'update-subscription',
            priceId: product.id,
            couponId: product.discountCouponId,
        });
    }
}
