import { Component, OnInit, Input } from '@angular/core';

import { TutorialModel } from '@together/common';

@Component({
    selector: 'app-tutorial-button',
    templateUrl: './tutorial-button.component.html',
    styleUrls: ['./tutorial-button.component.scss'],
})
export class TutorialButtonComponent implements OnInit {
    @Input() tutorial: TutorialModel;

    constructor() {}
    ngOnInit() {}
}
