<div class="contact" [ngClass]="{ 'contact--light': isLight() }" *ngIf="type != 'new'">
    <div
        class="contact__avatar avatar"
        [ngClass]="{ 'contact__avatar--placeholder': !contact }"
        [style.backgroundImage]="'url(' + contact?.getAvatarUrl() + ')'">
        <div class="contact__status" [ngClass]="'contact__status--' + contact?.status"></div>
    </div>
    <div class="contact__name" *ngIf="contact">{{ contact?.displayName }}</div>
    <div class="contact__name" *ngIf="!contact">
        <div class="placeholder placeholder--50"></div>
    </div>
</div>

<!-- New Design-->
<div
    class="contact-v2"
    [ngClass]="{ 'contact-v2--remove-card-action': showRemove }"
    *ngIf="type == 'new'"
    (click)="callSelected.emit()">
    <div
        class="contact-v2__avatar avatar"
        [ngClass]="{ 'contact__avatar--placeholder': !contact }"
        [style.backgroundImage]="'url(' + contact?.getAvatarUrl() + ')'">
        <div class="contact-v2__status" [ngClass]="'contact-v2__status--' + contact?.status"></div>
    </div>
    <div class="contact-v2__name" *ngIf="contact">{{ contact?.displayName }}</div>
    <div class="contact-v2__name" *ngIf="!contact">
        <div class="placeholder placeholder--50"></div>
    </div>
    <div class="contact-v2__call-btn" *ngIf="!showRemove" ></div>
    <div class="contact-v2__remove" *ngIf="showRemove" (click)="removeSelected.emit()"></div>
</div>
