<div class="layout__banner-content">
    <div class="toast__close" (click)="dismissBanner.emit(banner.messageId)"></div>
    <div class="banner-toast banner-toast__upgrade">
        <div class="banner-toast__body">
            <div class="banner-toast__body__image" *ngIf="banner.imageUrl">
                <img [src]="banner.imageUrl" />
            </div>
            <div class="banner-toast__body__content">
                <div class="banner-toast__body__content__title">{{ banner.title?.localizations?.en }}</div>
                <div class="banner-toast__body__content__desc">
                    {{ banner.subtitle?.localizations?.en }}
                </div>
            </div>
        </div>
        <div class="banner-toast__action-box" *ngIf="banner.actions?.length">
            <button
                class="button-v2 --rounded --white --purple-text --wide"
                (click)="performAction.emit(banner.actions[0])">
                {{ banner.actions[0].actionText.localizations.en }}
            </button>
        </div>
    </div>
</div>
