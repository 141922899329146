import { Injectable } from '@angular/core';
import { SimpleModalService } from '@looorent/ngx-simple-modal';
import { AbstractLogger } from '@mobilejazz/harmony-core';

import { AcceptLegalAgreementInteractor, GetLatestLegalAgreementInteractor } from '@together/common';
import { AcceptLegalAgreementModalComponent } from '../../web/modals/accept-legal-agreement/accept-legal-agreement-modal.component';

@Injectable({
    providedIn: 'root', // Force it to be a singleton
})
export class LegalCheckService {
    constructor(
        protected getLatestAgreement: GetLatestLegalAgreementInteractor,
        protected modalsService: SimpleModalService,
        protected logger: AbstractLogger,
        protected accceptLegalAgreement: AcceptLegalAgreementInteractor,
    ) {}

    public async start(autoAccept = false) {
        this.logger.info('LegalCheckService', `Start`);
        const latestRevision = await this.getLatestAgreement.execute();
        if (latestRevision?.id) {
            this.logger.info('LegalCheckService', `Latest revision found: ${latestRevision.id}`);
            if (autoAccept) {
                this.logger.info(
                    'LegalCheckService OpenObserveLoggerTag',
                    `Auto accepting revision on signup: ${latestRevision.id}`,
                );
                await this.accceptLegalAgreement.execute(latestRevision.id, 'NEW_REGISTRATION');
            } else {
                this.logger.info(
                    'LegalCheckService OpenObserveLoggerTag',
                    `Forcing user to accept revision: ${latestRevision.id}`,
                );
                await this.modalsService.addModal(
                    AcceptLegalAgreementModalComponent,
                    { agreement: latestRevision },
                    { closeOnClickOutside: false, closeOnEscape: false },
                );
            }
        } else {
            this.logger.info('LegalCheckService', `No latest revision found`);
        }
    }
}
