import { ShareOptions } from '@capacitor/share';
import { environment } from '@env/environment';
// tslint:disable:max-line-length
import { InviteModel } from '@together/common';

export function createShareOptions(invite: InviteModel): ShareOptions {
    const urlPrefix = environment.giftUrlPrefix;
    const inviteLink = `${urlPrefix}invites?code=${invite.shortCode}`;
    const installLink = `${urlPrefix}install?code=${invite.shortCode}`;

    return {
        title: 'Invitation to join Together',
        dialogTitle: 'App Invitation',
        text: [
            `Hi!`,
            ``,
            `I want to connect with you using 'Together': a new video calling app made for families where we can read stories and play games together.`,
            ``,
            `Please download 'Together' from here: ${installLink}`,
            ``,
            `Tap this link after you install the app to accept the invitation: ${inviteLink}`,
            ``,
            `Or enter your invite code directly in the app: ${invite.shortCode}`,
            ``,
            `See you soon in 'Together'!`,
            ``,
        ].join('\n'),
    };
}
