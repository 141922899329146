<div class="page book-catalog" [ngClass]="{ 'page--safe': addSafeMargin }">
    <div class="header">
        <div class="header__close" (click)="closeClick.emit()" *ngIf="showClose">Close</div>
        <div class="header__title">Book Catalog</div>
    </div>
    <div class="page__content">
        <div class="grid grid--thumbnails" *ngIf="books === false">
            <app-thumbnail-placeholder></app-thumbnail-placeholder>
            <app-thumbnail-placeholder></app-thumbnail-placeholder>
            <app-thumbnail-placeholder></app-thumbnail-placeholder>
            <app-thumbnail-placeholder></app-thumbnail-placeholder>
            <app-thumbnail-placeholder></app-thumbnail-placeholder>
            <app-thumbnail-placeholder></app-thumbnail-placeholder>
            <app-thumbnail-placeholder></app-thumbnail-placeholder>
            <app-thumbnail-placeholder></app-thumbnail-placeholder>
            <app-thumbnail-placeholder></app-thumbnail-placeholder>
            <app-thumbnail-placeholder></app-thumbnail-placeholder>
            <app-thumbnail-placeholder></app-thumbnail-placeholder>
            <app-thumbnail-placeholder></app-thumbnail-placeholder>
            <app-thumbnail-placeholder></app-thumbnail-placeholder>
        </div>
        <div class="grid grid--thumbnails" *ngIf="books">
            <div *ngFor="let book of books">
                <app-book-thumbnail
                    [book]="book"
                    [isFav]="users[0]?.isFavoriteBook(book.id)"
                    [isContentRestricted]="users[0]?.isContentRestricted(book)"
                    (click)="bookClick.emit(book)">
                </app-book-thumbnail>
            </div>
        </div>
    </div>
</div>
