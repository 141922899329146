import { Photobooth, PhotoboothEvent } from './photobooth.class';
import { AbstractLogger } from '@mobilejazz/harmony-core';
import firebase from 'firebase/app';
import { Base64 } from 'js-base64';
import { merge, Subject } from 'rxjs';
import { ActivityStatisticsMonitor } from './activities/activity-statistics-monitor';
import { CallDataTrackMonitorConstants } from './call-data-track-monitor';

import {
    ActivityDocument,
    ActivityEntityToActivityModelMapper,
    ActivityEvent,
    ActivityEventDeliveryMode,
    ActivityMode,
    ActivityModel,
    ActivityOutgoingEvent,
    AppEvent,
    BookModel,
    CallConfigurationModel,
    CallMetadata,
    ErrorEvent,
    IActivity,
} from '@together/common';
import { SequentialTaskQueue } from 'sequential-task-queue';
import { IframeActivity } from './activities/iframe.class';
import { ReadTogetherActivity } from './activities/read-together.class';
import { CallEvent } from './machines/call.machine';
import { IVideoStream } from './video-stream.interface';

enum SpringboardEvent {
    UpdateConfiguration = 'kHKSBUpdateConfigurationEvent',
    CloseActivity = 'kHKSBCloseActivityEvent',
    ToggleVideo = 'kHKSBToggleVideoEvent',
    ToggleMicrophone = 'kHKSBToggleMicrophoneEvent',
}

export class ActivityHub {
    event$: Subject<CallEvent>;

    protected currentActivity: IActivity;
    protected remoteEventsQueue: SequentialTaskQueue;

    constructor(
        protected firestore: firebase.firestore.Firestore,
        protected iframeActivity: IframeActivity,
        protected logger: AbstractLogger,
        protected metadata: CallMetadata,
        protected readActivity: ReadTogetherActivity,
        protected stream: IVideoStream,
        protected activityStatisticsMonitor: ActivityStatisticsMonitor,
        protected photobooth: Photobooth,
    ) {
        this.event$ = new Subject<CallEvent>();
        this.remoteEventsQueue = new SequentialTaskQueue();

        merge(this.iframeActivity.event$, this.readActivity.event$).subscribe(event => {
            this.handleActivityEvent(event);
        });

        // Pass the event from photobooth to the state machine
        this.photobooth.event$.subscribe(event => {
            this.event$.next(event);
        });

        // Reusable closure for the `remoteEventsQueue`
        const handleRemoteEvent = event => {
            return this.handleRemoteEvent(event);
        };

        this.stream.activityEvent$.subscribe(event => {
            // Force sequencial handling of remote events
            this.remoteEventsQueue.push(handleRemoteEvent, { args: [event] });
        });
    }

    closeActivity(notifyRemote = true) {
        // Short circuit if current activity is closed already
        if (!this.currentActivity) {
            return;
        }
        if (this.metadata.activityMode === ActivityMode.Admin) {
            this.activityStatisticsMonitor.closeTracking();
        }

        this.logger.info('ActivityHub OpenObserveLoggerTag', `Closing activity: ${this.currentActivity.id}`);
        this.logger.info('ActivityHub', `Triggered from: ${notifyRemote ? 'local' : 'remote'}`);

        // Remove iframe content
        this.currentActivity.close();
        this.currentActivity = null;

        // Notify remote user
        if (notifyRemote) {
            this.stream.send({
                deliveryMode: ActivityEventDeliveryMode.Guaranteed,
                originActivityId: 'com.bitwisesl.ios.holakid.activity.springboard',
                name: SpringboardEvent.CloseActivity,
                value: '',
            });
        }
    }

    closeSpringboard() {
        this.stream.send({
            deliveryMode: ActivityEventDeliveryMode.Guaranteed,
            originActivityId: 'com.bitwisesl.ios.holakid.activity.springboard',
            name: 'kHKSBHideSpringBoardEvent',
            value: '',
        });
    }

    notifyToggleVideo(value) {
        this.stream.send({
            deliveryMode: ActivityEventDeliveryMode.Guaranteed,
            originActivityId: 'com.bitwisesl.ios.holakid.activity.springboard',
            name: SpringboardEvent.ToggleVideo,
            value: value,
        });
    }

    notifyToggleMicrophone(value) {
        this.stream.send({
            deliveryMode: ActivityEventDeliveryMode.Guaranteed,
            originActivityId: 'com.bitwisesl.ios.holakid.activity.springboard',
            name: SpringboardEvent.ToggleMicrophone,
            value: value,
        });
    }

    handleActivityEvent(outgoingEvent: ActivityOutgoingEvent) {
        if (!this.currentActivity) {
            return;
        }

        switch (outgoingEvent.type) {
            case 'app': {
                const event = outgoingEvent.payload as AppEvent;

                switch (event.name) {
                    case 'com.bitwisesl.js.events.activityStarted':
                        this.logger.info('ActivityHub:Event OpenObserveLoggerTag', `Local activity ready`);
                        this.iframeActivity.localReady();
                        this.stream.send({
                            deliveryMode: ActivityEventDeliveryMode.Guaranteed,
                            name: 'jsactivity.events.activityLoaded',
                            originActivityId: this.currentActivity.id,
                            value: '',
                            content: '',
                        });

                        break;

                    case 'com.bitwisesl.js.events.animateLocalVideoViewForAttention':
                        this.logger.info('ActivityHub:Event OpenObserveLoggerTag', `Animate local video thumbnail`);
                        this.event$.next({ type: 'ANIMATE_LOCAL' });
                        break;

                    case 'com.bitwisesl.js.events.animateRemoteVideoViewForAttention':
                        this.logger.info('ActivityHub:Event OpenObserveLoggerTag', `Animate remote video thumbnail`);
                        this.event$.next({ type: 'ANIMATE_REMOTE' });
                        break;

                    case 'com.bitwisesl.js.events.interaction':
                        if (event.value === 'com.bitwisesl.js.events.activity.pointScored') {
                            this.logger.info('ActivityHub:Event OpenObserveLoggerTag', `Point scored`);

                            if (event.content && event.content.activityCapture) {
                                // const content = JSON.parse(event.content);
                                // console.log('activityCapture', event.content.activityCapture);

                                this.event$.next({
                                    type: 'POINT_SCORED',
                                    activityCapture: event.content.activityCapture,
                                });
                            } else {
                                this.event$.next({ type: 'POINT_SCORED' });
                            }
                        }

                        break;

                    case 'com.bitwisesl.js.events.showNotYourTurnUI':
                        this.logger.info('ActivityHub:Event OpenObserveLoggerTag', `Show not your turn overlay`);
                        this.event$.next({ type: 'SHOW_NOT_YOUR_TURN' });
                        break;

                    case 'com.bitwisesl.js.events.connectionLatency':
                        this.logger.info('ActivityHub:Event OpenObserveLoggerTag', `Connection Latency`);
                        this.jsActivityEventToRecordLatency(event as ActivityEvent);
                        break;

                    default:
                        this.logger.info(
                            'ActivityHub:Event OpenObserveLoggerTag',
                            `Unhandled app event: ${event.name}`,
                        );
                        break;
                }

                break;
            }

            case 'activity': {
                const event = outgoingEvent.payload as ActivityEvent;

                if (this.isReadingActivity()) {
                    return this.stream.send(event);
                } else {
                    return this.stream.send({
                        deliveryMode: ActivityEventDeliveryMode.Guaranteed,
                        name: 'activityEvent',
                        originActivityId: this.currentActivity.id,
                        value: '',
                        content: Base64.encode(JSON.stringify(event)),
                    });
                }
            }

            case 'error': {
                const event = outgoingEvent.payload as ErrorEvent;

                this.logger.error('ActivityHub:Event', `Unhandled error event: ${event.name}`);
                break;
            }
        }
    }

    protected async getActivity(id: string): Promise<ActivityModel> {
        // TODO: HANDLE ERROR CASE
        // TODO: Move to Harmony
        const mapper = new ActivityEntityToActivityModelMapper();
        const doc = await this.firestore.doc(`/activities/${id}`).get();

        return mapper.map({
            id: doc.id,
            data: doc.data() as ActivityDocument,
        });
    }

    protected async handleRemoteEvent(event: ActivityEvent) {
        // this.logger.info('ActivityHub:RemoteEvent', JSON.stringify(event, null, 4));
        // this.logger.info('ActivityHub:RemoteEvent', `Current activity: ${this.currentActivity ? this.currentActivity.id : 'none'}`);

        const isActivityLoaded = !!this.currentActivity && 'id' in this.currentActivity;

        if (event.originActivityId === 'com.bitwisesl.ios.holakid.activity.springboard') {
            switch (event.name) {
                case 'kHKSBRequestActivityModeEvent':
                    this.logger.info('ActivityHub:Event OpenObserveLoggerTag', `Animate "Let's Play"`);
                    this.event$.next({ type: 'ANIMATE_PLAY' });
                    break;

                case 'kHKSBShowSpringBoardEvent':
                    this.logger.info('ActivityHub:Event OpenObserveLoggerTag', `Show springboard`);
                    this.event$.next({ type: 'ACTIVITY_LIST' });
                    break;

                case 'kHKSBHideSpringBoardEvent':
                    this.logger.info('ActivityHub:Event OpenObserveLoggerTag', `Hide springboard`);
                    this.event$.next({ type: 'BACK' });
                    break;

                case 'kHKSBClientSelectedActivityEvent': {
                    const isReadingActivity = event.value === this.readActivity.id;

                    this.logger.info('ActivityHub:Event OpenObserveLoggerTag', `Start activity: ${event.value}`);

                    if (isReadingActivity) {
                        if (event.content) {
                            let book = new BookModel();
                            // Assign data
                            //Object.assign(book, bookData); // Does not work as it does not copy getters/setters
                            book = this.readActivity.constructBookModel(event.content);

                            this.launchActivityRead(book);
                        } else {
                            this.launchActivityRead();
                        }

                        this.event$.next({ type: 'ACTIVITY_STARTED_READ' });
                    } else {
                        this.launchActivityIframe(await this.getActivity(event.value));

                        this.event$.next({ type: 'ACTIVITY_STARTED_IFRAME' });
                    }

                    break;
                }

                case 'kHKSBLaunchActivityEvent': {
                    this.logger.info('ActivityHub:Event OpenObserveLoggerTag', `Start activity: ${event.value}`);

                    const activity = await this.getActivity(event.value);
                    const isReadActivity = activity.id === this.readActivity.id;
                    const isIframeActivity = activity.type === 'jsActivity';
                    if (isReadActivity) {
                        if (event.content) {
                            let book = new BookModel();
                            book = this.readActivity.constructBookModel(event.content);
                            this.initReadingActivity(activity, false, book);
                            this.readActivity.setInitialBook(book);
                        } else {
                            this.initReadingActivity(activity, false);
                        }

                        this.event$.next({ type: 'ACTIVITY_STARTED_READ' });
                    } else if (isIframeActivity) {
                        this.initActivity(activity);
                        this.event$.next({ type: 'ACTIVITY_STARTED_IFRAME' });
                    } else {
                        throw new Error('Unsupported activity type');
                    }

                    break;
                }

                case SpringboardEvent.CloseActivity:
                    this.logger.info('ActivityHub:Event OpenObserveLoggerTag', `Close activity`);
                    this.closeActivity(false);
                    this.event$.next({ type: 'ACTIVITY_CLOSE' });
                    break;

                case SpringboardEvent.UpdateConfiguration:
                    this.logger.info('ActivityHub:Event OpenObserveLoggerTag', `Update configuration`);
                    const data = JSON.parse(Base64.decode(event.content));
                    const configuration = new CallConfigurationModel(
                        data.allowChildToStartActivities,
                        data.enableCallHighlights,
                    );
                    this.event$.next({ type: 'UPDATE_CALL_CONFIGURATION', configuration });
                    break;
                case SpringboardEvent.ToggleVideo:
                    this.logger.info('ActivityHub:Event OpenObserveLoggerTag', `Toggle Video`);
                    if (event.value === 'ON') {
                        this.event$.next({ type: `TOGGLE_REMOTE_VIDEO_ON` });
                    } else if (event.value === 'OFF') {
                        this.event$.next({ type: `TOGGLE_REMOTE_VIDEO_OFF` });
                    }
                    break;
                case SpringboardEvent.ToggleMicrophone:
                    this.logger.info('ActivityHub:Event OpenObserveLoggerTag', `Toggle Microphone`);
                    if (event.value === 'ON') {
                        this.event$.next({ type: `TOGGLE_REMOTE_MICROPHONE_ON` });
                    } else if (event.value === 'OFF') {
                        this.event$.next({ type: `TOGGLE_REMOTE_MICROPHONE_OFF` });
                    }
                    break;
                default:
                    this.logger.info(
                        'ActivityHub:RemoteEvent OpenObserveLoggerTag',
                        `Unhandled springboard event: ${JSON.stringify(event, null, 4)}`,
                    );
                    break;
            }
        } else if (event.originActivityId === PhotoboothEvent.OriginActivityId) {
            this.photobooth.handleEvent(event);
        } else if (isActivityLoaded && event.originActivityId === this.currentActivity.id) {
            switch (event.name) {
                case 'jsactivity.events.activityLoaded':
                    this.logger.info('ActivityHub:Event OpenObserveLoggerTag', `Remote activity is ready`);
                    this.iframeActivity.remoteReady();
                    break;

                default:
                    try {
                        this.currentActivity.handleEvent(event);
                    } catch (err) {
                        this.logger.info(
                            'ActivityHub:RemoteEvent OpenObserveLoggerTag',
                            `Unhandled activity event: ${JSON.stringify(event, null, 4)}`,
                        );
                    }

                    break;
            }
        } else if (event.originActivityId === CallDataTrackMonitorConstants.originActivityId) {
            this.event$.next({ type: 'PROCESS_HEARTBEAT_EVENT', eventData: event });
        } else {
            this.logger.info(
                'ActivityHub:RemoteEvent OpenObserveLoggerTag',
                `Unhandled remote event: ${JSON.stringify(event, null, 4)}`,
            );
        }
    }

    protected initActivity(activity: ActivityModel, notifyRemote = false) {
        const isReadActivity = activity.id === this.readActivity.id;
        const isIframeActivity = activity.type === 'jsActivity';
        if (isReadActivity) {
            throw new Error('Unsupported handler for reading activity type. Call initReadingActivity instead.');
        } else if (isIframeActivity) {
            this.currentActivity = this.iframeActivity;
            if (this.metadata.activityMode === ActivityMode.Admin) {
                this.activityStatisticsMonitor.init(activity, null, null);
                this.activityStatisticsMonitor.startTracking();
            }
        } else {
            throw new Error('Unsupported activity type');
        }

        if (notifyRemote) {
            // Notify remote user
            this.stream.send({
                deliveryMode: ActivityEventDeliveryMode.Guaranteed,
                originActivityId: 'com.bitwisesl.ios.holakid.activity.springboard',
                name: 'kHKSBLaunchActivityEvent',
                value: activity.id,
                content: null,
            });
        }

        this.logger.info('ActivityHub OpenObserveLoggerTag', `Init activity: ${this.currentActivity.id}`);
        this.currentActivity.init(activity);
    }

    protected initReadingActivity(activity: ActivityModel, notifyRemote = false, book?: BookModel) {
        this.currentActivity = this.readActivity;
        if (this.metadata.activityMode === ActivityMode.Admin) {
            this.activityStatisticsMonitor.init(null, this.readActivity, book);
            this.activityStatisticsMonitor.startTracking();
        }
        if (notifyRemote) {
            // Notify remote user
            this.stream.send({
                deliveryMode: ActivityEventDeliveryMode.Guaranteed,
                originActivityId: 'com.bitwisesl.ios.holakid.activity.springboard',
                name: 'kHKSBLaunchActivityEvent',
                value: activity.id,
                content: book ? this.readActivity.encodeBook(book) : null,
            });
        }

        this.logger.info('ActivityHub OpenObserveLoggerTag', `Init activity: ${this.currentActivity.id}`);
        this.currentActivity.init(activity);
    }

    public getReadActivityId(): string {
        return this.readActivity.id;
    }

    isReadingActivity(): boolean {
        return this.currentActivity && this.currentActivity.id === this.readActivity.id;
    }

    launchActivityIframe(activity: ActivityModel) {
        this.initActivity(activity, true);
    }

    async launchActivityRead(book?: BookModel) {
        if (book) {
            this.logger.info('ActivityHub OpenObserveLoggerTag', `Launch read activity: ${book.title} (${book.id})`);
        } else {
            this.logger.info('ActivityHub', `Launch read activity: catalog`);
        }

        // We will always have the book starting 2.0
        if (book) {
            this.initReadingActivity(await this.getActivity(this.readActivity.id), true, book);
            this.readActivity.setInitialBook(book);
            // This is still needed for compatibility with older versions which does not handle book from launch activity event
            this.readActivity.sendLoadBook(book);
        } else {
            this.logger.info('ActivityHub', `Launch read activity without the book: catalog. On version < 2.0`);
            this.initReadingActivity(await this.getActivity(this.readActivity.id), true);
        }
    }

    launchActivityIframeAsChild(activity: ActivityModel) {
        // Notify remote user
        this.stream.send({
            deliveryMode: ActivityEventDeliveryMode.Guaranteed,
            originActivityId: 'com.bitwisesl.ios.holakid.activity.springboard',
            name: 'kHKSBClientSelectedActivityEvent',
            value: activity.id,
            content: null,
        });
    }

    launchActivityReadAsChild(book?: BookModel) {
        // Notify remote user
        this.stream.send({
            deliveryMode: ActivityEventDeliveryMode.Guaranteed,
            originActivityId: 'com.bitwisesl.ios.holakid.activity.springboard',
            name: 'kHKSBClientSelectedActivityEvent',
            value: this.readActivity.id,
            content: book ? this.readActivity.encodeBook(book) : null,
        });
    }

    openSpringboard() {
        this.stream.send({
            deliveryMode: ActivityEventDeliveryMode.Guaranteed,
            originActivityId: 'com.bitwisesl.ios.holakid.activity.springboard',
            name: 'kHKSBShowSpringBoardEvent',
            value: '',
        });
    }

    public notifyCallConfigurationChanged(config: CallConfigurationModel): void {
        const content = Base64.encode(JSON.stringify(config));
        this.stream.send({
            deliveryMode: ActivityEventDeliveryMode.Guaranteed,
            originActivityId: 'com.bitwisesl.ios.holakid.activity.springboard',
            name: SpringboardEvent.UpdateConfiguration,
            value: '',
            content,
        });
    }

    public requestPlayTime(): void {
        this.stream.send({
            deliveryMode: ActivityEventDeliveryMode.Guaranteed,
            originActivityId: 'com.bitwisesl.ios.holakid.activity.springboard',
            name: 'kHKSBRequestActivityModeEvent',
            value: '',
        });
    }

    private jsActivityEventToRecordLatency(event: ActivityEvent) {
        this.activityStatisticsMonitor.recordLatency(event);
    }
}
