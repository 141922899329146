<div class="modal-clean modal-clean--full-screen modal-play-demo --centered">
    <div class="modal-clean__content --no-outer-padding">
        <div class="modal-play-demo__container">
            <div class="modal-play-demo__container__overlay-buttons">
                <button class="button-v2 --icon-only --close" (click)="close()">
                    <fa-icon [icon]="['tg', 'times']"></fa-icon>
                </button>
                <button
                    *ngIf="isLoggedIn && hasStarted"
                    class="button-v2 --icon-only --favorite"
                    (click)="toggleActivityAsFavorite()">
                    <fa-icon *ngIf="isFav" [icon]="['tg', 'heart-solid']"></fa-icon>
                    <fa-icon *ngIf="!isFav" [icon]="['tg', 'heart-empty']"></fa-icon>
                </button>
            </div>
            <div class="intro-container" *ngIf="!hasStarted">
                <div class="user-box --user-adult">
                    <img class="user-box__img" src="./assets/user-adult.svg" />
                    <div class="user-box__desc">This shows the adult's screen</div>
                </div>
                <div class="action-button">
                    <button class="button-v2 --orange --rounded --large" (click)="startDemo()">Start Demo</button>
                </div>
                <div class="user-box --user-child">
                    <img class="user-box__img" src="./assets/user-child.svg" />
                    <div class="user-box__desc">This shows the child's screen</div>
                </div>
            </div>
            <div class="intro-container" *ngIf="hasStarted">
                <div class="activity-pane">
                    <app-iframe-viewer
                        [mode]="0"
                        [activityUrl]="activity.activityURL"
                        (activityEvent)="handleAdminEvent($event)"
                        #admin></app-iframe-viewer>
                </div>
                <div class="activity-pane">
                    <app-iframe-viewer
                        [mode]="1"
                        [activityUrl]="activity.activityURL"
                        (activityEvent)="handleChildEvent($event)"
                        #child></app-iframe-viewer>
                </div>
            </div>
        </div>
    </div>
</div>
