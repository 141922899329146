import { ContentByTierFilter, GetContentByTierInteractor, ContentByTierModel } from '@together/common';
import { Component } from '@angular/core';
import { SimpleModalComponent } from '@looorent/ngx-simple-modal';
import { AnimationOptions } from 'ngx-lottie';

export interface ContentByTierModal {
    tier: string;
    tierName: string;
    displayPrice: string;
}

@Component({
    selector: 'app-content-by-tier-modal',
    templateUrl: './content-by-tier-modal.component.html',
    styleUrls: ['./content-by-tier-modal.component.scss'],
})
export class ContentByTierModalComponent extends SimpleModalComponent<ContentByTierModal, boolean> {
    public isLoading = true;
    public content: ContentByTierModel;
    public animation: AnimationOptions = {
        path: './assets/animations/loading.json',
    };
    public tier: string;
    public displayPrice: string;
    public tierName: string;

    constructor(private readonly getContent: GetContentByTierInteractor) {
        super();
    }

    public ngOnInit(): void {
        this.load();
    }

    public triggerPurchase() {
        this.result = true;
        this.close();
    }

    private async load(refreshList = false): Promise<void> {
        this.isLoading = true;
        const filter: ContentByTierFilter = {
            tier: this.tier,
            includeBooks: true,
            includeActivities: true,
            includePhotoboothFilters: true,
        };
        this.content = await this.getContent.execute(filter, refreshList);
        this.isLoading = false;
    }
}
