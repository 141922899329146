import { ErrorHandler } from '@angular/core';
import { BugfenderClass } from '@bugfender/sdk';

export class AppErrorHandler extends ErrorHandler {
    constructor(protected bugfender: BugfenderClass, protected isProduction: boolean) {
        super();
    }

    handleError(error) {
        if (this.isProduction) {
            this.bugfender.sendCrash(error.message, [`Error: ${error.message}`, `Stack: ${error.stack}`].join('\n'));
        } else {
            console.error(`[AppErrorHandler] ${error}`);
        }
    }
}
