import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from '@looorent/ngx-simple-modal';

export interface ConfirmModal {
    title: string;
    message: string;
    primaryActionLabel?: string;
}

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent
    extends SimpleModalComponent<ConfirmModal, boolean>
    implements ConfirmModal, OnInit
{
    title: string;
    message: string;
    primaryActionLabel: string = 'Ok';

    constructor() {
        super();
    }

    ngOnInit() {}

    confirm() {
        this.result = true;
        this.close();
    }
}
