import { ActivityEvent, LatencyRecording } from '@together/common';

export class SocketActicityStatisticsHelper {
    private currentEvent: ActivityEvent;
    private latestLatency: number = 0;
    private totalLatency: number = 0;
    private count: number = 0;
    private highestValue: number = 0;
    private recsAboveHalfSecond: number = 0;

    constructor(event: ActivityEvent) {
        this.currentEvent = event;
        this.latestLatency = 0;
        this.highestValue = 0;
        this.recsAboveHalfSecond = 0;
        this.totalLatency = 0;
        this.count = 0;
    }

    get average(): number {
        return this.totalLatency / this.count;
    }

    get latencyRecording(): LatencyRecording {
        return {
            average: this.average,
            recordings: this.count,
            highestValue: this.highestValue,
            recsAboveHalfSecond: this.recsAboveHalfSecond,
        };
    }

    recordLatency(latency: number, event: ActivityEvent): void | never {
        if (this.currentEvent.originActivityId !== event.originActivityId) {
            throw new Error(`Activity Initialised is different from the current event's activity`);
        }
        this.latestLatency = latency / 1000; // Milliseconds to Seconds conversion
        if (this.latestLatency > this.highestValue) {
            this.highestValue = this.latestLatency;
        }
        if (this.latestLatency > 0.5) {
            this.recsAboveHalfSecond += 1;
        }
        this.totalLatency += this.latestLatency;
        this.count += 1;
    }
}
