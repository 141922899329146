import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UserModel } from '@together/common';

type ContactModifier = 'default' | 'light';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
    @Input() contact: UserModel;
    @Input() modifier: ContactModifier = 'default';
    @Input() type: string = '';
    @Input() showRemove: boolean;

    @Output() public callSelected = new EventEmitter<void>();
    @Output() public removeSelected = new EventEmitter<void>();

    constructor() {}
    ngOnInit() {}

    isLight(): boolean {
        return this.modifier === 'light';
    }
}
