import { Subject, ReplaySubject } from 'rxjs';
import { AbstractLogger } from '@mobilejazz/harmony-core';
import {
    createLocalAudioTrack,
    createLocalVideoTrack,
    connect,
    LocalAudioTrack,
    LocalDataTrack,
    LocalVideoTrack,
    RemoteAudioTrack,
    RemoteVideoTrack,
    Room,
} from 'twilio-video';
import * as deepar from 'deepar';

import { environment } from '@env/environment';
import {
    ActivityEvent,
    AnalyticsEvent,
    CallMetadata,
    CallMode,
    FaceFilterModel,
    LogAnalyticsEventInteractor,
    GenerateTwilioTokenInteractor,
    SingularEvent,
} from '@together/common';
import { IVideoStream, StreamEvent, StreamEventType } from '../video-stream.interface';

export class TwilioVideoStream implements IVideoStream {
    activityEvent$: Subject<ActivityEvent>;
    event$: ReplaySubject<StreamEvent>;

    protected TOKEN_URL = environment.twilioTokenURL;
    protected accessToken: string;
    protected audioTrack: LocalAudioTrack;
    protected dataTrack: LocalDataTrack;
    protected room: Room;
    protected tracks: any[];
    protected videoTrack: LocalVideoTrack;
    protected videoElLocal: HTMLVideoElement;
    protected videoElRemote: HTMLVideoElement;
    protected cameraFacingMode = 'user';
    protected deepAR: deepar.DeepAR;
    protected videoElDeepARInput: HTMLVideoElement;

    constructor(
        protected logAnalyticsEvent: LogAnalyticsEventInteractor,
        protected logger: AbstractLogger,
        protected metadata: CallMetadata,
        protected iframeEl: Element,
        protected localEl: Element,
        protected remoteEl: Element,
        protected deeparCanvasEl: HTMLCanvasElement,
        protected generateTwilioTokenInteractor: GenerateTwilioTokenInteractor,
    ) {
        this.activityEvent$ = new Subject();
        this.event$ = new ReplaySubject();
    }

    protected async initLocalTracks() {
        this.logger.info('TwilioVideoStream', `Init local tracks`);

        try {
            this.audioTrack = await createLocalAudioTrack();
            this.videoTrack = await createLocalVideoTrack({
                width: 640,
                name: 'Camera',
                facingMode: this.cameraFacingMode,
            });
        } catch (err) {
            this.logAnalyticsEvent.execute(AnalyticsEvent.CameraSourceDidFail);
            throw err;
        }

        this.dataTrack = new LocalDataTrack({ ordered: true, logLevel: 'warn' });
        this.tracks = [this.videoTrack, this.audioTrack, this.dataTrack];

        // Init video preview
        this.videoElLocal = this.videoTrack.attach() as HTMLVideoElement;
        this.localEl.appendChild(this.videoElLocal);
    }

    protected attachTrack(track: RemoteAudioTrack | RemoteVideoTrack): void {
        const el = track.attach();

        if (track.kind === 'video') {
            //Remove the previous video element and append the AR effect applied track to the element
            const videoElementRemote = this.remoteEl.querySelector('video');
            if (videoElementRemote) {
                this.remoteEl.removeChild(this.videoElRemote);
            }
            this.remoteEl.appendChild(el);
            this.videoElRemote = el as HTMLVideoElement;
            this.event$.next({ type: StreamEventType.RemoteVideoPublished });
            // const openImage = (img: ImageBitmap) => {
            //     const canvas = document.createElement('canvas');
            //     const ctx = canvas.getContext('2d');

            //     canvas.height = img.height;
            //     canvas.width = img.width;
            //     ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            //     const image = new Image();
            //     image.src = canvas.toDataURL();

            //     const w = window.open('');
            //     w.document.body.appendChild(image);
            // };

            // setTimeout(async () => {
            //     openImage(await this.getLocalFrame());
            //     openImage(await this.getRemoteFrame());
            // }, 2000);
        } else {
            this.remoteEl.appendChild(el);
        }
    }

    protected cleanup() {
        const mediaTracks = [this.videoTrack, this.audioTrack];

        this.logger.info('TwilioVideoStream', `Clean-up`);

        mediaTracks.forEach(track => track.stop());
        this.room.localParticipant.unpublishTracks(this.tracks);
        mediaTracks.forEach(track => track.detach());
    }

    async connect(): Promise<void> {
        this.logger.info('TwilioVideoStream', `Connect`);

        this.accessToken = await this.generateTwilioTokenInteractor.execute(
            this.TOKEN_URL,
            this.metadata.localParticipant.id,
            this.metadata.callId,
        );

        // Init local tracks
        await this.initLocalTracks();

        // Connection error handled by CallMachine `connect.onError`
        const options = { name: this.metadata.callId, tracks: this.tracks };
        this.room = await connect(this.accessToken, options);

        const addParticipant = participant => {
            this.logger.info('TwilioVideoStream', `Participant joined`);

            // Log that remote participant has joined, this is only logged for the Admin
            if (this.metadata.callMode === CallMode.Create) {
                this.logAnalyticsEvent.execute(AnalyticsEvent.CallParticipantJoined);
                this.logAnalyticsEvent.execute(SingularEvent.SngCallCompleted);
            }

            // Add Tracks
            participant.tracks.forEach(publication => {
                if (publication.isSubscribed) {
                    const track = publication.track;
                    if (track.kind !== 'data') {
                        this.attachTrack(track);
                    }
                }
            });

            participant.on('trackSubscribed', track => {
                if (track.kind === 'data') {
                    this.logger.info('TwilioVideoStream', `Subscribed to data track`);

                    track.on('message', data => {
                        try {
                            data = JSON.parse(data);
                        } catch (e) {
                            data = String.fromCharCode.apply(null, new Uint8Array(data));
                            data = JSON.parse(data);
                        }

                        this.activityEvent$.next(data);
                    });
                } else {
                    this.attachTrack(track);
                }
            });

            participant.on('trackUnpublished', track => {
                this.logger.info('TwilioVideoStream', `Track unpublished`);
                if (track.kind === 'video') {
                    this.event$.next({ type: StreamEventType.RemoteVideoUnpublished });
                }
            });

            participant.on('disconnected', () => {
                // this.logger.info('TwilioVideoStream', `Participant disconnected`);
            });

            // Handle reconnections
            participant.on('reconnecting', () => {
                this.logger.info('TwilioVideoStream', `Participant reconnecting`);
                this.event$.next({ type: StreamEventType.RemoteReconnecting });
            });

            participant.on('reconnected', () => {
                this.logger.info('TwilioVideoStream', `Participant reconnected`);
                this.event$.next({ type: StreamEventType.RemoteJoined });
            });

            // Notify that the remote has joined
            this.event$.next({ type: StreamEventType.RemoteJoined });
        };

        // Log any Participants already connected to the Room
        this.room.participants.forEach(participant => {
            addParticipant(participant);
        });

        this.room.on('participantConnected', participant => {
            addParticipant(participant);
        });

        this.room.on('participantDisconnected', participant => {
            this.logger.info('TwilioVideoStream', `Participant disconnected`);
            this.disconnect();
            this.event$.next({ type: StreamEventType.RemoteDisconnected });
        });

        this.room.on('disconnected', () => {
            this.logger.info('TwilioVideoStream', `Room disconnected`);
            this.disconnect();
            this.event$.next({ type: StreamEventType.RemoteDisconnected });
        });
    }

    disconnect() {
        this.logger.info('TwilioVideoStream', `Disconnect`);

        if (this.room) {
            this.cleanup();
            this.room.disconnect();
        }
    }

    protected getFrame(videoEl: HTMLVideoElement): Promise<Blob> {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.height = videoEl.videoHeight;
        canvas.width = videoEl.videoWidth;
        ctx.drawImage(videoEl, 0, 0, canvas.width, canvas.height);

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (blob) {
                    resolve(blob);
                } else {
                    reject();
                }
            });
        });
    }

    async getLocalCapture(): Promise<Blob> {
        return this.getFrame(this.videoElLocal);
    }

    async getRemoteCapture(): Promise<Blob> {
        return this.getFrame(this.videoElRemote);
    }

    getLocalVideoEl(): HTMLVideoElement {
        return this.videoElLocal;
    }

    getRemoteVideoEl(): HTMLVideoElement {
        return this.videoElRemote;
    }

    send(event: ActivityEvent) {
        // this.logger.info('TwilioVideoStream', `Data-track out: ${JSON.stringify(event, null, 4)}`);
        this.dataTrack.send(JSON.stringify(event));
    }

    setMicrophoneState(state: boolean): IVideoStream {
        this.logger.info('TwilioVideoStream', `Toggle Microphone, state=${state ? 'enabled' : 'disabled'}`);

        if (state) {
            this.audioTrack.enable();
        } else {
            this.audioTrack.disable();
        }

        return this;
    }

    setVideoState(state: boolean): IVideoStream {
        this.logger.info('TwilioVideoStream', `Toggle Video, state=${state ? 'enabled' : 'disabled'}`);

        if (state) {
            this.videoTrack.enable();
        } else {
            this.videoTrack.disable();
        }

        return this;
    }

    async switchCamera(): Promise<void> {
        if (this.cameraFacingMode === 'user') {
            this.cameraFacingMode = 'environment';
        } else {
            this.cameraFacingMode = 'user';
        }
        this.videoTrack.stop();
        await this.reinitialiseVideoTrack();
    }

    public async startPhotobooth() {
        this.deeparCanvasEl.width = this.videoElLocal?.videoWidth || 100; // Replace with the desired width
        this.deeparCanvasEl.height = this.videoElLocal?.videoHeight || 100; // Replace with the desired height
        this.initDeepARInputVideoElement();
        this.deepAR = await deepar.initialize({
            licenseKey: environment.deepArConfig.licenseKey,
            canvas: this.deeparCanvasEl,
            additionalOptions: {
                cameraConfig: {
                    disableDefaultCamera: true,
                },
            },
        });
        // Set the new video element as the input to DeepAR. This is to prevent the local video source from being overridden or removed after applying effect
        this.deepAR.setVideoElement(this.videoElDeepARInput, false);
        const localDeepARTrack = this.deeparCanvasEl.captureStream(25).getVideoTracks()[0];

        //Unpublish original tracks before sending the effect applied track
        this.room.localParticipant.unpublishTrack(this.videoTrack);
        this.event$.next({ type: StreamEventType.LocalVideoUnpublished });
        this.videoTrack = new LocalVideoTrack(localDeepARTrack, {
            name: 'DeepAR',
        });
        // Remove the local video element from DOM and publish the new track with a 1s delay to maintain order between unpublish and publish
        setTimeout(() => {
            this.localEl.removeChild(this.videoElLocal);
            this.room.localParticipant.publishTrack(this.videoTrack);
            this.videoElLocal = this.videoTrack.attach() as HTMLVideoElement;
            this.localEl.appendChild(this.videoElLocal);
            this.event$.next({ type: StreamEventType.LocalVideoPublished });
            this.event$.next({ type: StreamEventType.PhotoboothReady });
        }, 2000);
    }

    public async shutDownDeepAR() {
        if (this.deepAR) {
            this.deepAR.stopCamera();
            this.deepAR.clearEffect('faceMask');
            this.deepAR.shutdown();
            this.deepAR = null;
            await this.reinitialiseVideoTrack();
        }
    }

    public async applyFaceFilter(filter: FaceFilterModel): Promise<void> {
        if (filter.id === 'noneId') {
            this.clearFaceFilter();
            return;
        }
        let effectUrl = filter.filterURL;
        // Clear any previously applied effect and switch to the new effect
        this.deepAR.clearEffect('faceMask');

        await this.deepAR.switchEffect(effectUrl, { slot: 'faceMask' });
    }

    public async clearFaceFilter() {
        // Clear any previously applied effect and switch to the new effect
        this.deepAR.clearEffect('faceMask');
    }

    private async reinitialiseVideoTrack() {
        try {
            this.logger.info('TwilioVideoStream', `Unpublish Existing Track and Publish new track`);
            this.room.localParticipant.unpublishTrack(this.videoTrack);
            this.event$.next({ type: StreamEventType.LocalVideoUnpublished });

            this.videoTrack = await createLocalVideoTrack({
                width: 640,
                name: 'Camera',
                facingMode: this.cameraFacingMode,
            });
        } catch (err) {
            this.logAnalyticsEvent.execute(AnalyticsEvent.CameraSourceDidFail);
            throw err;
        }
        // Remove the local video element from DOM and publish the new track with a 1s delay to maintain order between unpublish and publish
        setTimeout(() => {
            this.localEl.removeChild(this.videoElLocal);
            this.room.localParticipant.publishTrack(this.videoTrack);
            this.event$.next({ type: StreamEventType.LocalVideoPublished });
            this.videoElLocal = this.videoTrack.attach() as HTMLVideoElement;
            this.localEl.appendChild(this.videoElLocal);
        }, 2000);
    }

    private initDeepARInputVideoElement() {
        // Create a new video element and set the MediaStream object as its srcObject
        if (this.videoElLocal) {
            const stream = this.videoElLocal.srcObject as MediaStream;
            this.videoElDeepARInput = document.createElement('video');
            this.videoElDeepARInput.srcObject = stream;
            this.videoElDeepARInput.play();
        }
    }
}
