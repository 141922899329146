import { ProductDiscount, SubscripionPromotion } from './../../../../environments/types';
import { AgeVerificationModalComponent } from '@app/web/modals/age-verification-modal/age-verification-modal.component';
import {
    AnalyticsEvent,
    BookModel,
    CohortTypes,
    GetPlatformInteractor,
    IsAdultInteractor,
    LogAnalyticsEventInteractor,
    UserModel,
    UserService,
    EntitlementLevels,
} from '@together/common';
import { SimpleModalService } from '@looorent/ngx-simple-modal';
import { Observable } from 'rxjs';

import { SubscriptionState } from './store-service.interface';
import { AlertModalComponent } from '@app/web/modals/alert-modal/alert-modal.component';
import { UpgradeModalComponent } from '@app/web/modals/upgrade-modal/upgrade-modal.component';
import { ChangeSubscriptionModalComponent } from '@app/web/modals/change-subscription-modal/change-subscription-modal.component';
import { WaitModalService } from '../wait-modal.service';
import { ProductPrice, ProductPeriod } from '@env/types';
import { ManageSubscriptionModalComponent } from '@app/web/modals/manage-subscription-modal/manage-subscription-modal.component';
import { UpgradeTierBasedModalComponent } from '@app/web/modals/upgrade-tier-based-modal/upgrade-tier-based-modal.component';
import { ChangeTierBasedSubscriptionModalComponent } from '@app/web/modals/change-tier-based-subscription-modal/change-tier-based-subscription-modal.component';

export enum BookPurchaseTransactionType {
    Rent = 'rent',
    Sale = 'sale',
}

export class BaseStoreService {
    constructor(
        protected logAnalyticsEvent: LogAnalyticsEventInteractor,
        protected modalService: SimpleModalService,
        protected getPlatform: GetPlatformInteractor,
        protected isAdult: IsAdultInteractor,
        protected waitModal: WaitModalService,
        protected userService: UserService,
    ) {}

    /**
     * Savings is computed for a product in comparison with another product.
     * Eg: Yearly product can be compared with monthly
     * [comparePrice: price of monthly product $6.99,
     * periodPrice: price of the yearly product $59.99,
     * comparePeriod: period of yearly/period of monthly 12/1 = 12,
     * savings: 6.99 * 12 - 59.99 = 23.89]
     * Eg: Yearly product can be compared with six monthly
     * [comparePrice: price of six monthly  product $34.99,
     * periodPrice: price of the yearly product $59.99,
     * comparePeriod: period of yearly/period of six monthly  12/6 = 2,
     * savings: 34.99 * 2 - 59.99 = 9.99]
     * @param comparePrice Unit price of the product being compared with
     * @param periodPrice Price of the product for which savings is computed
     * @param comparePeriod No of period units of the product being compare with that comprises the period of the product for which savings is computed
     * @returns savings value
     */
    protected calculateSaving(
        comparePrice: number,
        periodPrice: number,
        comparePeriod: number,
    ): Record<string, number> {
        let savingAmount: number;
        let savingPercentage: number;
        let equivalentComparePrice: number;

        if (comparePeriod > 1) {
            equivalentComparePrice = comparePrice * comparePeriod;
            const savingNormalized = equivalentComparePrice > periodPrice ? equivalentComparePrice - periodPrice : 0;

            savingAmount = Math.round(savingNormalized * 100) / 100;
            savingPercentage = Math.round((savingAmount / equivalentComparePrice) * 100);
        }

        return {
            savingAmount,
            savingPercentage,
            equivalentComparePrice,
        };
    }

    protected renderTemplate(
        template: string,
        period: ProductPeriod,
        price: ProductPrice,
        regularPrice: ProductPrice,
        savingsData: Record<string, number>,
    ): string {
        const roundPrice = (price: number) => (Math.round(price * 100) / 100).toString();
        const replacements = {
            currency: price.currency,
            price: price.amount.toString(),
            pricePerMonth: roundPrice(price.amount / period.inMonths),
            regularPrice: regularPrice.amount.toString(),
            savingAmount: savingsData?.savingAmount ? `${price.currency}${savingsData.savingAmount}` : '',
            savingPercentage: savingsData?.savingPercentage ? `${savingsData.savingPercentage}%` : '',
            projectedStrikethroughPrice: savingsData?.equivalentComparePrice
                ? `${price.currency}${savingsData.equivalentComparePrice}`
                : '',
        };

        return Object.entries(replacements).reduce(
            (result, [key, value]) => result.replace(`{{${key}}}`, value),
            template,
        );
    }

    protected isActivePromotion(promotion: SubscripionPromotion): boolean {
        if (promotion?.activePeriod) {
            const now = Date.now();
            const start = new Date(promotion.activePeriod.startDate);
            const end = new Date(promotion.activePeriod.endDate);
            const isActivePeriod = now.valueOf() >= start.valueOf() && now.valueOf() <= end.valueOf();
            return isActivePeriod;
        }
        return false;
    }

    protected isActiveDiscount(discount: ProductDiscount): boolean {
        if (discount && discount?.couponId && discount?.activePeriod) {
            const now = Date.now();
            const start = new Date(discount.activePeriod.startDate);
            const end = new Date(discount.activePeriod.endDate);
            const isActivePeriod = now.valueOf() >= start.valueOf() && now.valueOf() <= end.valueOf();
            return isActivePeriod;
        }
        return false;
    }

    protected isDiscountApplicableForUser(discount: ProductDiscount, user: UserModel): boolean {
        if (discount?.maxActivationCount && discount?.couponId) {
            const activationCount = user?.getDiscountActivationCount(discount.couponId);
            if (activationCount >= discount.maxActivationCount) {
                return false;
            }
        }
        return true;
    }

    public getSubscriptionState$(): Observable<SubscriptionState> {
        return new Observable();
    }

    public init(): void {}

    public async syncPurchases(): Promise<void> {}

    public async restorePurchases(): Promise<void> {}

    public showBuyGiftSuccessModal(code: string): void {
        this.logAnalyticsEvent.execute(AnalyticsEvent.InAppPurchase);
        this.modalService.addModal(AlertModalComponent, {
            title: 'Great!',
            message: `Thanks for purchasing a gift with code ${code}.`,
            type: 'success',
            icon: 'checked-round',
        });
    }

    public showBuySubscriptionSuccessModal(): void {
        this.logAnalyticsEvent.execute(AnalyticsEvent.InAppPurchase);
        this.modalService.addModal(AlertModalComponent, {
            title: 'Great!',
            message: [
                `Thanks for upgrading! We hope you and your family enjoy Together.`,
                `If you have any questions or feedback, please contact us at support@togethervideoapp.com.`,
            ].join(' '),
            type: 'success',
            icon: 'checked-round',
        });
    }

    public showBuyExtraTimeSuccessModal(): void {
        this.logAnalyticsEvent.execute(AnalyticsEvent.InAppPurchase);
        this.modalService.addModal(AlertModalComponent, {
            title: 'Great!',
            message: [
                `Thanks for purchasing extra time! You can check your available time in your settings page.`,
                `If you have any questions or feedback, please contact us at support@togethervideoapp.com.`,
            ].join(' '),
            type: 'success',
            icon: 'checked-round',
        });
    }

    showBuyBookSuccessModal(book: BookModel): void {
        this.modalService.addModal(AlertModalComponent, {
            title: 'Nice!',
            message: [`You purchased ${book.title}, and it’s available to read right away!`].join(' '),
            type: 'success',
            icon: 'checked-round',
        });
    }

    showRentBookSuccessModal(book: BookModel): void {
        this.modalService.addModal(AlertModalComponent, {
            title: 'Nice!',
            message: [`You rented ${book.title}, and it’s available to read right away!`].join(' '),
            type: 'success',
            icon: 'checked-round',
        });
    }

    protected async openUpgradeModal(user: UserModel, preSelectTier?: string): Promise<void> {
        try {
            // Refresh purchases before opening the UpgradeModal
            // This is to ensure that the user profile is up to date and avoid double purchases
            this.waitModal.show();

            if (user.isSubscribed() && user?.subscriptionProduct?.subscriptionState === 'cancelled') {
                this.modalService.addModal(ManageSubscriptionModalComponent);
            } else if (user.isChangeSubscriptionAllowed()) {
                if (user?.isLimitedContentCohort() && user?.isBasicSubscription()) {
                    this.modalService.addModal(ChangeTierBasedSubscriptionModalComponent);
                } else {
                    this.modalService.addModal(ChangeSubscriptionModalComponent);
                }
            } else {
                if (user?.isLimitedContentCohort()) {
                    this.modalService.addModal(UpgradeTierBasedModalComponent, { preSelectTier });
                } else {
                    this.modalService.addModal(UpgradeModalComponent);
                }
            }
        } catch (err) {
            this.modalService.addModal(AlertModalComponent, {
                title: 'Oops!',
                message: `There was an error with your upgrade. Please check that you're connected to the internet.`,
                type: 'error',
                icon: 'error',
                subText: '(purchase_006)',
            });
        } finally {
            this.waitModal.hide();
        }
    }

    public async showUpgradeModal(preSelectTier?: string): Promise<void> {
        const user = await this.userService.getUser();
        const isAdult = await this.isAdult.execute();
        const isWeb = this.getPlatform.isWeb();
        const isSubscriptionPlatform = this.getPlatform.execute() === user?.getSubscriptionPlatform();
        if (user?.isChangeSubscriptionAllowed() && !isSubscriptionPlatform) {
            this.modalService.addModal(AlertModalComponent, {
                title: 'Oops!',
                message: `To upgrade, open Together on the device where you originally subscribed.`,
                type: 'warning',
                icon: 'exclamation',
            });
            return;
        }

        if (isAdult || isWeb) {
            this.openUpgradeModal(user, preSelectTier);
        } else {
            const hasVerified = await this.modalService.addModal(AgeVerificationModalComponent).toPromise();

            if (hasVerified) {
                this.openUpgradeModal(user, preSelectTier);
            } else {
                this.modalService.addModal(AlertModalComponent, {
                    title: 'Oops!',
                    message: 'You are not allowed to do this action',
                    type: 'warning',
                    icon: 'exclamation',
                });
            }
        }
    }

    public getRedirectTo(): object {
        const location = typeof window !== 'undefined' ? window.location : { host: '' };
        const isLocal = ['localhost:4200'].includes(location.host);

        return isLocal ? { redirectTo: 'local' } : {};
    }

    public isSubscriptionProvisioned(user: UserModel, productId, requestType): boolean {
        if (!user.isSubscribed()) {
            return false;
        }
        if (!user.subscriptionProduct?.subscriptionProductId?.includes(productId)) {
            return false;
        }
        if (user.isLimitedContentCohort() && !user.subscriptionProduct?.entitlements?.length) {
            return false;
        }
        if (
            user.isLimitedContentCohort() &&
            requestType === 'UPGRADE' &&
            !user.subscriptionProduct?.entitlements?.includes(EntitlementLevels.PRO)
        ) {
            return false;
        }

        return true;
    }
}
