import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { ActivityModel, GetPlatformInteractor, UserService } from '@together/common';
import { SimpleModalService } from '@looorent/ngx-simple-modal';

@Component({
    selector: 'app-featured-activity-thumbnail',
    templateUrl: './featured-activity-thumbnail.component.html',
    styleUrls: ['./featured-activity-thumbnail.component.scss'],
})
export class FeaturedActivityThumbnailComponent implements OnInit {
    @Input() activity: ActivityModel;
    @Input() isFav = false;
    @Input() isContentRestricted = false;

    @Output() public toggleFavorite = new EventEmitter<ActivityModel>();
    @Output() public selected = new EventEmitter<ActivityModel>();

    public isLoggedIn = false;
    private isWeb: boolean;

    constructor(
        protected modalService: SimpleModalService,
        protected userService: UserService,
        protected getPlatform: GetPlatformInteractor,
    ) {}

    async ngOnInit() {
        this.isLoggedIn = await this.userService.isLoggedIn();
        this.isWeb = this.getPlatform.isWeb();
    }
}
