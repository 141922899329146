<div class="modal-clean modal-app-rating">
    <div class="modal-clean__close" (click)="close()">
      <fa-icon [icon]="['tg', 'times-alt']"></fa-icon>
    </div>
    <div class="modal-clean__content">
        <div class="modal-app-rating__content">
            <img class="modal-app-rating__image" src="./assets/hero-rabbit.svg" alt="">
            <h2 class="modal-app-rating__title">Are you enjoying Together?</h2>
            <p class="modal-app-rating__text">Please leave a review of the app in the app store. Thanks so much!</p>
            <a class="modal-app-rating__button button --blue --wide" (click)="writeReview()">Write a Review</a>
            <a class="modal-app-rating__button button --empty --wide" (click)="close()">Later</a>
        </div>
    </div>
</div>
