import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from '@looorent/ngx-simple-modal';

@Component({
    selector: 'app-permissions-prompt-modal',
    templateUrl: './permissions-prompt-modal.component.html',
    styleUrls: ['./permissions-prompt-modal.component.scss'],
})
export class PermissionsPromptModalComponent extends SimpleModalComponent<void, void> implements OnInit {
    constructor() {
        super();
    }

    ngOnInit() {}
}
