import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LogAnalyticsEventInteractor, UserService } from '@together/common';
import { IStoreService } from './store/store-service.interface';
import { STORAGE_SUBSCRIPTION_PRODUCT_ID_KEY } from './store/stripe-store.service';

@Injectable({
    providedIn: 'root', // Force it to be a singleton
})
export class PromoPaymentDeepLinkService {
    constructor(
        protected logAnalyticsEvent: LogAnalyticsEventInteractor,
        protected userService: UserService,
        @Inject('StoreService') protected storeService: IStoreService,
        protected router: Router,
    ) {}

    async handleURL(url: URL): Promise<void> {
        const isOK = url.searchParams.has('promo_subscription_ok');
        const isKO = url.searchParams.has('promo_subscription_ko');
        if (!isOK && !isKO) {
            return;
        }

        if (!(await this.userService.isLoggedIn())) {
            this.router.navigate(['/promopayment-success']);
        }
    }
}
