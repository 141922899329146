import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AddBookModalComponent } from '@app/web/modals/add-book-modal/add-book-modal.component';
import { SimpleModalService } from '@looorent/ngx-simple-modal';

@Component({
    selector: 'app-add-book-btn',
    templateUrl: './add-book-btn.component.html',
    styleUrls: ['./add-book-btn.component.scss'],
})
export class AddBookBtnComponent {
    @Output() onBookAdded = new EventEmitter<void>();
    @Input() style = 'button';
    @Input() btnClass = '';

    constructor(protected modalService: SimpleModalService) {}

    async showAddBookModal(): Promise<void> {
        const newBook = await this.modalService
            .addModal(AddBookModalComponent, null, {
                closeOnEscape: false,
                closeOnClickOutside: false,
            })
            .toPromise();

        if (newBook) {
            this.onBookAdded.emit();
        }
    }
}
