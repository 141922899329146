import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { InAppBanner, InAppBannerAction } from '@together/common';

@Component({
    selector: 'app-in-app-banner',
    templateUrl: './in-app-banner.component.html',
    styleUrls: ['./in-app-banner.component.scss'],
})
export class InAppBannerComponent implements OnInit {
    @Input() banner: InAppBanner;
    @Output() public dismissBanner = new EventEmitter<string>();
    @Output() public performAction = new EventEmitter<InAppBannerAction>();

    constructor() {}

    ngOnInit() {}
}
