import { RelativeTimePipe } from './pipes/relative-time.pipe';
import { SpringboardService } from '@app/shared/services/springboard.service';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import firebase from 'firebase/app';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SimpleModalModule, SimpleModalService } from '@looorent/ngx-simple-modal';
import { defaultSimpleModalOptions } from '@looorent/ngx-simple-modal';
import { AbstractLogger } from '@mobilejazz/harmony-core';
import { PortalModule } from '@angular/cdk/portal';

// Common
import {
    IsAdultInteractor,
    GetPlatformInteractor,
    PutUserInteractor,
    UserService,
    LogAnalyticsEventInteractor,
    GetUserByIdInteractor,
    RedeemPromoCodeInteractor,
    GetUserPromoCodesInteractor,
    GeneratePromoCodeInteractor,
    CopyToClipboardInteractor,
    CompleteBookPurchaseInteractor,
    UserObserverInteractor,
} from '@together/common';

// Components
import { ActivitiesCatalogComponent } from './components/activities-catalog/activities-catalog.component';
import { ActivityThumbnailComponent } from './components/activity-thumbnail/activity-thumbnail.component';
import { AddBookBtnComponent } from './components/add-book-btn/add-book-btn.component';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { BooksCatalogComponent } from './components/books-catalog/books-catalog.component';
import { BookThumbnailComponent } from './components/book-thumbnail/book-thumbnail.component';
import { ContactComponent } from './components/contact/contact.component';
import { FeaturedBookThumbnailComponent } from './components/featured-book-thumbnail/featured-book-thumbnail.component';
import { FeaturedActivityThumbnailComponent } from './components/featured-activity-thumbnail/featured-activity-thumbnail.component';
import { InAppBannerComponent } from './components/in-app-banner/in-app-banner.component';
import { InviteBtnComponent } from './components/invite-btn/invite-btn.component';
import { RatingComponent } from './components/rating/rating.component';
import { SectionFavoritesComponent } from './components/springboard/components/section-favorites/section-favorites.component';
import { SectionFeaturedComponent } from './components/springboard/components/section-featured/section-featured.component';
import { SectionSearchComponent } from './components/springboard/components/section-search/section-search.component';
import { SectionTitlePortalComponent } from './components/springboard/components/section-title-portal/section-title-portal.component';
import { SpringboardComponent } from './components/springboard/springboard.component';
import { SpringboardHomeComponent } from './components/springboard-home/springboard-home.component';
import { ThumbnailPlaceholderComponent } from './components/thumbnail-placeholder/thumbnail-placeholder.component';
import { TutorialButtonComponent } from './components/tutorial-button/tutorial-button.component';
import { ToastSnackBarComponent } from './components/toast-snack-bar/toast-snack-bar.component';

// Modals
import { AcceptInviteModalComponent } from '../web/modals/accept-invite-modal/accept-invite-modal.component';
import { AgeVerificationModalComponent } from '@app/web/modals/age-verification-modal/age-verification-modal.component';
import { AlertModalComponent } from '../web/modals/alert-modal/alert-modal.component';
import { AppRatingModalComponent } from '../web/modals/app-rating-modal/app-rating-modal.component';
import { CancelSubscriptionModalComponent } from '../web/modals/cancel-subscription-modal/cancel-subscription-modal.component';
import { CallFeedbackModalComponent } from '../web/modals/call-feedback-modal/call-feedback-modal.component';
import { CallHighlightModalComponent } from '../web/modals/call-highlight-modal/call-highlight-modal.component';
import { ChangeSubscriptionModalComponent } from '../web/modals/change-subscription-modal/change-subscription-modal.component';
import { ChangeTierBasedSubscriptionModalComponent } from './../web/modals/change-tier-based-subscription-modal/change-tier-based-subscription-modal.component';
import { ConfirmationModalComponent } from '../web/modals/confirmation-modal/confirmation-modal.component';
import { ManageGiftsModalComponent } from '../web/modals/manage-gifts-modal/manage-gifts-modal.component';
import { ManageSubscriptionModalComponent } from '../web/modals/manage-subscription-modal/manage-subscription-modal.component';
import { PermissionsDeniedModalComponent } from '../web/modals/permissions-denied-modal/permissions-denied-modal.component';
import { PermissionsPromptModalComponent } from '../web/modals/permissions-prompt-modal/permissions-prompt-modal.component';
import { PurchaseGiftModalComponent } from '../web/modals/purchase-gift-modal/purchase-gift-modal.component';
import { PurchaseTimeModalComponent } from '../web/modals/purchase-time-modal/purchase-time-modal.component';
import { RedeemModalComponent } from '@app/web/modals/redeem-modal/redeem-modal.component';
import { ShareGiftModalComponent } from '@app/web/modals/share-gift-modal/share-gift-modal.component';
import { UnfavoriteModalComponent } from '../web/modals/unfavorite-modal/unfavorite-modal.component';
import { UpgradeModalComponent } from '../web/modals/upgrade-modal/upgrade-modal.component';
import { WaitModalComponent } from '../web/modals/wait-modal/wait-modal.component';
import { WebviewModalComponent } from '../web/modals/webview-modal/webview-modal.component';
import { StripePaymentModalComponent } from '../web/modals/stripe-payment-modal/stripe-payment-modal.component';
import { UpgradeTierBasedModalComponent } from '../web/modals/upgrade-tier-based-modal/upgrade-tier-based-modal.component';

// Presenters
import { DefaultManageGiftsPresenter } from '@app/web/modals/manage-gifts-modal/manage-gifts-modal.presenter';
import { DefaultPurchaseGiftModalPresenter } from '@app/web/modals/purchase-gift-modal/purchase-gift-modal.presenter';
import { DefaultRedeemModalPresenter } from '../web/modals/redeem-modal/redeem-modal.presenter';
import { DefaultShareGiftPresenter } from '../web/modals/share-gift-modal/share-gift-modal.presenter';

// Services (+misc)
import { CallMachineFactory } from './together/machines/call.machine.factory';
import { CallRouterMachineFactory } from './together/machines/call-router.machine.factory';
import { WaitModalService } from './services/wait-modal.service';
import { ModalsService } from './services/modals.service';

// Store
import { NativeStoreRedirectService } from './services/store/native-store-redirect.service';
import { NativeStoreService } from './services/store/native-store.service';
import { StripeStore } from './services/store/stripe-store.service';
import { IStoreService } from './services/store/store-service.interface';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

let storeSingleton: IStoreService;

// Environment
import { environment } from '@env/environment';
import { NativeStoreConfig, PaymentProviderType, StripeStoreConfig } from '@env/types';
import { LottieComponent } from 'ngx-lottie';

const SHARED_MODULES: any[] = [
    CommonModule,
    FontAwesomeModule,
    IonicModule,
    PortalModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSnackBarModule,
    InfiniteScrollModule,
];

const SHARED_COMPONENTS: any[] = [
    ActivitiesCatalogComponent,
    ActivityThumbnailComponent,
    AddBookBtnComponent,
    AppHeaderComponent,
    BooksCatalogComponent,
    BookThumbnailComponent,
    CancelSubscriptionModalComponent,
    ContactComponent,
    FeaturedActivityThumbnailComponent,
    FeaturedBookThumbnailComponent,
    InAppBannerComponent,
    InviteBtnComponent,
    RatingComponent,
    SpringboardComponent,
    SectionFeaturedComponent,
    SectionFavoritesComponent,
    SectionSearchComponent,
    SectionTitlePortalComponent,
    SpringboardHomeComponent,
    ThumbnailPlaceholderComponent,
    TutorialButtonComponent,
    ToastSnackBarComponent,
];

const MODALS: any[] = [
    AcceptInviteModalComponent,
    AgeVerificationModalComponent,
    AlertModalComponent,
    AppRatingModalComponent,
    CallFeedbackModalComponent,
    CallHighlightModalComponent,
    ChangeSubscriptionModalComponent,
    ChangeTierBasedSubscriptionModalComponent,
    ConfirmationModalComponent,
    ManageGiftsModalComponent,
    ManageSubscriptionModalComponent,
    PermissionsDeniedModalComponent,
    PermissionsPromptModalComponent,
    PurchaseGiftModalComponent,
    PurchaseTimeModalComponent,
    RedeemModalComponent,
    ShareGiftModalComponent,
    StripePaymentModalComponent,
    UnfavoriteModalComponent,
    UpgradeModalComponent,
    UpgradeTierBasedModalComponent,
    WaitModalComponent,
    WebviewModalComponent,
];

const PIPES: any[] = [RelativeTimePipe];

@NgModule({
    imports: [
        ...SHARED_MODULES,
        SimpleModalModule.forRoot(
            { container: document.body },
            {
                ...defaultSimpleModalOptions,
                closeOnEscape: true,
                closeOnClickOutside: true,
                wrapperDefaultClasses: 'modal-wrapper modal-wrapper--fade',
                wrapperClass: 'modal-wrapper--in',
                animationDuration: 300,
                // autoFocus: true,
            },
        ),
        LottieComponent,
    ],
    declarations: [...SHARED_COMPONENTS, ...MODALS, ...PIPES],
    exports: [...SHARED_COMPONENTS, ...SHARED_MODULES, ...PIPES, SimpleModalModule],
    providers: [
        // SERVICES
        // CallDOMService,
        CallMachineFactory,
        CallRouterMachineFactory,
        // CallRouterService,
        // CallService,
        // ChildGuardService,
        // PRESENTERS
        {
            provide: 'ManageGiftsPresenter',
            deps: [GetUserPromoCodesInteractor, ModalsService, UserService],
            useFactory: (
                getUserPromoCodes: GetUserPromoCodesInteractor,
                modals: ModalsService,
                userService: UserService,
            ) => new DefaultManageGiftsPresenter(getUserPromoCodes, modals, userService),
        },
        {
            provide: 'PurchaseGiftModalPresenter',
            deps: [LogAnalyticsEventInteractor, ModalsService, WaitModalService, 'StoreService', UserService],
            useFactory: (
                logAnalyticsEvent: LogAnalyticsEventInteractor,
                modals: ModalsService,
                wait: WaitModalService,
                store: IStoreService,
                userService: UserService,
            ) => new DefaultPurchaseGiftModalPresenter(logAnalyticsEvent, modals, wait, store, userService),
        },
        {
            provide: 'RedeemModalPresenter',
            deps: [GetUserByIdInteractor, LogAnalyticsEventInteractor, RedeemPromoCodeInteractor, UserService],
            useFactory: (
                getUserById: GetUserByIdInteractor,
                logAnalyticsEvent: LogAnalyticsEventInteractor,
                redeemPromoCode: RedeemPromoCodeInteractor,
                userService: UserService,
            ) => new DefaultRedeemModalPresenter(getUserById, logAnalyticsEvent, redeemPromoCode, userService),
        },
        {
            provide: 'ShareGiftPresenter',
            deps: [CopyToClipboardInteractor, GetPlatformInteractor, ModalsService, WaitModalService],
            useFactory: (
                copyToClipboard: CopyToClipboardInteractor,
                getPlatform: GetPlatformInteractor,
                modals: ModalsService,
                wait: WaitModalService,
            ) => new DefaultShareGiftPresenter(copyToClipboard, getPlatform, modals, wait),
        },
        {
            provide: NativeStoreRedirectService,
            deps: [SimpleModalService, GetPlatformInteractor],
            useFactory: (modalService: SimpleModalService, getPlatform: GetPlatformInteractor,) => {
                return new NativeStoreRedirectService(
                    modalService,
                    environment.store.native.storeAndroidURL,
                    environment.store.native.storeIOSURL,
                    getPlatform
                );
            },
        },
        {
            provide: 'StoreService',
            deps: [
                'PaymentProviderType',
                'FirebaseRemoteConfig',
                'FirebaseFunctions',
                GeneratePromoCodeInteractor,
                GetPlatformInteractor,
                CompleteBookPurchaseInteractor,
                LogAnalyticsEventInteractor,
                AbstractLogger,
                SimpleModalService,
                PutUserInteractor,
                UserService,
                SpringboardService,
                IsAdultInteractor,
                WaitModalService,
                UserObserverInteractor,
            ],
            useFactory: (
                provider: PaymentProviderType,
                remoteConfig: firebase.remoteConfig.RemoteConfig,
                functions: firebase.functions.Functions,
                generatePromoCode: GeneratePromoCodeInteractor,
                getPlatform: GetPlatformInteractor,
                completeBookPurchaseInteractor: CompleteBookPurchaseInteractor,
                logAnalyticsEvent: LogAnalyticsEventInteractor,
                logger: AbstractLogger,
                modalService: SimpleModalService,
                putUser: PutUserInteractor,
                userService: UserService,
                springboard: SpringboardService,
                isAdult: IsAdultInteractor,
                waitModal: WaitModalService,
                userObserver: UserObserverInteractor,
            ) => {
                logger.info('StoreService', `Provider: ${provider}`);

                if (!storeSingleton) {
                    if (provider === PaymentProviderType.NativeStore) {
                        let nativeStoreConfig: NativeStoreConfig;

                        try {
                            nativeStoreConfig = {
                                ...environment.store.native,
                                ...JSON.parse(remoteConfig.getString('nativeStoreConfig')),
                            };
                        } catch {
                            nativeStoreConfig = environment.store.native;
                        }

                        storeSingleton = new NativeStoreService(
                            nativeStoreConfig,
                            functions,
                            getPlatform,
                            generatePromoCode,
                            completeBookPurchaseInteractor,
                            logAnalyticsEvent,
                            logger,
                            modalService,
                            putUser,
                            //store,
                            userService,
                            springboard,
                            isAdult,
                            waitModal,
                        );
                    } else {
                        let stripeStoreConfig: StripeStoreConfig;
                        try {
                            stripeStoreConfig = {
                                ...environment.store.stripe,
                                ...JSON.parse(remoteConfig.getString('stripeStoreConfig')),
                            };
                        } catch {
                            stripeStoreConfig = environment.store.stripe;
                        }
                        storeSingleton = new StripeStore(
                            stripeStoreConfig,
                            functions,
                            getPlatform,
                            logAnalyticsEvent,
                            logger,
                            modalService,
                            isAdult,
                            waitModal,
                            userService,
                            userObserver,
                            springboard,
                        );
                    }
                }

                return storeSingleton;
            },
        },
    ],
})
export class SharedModule {}
