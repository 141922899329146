<div class="modal-clean modal-clean--wide modal-redeem --centered">
    <div class="modal-clean__content">
        <div class="modal-clean__header">
            <div class="modal__title" *ngIf="!isFromLink">Redeem Gift</div>
            <div class="modal__title" *ngIf="isFromLink">You Received a Gift!</div>
            <div class="modal__close" (click)="presenter.onActionClose()"></div>
        </div>
        <div class="modal-redeem__container">
            <img class="modal-redeem__gift-box" src="./assets/present-box.svg" />

            <p class="modal-redeem__description" *ngIf="!isFromLink">
                Enter your gift code to redeem your subscription.
            </p>
            <p class="modal-redeem__description" *ngIf="isFromLink">
                Your gift code is entered below. Tap the button to redeem!
            </p>
            <form
                class="form"
                [formGroup]="redeemForm"
                (ngSubmit)="onRedeemSubmit()"
                autocomplete="false"
                spellcheck="false">
                <label *ngIf="!isFromLink">Gift Code</label>
                <label *ngIf="isFromLink" class="--grey">Your Gift Code</label>
                <input
                    type="text"
                    name="code"
                    trim
                    formControlName="code"
                    autocorrect="off"
                    autocapitalize="none"
                    autocomplete="off"
                    placeholder="Enter Gift Code" />
                <div class="form__input-errors" *ngIf="isControlInvalid(redeemForm, 'code')">
                    <div *ngIf="redeemForm.get('code').errors.required">Code is required</div>
                </div>
                <div class="btn-container">
                    <button
                        *ngIf="!isFromLink"
                        class="button-v2 --purple --large --rounded --wide"
                        type="submit"
                        [disabled]="isLoading">
                        Redeem now!
                    </button>
                    <button
                        *ngIf="isFromLink"
                        class="button-v2 --purple --large --rounded --wide"
                        type="submit"
                        [disabled]="isLoading">
                        Redeem Gift
                    </button>
                </div>
            </form>
            <div class="modal-redeem__extra-actions">
                <p>Questions or feedback</p>
                <a class="" href="mailto:support@togethervideoapp.com">Contact Us</a>
            </div>
        </div>
    </div>
</div>
