import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { InviteModalComponent } from '@app/web/modals/invite-modal/invite-modal.component';
import { SimpleModalService } from '@looorent/ngx-simple-modal';

type InviteBtnModifier = 'default' | 'light';

@Component({
    selector: 'app-invite-btn',
    templateUrl: './invite-btn.component.html',
    styleUrls: ['./invite-btn.component.scss'],
})
export class InviteBtnComponent implements OnInit, AfterViewInit {
    @Input() modifier: InviteBtnModifier = 'default';

    constructor(protected modalService: SimpleModalService) {}

    ngOnInit() {}

    ngAfterViewInit() {
        // setTimeout(() => {
        //     this.showInviteModal();
        // });
    }

    isLight(): boolean {
        return this.modifier === 'light';
    }

    showInviteModal() {
        this.modalService.addModal(InviteModalComponent);
    }
}
