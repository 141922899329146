<div class="modal-clean modal-clean--wide modal-change-subscription --centered">
    <div class="modal-clean__content --no-outer-padding">
        <div class="modal-clean__header">
            <div class="modal__close" (click)="close()"></div>
            <div class="modal__title">Unlock Deeper Discounts!</div>
        </div>
        <div class="modal-change-subscription__container">
            <div class="modal-change-subscription__gift-box">
                <img src="./assets/happy-panda.svg" />
            </div>
            <div class="modal-change-subscription__summary">
                <span class="--highlighted">Yearly subscribers save big.</span> Upgrade your plan and get even more
                value from Together!
            </div>
            <div class="modal-change-subscription__promos" *ngIf="upgradeProduct">
                <div class="modal-change-subscription__promos__plan-box">
                    <div class="modal-change-subscription__promos__plan-box__title">Current Plan</div>
                    <div class="modal-change-subscription__promos__plan-box__price">
                        {{ getCurrencySymbol(user?.subscriptionProduct?.currency)
                        }}{{ user?.subscriptionProduct?.price }}
                    </div>
                    <div
                        class="modal-change-subscription__promos__plan-box__duration"
                        *ngIf="user?.subscriptionProduct?.subscriptionPeriod === 'monthly'">
                        Billed monthly
                    </div>
                    <div
                        class="modal-change-subscription__promos__plan-box__duration"
                        *ngIf="user?.subscriptionProduct?.subscriptionPeriod === 'six_month'">
                        Billed 2x a year
                    </div>
                </div>
                <div class="modal-change-subscription__promos__icon">
                    <img src="/assets/images/purple-arrow-right.png" />
                </div>
                <div class="modal-change-subscription__promos__plan-box --suggested">
                    <div class="__discount-text">
                        <div class="__discount-text__content">SAVE {{ upgradeProduct.savingAmount }}</div>
                    </div>
                    <div class="modal-change-subscription__promos__plan-box__title">Yearly Plan</div>
                    <div class="modal-change-subscription__promos__plan-box__price">{{ upgradeProduct.price }}</div>
                    <div class="modal-change-subscription__promos__plan-box__duration">Billed annually</div>
                </div>
            </div>
            <div class="modal-change-subscription__column">
                <button class="button-v2 --purple --wide --medium --rounded" (click)="purchase(upgradeProduct.id)">
                    Switch and Save
                </button>
            </div>
            <div class="modal-change-subscription__disclaimer">
                {{ disclaimerPolicyText }}
            </div>
        </div>
    </div>
</div>
