import { ModalsService } from '@app/shared/services/modals.service';
import { WaitModalService } from '@app/shared/services/wait-modal.service';
import { Share, ShareOptions } from '@capacitor/share';
import { environment } from '@env/environment';
import { CopyToClipboardInteractor, GetPlatformInteractor, Platform, PromoCodeModel } from '@together/common';
import { ShareGiftView } from './share-gift-modal.component';

export interface ShareGiftPresenter {
    attachView(view: ShareGiftView): void;
    onActionClose(): void;
    onActionCopy(promoCode: PromoCodeModel): void;
    onActionShare(promoCode: PromoCodeModel): Promise<void>;
    onViewNgOnInit(): void;
}

export class DefaultShareGiftPresenter implements ShareGiftPresenter {
    private view: ShareGiftView;

    constructor(
        protected copyToClipboard: CopyToClipboardInteractor,
        protected getPlatform: GetPlatformInteractor,
        protected modals: ModalsService,
        protected wait: WaitModalService,
    ) {}

    public attachView(view: ShareGiftView): void {
        this.view = view;
    }

    public onViewNgOnInit(): void {
        switch (this.getPlatform.execute()) {
            case Platform.Android:
                // Show share button
                this.view.onDisplayCopyButton(false);
                this.view.onDisplayShareButton(true);
                break;

            default:
                // Show copy button
                this.view.onDisplayCopyButton(true);
                this.view.onDisplayShareButton(false);
                break;
        }
    }

    public onActionClose(): void {
        this.view.onNotifyClose();
    }

    public onActionCopy(promoCode: PromoCodeModel): void {
        this.copyToClipboard.execute(promoCode.promoCode);
        this.modals.alert('Done!', `Gift code copied to your clipboard.`, 'success', 'checked-round');
    }

    private createShareOptions(promoCode: PromoCodeModel): ShareOptions {
        const giftUrlPrefix = environment.giftUrlPrefix;
        const link = giftUrlPrefix + `promocode?code=${promoCode.promoCode}`;

        return {
            title: 'Gift to join Together',
            dialogTitle: 'Gift',
            text: `Hello! I have a gift for you: a ${promoCode.title} subscription for Together, an app that lets you read books and play games while video chatting with your loved ones. Use this link to redeem your gift: ${link}`,
        };
    }

    public async onActionShare(promoCode: PromoCodeModel): Promise<void> {
        this.wait.show();

        try {
            await Share.share(this.createShareOptions(promoCode));
        } catch (err) {
            this.modals.alert(
                'Error',
                'Error while sharing the gift code. Please try it again.',
                'error',
                'error',
                '(gift_010)',
            );
        } finally {
            this.wait.hide();
        }
    }
}
