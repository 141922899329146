import { Subject, Subscription } from 'rxjs';
import { Base64 } from 'js-base64';
import { AbstractLogger } from '@mobilejazz/harmony-core';

import { PointerEvent } from '@app/web/common/pdf-viewer/lib/pointer.class';
import {
    ActivityEvent,
    ActivityEventDeliveryMode,
    ActivityModel,
    ActivityOutgoingEvent,
    ActivityTouchData,
    BookModel,
    IActivity,
} from '@together/common';

type ReadActivityIncomingEvent =
    | { type: 'OPEN_BOOK'; book: BookModel }
    | { type: 'CLOSE_BOOK' }
    | { type: 'CHANGE_PAGE'; page: number }
    | { type: 'SHOW_CHILD_CONTROLS'; showChildControls: boolean }
    | { type: 'TOUCH'; touch: PointerEvent }
    | { type: 'UPDATE_BOOK'; book: BookModel };

interface BookReadingTouchEvent {
    pageNumber: number;
    touchData: ActivityTouchData;
}

export class ReadTogetherActivity implements IActivity {
    /** Firestore activity ID, ignore the "ios" bit, that's legacy */
    id = 'com.bitwisesl.ios.holakid.activity.bookreading';
    initialBook: BookModel;
    event$: Subject<ActivityOutgoingEvent> = new Subject();
    incomingEvent$: Subject<ReadActivityIncomingEvent> = new Subject();

    protected subscriptions: Subscription[] = [];

    constructor(protected logger: AbstractLogger) {}

    close() {
        this.initialBook = null;
    }

    encodeBook(book: BookModel): string {
        return Base64.encode(
            JSON.stringify({
                // iOS version needs `bookId` to be able to deserialize to Book model
                bookId: book.id,
                ...book,
            }),
        );
    }

    handleEvent(event: ActivityEvent) {
        let book: BookModel;

        switch (event.name) {
            case 'kBookReadingOpenBookEvent':
                book = this.constructBookModel(event.content);
                this.logger.info(
                    'ReadTogetherActivity OpenObserveLoggerTag',
                    `Open book: ${book.title} (${book.pdfURL})`,
                );
                this.incomingEvent$.next({ type: 'OPEN_BOOK', book });
                break;

            case 'kBookReadingCloseBookEvent':
                this.logger.info('ReadTogetherActivity OpenObserveLoggerTag', 'Close book');
                this.incomingEvent$.next({ type: 'CLOSE_BOOK' });
                break;

            case 'kBookReadingChildWantsToOpenBookEvent':
                book = this.constructBookModel(event.content);
                this.logger.info(
                    'ReadTogetherActivity OpenObserveLoggerTag',
                    `Open book Suggestion: ${book.title} (${book.pdfURL})`,
                );
                this.logger.info(
                    'ReadTogetherActivity',
                    'OK. I like the suggestion. Ping back with `kBookReadingOpenBookEvent`.',
                );

                // Accept suggestion, load book and notify the remote to open it also
                this.sendLoadBook(book);
                this.incomingEvent$.next({ type: 'OPEN_BOOK', book });
                break;

            case 'kBookReadingEnableControlsEvent':
                this.logger.info('ReadTogetherActivity OpenObserveLoggerTag', `Show controls: ${event.value}`);
                this.incomingEvent$.next({
                    type: 'SHOW_CHILD_CONTROLS',
                    showChildControls: event.value === 'true',
                });
                break;

            case 'kBookReadingChangePageEvent':
                const page = +event.value + 1;
                this.logger.info('ReadTogetherActivity', `Open page: ${page}`);
                this.incomingEvent$.next({ type: 'CHANGE_PAGE', page });
                break;

            case 'kBookReadingTouchEvent':
                const data: BookReadingTouchEvent = JSON.parse(Base64.decode(event.content));
                const touch = {
                    visible: data.touchData.phase === 1,
                    position: { x: data.touchData.xPoint, y: data.touchData.yPoint },
                };

                // this.logger.info('ReadTogetherActivity', `Touch event, phase=${touch.phase} x=${touch.xPoint} y=${touch.yPoint}`);
                this.incomingEvent$.next({ type: 'TOUCH', touch });
                break;

            case 'kBookReadingBookDataChangedEvent':
                // This is sent from iOS when the user completes a purchase during the call
                // the remote end should update the book with purchase details.
                this.logger.info('ReadTogetherActivity OpenObserveLoggerTag', `Update Book Data`);
                if (event.content) {
                    let book = new BookModel();
                    book = this.constructBookModel(event.content);
                    this.incomingEvent$.next({ type: 'UPDATE_BOOK', book });
                }
                break;

            default:
                this.logger.error('ReadTogetherActivity', `Unknown event type: ${event.name}`);
                throw new Error(`Unknown event type: ${event.name}`);
        }
    }

    init(activity: ActivityModel) {}

    sendCloseBook() {
        this.event$.next({
            type: 'activity',
            payload: {
                deliveryMode: ActivityEventDeliveryMode.Guaranteed,
                originActivityId: this.id,
                name: 'kBookReadingCloseBookEvent',
                value: '',
            },
        });
    }

    sendEnableChildControls(enabled: boolean): void {
        this.logger.info('ReadTogetherActivity OpenObserveLoggerTag', `Enable child controls: ${enabled}`);
        this.event$.next({
            type: 'activity',
            payload: {
                deliveryMode: ActivityEventDeliveryMode.Guaranteed,
                originActivityId: this.id,
                name: 'kBookReadingEnableControlsEvent',
                value: enabled.toString(),
            },
        });
    }

    sendLoadBook(book: BookModel) {
        this.logger.info('ReadTogetherActivity', `Load book: ${book.title} (${book.pdfURL})`);
        this.event$.next({
            type: 'activity',
            payload: {
                deliveryMode: ActivityEventDeliveryMode.Guaranteed,
                originActivityId: this.id,
                name: 'kBookReadingOpenBookEvent',
                value: '',
                content: this.encodeBook(book),
            },
        });
    }

    sendGotoPage(page: number) {
        this.logger.info('ReadTogetherActivity', `Send go to page event. page=${page}`);
        this.event$.next({
            type: 'activity',
            payload: {
                originActivityId: this.id,
                deliveryMode: ActivityEventDeliveryMode.Guaranteed,
                name: 'kBookReadingChangePageEvent',
                value: (page - 1).toString(),
            },
        });
    }

    sendPointerEvent(page: number, pointerEvent: PointerEvent) {
        this.event$.next({
            type: 'activity',
            payload: {
                originActivityId: this.id,
                deliveryMode: ActivityEventDeliveryMode.Guaranteed,
                name: 'kBookReadingTouchEvent',
                value: '',
                content: Base64.encode(
                    JSON.stringify({
                        pageNumber: page - 1,
                        touchData: {
                            phase: pointerEvent.visible ? 1 : 3,
                            xPoint: pointerEvent.position.x,
                            yPoint: pointerEvent.position.y,
                        },
                    } as BookReadingTouchEvent),
                ),
            },
        });
    }

    sendBookSuggestion(book: BookModel) {
        this.event$.next({
            type: 'activity',
            payload: {
                deliveryMode: ActivityEventDeliveryMode.Guaranteed,
                originActivityId: this.id,
                name: 'kBookReadingChildWantsToOpenBookEvent',
                value: '',
                content: this.encodeBook(book),
            },
        });
    }

    setInitialBook(book: BookModel) {
        this.initialBook = book;
        this.incomingEvent$.next({ type: 'OPEN_BOOK', book });
    }

    constructBookModel(content) {
        const bookData: any = JSON.parse(Base64.decode(content));
        bookData.updatedDate = new Date(bookData.updatedDate);
        bookData.creationDate = new Date(bookData.creationDate);
        if (bookData.purchaseDetails) {
            if (bookData.purchaseDetails.purchaseDate) {
                bookData.purchaseDetails.purchaseDate = new Date(bookData.purchaseDetails.purchaseDate);
            }
            if (bookData.purchaseDetails.rentalStartDate) {
                bookData.purchaseDetails.rentalStartDate = new Date(bookData.purchaseDetails.rentalStartDate);
            }
        }
        let book = new BookModel();
        const props = Object.keys(bookData);
        for (const prop of props) {
            const descriptor = Object.getOwnPropertyDescriptor(bookData, prop);
            Object.defineProperty(book, prop, descriptor);
        }
        book.id = bookData.bookId;
        return book;
    }
}
