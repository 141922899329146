<div class="modal modal-confirmation">
    <div class="modal__header">
        <div class="modal__title">Unfavorite?</div>
        <div class="modal__close" (click)="close()">✘ Close</div>
    </div>
    <div class="modal__content modal-confirmation__content">
        <p class="modal-confirmation__message">Unfavorite? "{{ item.value.title }}"</p>
        <div class="modal-confirmation__buttons">
            <button class="button-v2 --wide --grey" (click)="close()">Cancel</button>
            <button class="button-v2 --wide --blue" (click)="confirm()">OK</button>
        </div>
    </div>
</div>
