import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { SpringBoardActionTypes, SpringboardService } from '@app/shared/services/springboard.service';
import { CallState } from '@app/shared/together/machines/call.machine';
import { ProfileModalComponent } from '@app/web/modals/profile-modal/profile-modal.component';
import { WebviewModalComponent } from '@app/web/modals/webview-modal/webview-modal.component';
import { environment } from '@env/environment';
import {
    ActivityModel,
    BookModel,
    GetBookByIdInteractor,
    GetPlatformInteractor,
    UserModel,
    UserService,
} from '@together/common';
import firebase from 'firebase/app';
import { SimpleModalService } from '@looorent/ngx-simple-modal';
import { Subscription } from 'rxjs';

export enum SelectionType {
    Activity = 'activity',
    Book = 'book',
}

export enum ToggleFavouriteAction {
    Favourite = 'FAVOURITE',
    UnFavourite = 'UNFAVOURITE',
}

export class SpringboardActivitySelection {
    constructor(public activity: ActivityModel) {}
}

export class SpringboardBookSelection {
    constructor(public book: BookModel) {}
}

export class SpringboardShowAllBooks {
    constructor() {}
}

export class SpringboardShowAllGames {
    constructor() {}
}

export class SpringboardShowAllLearningActivities {
    constructor() {}
}

export type SpringboardSelection =
    | SpringboardActivitySelection
    | SpringboardBookSelection
    | SpringboardShowAllBooks
    | SpringboardShowAllGames
    | SpringboardShowAllLearningActivities;

export interface ToggleFavoriteBook {
    type: 'book';
    value: BookModel;
    action: ToggleFavouriteAction;
}

export interface TogglefavoriteGame {
    type: 'game';
    value: ActivityModel;
    action: ToggleFavouriteAction;
}

export interface ToggleFavoriteActivity {
    type: 'learn';
    value: ActivityModel;
    action: ToggleFavouriteAction;
}

export interface ToggleFavoriteTopic {
    type: 'topic';
    value: ActivityModel;
    action: ToggleFavouriteAction;
}

export type TogglefavoriteItem = ToggleFavoriteActivity | ToggleFavoriteBook | TogglefavoriteGame | ToggleFavoriteTopic;

export enum SpringboardSection {
    Books = 'books',
    Games = 'games',
    Activities = 'activities',
    Topics = 'topics',
}

export interface SpringboardState {
    section: SpringboardSection;
    isFiltered: boolean;
    filters: Record<string, unknown>;
}

@Component({
    selector: 'app-springboard',
    templateUrl: './springboard.component.html',
    styleUrls: ['./springboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpringboardComponent implements OnChanges, OnInit, OnDestroy {
    @Input() public state: SpringboardState;
    @Input() public callState: CallState;
    @Input() public users: UserModel[];
    @Input() public showSpringboardActions: boolean;

    @Output() public selected = new EventEmitter<SpringboardSelection>();
    @Output() public toggleFavorite = new EventEmitter<TogglefavoriteItem>();
    @Output() public stateChanged = new EventEmitter<SpringboardState>();
    @Output() public showUpgradeModal = new EventEmitter();
    @Output() public showFavorites = new EventEmitter();

    public isSubscribed = false;
    public SelectionType = SelectionType;
    public SpringboardSection = SpringboardSection;

    public isLoggedIn: boolean;
    public shouldShowAddBook: boolean;
    public upgradeBannerText: string;
    public hasUnreadInboxMessage: boolean;

    public sections = [
        {
            id: SpringboardSection.Books,
            label: 'Books',
            icon: 'book',
        },
        {
            id: SpringboardSection.Games,
            label: 'Games',
            icon: 'game',
        },
        {
            id: SpringboardSection.Activities,
            label: 'Activities',
            icon: 'trophy',
        },
    ];
    protected inboxMessageSubscription: Subscription;
    protected subs = new Subscription();

    constructor(
        protected userService: UserService,
        protected modalService: SimpleModalService,
        protected router: Router,
        @Inject('FirebaseRemoteConfig')
        private readonly remoteConfig: firebase.remoteConfig.RemoteConfig,
        private getPlatform: GetPlatformInteractor,
        private readonly cdr: ChangeDetectorRef,
        protected springboardService: SpringboardService,
        protected getBookById: GetBookByIdInteractor,
    ) {}

    public async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.hasOwnProperty('users')) {
            const user: UserModel = changes.users?.currentValue ? changes.users.currentValue[0] : null;
            if (user) {
                this.isSubscribed = user.isSubscribed();
                this.upgradeBannerText = user.getUpgradeBannerText();
                if (!this.inboxMessageSubscription) {
                    await this.getRecentInboxMessage();
                }
            }
        }
    }

    public async ngOnInit(): Promise<void> {
        this.isLoggedIn = await this.userService.isLoggedIn();
        this.shouldShowAddBook = this.showSpringboardActions;
        let canShowTopics = this.remoteConfig.getBoolean('showsTopics');
        if (canShowTopics) {
            this.sections.push({
                id: SpringboardSection.Topics,
                label: 'Topics',
                icon: 'bulb',
            });
        }
        this.subs.add(
            this.springboardService.actions$().subscribe(action => {
                switch (action.type) {
                    case SpringBoardActionTypes.SwitchSection:
                        this.showSection(action.section);
                        break;
                    default:
                        break;
                }
            }),
        );
    }

    public ngOnDestroy() {
        //Unsubscribe from inbox listener
        if (this.inboxMessageSubscription) {
            this.inboxMessageSubscription.unsubscribe();
            this.inboxMessageSubscription = null;
        }
        this.subs.unsubscribe();
    }

    public emitSelect(item: unknown): void {
        switch (this.state.section) {
            case SpringboardSection.Activities:
            case SpringboardSection.Games:
            case SpringboardSection.Topics:
                return this.selected.emit(new SpringboardActivitySelection(item as ActivityModel));

            case SpringboardSection.Books:
                return this.selected.emit(new SpringboardBookSelection(item as BookModel));
        }
    }

    public emitTogglefavorite(value: BookModel | ActivityModel): void {
        switch (this.state.section) {
            case SpringboardSection.Activities: {
                const action = this.users[0].isFavoriteActivity(value.id)
                    ? ToggleFavouriteAction.UnFavourite
                    : ToggleFavouriteAction.Favourite;
                return this.toggleFavorite.emit({ type: 'learn', value: value as ActivityModel, action: action });
            }

            case SpringboardSection.Books: {
                const action = this.users[0].isFavoriteBook(value.id)
                    ? ToggleFavouriteAction.UnFavourite
                    : ToggleFavouriteAction.Favourite;
                return this.toggleFavorite.emit({ type: 'book', value: value as BookModel, action: action });
            }

            case SpringboardSection.Games: {
                const action = this.users[0].isFavoriteActivity(value.id)
                    ? ToggleFavouriteAction.UnFavourite
                    : ToggleFavouriteAction.Favourite;
                return this.toggleFavorite.emit({ type: 'game', value: value as ActivityModel, action: action });
            }
        }
    }

    public getCurrentSection() {
        return this.sections.find(s => s.id === this.state.section);
    }

    public showSection(id: SpringboardSection): void {
        const section = this.sections.find(s => s.id === id);

        this.stateChanged.emit({
            section: id,
            isFiltered: false,
            filters: {},
        });
    }

    public hideFilters(): void {
        this.stateChanged.emit({
            ...this.state,
            isFiltered: false,
            filters: {},
        });
    }

    public filterBy(filters: Record<string, unknown>): void {
        this.stateChanged.emit({
            ...this.state,
            isFiltered: true,
            filters,
        });
    }

    public showProfileModal(): void {
        this.modalService.addModal(ProfileModalComponent);
    }

    public back() {
        this.router.navigate(['/']);
    }

    public showHelpModal(): void {
        this.modalService.addModal(WebviewModalComponent, {
            title: 'FAQ',
            url: environment.purchaseFaqsURL,
        });
    }

    public isWelcomePage(): boolean {
        return this.router.url.includes('explore');
    }

    public async getRecentInboxMessage() {
        this.inboxMessageSubscription = new Subscription();
        this.inboxMessageSubscription.add(
            this.userService.getInboxMessageNotifier$().subscribe(hasUnreadInboxMessage => {
                this.hasUnreadInboxMessage = hasUnreadInboxMessage;
                this.cdr.detectChanges();
            }),
        );
    }

    public async openInboxModal() {
        this.hasUnreadInboxMessage = false;
        this.cdr.detectChanges();
        this.springboardService.openInbox();
    }
}
