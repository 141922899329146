import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { SimpleModalComponent, SimpleModalService } from '@looorent/ngx-simple-modal';

import { ActionType, AlertModalComponent } from '@app/web/modals/alert-modal/alert-modal.component';
import { WaitModalService } from '@app/shared/services/wait-modal.service';
import {
    UserModel,
    RemoveContactInteractor,
    LogAnalyticsEventInteractor,
    AnalyticsEvent,
    InviteModel,
    InviteObserverInteractor,
    UserService,
    AcceptInviteInteractor,
    DenyInviteInteractor,
} from '@together/common';
import { InviteModalComponent } from '../invite-modal/invite-modal.component';
import { AbstractLogger } from '@mobilejazz/harmony-core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-pending-invites-modal',
    templateUrl: './pending-invites-modal.component.html',
    styleUrls: ['./pending-invites-modal.component.scss'],
})
export class PendingInvitesModalComponent extends SimpleModalComponent<void, boolean> implements OnInit, OnDestroy {
    public invites: InviteModel[] = [];
    private user: UserModel;
    private subscription: Subscription;

    constructor(
        protected inviteObserver: InviteObserverInteractor,
        protected modalService: SimpleModalService,
        protected removeContact: RemoveContactInteractor,
        protected waitService: WaitModalService,
        private logAnalyticsEvent: LogAnalyticsEventInteractor,
        private zone: NgZone,
        protected userService: UserService,
        protected acceptInvite: AcceptInviteInteractor,
        protected denyInvite: DenyInviteInteractor,
        protected logger: AbstractLogger,
    ) {
        super();
    }

    async ngOnInit() {
        this.user = await this.userService.getUser();
        this.subscription = new Subscription();
        this.subscription.add(
            this.inviteObserver.execute(this.user).subscribe(invite => {
                this.zone.run(() => {
                    if (invite !== 'REMOVED') {
                        this.invites = [invite as InviteModel, ...this.invites];
                    }
                });
            }),
        );
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    async accept(invite) {
        this.waitService.show();
        try {
            await this.acceptInvite.execute(invite);
            this.invites = this.invites.filter(i => i.id !== invite.id);
            this.modalService.addModal(AlertModalComponent, {
                title: 'Success!',
                message: `Great! Now you are connected with ${invite.creator.displayName}.`,
                type: 'success',
                icon: 'checked-round',
            });
        } catch (err) {
            this.logger.error('AcceptInviteModal', err);
            const message = err ? err.message : `There was an error!`;
            this.modalService.addModal(AlertModalComponent, {
                title: 'Oops!',
                message: message,
                type: 'error',
                icon: 'error',
            });
        } finally {
            this.waitService.hide();
        }
    }

    deny(invite) {
        this.modalService
            .addModal(AlertModalComponent, {
                type: 'error',
                icon: 'question',
                title: `Are you sure?`,
                message: `Declining this request will permanently remove it. This cannot be undone. <br><br>No one will be notified that you declined the request.`,
                primaryActionLabel: 'Decline Request',
                secondaryActionLabel: 'Cancel',
            })
            .subscribe(async action => {
                if (action === ActionType.Primary) {
                    this.waitService.show();
                    try {
                        await this.denyInvite.execute(invite, this.user);
                        this.invites = this.invites.filter(i => i.id !== invite.id);
                    } catch (err) {
                        this.logger.error('AcceptInviteModal', err);
                        this.modalService.addModal(AlertModalComponent, {
                            title: 'Oops!',
                            message: `There was an error!`,
                            type: 'error',
                            icon: 'error',
                        });
                    } finally {
                        this.waitService.hide();
                    }
                }
            });
    }

    async acceptAll() {
        this.waitService.show();
        try {
            for (let i = 0; i < this.invites.length; i++) {
                await this.acceptInvite.execute(this.invites[i]);
            }
            this.closeModal();
            this.modalService.addModal(AlertModalComponent, {
                title: 'Success!',
                message: `Great! Now you are connected with all your inviters.`,
                type: 'success',
                icon: 'checked-round',
            });
        } catch (err) {
            this.logger.error('AcceptInviteModal', err);
            const message = err ? err.message : `There was an error!`;
            this.modalService.addModal(AlertModalComponent, {
                title: 'Oops!',
                message: message,
                type: 'error',
                icon: 'error',
            });
        } finally {
            this.waitService.hide();
        }
    }

    closeModal() {
        this.result = false;
        this.close();
    }
}
