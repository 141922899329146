import { Interpreter, State, Machine } from 'xstate';

export interface IframeActivityStateSchema {
    states: {
        loading: {
            states: {
                local: {
                    states: {
                        loading: {};
                        done: {};
                    };
                };
                remote: {
                    states: {
                        loading: {};
                        done: {};
                    };
                };
            };
        };
        running: {};
    };
}

export type IframeActivityEvent =
    | { type: 'LOCAL_READY' }
    | { type: 'REMOTE_READY' }
;

// tslint:disable-next-line:no-empty-interface
export interface IframeActivityContext {}

export type IframeActivityState = State<IframeActivityContext, IframeActivityEvent>;
export type IframeActivityInterpreter = Interpreter<IframeActivityContext, IframeActivityStateSchema, IframeActivityEvent>;

export const IframeActivityMachine = Machine<IframeActivityContext, IframeActivityStateSchema, IframeActivityEvent>({
    id: 'iframe-activity',
    initial: 'loading',
    states: {
        loading: {
            type: 'parallel',
            onDone: {
                target: 'running',
            },
            states: {
                local: {
                    initial: 'loading',
                    states: {
                        loading: {
                            on: {
                                LOCAL_READY: 'done',
                            },
                        },
                        done: {
                            type: 'final',
                        },
                    },
                },
                remote: {
                    initial: 'loading',
                    states: {
                        loading: {
                            on: {
                                REMOTE_READY: 'done',
                            },
                        },
                        done: {
                            type: 'final',
                        },
                    },
                },
            },
        },
        running: {},
    },
});
