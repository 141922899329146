import { CallDataTrackMonitor } from '../call-data-track-monitor';
import { Machine, sendParent, StateNodeConfig, assign, State, Interpreter, send } from 'xstate';
import { ActivityHub } from '../activity-hub.class';
import { ReadTogetherActivity } from '../activities/read-together.class';
import { IframeActivity } from '../activities/iframe.class';
import {
    ActivityModel,
    BookModel,
    CallConfigurationModel,
    ActivityEvent,
    FaceFilterModel,
    PhotoboothImageModel,
    UserModel,
} from '@together/common';
import { IVideoStream } from '../video-stream.interface';
import { Photobooth } from '../photobooth.class';

export interface CallStateSchema {
    states: {
        waiting_dom: {};
        connecting: {};
        done: {};
        connected: {
            states: {
                local: {
                    states: {
                        lastState: {};
                        video: {};
                        springboard: {
                            states: {
                                home: {};
                                game: {};
                                learn: {};
                            };
                        };
                        activity: {
                            states: {
                                lastState: {};
                                iframe: {};
                                read: {};
                            };
                        };
                        photobooth: {
                            states: {
                                loading: {};
                                local: {};
                                remote: {};
                                photo: {
                                    states: {
                                        timer: {
                                            states: {
                                                1: {};
                                                2: {};
                                                3: {};
                                                4: {};
                                                5: {};
                                            };
                                        };
                                        preview: {};
                                    };
                                };
                            };
                        };
                    };
                };
                menu: {
                    states: {
                        closed: {};
                        open: {
                            states: {
                                child_guard: {};
                                options: {};
                            };
                        };
                    };
                };
                microphone: {
                    states: {
                        on: {};
                        off: {};
                    };
                };
                video: {
                    states: {
                        on: {};
                        off: {};
                        unpublished: {};
                    };
                };
                remote: {
                    states: {
                        connecting: {};
                        connected: {
                            states: {
                                video: {
                                    states: {
                                        on: {};
                                        off: {};
                                    };
                                };
                                microphone: {
                                    states: {
                                        on: {};
                                        off: {};
                                    };
                                };
                            };
                        };
                        unpublished: {};
                    };
                };
                animate_local: {
                    states: {
                        on: {};
                        off: {};
                    };
                };
                animate_remote: {
                    states: {
                        on: {};
                        off: {};
                    };
                };
                animate_play: {
                    states: {
                        on: {};
                        off: {};
                    };
                };
                animate_photobooth: {
                    states: {
                        on: {};
                        off: {};
                    };
                };
                not_your_turn: {
                    states: {
                        on: {};
                        off: {};
                    };
                };
                requesting_play_time: {
                    states: {
                        on: {};
                        off: {};
                    };
                };
                requesting_photobooth: {
                    states: {
                        on: {};
                        off: {};
                    };
                };
            };
        };
    };
}

export interface VideoStreamDOMEls {
    local: HTMLDivElement;
    remote: HTMLDivElement;
    deeparCanvas: HTMLCanvasElement;
}

export interface IframeActivityDOMEls {
    iframe: HTMLIFrameElement;
}

export interface ActivityLaunchIframeEvent {
    type: 'ACTIVITY_LAUNCH_IFRAME';
    activity?: ActivityModel;
}
export interface ActivityLaunchReadEvent {
    type: 'ACTIVITY_LAUNCH_READ';
    book?: BookModel;
}

export interface UpdateCallConfigurationEvent {
    type: 'UPDATE_CALL_CONFIGURATION';
    configuration: CallConfigurationModel;
}

export interface ProcessHeartBeatEvent {
    type: 'PROCESS_HEARTBEAT_EVENT';
    eventData: ActivityEvent;
}

export interface ApplyFaceFilterRequestEvent {
    type: 'APPLY_FACE_FILTER_REQUEST';
    filter: FaceFilterModel;
}

export interface ApplyFaceFilterResponseEvent {
    type: 'APPLY_FACE_FILTER_RESPONSE';
    filter: FaceFilterModel;
}

export interface FlipFaceFiltersEvent {
    type: 'FLIP_FACE_FILTERS';
    notifyRemote: boolean;
}

export interface AccountUpgradedEvent {
    type: 'ACCOUNT_UPGRADED';
    value?: UserModel;
}

export type CallEvent =
    | { type: 'ACTIVITY_CLOSE' }
    | ActivityLaunchIframeEvent
    | ActivityLaunchReadEvent
    | { type: 'ACTIVITY_LIST' }
    | { type: 'ACTIVITY_STARTED_IFRAME' }
    | { type: 'ACTIVITY_STARTED_READ' }
    | { type: 'ANIMATE_LOCAL' }
    | { type: 'ANIMATE_PLAY' }
    | { type: 'ANIMATE_REMOTE' }
    | { type: 'BACK' }
    | { type: 'CONNECT' }
    | { type: 'DISCONNECT' }
    | { type: 'DONE' }
    | { type: 'ERROR' }
    | { type: 'MENU_TOGGLE' }
    | { type: 'MENU_UNBLOCK' }
    | { type: 'NEXT_VIDEO_INPUT' }
    | { type: 'PERMISSIONS_DENIED' }
    | { type: 'START_CAMERA_FAILURE' }
    | { type: 'POINT_SCORED'; activityCapture?: Blob }
    | ProcessHeartBeatEvent
    | { type: 'READ_CLOSE_BOOK' }
    | { type: 'READ_OPEN_BOOK' }
    | { type: 'REMOTE_CONNECTED' }
    | { type: 'REMOTE_DISCONNECTED' }
    | { type: 'REMOTE_DROPPED' }
    | { type: 'REMOTE_RECONNECTING' }
    | { type: 'REQUEST_PLAY_TIME' }
    | { type: 'SHOW_GAMES' }
    | { type: 'SHOW_LEARN' }
    | { type: 'SHOW_NOT_YOUR_TURN' }
    | { type: 'TOGGLE_ALLOW_CHILD_ACTIVITIES'; toggleSpringboard?: boolean }
    | { type: 'TOGGLE_MICROPHONE'; value: string }
    | { type: 'TOGGLE_VIDEO'; value: string }
    | { type: 'SWITCH_CAMERA' }
    | { type: 'LOCAL_TRACK_UNPUBLISHED' }
    | { type: 'LOCAL_TRACK_PUBLISHED' }
    | { type: 'REMOTE_TRACK_UNPUBLISHED' }
    | { type: 'REMOTE_TRACK_PUBLISHED' }
    | { type: 'SHOW_PHOTOBOOTH'; notifyRemote?: boolean }
    | { type: 'REQUEST_PHOTOBOOTH' }
    | { type: 'ANIMATE_PHOTOBOOTH' }
    | { type: 'PHOTOBOOTH_READY' }
    | { type: 'BACK'; notifyRemote?: boolean }
    | { type: 'TAKE_PHOTO'; notifyRemote?: boolean }
    | { type: 'CREATE_PHOTOBOOTH_IMAGE' }
    | { type: 'SHOW_PHOTOBOOTH_PREVIEW' }
    | { type: 'SHOW_PHOTOBOOTH_GALLERY' }
    | { type: 'INCREMENT_PHOTOBOOTH_TIMER' }
    | ApplyFaceFilterRequestEvent
    | ApplyFaceFilterResponseEvent
    | UpdateCallConfigurationEvent
    | FlipFaceFiltersEvent
    | { type: 'TOGGLE_REMOTE_VIDEO_ON' }
    | { type: 'TOGGLE_REMOTE_VIDEO_OFF' }
    | { type: 'TOGGLE_REMOTE_MICROPHONE_ON' }
    | { type: 'TOGGLE_REMOTE_MICROPHONE_OFF' }
    | { type: 'REFRESH_REMOTE_USER' }
    | AccountUpgradedEvent;

export interface CallContext {
    callConfiguration: CallConfigurationModel;
    hub: ActivityHub;
    iframeActivity: IframeActivity;
    isAdmin: boolean;
    readActivity: ReadTogetherActivity;
    stream: IVideoStream;
    videoInputs: [];
    dataTrackMonitor: CallDataTrackMonitor;
    photobooth: Photobooth;
    canUseVonageAndPhotoBooth: boolean;
}

export type CallState = State<CallContext, CallEvent>;
export type CallInterpreter = Interpreter<CallContext, CallStateSchema, CallEvent>;

export const CallMachine = Machine<CallContext, CallStateSchema, CallEvent>(
    {
        id: 'call',
        initial: 'waiting_dom',
        context: {
            callConfiguration: null,
            hub: null,
            iframeActivity: null,
            isAdmin: false,
            readActivity: null,
            stream: null,
            videoInputs: [],
            dataTrackMonitor: null,
            photobooth: null,
            canUseVonageAndPhotoBooth: false,
        },
        states: {
            waiting_dom: {
                invoke: {
                    src: 'waitDOM',
                    onDone: {
                        target: 'connecting',
                        actions: assign((_, event) => {
                            return {
                                hub: (event.data || {}).hub,
                                iframeActivity: (event.data || {}).iframeActivity,
                                readActivity: (event.data || {}).readActivity,
                                stream: (event.data || {}).stream,
                                dataTrackMonitor: (event.data || {}).dataTrackMonitor,
                                photobooth: (event.data || {}).photobooth,
                            };
                        }),
                    },
                    onError: {
                        target: 'done',
                        actions: sendParent('ISSUE'),
                    },
                },
            },
            connecting: {
                invoke: {
                    src: 'connect',
                },
                on: {
                    DISCONNECT: 'done',
                    DONE: {
                        target: 'connected',
                        actions: sendParent('CALL.CONNECTED'),
                    },
                    ERROR: {
                        target: 'done',
                        actions: sendParent('ISSUE'),
                    },
                    PERMISSIONS_DENIED: {
                        target: 'done',
                        actions: sendParent('PERMISSIONS_DENIED'),
                    },
                    START_CAMERA_FAILURE: {
                        target: 'done',
                        actions: sendParent('START_CAMERA_FAILURE'),
                    },
                },
            },
            done: {
                type: 'final',
                entry: 'disconnect',
            },
            connected: {
                type: 'parallel',
                invoke: [{ src: 'activityHubEvent$' }, { src: 'streamEvent$' }],
                entry: 'sendCallConfiguration',
                on: {
                    DISCONNECT: {
                        target: 'done',
                        actions: ['stopMonitoring', 'uploadLatencyData', 'shutdownDeepAR'],
                    },
                    REMOTE_DROPPED: {
                        target: 'done',
                        actions: [
                            sendParent('CALL.REMOTE_DROPPED'),
                            'stopMonitoring',
                            'uploadLatencyData',
                            'shutdownDeepAR',
                        ],
                    },
                    REMOTE_DISCONNECTED: {
                        target: 'done',
                        actions: [
                            sendParent('CALL.DISCONNECTED'),
                            'stopMonitoring',
                            'uploadLatencyData',
                            'shutdownDeepAR',
                        ],
                    },
                },
                states: {
                    local: {
                        initial: 'lastState',
                        states: {
                            lastState: {
                                type: 'history',
                                target: 'video',
                            } as StateNodeConfig<CallContext, {}, CallEvent>, // TODO: REMOVE TYPING, FIX?
                            video: {
                                entry: 'notifySpringboardClose',
                                on: {
                                    ACTIVITY_STARTED_IFRAME: 'activity.iframe',
                                    ACTIVITY_STARTED_READ: 'activity.read',
                                    ACTIVITY_LIST: 'springboard',
                                    SHOW_PHOTOBOOTH: 'photobooth',
                                },
                            },
                            springboard: {
                                initial: 'home',
                                entry: 'notifySpringboardOpen',
                                on: {
                                    BACK: 'video',
                                    ACTIVITY_LAUNCH_IFRAME: {
                                        target: 'activity.iframe',
                                        actions: 'launchActivityIframe',
                                    },
                                    ACTIVITY_LAUNCH_READ: {
                                        target: 'activity.read',
                                        actions: 'launchActivityRead',
                                    },
                                    ACTIVITY_STARTED_IFRAME: 'activity.iframe',
                                    ACTIVITY_STARTED_READ: 'activity.read',
                                },
                                states: {
                                    home: {
                                        on: {
                                            SHOW_GAMES: 'game',
                                            SHOW_LEARN: 'learn',
                                        },
                                    },
                                    game: {
                                        on: {
                                            BACK: 'home',
                                        },
                                    },
                                    learn: {
                                        on: {
                                            BACK: 'home',
                                        },
                                    },
                                },
                            },
                            activity: {
                                exit: ['closeActivity', 'notifySpringboardClose'],
                                on: {
                                    ACTIVITY_CLOSE: 'springboard',
                                    POINT_SCORED: {
                                        target: 'lastState',
                                        actions: send(
                                            (context, event) => {
                                                return {
                                                    type: 'CALL.POINT_SCORED',
                                                    activityCapture: event.activityCapture || '',
                                                    localCapture: context.stream.getLocalCapture(),
                                                    remoteCapture: context.stream.getRemoteCapture(),
                                                };
                                            },
                                            {
                                                to: '#_parent', // Send to parent
                                            },
                                        ),
                                    },
                                },
                                states: {
                                    lastState: {
                                        type: 'history',
                                    },
                                    read: {},
                                    iframe: {},
                                },
                            },
                            photobooth: {
                                initial: 'loading',
                                entry: ['initialisePhotobooth'],
                                on: {
                                    BACK: {
                                        target: '#call.connected.local.video',
                                        actions: ['notifyPhotoboothClose'],
                                    },
                                },
                                states: {
                                    loading: {
                                        on: {
                                            PHOTOBOOTH_READY: {
                                                target: 'local',
                                            },
                                        },
                                    },
                                    local: {
                                        on: {
                                            FLIP_FACE_FILTERS: {
                                                target: 'remote',
                                                actions: ['notifyFlipFaceFilters'],
                                            },
                                            TAKE_PHOTO: {
                                                target: 'photo',
                                                actions: ['notifyTakePhotoBoothPhoto'],
                                            },
                                            SHOW_PHOTOBOOTH_GALLERY: {
                                                actions: ['showPhotoboothGallery'],
                                            },
                                        },
                                    },
                                    remote: {
                                        on: {
                                            FLIP_FACE_FILTERS: {
                                                target: 'local',
                                                actions: ['notifyFlipFaceFilters'],
                                            },
                                            TAKE_PHOTO: {
                                                target: 'photo',
                                                actions: ['notifyTakePhotoBoothPhoto'],
                                            },
                                            SHOW_PHOTOBOOTH_GALLERY: {
                                                actions: ['showPhotoboothGallery'],
                                            },
                                            APPLY_FACE_FILTER_REQUEST: {
                                                actions: ['notifyApplyFilterRequest'],
                                            },
                                        },
                                    },
                                    photo: {
                                        initial: 'timer',
                                        states: {
                                            timer: {
                                                entry: ['startTakePhotoTimer'],
                                                initial: 1,
                                                states: {
                                                    1: {
                                                        on: {
                                                            INCREMENT_PHOTOBOOTH_TIMER: {
                                                                target: '2',
                                                            },
                                                        },
                                                    },
                                                    2: {
                                                        on: {
                                                            INCREMENT_PHOTOBOOTH_TIMER: {
                                                                target: '3',
                                                            },
                                                        },
                                                    },
                                                    3: {
                                                        on: {
                                                            INCREMENT_PHOTOBOOTH_TIMER: {
                                                                target: '4',
                                                            },
                                                        },
                                                    },
                                                    4: {
                                                        on: {
                                                            INCREMENT_PHOTOBOOTH_TIMER: {
                                                                target: '5',
                                                            },
                                                        },
                                                    },
                                                    5: {
                                                        on: {
                                                            SHOW_PHOTOBOOTH_PREVIEW: {
                                                                target: '#call.connected.local.photobooth.photo.preview',
                                                            },
                                                        },
                                                    },
                                                },
                                            },
                                            preview: {
                                                on: {
                                                    SHOW_PHOTOBOOTH_GALLERY: [
                                                        {
                                                            target: '#call.connected.local.photobooth.local',
                                                            cond: context =>
                                                                context.photobooth?.photoboothMode === 'local',
                                                        },
                                                        {
                                                            target: '#call.connected.local.photobooth.remote',
                                                            cond: context =>
                                                                context.photobooth?.photoboothMode === 'remote',
                                                        },
                                                    ],
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                        },
                        on: {
                            NEXT_VIDEO_INPUT: {
                                target: '#call.connected.local.lastState',
                                actions: 'nextVideoInput',
                            },
                            TOGGLE_ALLOW_CHILD_ACTIVITIES: {
                                target: '#call.connected.local.lastState',
                                actions: 'toggleAllowChildActivities',
                            },
                            UPDATE_CALL_CONFIGURATION: {
                                target: '#call.connected.local.lastState',
                                actions: 'updateCallConfiguration',
                            },
                            PROCESS_HEARTBEAT_EVENT: {
                                target: '#call.connected.local.lastState',
                                actions: 'processHeartbeatEvent',
                            },
                            REFRESH_REMOTE_USER: {
                                target: '#call.connected.local.lastState',
                                actions: 'refreshRemoteUser',
                            },
                            ACCOUNT_UPGRADED: {
                                actions: ['notifyAccountUpgradedEvent'],
                            },
                        },
                    },
                    menu: {
                        initial: 'closed',
                        states: {
                            closed: {
                                on: {
                                    MENU_TOGGLE: [
                                        { target: 'open.options', cond: 'isAdmin' },
                                        { target: 'open.child_guard' },
                                    ],
                                },
                            },
                            open: {
                                on: {
                                    MENU_TOGGLE: 'closed',
                                },
                                states: {
                                    options: {},
                                    child_guard: {
                                        // invoke: { src: 'unblock$' },
                                        on: {
                                            MENU_UNBLOCK: 'options',
                                        },
                                    },
                                },
                            },
                        },
                    },
                    microphone: {
                        initial: 'on',
                        states: {
                            on: {
                                entry: 'enableMicrophone',
                                on: {
                                    TOGGLE_MICROPHONE: {
                                        target: 'off',
                                        actions: ['notifyToggleMicrophone'],
                                    },
                                },
                            },
                            off: {
                                entry: 'disableMicrophone',
                                on: {
                                    TOGGLE_MICROPHONE: {
                                        target: 'on',
                                        actions: ['notifyToggleMicrophone'],
                                    },
                                },
                            },
                        },
                    },
                    video: {
                        initial: 'on',
                        states: {
                            on: {
                                //entry: 'enableVideo',
                                on: {
                                    TOGGLE_VIDEO: {
                                        target: 'off',
                                        actions: ['disableVideo', 'notifyToggleVideo'],
                                    },
                                    SWITCH_CAMERA: {
                                        actions: 'switchCamera',
                                    },
                                    LOCAL_TRACK_UNPUBLISHED: 'unpublished',
                                },
                            },
                            off: {
                                //entry: 'disableVideo',
                                on: {
                                    TOGGLE_VIDEO: {
                                        target: 'on',
                                        actions: ['enableVideo', 'notifyToggleVideo'],
                                    },
                                },
                            },
                            unpublished: {
                                on: {
                                    LOCAL_TRACK_PUBLISHED: 'on',
                                },
                            },
                        },
                    },
                    remote: {
                        initial: 'connecting',
                        states: {
                            connecting: {
                                on: {
                                    REMOTE_CONNECTED: 'connected',
                                },
                            },
                            connected: {
                                type: 'parallel',
                                entry: 'startMonitoring',
                                on: {
                                    REMOTE_RECONNECTING: 'connecting',
                                    REMOTE_TRACK_UNPUBLISHED: 'unpublished',
                                },
                                states: {
                                    video: {
                                        initial: 'on',
                                        states: {
                                            on: {
                                                on: {
                                                    TOGGLE_REMOTE_VIDEO_OFF: {
                                                        target: 'off',
                                                    },
                                                },
                                            },
                                            off: {
                                                on: {
                                                    TOGGLE_REMOTE_VIDEO_ON: {
                                                        target: 'on',
                                                    },
                                                },
                                            },
                                        },
                                    },
                                    microphone: {
                                        initial: 'on',
                                        states: {
                                            on: {
                                                on: {
                                                    TOGGLE_REMOTE_MICROPHONE_OFF: {
                                                        target: 'off',
                                                    },
                                                },
                                            },
                                            off: {
                                                on: {
                                                    TOGGLE_REMOTE_MICROPHONE_ON: {
                                                        target: 'on',
                                                    },
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                            unpublished: {
                                on: {
                                    REMOTE_TRACK_PUBLISHED: 'connected',
                                },
                            },
                        },
                    },
                    animate_local: {
                        initial: 'off',
                        states: {
                            off: {
                                on: {
                                    ANIMATE_LOCAL: 'on',
                                },
                            },
                            on: {
                                after: {
                                    ANIMATE_DURATION: 'off',
                                },
                            },
                        },
                    },
                    animate_remote: {
                        initial: 'off',
                        states: {
                            off: {
                                on: {
                                    ANIMATE_REMOTE: 'on',
                                },
                            },
                            on: {
                                after: {
                                    ANIMATE_DURATION: 'off',
                                },
                            },
                        },
                    },
                    animate_play: {
                        initial: 'off',
                        states: {
                            off: {
                                on: {
                                    ANIMATE_PLAY: 'on',
                                },
                            },
                            on: {
                                after: {
                                    ANIMATE_DURATION: 'off',
                                },
                            },
                        },
                    },
                    animate_photobooth: {
                        initial: 'off',
                        states: {
                            off: {
                                on: {
                                    ANIMATE_PHOTOBOOTH: 'on',
                                },
                            },
                            on: {
                                after: {
                                    ANIMATE_DURATION: 'off',
                                },
                            },
                        },
                    },
                    not_your_turn: {
                        initial: 'off',
                        states: {
                            off: {
                                on: {
                                    SHOW_NOT_YOUR_TURN: 'on',
                                },
                            },
                            on: {
                                after: {
                                    NOT_YOUR_TURN_DURATION: 'off',
                                },
                            },
                        },
                    },
                    requesting_play_time: {
                        initial: 'off',
                        states: {
                            off: {
                                on: {
                                    REQUEST_PLAY_TIME: {
                                        target: 'on',
                                        actions: 'notifyRequestingPlayTime',
                                    },
                                },
                            },
                            on: {
                                after: {
                                    REQUESTING_PLAY_TIME_DURATION: 'off',
                                },
                            },
                        },
                    },
                    requesting_photobooth: {
                        initial: 'off',
                        states: {
                            off: {
                                on: {
                                    REQUEST_PHOTOBOOTH: {
                                        target: 'on',
                                        actions: 'notifyRequestingPhotobooth',
                                    },
                                },
                            },
                            on: {
                                after: {
                                    REQUESTING_PHOTOBOOTH_DURATION: 'off',
                                },
                            },
                        },
                    },
                },
            },
        },
    },
    {
        delays: {
            ANIMATE_DURATION: 1500,
            NOT_YOUR_TURN_DURATION: 1000,
            REQUESTING_PLAY_TIME_DURATION: 1500,
            REQUESTING_PHOTOBOOTH_DURATION: 1500,
        },
        guards: {
            isAdmin: context => context.isAdmin,
        },
    },
);
