import { Component, OnInit, Output, EventEmitter, AfterViewInit, ElementRef, ViewChild, Input } from '@angular/core';
import { ActivityOutgoingEvent, ActivityEvent } from '@together/common';

@Component({
    selector: 'app-iframe-viewer',
    templateUrl: './iframe-viewer.component.html',
    styleUrls: ['./iframe-viewer.component.scss'],
})
export class IframeViewerComponent implements OnInit, AfterViewInit {
    @Input() mode: number;
    @Input() activityUrl: string;
    @Output() activityEvent = new EventEmitter<ActivityOutgoingEvent>();

    @ViewChild('iframe') iframe: ElementRef;

    constructor() {}
    ngOnInit() {}

    ngAfterViewInit() {
        const childWindow = this.iframe.nativeElement.contentWindow;

        window.addEventListener('message', message => {
            if (message.source === childWindow) {
                this.activityEvent.emit(message.data);
            }
        });

        // Load URL
        const params = {
            callType: 'iframe',
            mode: this.mode,
            localUserId: '',
            localDisplayName: this.mode == 0 ? 'Adult' : 'Child',
            localAvatar: '',
            remoteUserId: '',
            remoteDisplayName: this.mode == 0 ? 'Child' : 'Adult',
            remoteAvatar: '',
        };

        const getParams = Object.entries(params)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url =
            this.activityUrl ||
            'https://together-content.s3.amazonaws.com/activities/com.togethervideoapp.activities.js.darts/game/index.html';
        const activityURL = `${url}?${getParams}`;

        this.iframe.nativeElement.src = activityURL;
    }

    handleEvent(event: ActivityEvent) {
        this.iframe.nativeElement.contentWindow.postMessage(event, '*');
    }
}
