import { SpringboardService } from '@app/shared/services/springboard.service';
import firebase from 'firebase/app';
import { AbstractLogger } from '@mobilejazz/harmony-core';
import { ReplaySubject, Observable } from 'rxjs';
import { SimpleModalService } from '@looorent/ngx-simple-modal';

import { Deferred, createDeferred } from '../../utilities';
import {
    IStoreService,
    SubscriptionState,
    SubscriptionProduct,
    ExtraTimeProduct,
    BookProduct,
    GiftProduct,
    SubscriptionTier,
    LimitedContentCohortConfig,
} from './store-service.interface';
import {
    NativeStoreConfig,
    PaymentProviderType,
    ProductPrice,
    StoreLifetimeProduct,
    StoreSubscriptionProduct,
    SubscripionPromotion,
} from '@env/types';
import { BaseStoreService, BookPurchaseTransactionType } from './base-store.service';
import { WaitModalService } from '../wait-modal.service';
import {
    GeneratePromoCodeInteractor,
    GetPlatformInteractor,
    IsAdultInteractor,
    LogAnalyticsEventInteractor,
    PromoCodeModel,
    PutUserInteractor,
    UserService,
    Platform,
    CompleteBookPurchaseInteractor,
    PurchaseModel,
    AnalyticsEvent,
    SingularEvent,
    SubscriptionPeriod,
    UserModel,
    BookModel,
} from '@together/common';
import { STORAGE_BOOK_PURCHASE_ID_KEY } from './stripe-store.service';
import { AlertModalComponent } from '@app/web/modals/alert-modal/alert-modal.component';

import { environment } from '@env/environment';
import {
    CustomerInfo,
    GoogleProductChangeInfo,
    LOG_LEVEL,
    PRODUCT_CATEGORY,
    PRORATION_MODE,
    Purchases,
    PurchasesError,
    PurchasesStoreProduct,
    PurchasesStoreTransaction,
} from '@revenuecat/purchases-capacitor';

export class NativeStoreService extends BaseStoreService implements IStoreService {
    protected subscriptionState$: ReplaySubject<SubscriptionState>;
    protected extraTimeProducts: Deferred<PurchasesStoreProduct[]>;
    protected bookSaleProducts: Deferred<PurchasesStoreProduct[]>;
    protected bookRentalProducts: Deferred<PurchasesStoreProduct[]>;
    protected lifetimeCfg: StoreLifetimeProduct;
    protected lifetimeProduct: Deferred<PurchasesStoreProduct>;
    protected giftProducts: Deferred<PurchasesStoreProduct[]>;
    protected subscriptionProducts: Deferred<PurchasesStoreProduct[]>;
    protected subscriptionProductsLimitedContentCohort: Deferred<PurchasesStoreProduct[]>;
    protected upgradeSubscriptionProducts: Deferred<PurchasesStoreProduct[]>;
    protected additionalProducts: Deferred<PurchasesStoreProduct[]>;
    protected lifetimePurchaseResult: Deferred<SubscriptionState>;
    protected extraTimePurchaseResult: Deferred<boolean>;
    protected purchaseBookResult: Deferred<boolean>;
    protected bookRentalResult: Deferred<boolean>;
    protected giftPurchaseResult: Deferred<PromoCodeModel>;
    protected subscriptionPurchaseResult: Deferred<SubscriptionState>;
    protected upgradeSubscriptionPurchaseResult: Deferred<SubscriptionState>;
    public shouldRefreshUserAfterManageSubscription = false;

    constructor(
        protected config: NativeStoreConfig,
        protected functions: firebase.functions.Functions,
        protected getPlatform: GetPlatformInteractor,
        protected generatePromoCode: GeneratePromoCodeInteractor,
        protected completeBookPurchaseInteractor: CompleteBookPurchaseInteractor,
        protected logAnalyticsEvent: LogAnalyticsEventInteractor,
        protected logger: AbstractLogger,
        protected modalService: SimpleModalService,
        protected putUser: PutUserInteractor,
        protected userService: UserService,
        protected springboard: SpringboardService,
        protected isAdult: IsAdultInteractor,
        protected waitModal: WaitModalService,
    ) {
        super(logAnalyticsEvent, modalService, getPlatform, isAdult, waitModal, userService);

        this.subscriptionState$ = new ReplaySubject();
        this.lifetimeCfg = this.config.lifetimeProducts.find(p => p.isEnabled);
        this.config.subscriptionProducts = this.config.subscriptionProducts.filter(p => p.isEnabled);
        this.config.subscriptionUpgradeProducts = this.config.subscriptionUpgradeProducts.filter(p => p.isEnabled);
    }

    async getExtraTimeProducts(): Promise<ExtraTimeProduct[]> {
        return this.extraTimeProducts.promise.then(products => {
            return this.config.consumableProducts.extraTimeProducts.map(product => {
                const storeProduct: PurchasesStoreProduct = products.find(p => p.identifier === product.id);

                return {
                    id: product.id,
                    price: storeProduct.priceString,
                    hours: product.hours,
                    storeProduct,
                };
            });
        });
    }

    async getBookRentalProducts(): Promise<BookProduct[]> {
        return this.bookRentalProducts.promise.then(products => {
            return this.config.consumableProducts.bookPurchases.bookRentalProducts.map(product => {
                const storeProduct: PurchasesStoreProduct = products.find(p => p.identifier === product.id);

                return {
                    id: product.id,
                    tierId: product.tierId,
                    price: storeProduct.priceString,
                    storeProduct,
                };
            });
        });
    }

    async getBookSaleProducts(): Promise<BookProduct[]> {
        return this.bookSaleProducts.promise.then(products => {
            return this.config.consumableProducts.bookPurchases.bookSaleProducts.map(product => {
                const storeProduct: PurchasesStoreProduct = products.find(p => p.identifier === product.id);

                return {
                    id: product.id,
                    tierId: product.tierId,
                    price: storeProduct.priceString,
                    storeProduct,
                };
            });
        });
    }

    getProvider(): PaymentProviderType {
        return PaymentProviderType.NativeStore;
    }

    getStoreConfig(): NativeStoreConfig {
        return this.config;
    }

    public async getGiftProducts(): Promise<GiftProduct[]> {
        return this.giftProducts.promise.then(products => {
            return this.config.giftProducts
                .filter(e => e.isEnabled)
                .map(entry => {
                    const storeProduct: PurchasesStoreProduct = products.find(p => p.identifier === entry.id);
                    return {
                        id: entry.id,
                        title: entry.title,
                        price: storeProduct.priceString,
                        isBestValue: entry.isBestValue,
                        isLifetime: entry.isLifetime,
                        storeProduct,
                    };
                });
        });
    }

    async getSubscriptionProducts(): Promise<SubscriptionProduct[]> {
        return Promise.all([
            this.lifetimeProduct.promise,
            this.subscriptionProducts.promise,
            this.additionalProducts.promise,
        ]).then(([lifetime, products, additionalProducts]) => {
            const monthly = this.config.subscriptionProducts.find(entry => entry.period.inMonths === 1);
            const monthlyPrice = products.find(p => p.defaultOption?.productId === monthly.id).price;
            const subscriptionProducts = this.constructSubscriptionProduct(
                products,
                this.config.subscriptionProducts,
                monthlyPrice,
                1,
                additionalProducts,
            );
            const strikethroughPriceLifetime =
                additionalProducts?.find(p => p.identifier === this.lifetimeCfg.retailPriceProductId)?.priceString ||
                null;

            const lifetimeProduct: SubscriptionProduct = {
                id: lifetime.identifier,
                price: lifetime.priceString,
                period: 'Lifetime',
                description: this.lifetimeCfg.description,
                isBestValue: this.lifetimeCfg.isBestValue,
                featuredTitle: this.lifetimeCfg.featuredTitle,
                isLifetime: true,
                storeProduct: lifetime,
                strikethroughPrice: strikethroughPriceLifetime,
            };
            return [...subscriptionProducts, lifetimeProduct];
        });
    }

    async getLimitedContentCohortConfig(): Promise<LimitedContentCohortConfig> {
        const tiers = await this.getTierBasedSubscriptionProducts();
        const limitedContentCohort: LimitedContentCohortConfig = {
            disclaimer: this.config.limitedContentCohort?.disclaimer,
            description: this.config.limitedContentCohort?.description,
            tiers,
        };
        return limitedContentCohort;
    }

    private async getTierBasedSubscriptionProducts(): Promise<SubscriptionTier[]> {
        return Promise.all([
            this.subscriptionProductsLimitedContentCohort.promise,
            this.additionalProducts.promise,
        ]).then(([storeProducts, additionalProducts]) => {
            const subscriptionTiers: SubscriptionTier[] = this.config.limitedContentCohort?.tiers.map(entry => {
                const monthly = this.config.subscriptionProducts.find(entry => entry.period.inMonths === 1);
                const monthlyPrice = storeProducts.find(p => p.defaultOption?.productId === monthly.id).price;
                const tier = {
                    tierId: entry.tierId,
                    tierName: entry.tierName,
                    tierType: entry.tierType,
                    tierDescription: entry.tierDescription,
                    contentIncluded: entry.contentIncluded,
                    isBestValue: entry.isBestValue,
                    products: this.constructSubscriptionProduct(
                        storeProducts,
                        entry.products,
                        monthlyPrice,
                        1,
                        additionalProducts,
                    ),
                };
                return tier;
            });
            return subscriptionTiers;
        });
    }

    async getUpgradeSubscriptionProducts(): Promise<SubscriptionProduct[]> {
        const user = await this.userService.getUser();
        const compareProductPeriod =
            user?.subscriptionProduct?.subscriptionPeriod === SubscriptionPeriod.SIX_MONTH ? 6 : 1;
        return Promise.all([this.upgradeSubscriptionProducts.promise, this.subscriptionProducts.promise]).then(
            ([products, regularProducts]) => {
                const compareProduct = this.config.subscriptionProducts.find(
                    entry => entry.period.inMonths === compareProductPeriod,
                );
                const compareProductPrice = regularProducts.find(
                    p => p.defaultOption?.productId === compareProduct.id,
                ).price;

                const subscriptionUpgradeProducts = this.constructSubscriptionProduct(
                    products,
                    this.config.subscriptionUpgradeProducts,
                    compareProductPrice,
                    compareProductPeriod,
                );

                return subscriptionUpgradeProducts;
            },
        );
    }

    private constructSubscriptionProduct(
        products: PurchasesStoreProduct[],
        configProducts: StoreSubscriptionProduct[],
        comparePrice: number,
        compareProductPeriod: number,
        additionalProducts?: PurchasesStoreProduct[],
    ): SubscriptionProduct[] {
        const subscriptionProducts = configProducts.map<SubscriptionProduct>(product => {
            const storeProduct: PurchasesStoreProduct = products.find(p => p.defaultOption?.productId === product.id);
            const currency = storeProduct.priceString.replace(/[\d.,]/g, '');
            const hasIntroPrice = !!storeProduct.introPrice?.price;
            const introPrice: ProductPrice = {
                amount: hasIntroPrice ? storeProduct.introPrice.price : 0,
                currency,
                currencyCode: storeProduct.currencyCode,
            };
            const regularPrice: ProductPrice = {
                amount: storeProduct.price,
                currency,
                currencyCode: storeProduct.currencyCode,
            };
            const price = hasIntroPrice ? introPrice : regularPrice;
            const strikethroughPrice = product.retailPriceProductId
                ? additionalProducts?.find(p => p.defaultOption?.productId === product.retailPriceProductId)
                      ?.priceString || null
                : null;
            return {
                id: product.id,
                price: hasIntroPrice ? storeProduct.introPrice.priceString : storeProduct.priceString,
                period: product.period.text,
                periodInMonths: product.period.inMonths,
                title: product.title
                    ? this.renderTemplate(product.title, product.period, price, regularPrice)
                    : undefined,
                featuredTitle: product.featuredTitle,
                description: product.description
                    ? this.renderTemplate(product.description, product.period, price, regularPrice)
                    : undefined,
                isBestValue: product.isBestValue ?? false,
                isLifetime: false,
                saving: `${currency}${this.calculateSaving(
                    comparePrice,
                    price.amount,
                    product.period.inMonths / compareProductPeriod,
                )}`,
                storeProduct,
                strikethroughPrice,
            } as SubscriptionProduct;
        });
        return subscriptionProducts;
    }

    getSubscriptionState$(): Observable<SubscriptionState> {
        return this.subscriptionState$.asObservable();
    }

    init(): void {
        this.logger.info('NativeStoreService', 'Init');
        //RC purchases plugin
        Purchases.setLogLevel({
            level: LOG_LEVEL.DEBUG,
        }); // Enable to get debug logs
        Purchases.configure({
            apiKey: environment.revenueCat.apiKey,
            shouldShowInAppMessagesAutomatically: true,
        });
        // Get data from the store
        this.fetchProducts();
    }

    public async loginWithProvider(user: UserModel) {
        //For Revenue Cat User should be logged in to establish user identity
        const { customerInfo, created } = await Purchases.logIn({
            appUserID: user.id,
        });
        this.logger.info('NativeStoreService OpenObserveLoggerTag', `Login With RC: ${JSON.stringify(customerInfo)}`);
        this.setUserAttributesInRevenueCat(user);
    }

    public async logoutWithProvider() {
        await Purchases.logOut();
    }

    private fetchProducts() {
        this.extraTimeProducts = createDeferred<PurchasesStoreProduct[]>();
        this.giftProducts = createDeferred<PurchasesStoreProduct[]>();
        this.lifetimeProduct = createDeferred<PurchasesStoreProduct>();
        this.subscriptionProducts = createDeferred<PurchasesStoreProduct[]>();
        this.upgradeSubscriptionProducts = createDeferred<PurchasesStoreProduct[]>();
        this.subscriptionProductsLimitedContentCohort = createDeferred<PurchasesStoreProduct[]>();
        this.additionalProducts = createDeferred<PurchasesStoreProduct[]>();
        this.bookRentalProducts = createDeferred<PurchasesStoreProduct[]>();
        this.bookSaleProducts = createDeferred<PurchasesStoreProduct[]>();
        Purchases.getProducts({
            productIdentifiers: this.config.subscriptionProducts.map(p => p.id),
            type: PRODUCT_CATEGORY.SUBSCRIPTION,
        }).then(response => {
            this.logger.info('NativeStoreService', `Subscriptions: ${JSON.stringify(response)}`);
            this.subscriptionProducts.resolve(response?.products);
        });
        Purchases.getProducts({
            productIdentifiers: this.config.subscriptionUpgradeProducts.map(p => p.id),
            type: PRODUCT_CATEGORY.SUBSCRIPTION,
        }).then(response => {
            this.logger.info('NativeStoreService', `Subscriptions Upgrades: ${JSON.stringify(response)}`);
            this.upgradeSubscriptionProducts.resolve(response?.products);
        });
        let tierProducts = [];
        this.config.limitedContentCohort?.tiers.forEach(t => {
            tierProducts = tierProducts.concat(t.products.filter(p => p.isEnabled).map(p => p.id));
        });
        Purchases.getProducts({
            productIdentifiers: tierProducts,
            type: PRODUCT_CATEGORY.SUBSCRIPTION,
        }).then(response => {
            this.logger.info('NativeStoreService', `Subscriptions: ${JSON.stringify(response)}`);
            this.subscriptionProductsLimitedContentCohort.resolve(response?.products);
        });
        Purchases.getProducts({
            productIdentifiers: [this.lifetimeCfg.id],
            type: PRODUCT_CATEGORY.NON_SUBSCRIPTION,
        }).then(response => {
            this.logger.info('NativeStoreService', `Lifetime: ${JSON.stringify(response)}`);
            this.lifetimeProduct.resolve(response?.products?.length ? response?.products[0] : null);
        });
        Purchases.getProducts({
            productIdentifiers: this.config.consumableProducts.bookPurchases.bookSaleProducts.map(p => p.id),
            type: PRODUCT_CATEGORY.NON_SUBSCRIPTION,
        }).then(response => {
            this.logger.info('NativeStoreService', `Book Sale: ${JSON.stringify(response)}`);
            this.bookSaleProducts.resolve(response?.products);
        });

        Purchases.getProducts({
            productIdentifiers: this.config.consumableProducts.bookPurchases.bookRentalProducts.map(p => p.id),
            type: PRODUCT_CATEGORY.NON_SUBSCRIPTION,
        }).then(response => {
            this.logger.info('NativeStoreService', `Book Rental: ${JSON.stringify(response)}`);
            this.bookRentalProducts.resolve(response?.products);
        });
        Purchases.getProducts({
            productIdentifiers: this.config.consumableProducts.extraTimeProducts.map(p => p.id),
            type: PRODUCT_CATEGORY.NON_SUBSCRIPTION,
        }).then(response => {
            this.logger.info('NativeStoreService', `Extra Time: ${JSON.stringify(response)}`);
            this.extraTimeProducts.resolve(response?.products);
        });

        Purchases.getProducts({
            productIdentifiers: this.config.giftProducts.map(p => p.id),
            type: PRODUCT_CATEGORY.NON_SUBSCRIPTION,
        }).then(response => {
            this.logger.info('NativeStoreService', `Gifts: ${JSON.stringify(response)}`);
            this.giftProducts.resolve(response?.products);
        });

        const additionalProductsIds = [
            ...this.config.subscriptionProducts.filter(p => !!p.retailPriceProductId).map(p => p.retailPriceProductId),
        ];
        let additionalProductsPromise = [];
        if (additionalProductsIds?.length) {
            additionalProductsPromise.push(
                Purchases.getProducts({
                    productIdentifiers: additionalProductsIds,
                    type: PRODUCT_CATEGORY.SUBSCRIPTION,
                }),
            );
        }
        if (this.lifetimeCfg.retailPriceProductId) {
            additionalProductsPromise.push(
                Purchases.getProducts({
                    productIdentifiers: [this.lifetimeCfg.retailPriceProductId],
                    type: PRODUCT_CATEGORY.NON_SUBSCRIPTION,
                }),
            );
        }
        Promise.all(additionalProductsPromise).then(response => {
            const additionalProducts = response?.length
                ? response.reduce((acc, res) => {
                      return acc.concat(res.products);
                  }, [])
                : [];
            this.logger.info('NativeStoreService', `Additional Products: ${JSON.stringify(additionalProducts)}`);
            this.additionalProducts.resolve(additionalProducts);
        });
    }

    async startBookPurchase(bookId: string, transactionType: BookPurchaseTransactionType): Promise<boolean> {
        const purchaseBook = this.functions.httpsCallable('startBookPurchase');
        return purchaseBook({
            platform: this.getPlatform.execute(),
            bookId,
            transactionType,
        })
            .then(result => {
                if (result.data) {
                    localStorage.setItem(STORAGE_BOOK_PURCHASE_ID_KEY, result.data);
                    return true;
                }
                return false;
            })
            .catch(error => {
                this.modalService.addModal(AlertModalComponent, {
                    title: 'Oops!',
                    message: error,
                    type: 'error',
                    icon: 'error',
                });
                return false;
            });
    }

    async openManageSubscription(): Promise<void> {
        const response = await Purchases.getCustomerInfo();
        if (response?.customerInfo?.managementURL) {
            this.shouldRefreshUserAfterManageSubscription = true;
            window.open(response.customerInfo.managementURL);
        }
    }

    public async purchaseGift(product: GiftProduct): Promise<PromoCodeModel> {
        this.logger.info('NativeStoreService', `Order:Init (Product=${product.id})`);
        this.giftPurchaseResult = createDeferred<PromoCodeModel>();
        Purchases.purchaseStoreProduct({
            product: product.storeProduct,
        })
            .then(async result => {
                await this.purchaseGiftResultOK('giftPurchaseResult', result);
            })
            .catch((error: PurchasesError) => {
                this.purchaseGiftResultError('giftPurchaseResult', product.id, error);
            });

        return this.giftPurchaseResult.promise;
    }

    async purchaseExtraTime(product: ExtraTimeProduct): Promise<boolean> {
        this.logger.info('NativeStoreService', `Order:Init (Product=${product.id})`);
        this.extraTimePurchaseResult = createDeferred<boolean>();
        Purchases.purchaseStoreProduct({
            product: product.storeProduct,
        })
            .then(async result => {
                await this.purchaseExtraTimeResultOK('extraTimePurchaseResult', result);
            })
            .catch((error: PurchasesError) => {
                this.purchaseExtraTimeResultError('extraTimePurchaseResult', product.id, error);
            });

        return this.extraTimePurchaseResult.promise;
    }

    async purchaseBook(product: BookProduct): Promise<boolean> {
        this.logger.info('NativeStoreService', `Order:Init (Product=${product.id})`);
        this.purchaseBookResult = createDeferred<boolean>();
        Purchases.purchaseStoreProduct({
            product: product.storeProduct,
        })
            .then(async result => {
                await this.purchaseBookResultOK('purchaseBookResult', result);
            })
            .catch((error: PurchasesError) => {
                this.purchaseBookResultError('purchaseBookResult', product.id, error);
            });

        return this.purchaseBookResult.promise;
    }

    async rentBook(product: BookProduct, book: BookModel): Promise<boolean> {
        this.logger.info('NativeStoreService', `Order:Init (Product=${product.id})`);
        this.bookRentalResult = createDeferred<boolean>();
        Purchases.purchaseStoreProduct({
            product: product.storeProduct,
        })
            .then(async result => {
                await this.rentBookResultOK('bookRentalResult', result, book);
            })
            .catch((error: PurchasesError) => {
                this.rentBookResultError('bookRentalResult', product.id, error);
            });

        return this.bookRentalResult.promise;
    }

    public async purchaseLifetime(product: SubscriptionProduct): Promise<SubscriptionState> {
        const productId = this.lifetimeCfg.id;
        this.logger.info('NativeStoreService', `Order:Init (Product=${productId})`);
        this.lifetimePurchaseResult = createDeferred<SubscriptionState>();
        Purchases.purchaseStoreProduct({
            product: product.storeProduct,
        })
            .then(async result => {
                await this.refreshSubscriptionFromRevenueCat();
                this.purchaseSubscriptionResultOK('lifetimePurchaseResult');
            })
            .catch((error: PurchasesError) => {
                this.purchaseSubscriptionResultError('lifetimePurchaseResult', error);
            });

        return this.lifetimePurchaseResult.promise;
    }

    async purchaseSubscription(product: SubscriptionProduct): Promise<SubscriptionState> {
        this.logger.info('NativeStoreService', `Order:Init (Product=${product.id})`);
        this.subscriptionPurchaseResult = createDeferred<SubscriptionState>();
        //this.store.order(product.id);
        Purchases.purchaseStoreProduct({
            product: product.storeProduct,
        })
            .then(async result => {
                await this.refreshSubscriptionFromRevenueCat();
                this.purchaseSubscriptionResultOK('subscriptionPurchaseResult');
            })
            .catch((error: PurchasesError) => {
                this.purchaseSubscriptionResultError('subscriptionPurchaseResult', error);
            });

        return this.subscriptionPurchaseResult.promise;
    }

    async purchasePromoSubscription(promoToken: string): Promise<SubscriptionState> {
        return null;
    }

    public async upgradeSubscription(product: SubscriptionProduct): Promise<SubscriptionState> {
        this.logger.info('NativeStoreService', `Order:Init (Product=${product.id})`);
        const user = await this.userService.getUser();
        this.upgradeSubscriptionPurchaseResult = createDeferred<SubscriptionState>();
        const googleProductChangeInfo: GoogleProductChangeInfo = {
            oldProductIdentifier: user.subscriptionProduct.subscriptionProductId,
            prorationMode: PRORATION_MODE.IMMEDIATE_AND_CHARGE_PRORATED_PRICE,
        };
        Purchases.purchaseStoreProduct({
            product: product.storeProduct,
            googleProductChangeInfo,
        })
            .then(async result => {
                await this.refreshSubscriptionFromRevenueCat();
                this.upgradeSubscriptionResultOK('upgradeSubscriptionPurchaseResult');
            })
            .catch((error: PurchasesError) => {
                this.upgradeSubscriptionResultError('upgradeSubscriptionPurchaseResult', error);
            });

        return this.upgradeSubscriptionPurchaseResult.promise;
    }

    protected purchaseSubscriptionResultError(
        promiseId: 'subscriptionPurchaseResult' | 'lifetimePurchaseResult',
        error?: PurchasesError,
    ): void {
        if (this[promiseId]) {
            const errorDetail = error ? JSON.stringify(error) : '';
            this.logger.error('NativeStoreService', `${errorDetail} Order:Error isSubscribed="false"`);
            this[promiseId].resolve({ isSubscribed: false, error });
            this.subscriptionState$.next({ isSubscribed: false, error });
        }
        this[promiseId] = null;
    }

    protected purchaseSubscriptionResultOK(promiseId: 'subscriptionPurchaseResult' | 'lifetimePurchaseResult'): void {
        if (this[promiseId]) {
            this.logger.info('NativeStoreService', `Order:Done isSubscribed="yes"`);
            this[promiseId].resolve({ isSubscribed: true });
            this.subscriptionState$.next({ isSubscribed: true });
        }
        this[promiseId] = null;
    }

    protected upgradeSubscriptionResultError(
        promiseId: 'upgradeSubscriptionPurchaseResult',
        error?: PurchasesError,
    ): void {
        if (this[promiseId]) {
            const errorDetail = error ? JSON.stringify(error) : '';
            this.logger.error('NativeStoreService', `${errorDetail} Order:Error isUpgraded="false"`);
            this[promiseId].resolve({ isSubscribed: false, isUpgraded: false, error });
            this.subscriptionState$.next({ isSubscribed: true, isUpgraded: false, error });
        }
        this[promiseId] = null;
    }

    protected upgradeSubscriptionResultOK(promiseId: 'upgradeSubscriptionPurchaseResult'): void {
        if (this[promiseId]) {
            this.logger.info('NativeStoreService', `Order:Done isUpgraded="yes"`);
            this[promiseId].resolve({ isSubscribed: true, isUpgraded: true });
            this.subscriptionState$.next({ isSubscribed: true, isUpgraded: true });
        }
        this[promiseId] = null;
    }

    protected async purchaseBookResultOK(
        promiseId: 'purchaseBookResult',
        result: {
            productIdentifier: string;
            customerInfo: CustomerInfo;
        },
    ): Promise<void> {
        if (this[promiseId]) {
            this.logger.info('NativeStoreService', `PurchaseBook:Success (Product=${result.productIdentifier})`);
            // Update user via API
            const purchaseId = localStorage.getItem(STORAGE_BOOK_PURCHASE_ID_KEY);
            // Delete the localStorage info
            localStorage.removeItem(STORAGE_BOOK_PURCHASE_ID_KEY);
            this.bookSaleProducts.promise.then(async products => {
                const storeProduct: PurchasesStoreProduct = products.find(
                    p => p.identifier === result.productIdentifier,
                );
                const purchaseDetail: PurchaseModel = await this.completeBookPurchaseInteractor.execute({
                    state: 'purchased',
                    purchaseId: purchaseId,
                    transactionId: this.getTransactionId(result.customerInfo, result.productIdentifier),
                    priceAmount: storeProduct.price,
                    priceCurrency: storeProduct.currencyCode,
                });
                if (purchaseDetail?.bookId) {
                    // Resolve purchase
                    this[promiseId].resolve(true);
                    this.springboard.bookPurchased(purchaseDetail, BookPurchaseTransactionType.Sale, false);
                    this.logAnalyticsEvent.execute(AnalyticsEvent.BookPurchased);
                    this.logAnalyticsEvent.execute(SingularEvent.SngPurchaseConsumables, {
                        purchase_type: 'book purchase',
                    });
                }
                this[promiseId] = null;
            });
        }
    }

    protected purchaseBookResultError(
        promiseId: 'purchaseBookResult',
        productId: string,
        error?: PurchasesError,
    ): void {
        if (this[promiseId]) {
            const errorDetail = error ? JSON.stringify(error) : '';
            this.logger.error('NativeStoreService', `PurchaseBook:Error ${errorDetail}) (Product=${productId})`);
            this[promiseId].resolve(false);
        }
        this[promiseId] = null;
    }

    protected async rentBookResultOK(
        promiseId: 'bookRentalResult',
        result: {
            productIdentifier: string;
            customerInfo: CustomerInfo;
        },
        book: BookModel,
    ): Promise<void> {
        if (this[promiseId]) {
            this.logger.info('NativeStoreService', `RentBook:Success (Product=${result.productIdentifier})`);
            // Update user via API
            const purchaseId = localStorage.getItem(STORAGE_BOOK_PURCHASE_ID_KEY);
            // Delete the localStorage info
            localStorage.removeItem(STORAGE_BOOK_PURCHASE_ID_KEY);
            const user = await this.userService.getUser();
            this.bookRentalProducts.promise.then(async products => {
                const storeProduct: PurchasesStoreProduct = products.find(
                    p => p.identifier === result.productIdentifier,
                );
                const purchaseDetail: PurchaseModel = await this.completeBookPurchaseInteractor.execute({
                    state: 'purchased',
                    purchaseId: purchaseId,
                    transactionId: this.getTransactionId(result.customerInfo, result.productIdentifier),
                    priceAmount: storeProduct.price,
                    priceCurrency: storeProduct.currencyCode,
                });
                if (purchaseDetail?.bookId) {
                    // Resolve purchase
                    this[promiseId].resolve(true);
                    this.springboard.bookPurchased(purchaseDetail, BookPurchaseTransactionType.Rent, false);
                    this.logAnalyticsEvent.execute(AnalyticsEvent.BookRented, {
                        bookName: book.title,
                        subscription_status: user.analyticsType,
                    });
                    this.logAnalyticsEvent.execute(SingularEvent.SngPurchaseConsumables, {
                        purchase_type: 'book rental',
                    });
                }
                this[promiseId] = null;
            });
        }
    }

    protected rentBookResultError(promiseId: 'bookRentalResult', productId, error?: PurchasesError): void {
        if (this[promiseId]) {
            const errorDetail = error ? JSON.stringify(error) : '';
            this.logger.error('NativeStoreService', `RentBook:Error ${errorDetail}) (Product=${productId})`);
            this[promiseId].resolve(false);
        }
        this[promiseId] = null;
    }

    protected purchaseGiftResultError(
        promiseId: 'giftPurchaseResult',
        productId: string,
        error?: PurchasesError,
    ): void {
        if (this[promiseId]) {
            const errorDetail = error ? JSON.stringify(error) : '';
            this.logger.error('NativeStoreService', `PurchaseGift:Error ${errorDetail}) (Product=${productId})`);
            this[promiseId].reject();
        }
        this[promiseId] = null;
    }

    protected async purchaseGiftResultOK(
        promiseId: 'giftPurchaseResult',
        result: {
            productIdentifier: string;
            customerInfo: CustomerInfo;
        },
    ): Promise<PromoCodeModel | void> {
        if (this[promiseId]) {
            this.logger.info('NativeStoreService', `PurchaseGift:Success (Product=${result.productIdentifier})`);
            const giftCfg = this.config.giftProducts.find(product => product.id === result.productIdentifier);
            const promoCode = await this.generatePromoCode.execute({
                duration: giftCfg.isLifetime === true ? 0 : giftCfg.durationDays,
                isLifetime: giftCfg.isLifetime,
                transactionId: this.getTransactionId(result.customerInfo, result.productIdentifier),
                transactionPlatform: Platform.Android,
            });

            // Resolve purchase with promo-code
            this[promiseId].resolve(promoCode);
            this[promiseId] = null;
        }
    }

    protected purchaseExtraTimeResultError(
        promiseId: 'extraTimePurchaseResult',
        productId: string,
        error?: PurchasesError,
    ): void {
        if (this[promiseId]) {
            const errorDetail = error ? JSON.stringify(error) : '';
            this.logger.error('NativeStoreService', `PurchaseExtraTime:Error ${errorDetail}) (Product=${productId})`);
            this[promiseId].reject(false);
        }
        this[promiseId] = null;
    }

    protected async purchaseExtraTimeResultOK(
        promiseId: 'extraTimePurchaseResult',
        result: {
            productIdentifier: string;
            customerInfo: CustomerInfo;
        },
    ): Promise<PromoCodeModel | void> {
        if (this[promiseId]) {
            this.logger.info('NativeStoreService', `PurchaseExtraTime:Success (Product=${result.productIdentifier})`);
            // Update user via API
            const purchaseExtraTime = this.functions.httpsCallable('purchaseExtraTime');
            const envProd = this.config.consumableProducts.extraTimeProducts.find(
                product => product.id === result.productIdentifier,
            );
            await purchaseExtraTime({
                platform: this.getPlatform.execute(),
                productId: result.productIdentifier,
                purchasedTime: envProd.hours * 60 * 60,
                transactionId: this.getTransactionId(result.customerInfo, result.productIdentifier),
            });
            // Reload User
            await this.userService.reload();
            // Resolve purchase
            this[promiseId].resolve(true);
            this[promiseId] = null;
        }
    }

    async syncPurchases(): Promise<void> {
        this.logger.info('NativeStoreService', `Syncing purchases from RevenueCat`);
        Purchases.syncPurchases();
        await this.refreshSubscriptionFromRevenueCat();
    }

    async restorePurchases(): Promise<void> {
        this.logger.info('NativeStoreService OpenObserveLoggerTag', `Restoring purchases from RevenueCat`);
        await Purchases.restorePurchases();
        await this.refreshSubscriptionFromRevenueCat();
    }

    protected async removeSubscription() {
        // TODO: move to an interactor?
        // Reset `subscriptionEndDate`
        const user = await this.userService.getUser();

        // Reset in memory instance
        user.subscriptionEndDate = null;
        user.subscriptionProduct = null;

        // Reset database user
        await this.putUser.execute(user, {
            subscriptionEndDate: null,
            subscriptionPlatform: null,
            subscriptionProduct: null,
        });

        this.subscriptionState$.next({ isSubscribed: false });
    }

    async getSubscriptionPromotion(): Promise<SubscripionPromotion> {
        return this.isActivePromotion(this.config.subscriptionPromotion) ? this.config.subscriptionPromotion : null;
    }

    private getTransactionId(customerInfo: CustomerInfo, productId): string {
        const transaction: PurchasesStoreTransaction = customerInfo.nonSubscriptionTransactions.find(
            t => t.productIdentifier === productId,
        );
        return transaction ? transaction.transactionIdentifier : '';
    }

    private async refreshSubscriptionFromRevenueCat() {
        const refreshSubscription = this.functions.httpsCallable('revenueCatRefreshSubscription');
        await refreshSubscription({});
        await this.userService.reload();
    }

    private setUserAttributesInRevenueCat(user) {
        const userAttributes = {
            creationDate: user.creationDate,
        };
        if (user.businessModelCohort) {
            userAttributes['businessModelCohort'] = user.businessModelCohort.name;
        }
        Purchases.setAttributes(userAttributes);
    }
}
