<div class="modal-clean modal-clean--wide modal-settings --centered">
    <div class="modal-clean__content --no-outer-padding">
        <div class="modal-clean__header">
            <div *ngIf="!isSignUpFlow" class="modal__title">{{ 'core.PROFILE' | translate }}</div>
            <div *ngIf="isSignUpFlow" class="modal__title">{{ 'Complete Your Profile' | translate }}</div>
            <div class="modal__close" (click)="close()" *ngIf="!isSignUpFlow"></div>
        </div>
        <div class="modal-settings__container">
            <form
                *ngIf="settingsForm"
                class="form"
                [formGroup]="settingsForm"
                (ngSubmit)="onSubmit()"
                autocomplete="false"
                spellcheck="false">
                <div class="modal-settings__container__form --with-padding">
                    <p>{{ 'pages.settings.ACCOUNT_TYPE' | translate }}</p>
                    <div class="user-type">
                        <div class="__type" [ngClass]="{ '--active': isAdultActive() }" (click)="setActivityMode(0)">
                            <img class="__img" src="./assets/user-adult.svg" />
                            <div class="__title">
                                {{ 'core.ADULT' | translate }}
                            </div>
                        </div>
                        <div class="__type" [ngClass]="{ '--active': isChildActive() }" (click)="setActivityMode(1)">
                            <img class="__img" src="./assets/user-child.svg" />
                            <div class="__title">
                                {{ 'core.CHILD' | translate }}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="+settingsForm.value.preferredActivityMode === 0">
                        <label>{{ 'pages.settings.DISPLAY_NAME' | translate }}:</label>
                        <input
                            type="text"
                            formControlName="displayName"
                            placeholder="Enter your name"
                            autocorrect="off"
                            autocapitalize="none"
                            autocomplete="off" />
                    </div>
                    <div *ngIf="+settingsForm.value.preferredActivityMode === 1">
                        <label>{{ 'pages.settings.ADULT_NAME' | translate }}:</label>
                        <input
                            type="text"
                            formControlName="adultDisplayName"
                            placeholder="Enter adult name"
                            autocorrect="off"
                            autocapitalize="none"
                            autocomplete="off" />

                        <label>{{ 'pages.settings.CHILD_NAME' | translate }}:</label>
                        <input
                            type="text"
                            formControlName="displayName"
                            placeholder="Enter your child’s name"
                            autocorrect="off"
                            autocapitalize="none"
                            autocomplete="off" />
                    </div>
                </div>
                <div class="modal-settings__avatar-section">
                    <label class="--with-padding">{{ 'pages.settings.PROFILE_IMAGE' | translate }}:</label>
                    <div class="modal-settings__avatars horizontal-scroll horizontal-scroll--avatars --with-padding">
                        <div
                            *ngFor="let avatar of avatars"
                            class="avatar-picture"
                            (click)="selectAvatar(avatar)"
                            [ngClass]="{ 'avatar-picture--selected': avatar == settingsForm.value.avatar }">
                            <img
                                class="avatar-picture__img"
                                [src]="'./assets/avatar/' + avatar + '.svg'"
                                alt="avatar {{ avatar }}" />
                        </div>
                    </div>
                </div>
                <div class="modal-settings__action-btn">
                    <button
                        class="button-v2 --purple --large --wide --rounded"
                        type="submit"
                        [disabled]="!settingsForm.valid || isLoading">
                        <span *ngIf="!isLoading">{{ 'core.SAVE' | translate }}</span>
                        <span *ngIf="isLoading">{{ 'core.SAVING' | translate }} ...</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
