import { Component } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { SimpleModalComponent } from '@looorent/ngx-simple-modal';

@Component({
    selector: 'app-wait-modal',
    templateUrl: './wait-modal.component.html',
    styleUrls: ['./wait-modal.component.scss'],
})
export class WaitModalComponent extends SimpleModalComponent<void, void> {
    public animation: AnimationOptions = {
        path: './assets/animations/loading.json',
    };

    constructor() {
        super();
    }
}
