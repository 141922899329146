<div class="modal-clean modal-clean--wide --centered modal-confirmation">
    <div class="modal-clean__content">
        <div class="modal-clean__header">
            <div class="modal__title">{{ title }}</div>
            <div class="modal__close" (click)="close()"></div>
        </div>
        <div class="modal-confirmation__content">
            <p class="modal-confirmation__message">{{ message }}</p>
            <div class="modal-confirmation__buttons">
                <button class="button-v2 --purple --large --rounded --wide" (click)="confirm()">{{primaryActionLabel}}</button>
                <div class="modal-confirmation__buttons__deny" (click)="close()">Cancel</div>
            </div>
        </div>
    </div>
</div>
