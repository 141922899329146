<div class="modal-clean modal-clean--wide modal-upgrade --centered">
    <div class="modal-clean__content --no-outer-padding">
        <div class="modal-clean__header">
            <div class="modal__close" (click)="close()"></div>
            <div class="modal__title">Subscribe</div>
        </div>
        <div class="modal-upgrade__discount-banner" *ngIf="!isSubscribed && subscriptionPromotion?.title">
            <div class="modal-upgrade__discount-banner__content">{{ subscriptionPromotion.title }}</div>
        </div>
        <div class="modal-upgrade__campaign-banner" *ngIf="!isSubscribed && campaignDetails?.activationCode">
            <div class="modal-upgrade__campaign-banner__close" (click)="dismissCampaignBanner()"></div>
            <div class="modal-upgrade__campaign-banner__image-box">
                <img src="{{ campaignDetails.campaignLogo }}" />
            </div>
            <div class="modal-upgrade__campaign-banner__content-box">
                <div class="modal-upgrade__campaign-banner__content-box__title">Code is active</div>
                <div class="modal-upgrade__campaign-banner__content-box__desc">
                    {{ campaignDetails.campaignText }}
                </div>
            </div>
        </div>

        <div class="modal-upgrade__container" *ngIf="!isSubscribed">
            <img class="modal-upgrade__gift-box" src="./assets/happy-panda.svg" />
            <div class="modal-upgrade__summary">
                <div class="modal-upgrade__summary__item">
                    <fa-icon [icon]="['tg', 'sticker-checked']"></fa-icon>
                    <div><span class="--highlight">Unlimited Hours</span> of video calls each month</div>
                </div>
                <div class="modal-upgrade__summary__item">
                    <fa-icon [icon]="['tg', 'sticker-checked']"></fa-icon>
                    <div><span class="--highlight">Incoming & outgoing</span> calls with contacts</div>
                </div>
                <div class="modal-upgrade__summary__item">
                    <fa-icon [icon]="['tg', 'sticker-checked']"></fa-icon>
                    <div><span class="--highlight">Access</span> to games, activities & books</div>
                </div>
            </div>
            <div class="modal-upgrade__products">
                <button
                    *ngFor="let product of products; index as i"
                    class="button-v2 --product-btn --shade-purple --large --wide --rounded"
                    [ngClass]="{ '--shade-purple': !product.isBestValue, '--purple': product.isBestValue }"
                    (click)="purchase(product.id)">
                    <span class="--product-info" *ngIf="!product.discountConfig">
                        <span class="--title" *ngIf="product.title">{{ product.title }}</span>
                        <span class="--title" *ngIf="!product.title">{{ product.period }}</span>
                        <span *ngIf="product.description" class="--sub-text">{{ product.description }}</span>
                    </span>
                    <span class="--product-info" *ngIf="product.discountConfig">
                        <span class="--title" *ngIf="product.title">{{ product.discountConfig.discountTitle }}</span>
                        <span *ngIf="product.description" class="--sub-text">{{
                            product.discountConfig.discountDescription
                        }}</span>
                    </span>
                    <span class="--product-price-box" *ngIf="product.strikethroughPrice">
                        <span class="--product-striked-price">{{ product.strikethroughPrice }}</span>
                        <span class="--product-price">{{ product.price }}</span>
                    </span>
                    <span class="--product-price" *ngIf="!product.strikethroughPrice">{{ product.price }}</span>
                    <span *ngIf="product.isBestValue" class="--product-tag">{{
                        product.featuredTitle ? product.featuredTitle : 'Most Popular'
                    }}</span>
                </button>
            </div>
            <div class="modal-upgrade__disclaimer" *ngIf="upgradeDisclaimerText !== ''">
                {{ upgradeDisclaimerText }}
            </div>
            <p class="modal-upgrade__payments-notice" *ngIf="showAndroidNotice">
                Payments are made via your Google Play Store account at confirmation of purchase and will auto-renew
                within 24hrs of expiration. You can manage this by going to Account Settings. Turn off auto-renew at
                least 24hrs before expiration to let your subscription lapse.
            </p>
            <div class="modal-upgrade__column" *ngIf="showAndroidNotice">
                <p>Are you already a subscriber?</p>
                <button class="button-v2 --light --wide --rounded" (click)="restorePurchases()">
                    Restore Purchases
                </button>
            </div>
            <div class="modal-upgrade__column">
                <p>Question or comments?</p>
                <a class="button-v2 --light --wide --rounded" href="mailto:support@togethervideoapp.com">Contact Us</a>
                <p class="modal-upgrade__thanks">❤️ Thanks for supporting the continuous development of this app!</p>
            </div>
            <div class="modal-upgrade__more-links">
                <a class="--more-info" (click)="showPrivacyModal()">Privacy Policy</a>
                <a class="" (click)="showTermsModal()">Terms of Service</a>
            </div>
        </div>
        <div class="modal-upgrade__container" *ngIf="isSubscribed">
            <img class="modal-upgrade__gift-box" src="./assets/present-box.svg" />
            <div class="modal-upgrade__column">
                <p>You're already subscribed!</p>
                <button class="button-v2 --light --wide --rounded" (click)="openManageSubscriptionModal()">
                    Manage Subscription
                </button>
            </div>
        </div>
    </div>
</div>
