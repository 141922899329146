import { getCurrencySymbol } from '@angular/common';
import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
    AnalyticsEvent,
    GetPlatformInteractor,
    LogAnalyticsEventInteractor,
    SubscriptionPeriod,
    UserModel,
    UserService,
} from '@together/common';
import { SimpleModalComponent, SimpleModalService } from '@looorent/ngx-simple-modal';
import { Subscription } from 'rxjs';
import { CancelSubscriptionModalComponent } from '@app/web/modals/cancel-subscription-modal/cancel-subscription-modal.component';
import { IStoreService, SubscriptionProduct } from '@app/shared/services/store/store-service.interface';
import { WaitModalService } from '@app/shared/services/wait-modal.service';

@Component({
    selector: 'app-manage-subscription-modal',
    templateUrl: './manage-subscription-modal.component.html',
    styleUrls: ['./manage-subscription-modal.component.scss'],
})
export class ManageSubscriptionModalComponent extends SimpleModalComponent<void, void> implements OnInit, OnDestroy {
    public isSubscribed: boolean;
    public user: UserModel;
    protected subs = new Subscription();
    public allowToChangeSubscription: boolean;
    public isSubscriptionPlatform: boolean;

    constructor(
        protected logAnalyticsEvent: LogAnalyticsEventInteractor,
        protected getPlatform: GetPlatformInteractor,
        protected modalService: SimpleModalService,
        protected router: Router,
        protected userService: UserService,
        protected zone: NgZone,
        protected waitService: WaitModalService,
        @Inject('StoreService') protected storeService: IStoreService,
    ) {
        super();
    }

    public async ngOnInit(): Promise<void> {
        this.user = await this.userService.getUser();

        this.logAnalyticsEvent.execute(AnalyticsEvent.ManageSubscriptionView);

        // Get subscription state & watch for changes
        this.isSubscribed = this.user.isSubscribed();

        this.subs.add(
            this.userService.getUser$().subscribe(user => {
                this.zone.run(() => {
                    this.user = user;
                    this.isSubscribed = this.user.isSubscribed();
                    const subscriptionPlatform = user.getSubscriptionPlatform();
                    this.isSubscriptionPlatform = this.getPlatform.execute() === subscriptionPlatform;
                    this.allowToChangeSubscription = this.isSubscriptionPlatform
                        ? user?.isChangeSubscriptionAllowed()
                        : false;
                });
            }),
        );
    }

    public ngOnDestroy() {
        this.subs.unsubscribe();
    }

    public getCurrencySymbol(currencyCode) {
        return currencyCode ? getCurrencySymbol(currencyCode, 'narrow') : '';
    }

    public goToCancel() {
        this.close();
        this.modalService.addModal(CancelSubscriptionModalComponent, null, {
            closeOnEscape: false,
            closeOnClickOutside: false,
        });
    }

    public async goToRenew() {
        this.waitService.show();
        this.logAnalyticsEvent.execute(AnalyticsEvent.ManageSubscriptionCancelRenew);
        this.close();
        await this.storeService.openManageSubscription();
        this.waitService.hide();
    }

    public async goToStore() {
        this.waitService.show();
        this.close();
        await this.storeService.openManageSubscription();
        this.waitService.hide();
    }

    public getSubscriptionPeriodText() {
        switch (this.user?.subscriptionProduct?.subscriptionPeriod) {
            case SubscriptionPeriod.YEARLY:
                return 'year';
            case SubscriptionPeriod.SIX_MONTH:
                return 'every 6 months';
            case SubscriptionPeriod.MONTHLY:
                return 'month';
            case SubscriptionPeriod.WEEKLY:
                return 'week';
            case SubscriptionPeriod.DAILY:
                return 'day';
        }
    }

    public showUpgradeModal(): void {
        this.storeService.showUpgradeModal();
    }
}
