import { State } from 'xstate';

import { CallRouterContext } from './together/machines/call-router.machine';
import { BookModel, CallMetadata, UserModel } from '@together/common';
import { environment } from '@env/environment';

export class UnreachableCaseError extends Error {
    constructor(val: any) {
        super(`Unreachable case: ${JSON.stringify(val)}`);
    }
}

function getCompactStates(states: string[]): string[] {
    return states.sort().filter(state => {
        return !states.find(s => s.includes(`${state}.`));
    });
}

export function getStateDebugStr(state: State<any, any>): string {
    return `state=${getCompactStates(state.toStrings()).join(' ')}`;
}

export function getCallMetadataDebug(metadata: CallMetadata): object {
    let localParticipant, remoteParticipant;

    if (environment.production) {
        localParticipant = metadata.localParticipant.id;
        remoteParticipant = metadata.remoteParticipant.id;
    } else {
        localParticipant = `${metadata.localParticipant.displayName} (${metadata.localParticipant.id})`;
        remoteParticipant = `${metadata.remoteParticipant.displayName} (${metadata.remoteParticipant.id})`;
    }

    return {
        localParticipant,
        remoteParticipant,
        activityMode: metadata.activityMode,
        callMode: metadata.callMode,
        callId: metadata.callId,
        callStatus: metadata.callStatus,
    };
}

export function getCallRouterContextStr(context: CallRouterContext): string {
    return JSON.stringify(
        {
            callEndTime: context.callEndTime,
            interactionCount: context.interactionCount,
            issue: context.issue && context.issue.code ? context.issue : null,
            metadata: context.metadata ? getCallMetadataDebug(context.metadata) : null,
        },
        null,
        4,
    );
}

/**
 * @typeParam T Type for the resolved promise.
 * @typeParam E Type for the error.
 */
export interface Deferred<T, E = any> {
    isResolved: boolean;
    isPending: boolean;
    isRejected: boolean;
    promise: Promise<T>;
    resolve: (value: T) => void;
    reject: (reason?: E) => void;
}

/**
 * @typeParam T Type for the resolved promise.
 * @typeParam E Type for the error.
 */
export function createDeferred<T, E = any>(): Deferred<T, E> {
    const deferred: Partial<Deferred<T, E>> = { isResolved: false, isPending: true, isRejected: false };

    deferred.promise = new Promise<T>((resolve, reject) => {
        deferred.resolve = (value: T) => {
            deferred.isResolved = true;
            deferred.isPending = false;
            resolve(value);
        };

        deferred.reject = (reason?: E) => {
            deferred.isRejected = true;
            deferred.isPending = false;
            reject(reason);
        };
    });

    return deferred as Deferred<T, E>;
}

export function b64DataToFile(b64Data: string, contentType: string, fileName?: string): File {
    const arr = b64Data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];

    const bstr = atob(arr[1]);
    let n = bstr.length;

    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    const blob = new Blob([u8arr], { type: mime });

    return new File([blob], fileName, { type: mime });
}

export function sortBooksForUser(books: BookModel[], user: UserModel): BookModel[] {
    if (!user.favoriteBooks) {
        return books;
    }

    return books.sort((bookA, bookB) => {
        const fabA = user.isFavoriteBook(bookA.id);
        const fabB = user.isFavoriteBook(bookB.id);

        // Priority: Fab > Owned > Others
        if (fabA && !fabB) {
            return -1;
        } else if (!fabA && fabB) {
            return 1;
        }

        return 0;
    });
}

/**
 * Promise based timer, waits the given amount of milliseconds
 * @param timeMs time to wait
 */
export function wait(timeMs: number): Promise<void> {
    return new Promise(resolve => {
        setTimeout(resolve, timeMs);
    });
}

/**
 * Given a promise forces the task to take a minimum time duration
 *
 * @param promise Promise to wait for
 * @param minimumDuration Minimum duration the promise takes to resolve (ms)
 */
export function waitPromise<T>(promise: Promise<T>, minimumDuration: number = 4000): Promise<T> {
    const startTime = Date.now();

    return new Promise((resolve, reject) => {
        promise.then(value => {
            const ellapsedTime = Date.now() - startTime;
            const waitTime = minimumDuration - Math.min(ellapsedTime, minimumDuration);

            // Wait until we reach `minimumDuration`
            // This makes loading spinner stay longer, and improves UX
            setTimeout(() => resolve(value), waitTime);
        }, reject);
    });
}
